import React, { useState, useEffect } from "react";
import { EditorState, RichUtils, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML, convertFromHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.css";
// import ColorPic from "./editorToolbar";

function Editors(props) {
  const {
    name,
    id,
    value,
    error,
    helperText,
    setFieldValue,
    setDescription,
    richTextdescription,
    // descrpError,
    // setDescrpError,
    readOnly
  } = props;



  const initialContentState = value ? convertFromHTML(value) : "";
  const [editorState, setEditorState] = useState(() => {
    if (initialContentState) return EditorState.createWithContent(initialContentState);
    return EditorState.createEmpty();
  });

  useEffect(() => {
    if (value) {
      const contentState = convertFromHTML(value);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
      setFieldValue(newEditorState);
      setDescription(value);
    } 
    else {
      setEditorState(EditorState.createEmpty());
      setDescription('');
      setFieldValue("");
    }
  }, [value]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const html = convertToHTML(contentState);
    setDescription(html);
  };

  // // Function to toggle inline styles (text color and background color)
  // const toggleInlineStyle = (style, value) => {
  //   const contentState = editorState.getCurrentContent();
  //   const selectionState = editorState.getSelection();
  //   const newContentState = Modifier.applyInlineStyle(
  //     contentState,
  //     selectionState,
  //     style,
  //     value
  //   );
  //   const newEditorState = EditorState.push(
  //     editorState,
  //     newContentState,
  //     "change-inline-style"
  //   );
  //   setEditorState(newEditorState);
  // };

  // const customInlineControls = [
  //   {
  //     name: "text-color",
  //     style: "COLOR",
  //     label: "Text Color",
  //     inlineStyle: { color: editorState.getCurrentInlineStyle().get("COLOR") },
  //     onClick: (style, value) => toggleInlineStyle(style, value),
  //   },
  //   {
  //     name: "background-color",
  //     style: "BACKGROUND-COLOR",
  //     label: "Background Color",
  //     inlineStyle: {
  //       backgroundColor: editorState.getCurrentInlineStyle().get("BACKGROUND-COLOR"),
  //     },
  //     onClick: (style, value) => toggleInlineStyle(style, value),
  //   },
  // ];

  return (
    <div className="App">
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        readOnly={readOnly}
        // toolbar={{
        //   options: ["inline", "blockType", "list", "textAlign", "link", "image"],
        //   inline: {
        //     inDropdown: false,
        //     className: "custom-inline-wrapper",
        //     options: customInlineControls, // Add custom inline controls for text color and background color
        //   },
        // }}
        // toolbar={{
        //   colorPicker: { component: ColorPic },
        // }}
        placeholder="Enter your description"
        id={id}
        name={name}
        hashtag={{
          separator: " ",
          trigger: "#",
        }}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: [
            { text: "JavaScript", value: "javascript", url: "js" },
            { text: "React", value: "golang", url: "go" },
          ],
        }}
      />
    </div>
  );
}

export default Editors;
