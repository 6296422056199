import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import ReusableButton from "../../../components/Custom/button/button";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { SxStyles } from "../../../styles/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Notification from "../../../components/Custom/Notification";
import { API } from "../../../Networking/API";
import { InputLabel } from "@material-ui/core";
const PptType = (props) => {
  const { data ,handleClose,getTrainingMaterial,type} = props;
  const styles = SxStyles.styles;
  const[checked,setChecked]=useState(false);
  const [updateMaterial, setUpdateMaterial] = useState(false);
  const[thumbnailtext,setThumbnailtext]=useState(false);
  const [pptdata, setPptdata] = useState({
    filename: data.filename,
    material: data.material,
    thumbnail: data.videoLink,
    duration: data.duration,
    pptLink:data.material!=="" ? "" : data.material
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  let mandateId = "";
  if (window.localStorage.getItem("UnitId")) {
    mandateId = window.localStorage.getItem("UnitId");
  } else {
    mandateId = window.localStorage.getItem("mandateId");
  }
  const uploadPPT = () => {
    if(pptdata.filename==="" || ( pptdata.material==="" && pptdata.pptLink==="" ) || pptdata.thumbnail==="" || pptdata.duration===""){
        return setNotify({
            isOpen: true,
            message: "Please Fill Mandatory Fields",
            type: "error",
          });
    }
    setChecked(true);
    API.updatePPT(
        data.id,
        pptdata.duration,
        pptdata.filename,
        type,
        mandateId,
        pptdata.thumbnail==="" ? "N/A" : pptdata.thumbnail,
        pptdata.material==="" ? pptdata.pptLink : pptdata.material,
      ).then(({ response }) => {
        if (response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: response?.data[0]?.message,
            type: "success",
          });
          getTrainingMaterial(window.localStorage.getItem("mandateId"));
          setUpdateMaterial(false);
          setTimeout(()=>{
            handleClose();
          },1000); 
        } else {
            setChecked(false);
          setNotify({
            isOpen: true,
            message: response?.error[0].error_msg,
            type: "error",
          });
        }
      });

  };
  const handlePPTChange = (event) => {
    const file = event.target.files[0];
    setPptdata({...pptdata,material:file});
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setPptdata({...pptdata,thumbnail:file});
  };
  const SheetJSFT = ["pdf,ppt,pptx,docx,doc"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  const SheetJSFTPPT = ["ppt, ppsx, pptx, pdf"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  const handleChange = (event) => {
    setPptdata({...pptdata,[event.target.name]:event.target.value});
  };
  useEffect(() => {
    if (data.material.length === 0) {
      setUpdateMaterial(true);
    }
    
  }, [updateMaterial]);

  useEffect(()=>{
if(data.videoLink.length===0){
    setThumbnailtext(true);
}
  },[thumbnailtext]);
  return (
    <div>
      <h2 style={{ color: "black" }}>Edit PPT:</h2>
      <Grid
        container
        className="training-materail"
        spacing={2}
        style={{
          padding: "10px",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Grid item xs={12} lg={4} md={3} sm={5}>
          <div style={styles.inputContainer}>
            <Typography style={styles.label} variant="h6">
              Name
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            <span>
              <ReusableInputfield
                name="filename"
                id="slideName"
                placeholder="Name"
                className="name-Training-material"
                  value={pptdata.filename}
                onChange={handleChange}
                size="small"
                style={{
                  height: "41px",
                  width: "100%",
                  marginTop: "-3px",
                }}
                // error={touched.fileName && Boolean(errors.fileName)}
                // helperText={touched.fileName && errors.fileName}
              />
            </span>
          </div>
        </Grid>

        <Grid item xs={12} lg={4} md={3} sm={5}>
          <div style={styles.inputContainer}>
            <Typography style={styles.label} variant="h6">
              PPT Link
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            <span>
              <ReusableInputfield
                name="pptLink"
                id="pptLink"
                placeholder="PPT Link"
                className="name-Training-material"
                //   value={formik.values.pptLink}
                style={{
                  height: "41px",
                  width: "100%",
                  marginTop: "-3px",
                }}
                onChange={handleChange}
                  disabled={pptdata.material!==""}

                size="small"
              />
            </span>
            <div></div>
          </div>
        </Grid>

        {/* for upload ppt */}
        <Grid item xs={12} lg={4} md={3} sm={5}>
          <div style={styles.inputContainer}>
            <Typography style={styles.label} variant="h6">
              Upload PPT
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            {updateMaterial ?  
            
       <>
            <input
              type="file"
              name="material"
              id="file"
              class="upload-box"
              accept={SheetJSFTPPT}
              style={{
                
                fontSize: "0.8em",
                fontFamily: "Poppins",
                height: "40px",
                position: "relative",
                bottom: "3px",
                width: "250px",
                marginTop: "-3px",
                opacity: pptdata.pptLink!=="" ? 0.5 :1 ,
              }}
              disabled={pptdata.pptLink!==""}
              onChange={(e) => handlePPTChange(e)}
              
            />
            </>
            :
            <div style={{ display: "flex" }}>
              <ReusableInputfield
                name="pdffile"
                id="pdffile"
                value={
                  pptdata.material.length > 19
                    ? `${pptdata.material.slice(0, 20)}...`
                    : pptdata.material
                }
                readOnly={true}
                size="small"
                style={{ height: "41px !important",padding:"13px 13px !important" }}
              />
              <IconButton
                aria-label="delete"
                style={{
                  position: "relative",
                  right: "15%",
                  paddingTop: "8px",
                }}
                onClick={() => {
                   setPptdata({...pptdata,material:""});
                  setUpdateMaterial(true);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </div>
}
          </div>
        </Grid>

        <Grid item xs={12} lg={4} md={3} sm={5}>
          <div style={styles.inputContainer}>
            <InputLabel
              className="InputLabel"
              style={styles.label}
              variant="h6"
            >
              Thumbnail
              <span style={styles.madatoryMark}>*</span>
            </InputLabel>
            {thumbnailtext ? 
            
        <>
            <input
              type="file"
              name="thumbnail"
              id="file"
              class="upload-box"
              accept={SheetJSFT}
              style={{
                fontSize: "0.8em",
                fontFamily: "Poppins",
                height: "40px",
                position: "relative",
                bottom: "3px",
                marginTop: "6px",
                width: "220px",
              }}
              onChange={(e) => handleFileChange(e)}
            />
            </>
            : 
            <div style={{ display: "flex" }}>
              <ReusableInputfield
                name="pdffile"
                id="pdffile"
                value={
                  pptdata.thumbnail.length > 19
                    ? `${pptdata.thumbnail.slice(0, 20)}...`
                    : pptdata.thumbnail
                }
                readOnly={true}
                size="small"
                style={{ height: "41px !important" }}
              />
              <IconButton
                aria-label="delete"
                style={{
                  position: "relative",
                  right: "15%",
                  paddingTop: "8px",
                }}
                onClick={() => {
                   setPptdata({...pptdata,thumbnail:""});
                //   setPptlink(false);
                  setThumbnailtext(true);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </div>
}
          </div>
        </Grid>

        <Grid item xs={12} lg={4} md={3} sm={5}>
          <div style={styles.inputContainer}>
            <Typography style={styles.label} variant="h6">
              Duration(in mins)
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            <span>
              <ReusableInputfield
                name="duration"
                id="duration"
                placeholder="Duration"
                className="name-Training-material"
                  value={pptdata.duration}
                style={{
                  height: "39px",
                  width: "100%",
                }}
                type="text"
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "10px 12px", width: "90%" },
                  },
                }}
                onChange={(e) => handleChange(e)}
                size="small"
              />
            </span>
          </div>
        </Grid>

        <Box
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            marginRight: "10px",
            marginTop:"20px"
          }}
        >
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            buttonName="Cancel"
            style={styles.cancelButton}
            onClick={handleClose}
          />
          <ReusableButton
            size="medium"
            variant="contained"
            className="qstnUpldPageBttn"
            buttonName={checked ? "Updating..." : "Update"}
            style={{...styles.createBtnDialog,marginLeft:"10px"}}
            onClick={uploadPPT}
          />
        </Box>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default PptType;
