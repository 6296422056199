import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import TimePicker from "react-time-picker";
import { Custom } from "../../../components/Custom/Custom";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import Notification from "../../../components/Custom/Notification";
import { MyForm, Form } from "../../../components/MyForm";
import { API } from "../../../Networking/API";
import ReactLoading from "react-loading";
import "./schedule.css";
import { DataTableHandler } from "../../../handler/dataTableHandler";

const initialValues = {
  department: "",
  dateOfJoin: new Date(),
  dateOfJoin01: new Date(),
  fromTimeOfQuiz: "",
  toTimeOfQuiz: "",
  numberOfQuestions: "",
  quizDuration: "",
  percentage: "",
};

const columns = [
  {
    field: "id",
    headerName: "S.No",
    width: 20,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "center",
  },
  {
    field: "Topic",
    headerName: "Topic",
    // flex: 1,
    width: 210,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "quizNumber",
    headerName: "Quiz",
    flex: 1,
    // width: 310,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "quizFromDate",
    headerName: "From Date",
    flex: 1,
    // width: 310,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "quizToDate",
    headerName: "To Date",
    flex: 1,
    // width: 310,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "quizFromTime",
    headerName: "From Time",
    flex: 1,
    // width: 310,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "quizToTime",
    headerName: "To Time",
    flex: 1,
    // width: 310,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
  },
];

const Quiz = (props) => {
  const { scheduleId } = props;
  const [done, setDone] = useState(false);
  const [filter, setFilter] = useState("");
  const [tableData, setTableData] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [fromtime, setFromTime] = useState("10:00");
  const [totime, setToTime] = useState("10:00");
  const [showPre, setShowPre] = useState(false);
  const [schedID, setSchedID] = useState();
  const [schedTopic, setSchedTopic] = useState();
  const [available, setAvailable] = useState(true);
  const [departmentValueDD, setDepartmentValueDD] = useState("");
  const [countValueDD, setCountValueDD] = useState("");
  const [departmentApiDD, setDepartmentApiDD] = useState([]);

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("department" in inputValues)
      code.department =
        inputValues.department.length !== 0 ? "" : "Quiz is required";
    if ("numberOfQuestions" in inputValues)
      code.numberOfQuestions =
        (inputValues.numberOfQuestions ? "" : "No Of Questions is required") ||
        (inputValues.numberOfQuestions <= countValueDD
          ? ""
          : "Selected Questions should be less than max questions ");
    if ("quizDuration" in inputValues)
      code.quizDuration = inputValues.quizDuration
        ? ""
        : "Quiz Duration is required";
    if ("percentage" in inputValues)
      code.percentage = inputValues.percentage ? "" : "Percentage is required";

    setErrors({
      ...code,
    });
    if (inputValues === values) {
      return Object.values(code).every((x) => x === "");
    }
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);

  useEffect(() => {
    if (scheduleId !== undefined) {
      getDeparmentApi(scheduleId);
      getScheduleById(scheduleId);
    }
  }, [scheduleId]);

  let array = [];

  const getDeparmentApi = async (id) => {
    API.getAdminDepartments(id).then(({ response }) => {
      const getCon = response;
      const quizdata = getCon.data.data[0].quizz_types;
      array = quizdata.map((value) => ({
        title: value.quiz_number,
        count: value.count,
      }));
      setDepartmentApiDD(array);
      setCountValueDD(array[0].count);
    });
  };

  const getScheduleById = async (id) => {
    setAvailable(true);
    setDone(true);
    API.getAdminDepartments(id)
      .then(async ({ response }) => {
        if (response.data.api_status === 200) {
          setDone(false);
          setSchedID(response.data.data[0].shedule_details[0].schedule_pk);
          const prereqdata = response.data.data[0].quizzes_for_schedule;
          const updatedData = await DataTableHandler.AdminQuiz(prereqdata);
          setTableData(updatedData);
        }
        if (response.data.api_status === 404) {
          setAvailable(false);
          setSchedID(response.data.data[0].shedule_details[0].schedule_pk);
          setSchedTopic(response.data.data[0].shedule_details[0].topic);
          setDone(false);
        }
      })
      .catch(function (error) {});
  };

  const onFromTimeChange = (e) => {
    setFromTime(e);
  };

  const onToTimeChange = (e) => {
    setToTime(e);
  };

  function convert(dateOfJoin) {
    var date = new Date(dateOfJoin),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const onSubmitEdit = async (e, id) => {
    e.preventDefault();
    if (validate()) {
      const date = convert(values.dateOfJoin);
      const date01 = convert(values.dateOfJoin01);
      try {
        API.addAdminQuizz(
          id,
          fromtime,
          totime,
          date,
          date01,
          values.department,
          values.numberOfQuestions,
          values.quizDuration,
          values.percentage
        ).then(({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            getScheduleById(id);
            setShowPre(false);
            setNotify({
              isOpen: true,
              message: "Added Quiz successfully",
              type: "success",
            });
          }
          if (resultInJson.api_status === 500) {
            getScheduleById(id);
            setShowPre(false);
            setNotify({
              isOpen: true,
              message: "Please attach file while uploading",
              type: "error",
            });
          }
          setFromTime("10:00");
          setToTime("10:00");
          resetForm();
        });
      } catch (e) {}
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              Select Quiz
              <span style={{ color: "red", fontSize: "12px", fontWeight: 400 }}>
                *
              </span>
            </span>
            <span style={{}}>
              <Custom.Dropdown4
                name="department"
                label="Select Source"
                value={values.departmentValueDD}
                options={departmentApiDD}
                error={errors.department}
                disabled={tableData.length === 0 ? false : true}
                onChange={handleInputChange}
                placeholder="Select Source"
                size="small"
                style={{
                  height: 40,
                  fontFamily: "Poppins",
                }}
              ></Custom.Dropdown4>
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              Total No. of Questions
            </span>
            <span>
              <Custom.Input
                name="totalQuestions"
                // placeholder="Full Name"
                value={countValueDD}
                // onChange={handleInputChange}
                // error={errors.firstname}
                size="small"
                disabled={true}
                style={{
                  border: " 1px solid #B9B9B9",
                  borderRadius: "8px",
                }}
                className={"inputColor"}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              No. of Questions{" "}
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                *
              </span>
            </span>
            <span style={{}}>
              <Custom.Input
                name="numberOfQuestions"
                placeholder="Question count"
                size="small"
                style={{
                  border: " 1px solid #B9B9B9",
                  borderRadius: "8px",
                }}
                type={"number"}
                disabled={tableData.length === 0 ? false : true}
                error={errors.numberOfQuestions}
                onChange={handleInputChange}
                value={values.numberOfQuestions}
                InputProps={{ inputProps: { max: 30 } }}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              Quiz Duration{" "}
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                *
              </span>
            </span>
            <span style={{}}>
              <Custom.Input
                type={"number"}
                size="small"
                className="questanddurationclass"
                minWidth="300px"
                name="quizDuration"
                label="In Minutes"
                error={errors.quizDuration}
                onChange={handleInputChange}
                value={values.quizDuration}
                disabled={tableData.length === 0 ? false : true}
                //onChange={handleInputChange}
                // InputProps={{ inputProps: { max: 30 } }}
              />
            </span>
          </div>
        </Grid>

        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              Pass Percentage{" "}
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                *
              </span>
            </span>
            <span style={{}}>
              <Custom.Input
                type={"number"}
                size="small"
                className="questanddurationclass"
                minWidth="300px"
                name="percentage"
                label="In %"
                disabled={tableData.length === 0 ? false : true}
                value={values.percentage}
                error={errors.percentage}
                onChange={handleInputChange}
                // InputProps={{ inputProps: { max: 30 } }}
              />
            </span>
          </div>
        </Grid>

        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              Start Date
            </span>
            <span style={{}}>
              <Custom.DatePicker
                name="dateOfJoin"
                className="common"
                value={values.dateOfJoin}
                onChange={handleInputChange}
                size="small"
                disabled={tableData.length === 0 ? false : true}
                sx={{ width: "245px !important" }}
                placeholder="YYYY/MM/DD"
              />
            </span>
          </div>
        </Grid>

        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              Start Time
            </span>
            <span style={{}}>
              <TimePicker
                name="toTimeOfQuiz"
                onChange={onFromTimeChange}
                value={fromtime}
                className="Quiz-timePicker"
                disableClock={true}
                disabled={tableData.length === 0 ? false : true}
                style={{
                  border: " 1px solid #B9B9B9",
                  borderRadius: "8px",
                }}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#000",
                marginBottom: "5px",
              }}
              variant="h6"
            >
              End Date
            </span>
            <span style={{}}>
              <Custom.DatePicker
                name="dateOfJoin01"
                className="common"
                value={values.dateOfJoin01}
                onChange={handleInputChange}
                //onChange={handleInputChange}
                size="small"
                disabled={tableData.length === 0 ? false : true}
                sx={{ width: "245px !important" }}
                placeholder="YYYY/MM/DD"
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                marginBottom: "5px",
                color: "#000",
              }}
              variant="h6"
            >
              End Time
            </span>
            <span style={{}}>
              <TimePicker
                name="toTimeOfQuiz"
                onChange={onToTimeChange}
                value={totime}
                className="timePicker"
                disableClock={true}
                disabled={tableData.length === 0 ? false : true}
                style={{
                  border: " 1px solid #B9B9B9",
                  borderRadius: "8px",
                }}
              />
            </span>
          </div>
        </Grid>

        {tableData.length < 1 ? (
          <Grid
            item
            xs={9}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <div
              style={{
                marginTop: "3%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Custom.Button
                style={{
                  color: "#02215B",
                  background: "#fff",
                  borderRadius: "6px",
                  border: "1px solid #02215B",
                  padding: "6px 20px",
                }}
                text="Reset"
                size="small"
                onClick={() => {
                  setFromTime("10:00");
                  setToTime("10:00");
                  resetForm();
                }}
              ></Custom.Button>

              <Custom.Button
                style={{
                  color: "#fff",
                  background: "#FB2485",
                  borderRadius: "6px",
                  padding: "6px 20px",
                }}
                text="Submit"
                size="small"
                onClick={(e) => onSubmitEdit(e, scheduleId)}
              ></Custom.Button>
            </div>
          </Grid>
        ) : null}
      </Grid>

      {done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <div
          className="quiz-container"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            style={{ minHeight: "300px", marginBottom: "100px", width: "100%" }}
            className="quiz-table-container"
          >
            {tableData.length === 0 ? (
              <Box
                style={{
                  backgroundColor: "#fcfbfb",
                  height: "20vh",
                  width: "100%",
                  // marginLeft: "30px",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4 className="no-content">No Quiz Found. Please Add A Quiz</h4>
              </Box>
            ) : (
              <>
                <DataTable
                  rows={tableData}
                  columns={columns}
                  rowHeight={40}
                  autoHeight
                  pageSize={10}
                  // hideFooter={true}
                  // hideFooterPagination={true}
                  hideFooterSelectedRowCount={true}
                  className="assigntable"
                />
              </>
            )}
          </Box>
        </div>
      )}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Quiz;
