import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    TextareaAutosize,
    Typography,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import { Button } from "react-bootstrap";
  import ReusableButton from "../../../../components/reusable-button/reusable-button";
  import { ReusableInputfield } from "../../../../components/reusable-input/reusable-input";
  import { SxStyles } from "../../../../styles/styles";
  import { useFormik } from "formik";
  import { UpdateQuestionsvalidations } from "../../../../Validation/FieldValidatorSchema";
  import { API } from "../../../../Networking/API";
  import Notification from "../../../../components/Custom/Notification";
  import { Custom } from "../../../../components/Custom/Custom";
  import { CodeFormatter } from "../../../../helper/codeFormatter";
  
  const UpdateQuizQuestions = (props) => {
    const { updateQuestionDetails, unitId, getQuizQuestions, handleClose } =
      props;
    const [heading, setHeading] = useState("");
    const [notify, setNotify] = useState({
      isOpen: false,
      message: "",
      type: "",
    });
    const languageDropdown = [
      { key: 0, title: "JavaScript", value: 0 },
      { key: 1, title: "HTML", value: 1 },
      { key: 2, title: "CSS", value: 2 },
      { key: 3, title: "Others", value: 3 },
    ];
  
    const { values, handleSubmit, handleChange, errors, touched } = useFormik({
      initialValues: {
        language: "",
        sno: "",
        id: "",
        Question: "",
        CorrectOption: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        optionE: "",
      },
      validationSchema: UpdateQuestionsvalidations,
      onSubmit: (values) => {
        if (heading === "Add") return handleCreateQuestion();
        handleUpdateQuestions();
      },
    });
  
    useEffect(() => {
      if (updateQuestionDetails) {
        values.Question = updateQuestionDetails.Question;
        values.CorrectOption = updateQuestionDetails.CorrectOption;
        values.id = updateQuestionDetails.id;
        values.sno = updateQuestionDetails.sno;
        values.optionA = updateQuestionDetails?.optionA;
        values.optionB = updateQuestionDetails?.optionB;
        values.optionC = updateQuestionDetails?.optionC;
        values.optionD = updateQuestionDetails?.optionD;
        values.optionE = updateQuestionDetails?.optionE;
        values.language =
          updateQuestionDetails?.language === "JavaScript"
            ? 0
            : updateQuestionDetails?.language === "CSS"
            ? 2
            : updateQuestionDetails?.language === "HTML"
            ? 1
            : 3;
        setHeading("Edit");
      } else setHeading("Add");
    }, []);
  
    const handleCreateQuestion = () => {
      API.addQuestionAndOptions(values, unitId).then(({ response }) => {
        if (response.api_status === 200) {
          getQuizQuestions("Question Added Successfully");
          handleClose();
        } else {
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
        }
      });
    };
    const handleUpdateQuestions = () => {
      API.updateQuestionsAndOptions(values, unitId).then(({ response }) => {
        if (response.api_status === 200) {
          getQuizQuestions(response.data[0].message);
          handleClose();
        } else {
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
        }
      });
    };
  
    return (
      <div>
        <Dialog
          open={true}
          maxWidth="md"
          onClose={() => handleClose}
          className="edit_modal"
          BackdropProps={{
            style: {
              width: "100%",
              background: "#00000022",
            },
          }}
        >
          <div>
            <DialogContent>
              <DialogContentText>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bolder",
                          color: "black",
                        }}
                      >
                        {heading} Question and Options:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={SxStyles.createCourseStyle.createCourseGrid}
                    >
                      <span
                        className="required"
                        style={SxStyles.createCourseStyle.createCourseSpan}
                      >
                        Select Language
                      </span>
                      <Custom.ReusableDropdown
                        name="language"
                        id="language"
                        placeholder="Select Type"
                        displayEmpty
                        options={languageDropdown}
                        onChange={handleChange}
                        value={values.language}
                        className="batchmuipopober"
                        size="small"
                        style={{
                          height: 40,
                          fontFamily: "Poppins",
                          width: "256px",
                        }}
                        error={touched.language && Boolean(errors.language)}
                        helperText={touched.language && errors.language}
                      ></Custom.ReusableDropdown>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={SxStyles.createCourseStyle.createCourseGrid}
                    >
                      <span
                        className="required"
                        style={SxStyles.createCourseStyle.createCourseSpan}
                      >
                        Question
                      </span>
                      <ReusableInputfield
                        rows={6}
                        name="Question"
                        id="Question"
                        placeholder="Enter Question"
                        onChange={handleChange}
                        value={values.Question}
                        variant="outlined"
                        multiline
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            style: {
                              resize: "auto",
                              height: "100%",
                            },
                          },
                        }}
                        error={touched.Question && Boolean(errors.Question)}
                        helperText={touched.Question && errors.Question}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={SxStyles.createCourseStyle.createCourseGrid}
                    >
                      <span
                        className="required"
                        style={SxStyles.createCourseStyle.createCourseSpan}
                      >
                        Option a
                      </span>
                      <ReusableInputfield
                        rows={1}
                        name="optionA"
                        placeholder="Enter Option"
                        value={values.optionA}
                        onChange={handleChange}
                        variant="outlined"
                        multiline
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            style: {
                              resize: "auto",
                              height: "100%",
                            },
                          },
                        }}
                        error={touched.optionA && Boolean(errors.optionA)}
                        helperText={touched.optionA && errors.optionA}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={SxStyles.createCourseStyle.createCourseGrid}
                    >
                      <span
                        className="required"
                        style={SxStyles.createCourseStyle.createCourseSpan}
                      >
                        Option b
                      </span>
                      <ReusableInputfield
                        rows={1}
                        name="optionB"
                        placeholder="Enter Option"
                        value={values.optionB}
                        onChange={handleChange}
                        variant="outlined"
                        multiline
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            style: {
                              resize: "auto",
                              height: "100%",
                            },
                          },
                        }}
                        error={touched.optionB && Boolean(errors.optionB)}
                        helperText={touched.optionB && errors.optionB}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={SxStyles.createCourseStyle.createCourseGrid}
                    >
                      <span style={SxStyles.createCourseStyle.createCourseSpan}>
                        Option c
                      </span>
                      <ReusableInputfield
                        rows={1}
                        name="optionC"
                        placeholder="Enter Option"
                        value={values.optionC}
                        onChange={handleChange}
                        variant="outlined"
                        multiline
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            style: {
                              resize: "auto",
                              height: "100%",
                            },
                          },
                        }}
                        error={touched.optionC && Boolean(errors.optionC)}
                        helperText={touched.optionC && errors.optionC}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={SxStyles.createCourseStyle.createCourseGrid}
                    >
                      <span style={SxStyles.createCourseStyle.createCourseSpan}>
                        Option d
                      </span>
                      <ReusableInputfield
                        rows={1}
                        name="optionD"
                        placeholder="Enter Option"
                        value={values.optionD}
                        onChange={handleChange}
                        variant="outlined"
                        multiline
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            style: {
                              resize: "auto",
                              height: "100%",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={SxStyles.createCourseStyle.createCourseGrid}
                    >
                      <span style={SxStyles.createCourseStyle.createCourseSpan}>
                        Option e
                      </span>
                      <ReusableInputfield
                        rows={1}
                        name="optionE"
                        placeholder="Enter Option"
                        value={values.optionE}
                        onChange={handleChange}
                        variant="outlined"
                        multiline
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            style: {
                              resize: "auto",
                              height: "100%",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={SxStyles.createCourseStyle.createCourseGrid}
                    >
                      <span
                        className="required"
                        style={SxStyles.createCourseStyle.createCourseSpan}
                      >
                        Correct Option
                      </span>
                      <ReusableInputfield
                        name="CorrectOption"
                        placeholder="Correct Option"
                        value={values.CorrectOption}
                        onChange={handleChange}
                        variant="outlined"
                        error={
                          touched.CorrectOption && Boolean(errors.CorrectOption)
                        }
                        helperText={touched.CorrectOption && errors.CorrectOption}
                      />
                    </Grid>
                  </Grid>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                size="medium"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#000936",
                  border: "1px solid #000936",
                  padding: "7px 20px",
                  borderRadius: "4px",
                  marginRight: "8px",
                  textTransform: "none",
                }}
                onClick={() => handleClose()}
              />
              <Button
                size="medium"
                variant="contained"
                className="qstnUpldPageBttn"
                buttonName="Delete"
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#fb2485",
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#fff",
                  padding: "8px 28px",
                  borderRadius: "4px",
                  textTransform: "none",
                  marginRight: "10px",
                  border: "rgb(251, 36, 133)",
                  cursor: "pointer",
                }}
              >
                {heading === "Add" ? "Create" : "Update"}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    );
  };
  
  export default UpdateQuizQuestions;