import React, { useEffect, useState } from "react";
import { Custom } from "../../../components/Custom/Custom";
import "./trainer-dashboard.css";
import { styled } from "@mui/material/styles";
import { Paper, Box } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";
import { API } from "../../../Networking/API";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReusableButton from "../../../components/reusable-button/reusable-button";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const items = {
  date: "Friday 26, August 2022",
  day: "Today",
  topic: "SF Administration & Application Fundamentals",
  time: "09:00 AM - 11:00 AM",
  duartion: "02:00",
  name: "Gaurav R",
  designation: "Senior Salesforce Consultant",
  experience: "10",
  certificate: "3",
  sessions: "8",
};

const TrainerDashboard = () => {
  const [upcomingSessionData, setUpcomingSessionData] = useState();
  const [done, setDone] = useState(undefined);
  const [userData, setUserData] = useState();
  const [sessionCount, setSessionCount] = useState();
  const [todaySession, setTodaySession] = useState();
  const [todayMaterial, setTodayMaterial] = useState();
  const jwtToken = Cookies.get("jwt_token");
  const navigate = useNavigate();

  useEffect(() => {
    const batchId = localStorage.getItem("courseId");

    if (batchId !== undefined || batchId !== null) {
      getDashboardData(batchId);
    }
  }, []);

  const getDashboardData = async (batchId) => {
    await API.getTrainerDashboard(batchId).then(({ response }) => {
      if (
        response.data.api_status === 200 ||
        response.data.api_status === 400
      ) {
        setDone(true);
        setSessionCount(response.data.data[0].sessions_details[0]);
        setTodaySession(response.data.data[0].today[0]);
        setUserData(response.data.data[0].user_details);
        setTodayMaterial(response.data.data[0].today[0]?.training_material);
        const UpcomingSessionDatass = response.data.data[0].upcoming;
        const formattedSessionDetails = UpcomingSessionDatass.map(
          (eachData) => ({
            id: eachData.schedule_pk,
            date: eachData.training_date,
            day: eachData.day,
            topic: eachData.topic,
            time: eachData.time,
            duartion: eachData.duration,
            trainingMaterial: eachData.training_material,
          })
        );
        setUpcomingSessionData(formattedSessionDetails);
      } else if (response.data.api_status === 404) {
        setUserData(response.data.data[0].user_details);
        setSessionCount(response.data.data[0].sessions_details[0]);
        setDone(true);
      } else if (response.data.api_status === 403) {
        Cookies.remove("jwt_token");
        navigate("/");
      } else {
      }
    });
  };
const handleTrainerBack=()=>{
  navigate("/user/");
};
  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          {sessionCount?.total_sessions > 0 ? (
            <>
              <h1 className="traineeHeading">Welcome {userData.user_name}!</h1>
              <Box
            style={{
              display: "flex",
              justifyContent: "start",
              margin: "50px 0px 20px 65px",
            }}
          >
            <ReusableButton
              onClick={handleTrainerBack}
              buttonName="Back"
              startIcon={<ArrowBackIosIcon fontSize="small" />}
              style={{
                borderRadius: "4px",
                backgroundColor: "#fff",
                fontFamily: "Helvetica",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "center",
                color: "#02215b",
                padding: "10px 20px",
                margin: "-40px 0px 0px 0px",
                textTransform: "initial",
              }}
              variant="outlined"
              size="medium"
            />
          </Box>
              <Box
                sx={{
                  marginLeft: "30px",
                  marginRight: "30px",
                  marginTop: "0px",
                }}
              >
                <Box
                  style={{
                    height: "400px",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    marginBottom: "0px",
                    marginLeft: "30px",
                    marginRight: "30px",
                  }}
                >
                  <Link
                    to="/user/bootcamp/trainer/home/session-details"
                    style={{ textDecoration: "none", color: "none" }}
                    state={{ data: todaySession.schedule_pk }}
                  >
                    <Grid
                      container
                      sx={{
                        verticalAlign: "center",
                        // backgroundImage: ,
                        padding: "1px 0px 24px 30px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "space-between",
                        height: "86px",
                        width: "100%",
                        borderTopLeftRadius: "12px",
                      }}
                      className="background-image"
                    >
                      <Grid item xs={4} md={7}>
                        <p className="dateText">{todaySession.training_date}</p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        className={
                          todaySession.day === "Today"
                            ? "spanHeaderText"
                            : "next-class"
                        }
                      >
                        <div>
                          <p className="spanTextTrainer">{todaySession.day}</p>
                        </div>
                      </Grid>
                    </Grid>
                  </Link>
                  <Grid
                    container
                    sx={{ paddingLeft: "24px", marginTop: "0px" }}
                  >
                    <Grid item xs={6} md={7}>
                      <Link
                        to="/user/bootcamp/trainer/home/session-details"
                        style={{ textDecoration: "none", color: "none" }}
                        state={{ data: todaySession.schedule_pk }}
                      >
                        <>
                          <h1
                            className="heading"
                            style={{
                              width: "520px",
                              marginBottom: "-6px",
                            }}
                          >
                            {todaySession.topic}
                          </h1>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-10px",
                            }}
                          >
                            <div>
                              <p className="timeText">
                                <span className="span">Time: </span>
                                {todaySession.time} IST
                              </p>
                            </div>
                            <FiberManualRecordIcon
                              style={{
                                fontSize: "12px",
                                marginLeft: "8px",
                                marginRight: "8px",
                              }}
                            />
                            <div>
                              <p className="timeText">
                                <span className="span">Duration: </span>
                                {todaySession.duration} Hours
                              </p>
                            </div>
                          </Box>
                        </>
                      </Link>
                      {todayMaterial.length > 0 ? (
                        <h1 className="bottom-heading">Training Materials</h1>
                      ) : null}
                      {todayMaterial !== undefined ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "15vh",
                              overflowY: "scroll",
                            }}
                          >
                            {todayMaterial.map((eachMaterial) => (
                              <Link
                                to="/user/bootcamp/trainer/home/session-details"
                                style={{
                                  textDecoration: "none",
                                  color: "none",
                                }}
                                state={{ data: todaySession.schedule_pk }}
                              >
                                <AttachFileIcon
                                  sx={{
                                    color: "#0078d4",
                                    marginRight: "8px",
                                    fontSize: "20px",
                                  }}
                                />
                                <span className="downloadText">
                                  {eachMaterial.material_name}
                                </span>
                              </Link>
                            ))}
                          </Box>
                        </>
                      ) : (
                        <>
                          <h1 style={{ color: "red" }}>
                            No Trainer Material available
                          </h1>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={4} md={1}>
                      <div className="border-style"></div>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Link
                        to="/user/bootcamp/trainer/home/session-details"
                        style={{ textDecoration: "none", color: "none" }}
                        state={{ data: todaySession.schedule_pk }}
                      >
                        <Box className="side-container">
                          <h1 className="sideText">Trainer Info</h1>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "28px",
                            }}
                          >
                            <AccountCircleIcon className="userIcon" />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <h1 className="trainer-name">
                                {userData.user_name}
                              </h1>
                              <p className="trainer-designation">
                                {userData.work_posiotion}
                              </p>
                              <p className="trainer-experience">
                                {userData.work_experience !== null
                                  ? `${userData.work_experience} years of experience`
                                  : null}
                              </p>
                            </div>
                          </Box>
                          <Grid container>
                            {userData.certificates !== null ? (
                              <Grid xs={4} md={7}>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      padding: "7px 8px 6px",
                                      borderRadius: "25px",
                                      backgroundColor: "#02215b",
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  >
                                    <span className="trainer-span-text">
                                      {userData.certificates}X
                                    </span>
                                  </Box>
                                  <p
                                    className="certifcation-text"
                                    style={{ width: "175px" }}
                                  >
                                    Certifications Achieved
                                  </p>
                                </Box>
                              </Grid>
                            ) : (
                              <Grid xs={4} md={0.7}></Grid>
                            )}
                            <Grid xs={4} md={5}>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  style={{
                                    padding: "6px 14px 6px",
                                    borderRadius: "25px",
                                    backgroundColor: "#02215b",
                                    width: "40px",
                                    height: "40px",
                                    marginLeft: "-20px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="trainer-span-text">
                                    {sessionCount.total_sessions}
                                  </span>
                                </Box>
                                <p className="certifcation-text">
                                  Sessions Assigned
                                </p>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>

                {/* bottom cards code */}
                <Grid container sx={{ marginTop: "-10px" }}>
                  <Grid item xs={4} md={5}>
                    <Box className="card-containers">
                      <span className="score">
                        {sessionCount.completed}/{sessionCount.total_sessions}
                      </span>
                      <div>
                        <p className="sessions-text">Sessions Conducted</p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={7}>
                    {upcomingSessionData !== undefined ? (
                      <Custom.CustomCarousel
                        kind="upcoming"
                        items={upcomingSessionData}
                        path="/user/bootcamp/trainer/home/session-details"
                      />
                    ) : (
                      <p style={{ color: "red" }}>No session</p>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <>
              <h1 className="traineeHeading">Welcome {userData?.user_name}!</h1>

              <Box
                sx={{
                  marginLeft: "30px",
                  marginRight: "30px",
                  marginTop: "0px",
                }}
              >
                <Box
                  style={{
                    height: "20vh",
                    width: "95%",
                    marginLeft: "30px",
                    borderRadius: "12px",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "30px",
                  }}
                >
                  <h3 style={{ color: "#02215b", marginTop: "10px" }}>
                    {" "}
                    No Sessions Assigned !!!
                  </h3>
                  {/* </Box> */}{" "}
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TrainerDashboard;
