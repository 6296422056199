import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton } from "@material-ui/core";
import { SxStyles } from "../../../styles/styles";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ReusableRememberMe } from "../../../components/Custom/remember-me/remember-me";

const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

const styles = SxStyles.styles;

const RowHandler = SortableHandle(({ sno, isChecked, handleSelectedBox }) => (
  <Handle className="handle">
    <span style={{ fontSize: "14px", fontWeight: "800", marginRight: "10px" }}>
      ::
    </span>
    <ReusableRememberMe
      checked={isChecked}
      onChange={(e) => handleSelectedBox(sno - 1)}
    />
    {sno}
  </Handle>
));

const TableRowsMandate = ({
  sno,
  first,
  second,
  third,
  fourth,
  id,
  type,
  description,
  handleDeleteInpre,
  handleEditModal,
  handleViewClick,
  handleSelectedBox,
  link,
  disabled,
  isChecked,
  isDisabled,
  OpenTheEditBox,
}) => {
  return (
    <tr style={{ cursor: "default" }}>
      <TableCell
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
          whiteSpace: "nowrap",
        }}
      >
        <RowHandler
          sno={sno}
          first={first}
          second={second}
          third={third}
          fourth={fourth}
          isChecked={isChecked}
          handleSelectedBox={handleSelectedBox}
        />
      </TableCell>
      <TableCell
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
          whiteSpace: "nowrap",
        }}
      >
        {third !== "GROUP_OF_IMAGES" ? (
          <span>{first}</span>
        ) : (
          <span
            style={{ color: "#0000EE", cursor: "pointer" }}
            onClick={() => OpenTheEditBox(id)}
          >
            {first}
          </span>
        )}
      </TableCell>
      <TableCell
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
          paddingRight: "0px !important",
          whiteSpace: "nowrap",
        }}
      >
        {link && second !== "N/A" ? (
          <a
            href={second}
            target="_blank"
            rel="noreferrer"
            style={styles.tableSpanText}
          >
            {second}
          </a>
        ) : (
          second
        )}
      </TableCell>
      <TableCell
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
          paddingLeft: "0px !important",
          whiteSpace: "nowrap",
        }}
      >
        {link && third !== "N/A" ? (
          <a
            href={`${third}`}
            target="_blank"
            rel="noreferrer"
            style={styles.tableSpanText}
          >
            {first}
          </a>
        ) : (
          third
        )}
      </TableCell>
      {fourth ? (
        <TableCell
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "400",
            color: "#000000",
            whiteSpace: "nowrap",
          }}
        >
          {fourth}
        </TableCell>
      ) : (
        <TableCell
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "400",
            color: "#000000",
            whiteSpace: "nowrap",
          }}
        >
          0
        </TableCell>
      )}

      <TableCell style={{ textAlign: "center", justifyContent:"end" }}>
        <>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
             <Tooltip title="View">
                    <IconButton
                      disabled={isDisabled}
                      aria-label="edit"
                      size="small"
                    >
                      <RemoveRedEyeIcon
                        disabled={isDisabled}
                        color={isDisabled ? "disabled" : "primary"}
                        fontSize="inherit"
                        style={styles.tableIcon}
                        onClick={() => {
                          handleViewClick(sno);
                        }}
                      />
                       </IconButton>
            </Tooltip> 
            
             <Tooltip title="Edit">
              <IconButton disabled={isDisabled} aria-label="edit" size="small">
                <EditIcon
                  disabled={isDisabled}
                  color={isDisabled ? "disabled" : "primary"}
                  fontSize="inherit"
                  style={styles.tableIcon}
                  onClick={() => {
                    handleEditModal(id, first, second, third, fourth,type,description);
                  }}
                />
              </IconButton>
            </Tooltip> 
            
            <Tooltip title="Delete">
              <IconButton disabled={isDisabled} aria-label="edit" size="small">
                <DeleteOutlineIcon
                  disabled={isDisabled}
                  color={isDisabled ? "disabled" : "primary"}
                  fontSize="inherit"
                  style={styles.tableIcon}
                  onClick={() => {
                    handleDeleteInpre(id);
                  }}
                />
              </IconButton>
            </Tooltip>
          </span>
        </>
      </TableCell>
    </tr>
  );
};

export default TableRowsMandate;
