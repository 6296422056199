import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function ReusableTabs({ tabsData }) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box
        sx={{
          paddingTop: "1%",
          borderRadius: "10px",
          marginLeft: "20px",
          marginRight:"20px",
          marginBottom:"8%"
        }}
      >
        <Box sx={{ borderRight: 4, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{
              borderTopLeftRadius: "10px",
            }}
          >
            {tabsData.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                className="tabs-item"
                style={{
                  textTransform: "capitalize",
                  ".tabs-item:last-child": {
                    borderTopRightRadius: "10px",
                  },
                }}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>

        {tabsData.map((tab, index) => (
          <TabPanel
            key={index}
            value={value}
            index={index}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {tabsData[value].children}
          </TabPanel>
        ))}
      </Box>
    </>
  );
}

export default ReusableTabs;
