import beautify from "js-beautify";

export const CodeFormatter = (codeQuestion, type) => {
  const splitedArray = codeQuestion.split("----");
  let word = "";
  let formattedQuestion;
  for (let question of splitedArray) {
    switch (type) {
      case "HTML":
        formattedQuestion = beautify.html(question, {
          indent_size: 4,
          wrap_line_length: 100,
        });
        word += "\n" + formattedQuestion;
        break;
      case "CSS":
        formattedQuestion = beautify.css(question, {
          indent_char: " ",
          indent_size: 4,
          autosemicolon: true,
        });
        word += "\n" + formattedQuestion;
        break;

      case "JavaScript":
        formattedQuestion = beautify.js_beautify(question, {
          indent_size: 2,
          space_in_empty_paren: false,
        });
        word += "\n" + formattedQuestion;
        break;
      default:
        return (word = question);
    }
  }
  return word;
};