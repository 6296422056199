export const InitialPath = {
  LOGIN_PATH: "/",
  SIGNUP_PATH: "/signup",
  FORGOTPASSWORD_PATH: "/forgotPassword",
  CONNECT_LOGIN_PATH: "/connectuser",
};

export const AdminPath = {
  ADMIN_HOME_PATH: "/home/",
  ADMIN_CANDIDATELIST_PATH: "/home/",
  ADMIN_REPORTS_PATH: "/home/reports",
  ADMIN_UPLOAD_SCHEDULE_PATH: "/home/scheduleupload",
  ADMIN_UPLOAD_HACKATHON_PATH: "/home/uploadhackathon",
  ADMIN_TRAINEE_UPLOAD_PATH: "/home/traineeupload",
  ADMIN_QUIZ_UPLOAD_PATH: "/home/uploadquiz",
  ADMIN_BATCH_PATH: "/home/batches",
  ADMIN_ADD_QUIZ_PATH: "/home/addQuiz",
  ADMIN_BOOTCAMP_PATH: "/home/bootcampview",
  ADMIN_CREATE_UNIT: "/home/createunit",
  ADMIN_HOME_COURSES: "/home/courses",
  ADMIN_CREATE_COURSE: "/home/createcourse",
  ADMIN_CREATE_PROGRAM: "/home/createProgram",
  ADMIN_CREATE_MANDATORY_TRAINING: "/home/createMandatoryTraining",
  ADMIN_CREATE_CONFIGURATIONS: "/home/createConfigurations"
};

export const TraineePath = {
  TRAINEE_HOME_PATH: "/trainee/home/",
  TRAINEE_CALENDAR_PATH: "/trainee/home/calendardetails",
  TRAINEE_SESSION_PATH: "/trainee/home/sessiondetails",
  TRAINEE_CHANGEPASSWORD_PATH: "/trainee/home/changePassword",
  TRAINEE_PROFILE_PATH: "/trainee/home/Profile",
  TRAINEE_QUIZQUESTION_PATH: "/trainee/home/quizQuestions",
  TRAINEE_QUIZRESULT_PATH: "/trainee/home/quizResult",
};

export const TrainerPath = {
  TRAINER_PROFILE_PATH: "/trainer/home/Profile",
  TRAINER_HOME_PATH: "/trainer/home/",
  TRAINER_SESSION_PATH: "/trainer/home/session",
  TRAINER_SESSION_DETAILS_PATH: "/trainer/home/session-details",
  TRAINER_HACKATHON_PATH: "/trainer/home/hackathon",
  TRAINER_HACAKTHON_DETAILS_PATH: "/trainer/home/hackathon-details",
};

export const MentorPath = {
  MENTOR_PROFILE_PATH: "/mentor/home/Profile",
  MENTOR_HOME_PATH: "/mentor/home/",
  MENTOR_SESSION_PATH: "/mentor/home/session",
  MENTOR_SESSION_DETAILS_PATH: "/mentor/home/session-details",
};

export const UsersPath = {
  USER_DASHBOARD: "/user/",
  USER_COURSES: "/user/courses",
  USER_COURSES_UNITS: "/user/courses/units",
  USER_PROFILE_PATH: "/user/profile",
  USER_QUESTIONS_PATH: "/user/quizQuestions",
  USER_QUIZ_RESULT_PATH: "/user/quizResult",
  USER_CHANGE_PASSWORD_PATH: "/user/changepassword",
  USER_MANDATORY_TRANING_MODULE: "/user/mandatoryTraning/:traningId",
  USER_MANDATORY_TRAINING: "/user/mandatoryTraining",

  //Newly_Added_Trainee
  TRAINEE_HOME_PATH: "/user/bootcamp/home/",
  TRAINEE_CALENDAR_PATH: "/user/bootcamp/home/calendardetails",
  TRAINEE_SESSION_PATH: "/user/bootcamp/home/sessiondetails",
  TRAINEE_CHANGEPASSWORD_PATH: "/user/bootcamp/home/changePassword",
  TRAINEE_PROFILE_PATH: "/user/bootcamp/home/Profile",
  TRAINEE_QUIZQUESTION_PATH: "/user/bootcamp/home/quizQuestions",
  TRAINEE_QUIZRESULT_PATH: "/user/bootcamp/home/quizResult",

  //Newly_Added_Trainer
  TRAINER_PROFILE_PATH: "/user/bootcamp/trainer/home/Profile",
  TRAINER_HOME_PATH: "/user/bootcamp/trainer/home/",
  TRAINER_SESSION_PATH: "/user/bootcamp/trainer/home/session",
  TRAINER_SESSION_DETAILS_PATH: "/user/bootcamp/trainer/home/session-details",
  TRAINER_HACKATHON_PATH: "/user/bootcamp/trainer/home/hackathon",
  TRAINER_HACAKTHON_DETAILS_PATH:
    "/user/bootcamp/trainer/home/hackathon-details",

  // Newly added mentor url`s here
  MENTOR_HOME_PATH: "/user/bootcamp/mentor/home/",
  MENTOR_PROFILE_PATH: "/user/bootcamp/mentor/home/Profile",
  MENTOR_SESSION_PATH: "/user/bootcamp/mentor/home/session",
  MENTOR_SESSION_DETAILS_PATH: "/user/bootcamp/mentor/home/session-details",
};
