import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { Custom } from "../../../../components/Custom/Custom";
import { InputLabel } from "@material-ui/core";
import "./createUnit.css";
import ReusableButton from "../../../../components/Custom/button/button";
import { useFormik } from "formik";
import {
  CreateUnitValidation,
  CreateMandateTrainingSchema,
} from "../../../../Validation/FieldValidatorSchema";
import { SxStyles } from "../../../../styles/styles";
import { ReusableInputfield } from "../../../../components/reusable-input/reusable-input";
import { API } from "../../../../Networking/API";
import { DropDownHandler } from "../../../../handler/dropDownHandler";
import {
  TimeDuration,
  ActiveSatus,
} from "../../../../constants/adminCreateUnit";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Notification from "../../../../components/Custom/Notification";
import { TechnologyConst } from "../../../../constants/adminCreateUnit";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import ReactLoading from "react-loading";
import {
  HandleUnitTag,
  HandleTagChange,
  CreateUnitHelper,
  DeleteUnitHelper,
} from "../../../../helper/unitHelper";
import DeletePopUp from "../masterDataModal/deletePopUp";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { margin } from "@mui/system";
import { CreateMandateTrainingHelper } from "../../../../helper/mandatoryHelper";

const DropdownValue = [
  { key: 0, title: "Unit", value: 0 },
  { key: 1, title: "Mandatory Training", value: 1 },
];

const CreateUnit = (props) => {
  const styles = SxStyles.styles;
  let { unitData, dissabled, setDisabled, setIsUnit, isUnit, setPageTitle } =
    props;
  const [isDisable, setIsDisabled] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState([]);
  const [selectType, setSelectType] = useState([]);
  const [duration, setDuration] = useState(TimeDuration);
  const [status, setStatus] = useState(ActiveSatus);
  const [selectedTag, setselectedTag] = useState([]);
  const [allTag, setAllTag] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [deleteModal, setDeletModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const UnitId = window.localStorage.getItem("UnitId");
  const mandateId = window.localStorage.getItem("mandateId");
  const [categories, setCategories] = useState([]);
  const [isPublishEnabled, setIsPublishEnabled] = useState(false);
  //const [selectTypeValue, setSelectTypeValue] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loaderForSaveLaterUnit, setloaderForSaveLaterUnit] = useState(false);
  const [loaderForPublishUnit, setloaderForPublishUnit] = useState(false);
  let navigate = useNavigate();
  let st = {
    "& .MuiOutlinedInput-input": {
      padding: "10px 10px",
    },
  };

  const formik = useFormik({
    initialValues: {
      unitName: "",
      Technology: "",
      Difficulty: "",
      Duration: "",
      Active: "",
      Tag: "",
      session: "",
      passPercentage: "",
      selectType: "",
      activeDate: new Date(),
      attempts: "",
    },
    validationSchema: CreateUnitValidation,
    onSubmit: (values) => {
      handleUnitCreation();
    },
  });

  useEffect(() => {
    if (formik.values.selectType === 1) {
      getselectedTags(true);
      setPageTitle("Mandatory Training");
      getCategories(1, 1000, 1, 1, "");
      // setIsUnit(false);
    } else if (formik.values.selectType === 0) {
      getselectedTags();
      setPageTitle("Unit");
      getCategories(0, 1000, 1, 1, "");
      // setIsUnit(true);
    } else {
      setAllTag([]);
    }
  }, [formik.values.selectType]);

  /*********State for AddCourse Unit */
  const [selectedCategory,setSelectedCategory] = useState("");

  useEffect(() => {
    getDifficultyLevels();
    setSelectType(DropdownValue);
  }, []);

  useEffect(() => {
    if (Object.keys(unitData).length === 0) {
      setTagValue(allTag);
      setselectedTag([]);
      setLoader(false);
    } else {
      setIsDisabled(true);
      HandleUnitTag(formik, unitData, setTagValue,  setselectedTag  , allTag ,setSelectedCategory);
      setLoader(false);
      setSelectedCategory( unitData?.categoryDetails?.id);
    }
  }, [unitData, allTag]);

  const getDifficultyLevels = () => {
    API.getDifficultyLevel().then(({ response }) => {
      const DiffcultyData = response.data.data;
      const uniqueDifficulties = [
        ...new Map(
          DiffcultyData.map((difficulty) => [
            difficulty.difficulty_name,
            difficulty,
          ])
        ).values(),
      ];
      setDifficultyLevel(DropDownHandler(uniqueDifficulties));
    });
  };

  const getselectedTags = (isMandate) => {
    API.getAllTags(isMandate ? 1 : 0, 1000, 1).then(({ response }) => {
      const TagData = response?.data.data.rows.map((TagValue) => {
        return { id: TagValue.id, title: TagValue.tag_name };
      });
      setAllTag(TagData);
    });
  };

  useEffect(() => {
    if (selectedTag.length > 0) {
      delete formik.errors["Tag"];
    }
  }, [selectedTag]);

  const handleUnitCreation = () => {
    if (formik.values.selectType === 0) {
      CreateUnitHelper(
        formik,
        setIsDisabled,
        setNotify,
        setIsCreate,
        isCreate,
        setDisabled,
        allTag,
        selectedTag,
        navigate,
        setloaderForSaveLaterUnit,
        setloaderForPublishUnit
      );
    } else {
      CreateMandateTrainingHelper(
        formik,
        setIsDisabled,
        setNotify,
        setIsCreate,
        isCreate,
        setDisabled,
        navigate,
        setloaderForSaveLaterUnit,
        setloaderForPublishUnit,
        null,
        selectedTag
      );
    }
  };

  const handleTagChange = (value) => {
    setselectedTag(value);
    HandleTagChange(value, formik, setTagValue, allTag);
  };

  const getCategories = (
    categoryType,
    limit,
    categoryStatus,
    page,
    searchInput
  ) => {
    API.getAllCategories(
      categoryType,
      limit,
      categoryStatus,
      page,
      searchInput
    ).then((response) => {
      if (response.response.data.api_status === 200) {
        const categories = response?.response?.data?.data?.rows?.map((item) => {
          return {
            key: item.id,
            title: item.category_name,
            value: item.id,
          };
        });
        setCategories(categories);
      }
    });
  };

  const deleteModalClose = () => {
    setDeletModal(false);
  };

  const HandleDeleteUnit = async () => {
    setDeletModal(false);
    navigate("/home/courses");
  };

  const cancelUnit = async () => {
    if (!isDisable) {
      setDeletModal(true);
    } else {
      navigate("/home/courses");
    }
  };
  const handleSelectType = (e) => {
    formik.handleChange(e);
    if (e && e.target.value === 1) {
      formik.values.Active = true;
    }
  };
  return (
    <>
      <Box
        style={{
          backgroundColor: "white",
          padding: "40px 0px 40px 20px",
          marginBottom: "60px",
        }}
      >
        {loader ? (
          <Box
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ paddingRight: "20px" }}>
              <Grid
                item
                xs={12}
                lg={3}
                md={3}
                sm={5}
                // style={{ marginRight: "34px" }}
              >
                <InputLabel className="InputLabel" style={styles.label}>
                  Select Type
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>
                <Custom.ReusableDropdown
                  disabled={(UnitId || mandateId) !== null ? true : false}
                  name="selectType"
                  id="selectType"
                  placeholder="Select Type"
                  displayEmpty
                  options={selectType}
                  onChange={(e) => {
                    handleSelectType(e);
                  }}
                  value={formik.values.selectType}
                  className="batchmuipopober"
                  size="small"
                  style={{
                    height: 40,
                    fontFamily: "Poppins",
                  }}
                  error={
                    formik.touched.selectType &&
                    Boolean(formik.errors.selectType)
                  }
                  helperText={
                    formik.touched.selectType && formik.errors.selectType
                  }
                ></Custom.ReusableDropdown>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                lg={3}
                md={3}
                // style={{ marginRight: "22px" }}
              >
                <InputLabel className="InputLabel" style={styles.label}>
                  Name
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>
                <ReusableInputfield
                  disabled={isDisable}
                  style={st}
                  className="UnitName"
                  variant="outlined"
                  name="unitName"
                  margin="normal"
                  type="text"
                  size="small"
                  value={formik.values.unitName}
                  id="unitName"
                  placeholder="Name"
                  onChange={formik.handleChange}
                  autoComplete="off"
                  InputProps={{
                    autoComplete: "off",
                    inputProps: {
                      // style: { padding: "10px 14px" },
                    },
                  }}
                  error={
                    formik.touched.unitName && Boolean(formik.errors.unitName)
                  }
                  helperText={formik.touched.unitName && formik.errors.unitName}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={3}
                md={3}
                sm={5}
                // style={{ marginRight: "34px" }}
              >
                <InputLabel className="InputLabel" style={styles.label}>
                  Categories
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>
                <Custom.ReusableDropdown
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "0.92px solid #cfcbcb !important",
                      },
                  }}
                  disabled={isDisable}
                  blurOnSelect
                  name="Technology"
                  id="Technology"
                  displayEmpty
                  placeholder="Select Technology"
                  options={categories}
                  onChange={(e)=>{
                    formik.handleChange(e);
                    setSelectedCategory(e.target.value);
                  }}
                  value={formik.values.Technology}
                  className="batchmuipopober"
                  size="small"
                  style={{
                    height: 40,
                    fontFamily: "Poppins",
                  }}
                  error={
                    formik.touched.Technology &&
                    Boolean(formik.errors.Technology)
                  }
                  helperText={
                    formik.touched.Technology && formik.errors.Technology
                  }
                ></Custom.ReusableDropdown>
              </Grid>
              {formik.values.selectType === 1 ? (
                <Grid
                  item
                  xs={12}
                  lg={3}
                  md={3}
                  sm={5}
                  // style={{ marginRight: "34px" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Number of Attempts
                    <span style={styles.madatoryMark}>*</span>
                  </InputLabel>

                  <ReusableInputfield
                    name="attempts"
                    id="attempts"
                    value={formik.values.attempts}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      formik.handleChange({
                        target: {
                          name: "attempts",
                          value: numericValue === "0" ? "" : numericValue,
                        },
                      });
                    }}
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        // style: { padding: "10px 14px" },
                      },
                    }}
                    error={
                      formik.touched.attempts && Boolean(formik.errors.attempts)
                    }
                    helperText={
                      formik.touched.attempts && formik.errors.attempts
                    }
                    disabled={mandateId === null ? false : isDisable}
                    style={st}
                    className="UnitName"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    size="small"
                    placeholder="no of attempts"
                    autoComplete="off"
                  />
                </Grid>
              ) : null}

              <Grid
                item
                xs={12}
                lg={3}
                md={3}
                sm={5}
                // style={{ marginRight: "34px" }}
              >
                <InputLabel className="InputLabel" style={styles.label}>
                  Difficulty Level
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>
                <Custom.ReusableDropdown
                  disabled={isDisable}
                  name="Difficulty"
                  id="Difficulty"
                  placeholder="Select Difficulty"
                  displayEmpty
                  options={difficultyLevel}
                  onChange={formik.handleChange}
                  value={formik.values.Difficulty}
                  className="batchmuipopober"
                  size="small"
                  style={{
                    height: 40,
                    fontFamily: "Poppins",
                  }}
                  error={
                    formik.touched.Difficulty &&
                    Boolean(formik.errors.Difficulty)
                  }
                  helperText={
                    formik.touched.Difficulty && formik.errors.Difficulty
                  }
                ></Custom.ReusableDropdown>
              </Grid>
              <Grid
                item
                xs={12}
                lg={3}
                md={3}
                sm={5}
                // style={
                //   formik.values.selectType === 1
                //     ? { marginRight: "18px", marginTop: "20px" }
                //     : { marginRight: "18px", marginTop: "0px" }
                // }
                // style={{ marginRight: "18px",marginTop: "20px" }}
              >
                <InputLabel className="InputLabel" style={styles.label}>
                  Duration
                </InputLabel>
                <ReusableInputfield
                  className="UnitName"
                  name="duration"
                  id="duration"
                  placeholder="Duration"
                  disabled={true}
                  size="small"
                  value={formik.values.Duration}
                  type="number"
                  InputProps={{
                    autoComplete: "off",
                    inputProps: {
                      style: { padding: "10px 12px", width: "90%" },
                    },
                  }}
                />
              </Grid>
              {formik.values.selectType === 0 ? (
                <Grid
                  item
                  xs={12}
                  lg={3}
                  md={3}
                  sm={5}
                  // style={{ marginTop: "18px", marginRight: "10px" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Active Status
                    <span style={styles.madatoryMark}>*</span>
                  </InputLabel>
                  <Custom.ReusableDropdown
                    disabled={isDisable}
                    name="Active"
                    id="Active"
                    displayEmpty
                    placeholder="Select Status"
                    options={status}
                    onChange={formik.handleChange}
                    value={formik.values.Active}
                    className="batchmuipopober"
                    size="small"
                    style={{
                      height: 40,
                      fontFamily: "Poppins",
                    }}
                    error={
                      formik.touched.Active && Boolean(formik.errors.Active)
                    }
                    helperText={formik.touched.Active && formik.errors.Active}
                  ></Custom.ReusableDropdown>
                </Grid>
              ) : null}
              {formik.values.selectType === 1 ? (
                <Grid
                  item
                  xs={12}
                  lg={3}
                  md={3}
                  sm={5}
                  style={{ width: "100%" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Active Date
                    <span style={styles.madatoryMark}>*</span>
                  </InputLabel>
                  <Custom.DatePicker
                    name="activeDate"
                    // id="activeDate"
                    // style={{
                    //   // width: "19vw",
                    //   padding: "10px 4px",
                    // }}
                    className="Active-Date-class"
                    value={formik.values.activeDate}
                    onChange={formik.handleChange}
                    // disabled={disabled}
                    size="small"
                    error={
                      formik.touched.activeDate &&
                      Boolean(formik.errors.activeDate)
                    }
                    helperText={
                      formik.touched.activeDate && formik.errors.activeDate
                    }
                    disabled={mandateId === null ? false : isDisable}
                    placeholder="YYYY/MM/DD"
                  />
                </Grid>
              ) : null}
              <Grid
                item
                xs={12}
                lg={3}
                md={3}
                sm={5}
                // style={{ marginTop: "20px" }}
              >
                <InputLabel className="InputLabel" style={styles.label}>
                  Pass Percentage
                </InputLabel>
                <ReusableInputfield
                  type={"text"}
                  size="medium"
                  className="Active-Date-class"
                  name="passPercentage"
                  id="passPercentage"
                  placeholder="In %"
                  style={{ width: "100%" }}
                  value={formik.values.passPercentage}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                    formik.handleChange({
                      target: {
                        name: "passPercentage",
                        value: numericValue,
                      },
                    });
                  }}
                  InputProps={{
                    autoComplete: "off",
                    inputProps: {
                      // style: { padding: "10px 14px" },
                    },
                  }}
                  error={
                    formik.touched.passPercentage &&
                    Boolean(formik.errors.passPercentage)
                  }
                  helperText={
                    formik.touched.passPercentage &&
                    formik.errors.passPercentage
                  }
                  disabled={(UnitId || mandateId) === null ? false : isDisable}
                />
              </Grid>
              <Grid item sm={12} md={12} xs={12} style={{ marginTop: "10px" }}>
                <InputLabel className="InputLabel" style={styles.label}>
                  About Session
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>
                <ReusableInputfield
                  rows={4}
                  disabled={isDisable}
                  displayEmpty
                  placeholder="About Session"
                  style={{ width: "99.6%" }}
                  className="description_in_Pre-requisite"
                  name="session"
                  id="session"
                  onChange={formik.handleChange}
                  value={formik.values.session}
                  multiline
                  size="medium"
                  error={
                    formik.touched.session && Boolean(formik.errors.session)
                  }
                  helperText={formik.touched.session && formik.errors.session}
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    inputProps: {
                      style: {
                        resize: "auto",
                        height: "100%",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                sm={11.3}
                md={4.5}
                xs={8}
                lg={4.5}
                style={{ marginTop: "30px", marginBottom: "30px" }}
              >
                <InputLabel className="InputLabel" style={styles.label}>
                  Add Tag
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>

                <Autocomplete
                  multiple
                  limitTags={2}
                  id="Tag"
                  name="Tag"
                  options={tagValue}
                  disabled={isDisable}
                  value={selectedTag}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, value) => {
                    handleTagChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={formik.touched.Tag && Boolean(formik.errors.Tag)}
                      helperText={formik.touched.Tag && formik.errors.Tag}
                      placeholder="Select tag"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box style={styles.buttonContainer}>
              <Box style={{ marginRight: "40px" }}>
                <ReusableButton
                  onClick={cancelUnit}
                  size="large"
                  variant="outlined"
                  buttonName="Cancel"
                  style={styles.cancelButton}
                  disabled={
                    loaderForSaveLaterUnit || loaderForPublishUnit
                      ? true
                      : false
                  }
                ></ReusableButton>
                {isDisable ? (
                  <ReusableButton
                    className="addbutton"
                    size="medium"
                    variant="contained"
                    style={styles.saveForLaterBtn}
                    buttonName="Edit"
                    onClick={() => {
                      setTimeout(function () {
                        setIsPublishEnabled(true);
                        setDisabled(false);
                        setIsDisabled(false);
                      }, 500);
                    }}
                  ></ReusableButton>
                ) : (
                  <ReusableButton
                    className="addbutton"
                    type="submit"
                    size="medium"
                    variant="contained"
                    endIcon={
                      !loaderForSaveLaterUnit ? (
                        ""
                      ) : (
                        <CircularProgress
                          size={20}
                          style={{ color: "white" }}
                        />
                      )
                    }
                    buttonName={
                      !loaderForSaveLaterUnit ? "Save for Later" : "Saving..."
                    }
                    style={
                      !loaderForPublishUnit
                        ? styles.saveForLaterBtn
                        : styles.saveForLaterBtnDisabled
                    }
                    disabled={loaderForPublishUnit ? true : false}
                    onClick={() => {
                      setIsPublishEnabled(false);
                    }}
                  ></ReusableButton>
                )}
                <ReusableButton
                  onClick={() => {
                    setIsCreate(true);
                  }}
                  type="submit"
                  size="large"
                  variant="contained"
                  endIcon={
                    !loaderForPublishUnit ? (
                      ""
                    ) : (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    )
                  }
                  buttonName={!loaderForPublishUnit ? "Publish" : "Publishing"}
                  style={
                    !loaderForSaveLaterUnit && isPublishEnabled
                      ? styles.createBtn
                      : styles.createBtnDisabled
                  }
                  disabled={
                    loaderForSaveLaterUnit || !isPublishEnabled ? true : false
                  }
                ></ReusableButton>
              </Box>
            </Box>
            <DeletePopUp
              deleteModal={deleteModal}
              deleteModalClose={deleteModalClose}
              HandleDeleteUnit={HandleDeleteUnit}
            />
          </form>
        )}
        <Notification notify={notify} setNotify={setNotify} />
      </Box>
    </>
  );
};
export default CreateUnit;
