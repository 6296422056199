import { React} from "react";
import "./mentor-dashbord.css";
import Box from "@mui/material/Box";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Legend,
} from "recharts";

export const MentorChart = ({ chartData, teamCountMentor }) => {
  return (
    <>
      <Box className="mentor-charts-Box">
        <ComposedChart
          width={520}
          height={200}
          data={chartData}
          margin={{ top: 20, right: 80, left: 0, bottom: 20 }}
        >
          <XAxis dataKey="user_name" />
          <YAxis />
          <Legend />
          <Bar
            dataKey="asn_percentage"
            name="Assignment"
            barSize={8}
            fill="#fb2485"
          />
          <Bar
            dataKey="quiz_percentage"
            name="Quiz"
            barSize={8}
            fill="#02215b"
          />
          <Line dataKey="asn_percentage" stroke="#fb2485" dot={false} />
          <Line dataKey="quiz_percentage" stroke="#02215b" dot={false} />
        </ComposedChart>
      </Box>

      <div className="vertical-line"></div>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          left: "72%",
          bottom: "284px",
          width: "20%",
        }}
      >
        <span className="count-text">{teamCountMentor}</span>
        <span className="over-all-mentors-text">Total Number of Mentees</span>
      </Box>
    </>
  );
};
