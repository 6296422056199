import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { ReusableInputfield } from "../../components/reusable-input/reusable-input";
import ReusableButton from "../../components/Custom/button/button";
import { ReusableRememberMe } from "../../components/Custom/remember-me/remember-me";
import loginImg1 from "../../assets/Images/adminLoginPageContentpic.png";
import Cookies from "js-cookie";
import Notification from "../../components/Custom/Notification";
import "./login.css";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { API } from "../../Networking/API";
import { useFormik } from "formik";
import {
  LoginPageNavigation,
  UserAuthLandingPage,
} from "../../Navigation/navigation";
import { LoginvalidationSchema } from "../../Validation/FieldValidatorSchema";
import constants from "../../api/index";
import { useContext } from "react";
import useProfileContext from "../../context/useProfileContext";

const Login = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  
  const {userDetails,setUserDetails} = useContext(useProfileContext);
  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
    },
    validationSchema: LoginvalidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
      SetSubmitHandle(true);
    },
  });

  const [isChecked, setIsChecked] = useState(false);
  const [SubmitHandle, SetSubmitHandle] = useState(false);
  const navigate = useNavigate();
  const handleConnectLogin = useCallback(() => {
    window.location.href = constants.connectApi;
  }, []);

  const classes = [];

  const onSubmitSuccess = async (
    jwtToken,
    userRole,
    userName,
    entryType,
    originalRole
  ) => {
    Cookies.set("jwt_token", jwtToken, {
      expires: 10,
    });

    Cookies.set("orginalRole", originalRole, {
      expires: 10,
    });
    setUserDetails({...userDetails,userRole:originalRole});
    Cookies.set("user_role", userRole, {
      expires: 10,
    });


    Cookies.set("user_name", userName, {
      expires: 10,
    });

    Cookies.set("entryType", entryType, {
      expires: 10,
    });

    // document.cookie = "entryType=" + JSON.stringify(entryType);
  };

  const handleSubmit = async (LoginValue) => {
    const { email, password } = LoginValue;
    API.adminLogin(email, password).then(async ({ response }) => {
      const data = response;
      if (data.api_status === 200) {
        const jwtToken = data.data[0].jwttoken;
        let userRole;
        let isAdmin = data.data[0].is_admin;
        let originalRole =
          isAdmin === true ? "Admin" : data.data[0]?.user_roles[0]?.role_name; // changed the data.data[0]?.user_roles[0]?.role_name changed to ====  data.data[0]?.user_roles[0][0]?.role_name because destructuring has changed

        let entryType = data.data[0].entry_type;
        if (data.data[0].is_admin) {
          userRole = "Admin";
        } else {
          userRole = "User";
        }

        const userName = data.data[0].user_name;
        await onSubmitSuccess(
          jwtToken,
          userRole,
          userName,
          entryType,
          originalRole
        );
        await LoginPageNavigation(userRole, navigate, isAdmin);

        if (isChecked === true && email !== null) {
          localStorage.loginusername = email;
          localStorage.loginpassword = password;
          localStorage.logincheckbox = isChecked;
        }
      } else {
        setNotify({
          isOpen: true,
          type: "error",
          message: data.error[0].error_msg,
        });
      }
    });
  };

  const onChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
  };

  const jwtToken = Cookies.get("jwt_token");
  const role = Cookies.get("user_role");
  if (jwtToken !== undefined) {
    return UserAuthLandingPage(role);
  }

  return (
    <>
      <div
        component="main"
        className="OverallDiv"
        style={{
          height: "100%",
          zIndex: 1,
          position: "absolute",
          width: "100%",
        }}
      >
        <Container component="main" maxWidth="md">
          <Card
            variant="outlined"
            sx={{ marginTop: "7%", Width: "100%", borderRadius: 0 }}
          >
            <CardContent sx={{ padding: "15px 33px 33px 0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <img
                      src={loginImg1}
                      alt="AdminContentPic"
                      width="350"
                      height="410"
                      style={{ marginLeft: "25px" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.textGrid}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <span className="adminLoginHeadingOne">Welcome!</span>
                    <span
                      className="adminLoginHeadingTwo"
                      style={{ marginTop: "9px" }}
                    >
                      ABSYZ Academy
                    </span>
                    <span
                      className="adminLoginHeadingThree"
                      style={{ marginTop: "14px", marginBottom: "7px" }}
                    >
                      User Login
                    </span>
                  </Box>

                  <Box sx={{ marginTop: 2 }}>
                    <form onSubmit={formik.handleSubmit}>
                      <Stack direction="column" spacing={3}>
                        <ReusableInputfield
                          variant="outlined"
                          name="email"
                          margin="normal"
                          type="text"
                          value={formik.values.email}
                          id="email"
                          fullWidth
                          label="E-Mail"
                          onChange={formik.handleChange}
                          autoComplete="off"
                          inputProps={{
                            autoComplete: "off",
                          }}
                          className={classes.inputfieldLabel}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />

                        <ReusableInputfield
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          id="password"
                          autoComplete="off"
                          inputProps={{
                            autoComplete: "off",
                          }}
                          className={classes.inputfieldLabel}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                        />

                        <Stack
                          direction="row"
                          spacing={2}
                          style={{
                            margin: 0,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <ReusableRememberMe
                              color="default"
                              size="small"
                              name="lsRememberMe"
                              checked={isChecked}
                              onChange={onChangeCheckbox}
                            />
                            <label
                              style={{
                                margin: 0,
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="loginFieldText"
                            >
                              Remember Me
                            </label>
                          </Stack>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              href="/forgotPassword"
                              underline="none"
                              style={{
                                fontSize: "0.8em",
                                color: "#717171",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                              }}
                              className="loginFieldText"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                        </Stack>
                      </Stack>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          paddingTop: "15px",
                        }}
                      >
                        <ReusableButton
                          size="medium"
                          variant="contained"
                          type="submit"
                          className="adminLoginButton"
                          buttonName="Login Now"
                          style={{
                            backgroundColor: "#fe1c7f",
                            fontFamily: "Poppins",
                            fontSize: "0.8em",
                            fontWeight: 200,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#fff",
                            padding: "12px 35px 12px 35px",
                            borderRadius: "4px",
                          }}
                          // onClick={handleSubmit}
                        />
                        <ReusableButton
                          size="medium"
                          variant="contained"
                          className="adminLoginButton"
                          buttonName="Connect Login"
                          style={{
                            backgroundColor: "#23344d",
                            fontFamily: "Poppins",
                            fontSize: "0.8em",
                            fontWeight: 200,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#fff",
                            marginLeft: "40px",
                            padding: "12px 35px 12px 35px",
                            borderRadius: "4px",
                          }}
                          onClick={handleConnectLogin}
                        />
                      </div>
                    </form>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};

export default Login;
