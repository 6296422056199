import { InputLabel, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { SxStyles } from "../../../styles/styles";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { Custom } from "../../../components/Custom/Custom";
import { useEffect, useState } from "react";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { AddBtn } from "../admin-layout/adminStyles";
import { useFormik } from "formik";
import { CreateTagValidation, CreateCategoryValidation } from "../../../Validation/FieldValidatorSchema";
import Notification from "../../../components/Custom/Notification";
import { API } from "../../../Networking/API";

const stausDropdownValue = [
  { key: 0, title: "Active", value: 1 },
  { key: 1, title: "InActive", value: 0 },
];

const typeDropdownValue = [
  { key: 0, title: "Unit", value: 0 },
  { key: 1, title: "Mandatory Training", value: 1 },
];

const categoryTypeDropdown = [
  { key: 0, title: "Unit", value: 0 },
  { key: 1, title: "Mandatory Training", value: 1 },
  { key: 2, title: "Course", value: 2 },
  { key: 3, title: "Program", value: 3 }
];

const TagsEdit = ({ handleCloseModal, getAllTags, tagData, handleEditTag }) => {
  const styles = SxStyles.styles;
  const handleCloseInByCancel = () => {
    handleCloseModal(false);
    setOpen(false);
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const formik = useFormik({
    initialValues: {
      tagName: "",
      description: "",
      status: "",
      type: "",
    },
    validationSchema: CreateTagValidation,
    onSubmit: (values) => {
      handleTagCreation();
    },
  });
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (tagData?.length > 0) {
      formik.setFieldValue("tagName", tagData[0].name);
      formik.setFieldValue("description", tagData[0].description);
      formik.setFieldValue("type", tagData[0].type === "Unit" ? 0 : 1);
      formik.setFieldValue("status", tagData[0].status === "Active" ? 1 : 0);
    }
  }, []);

  const handleTagCreation = () => {
    if (tagData) {
      return handleEditTag(formik.values);
    }
    let tagDetails = {
      tag_name: formik.values.tagName,
      description: formik.values.description,
      status: formik.values.status,
      tag_type: formik.values.type,
    };
    API.AddTag(tagDetails).then((respose) => {
      if (respose?.response?.api_status === 200) {
        setNotify({
          isOpen: true,
          message: respose?.response?.data[0]?.message,
          type: "success",
        });
        getAllTags(respose?.response?.data[0]?.message);
      } else {
        setNotify({
          isOpen: true,
          message: respose?.response?.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };
  return (
    <>
      <Dialog
        divider
        open={open}
        maxWidth="md"
        aperProps={{
          style: {
            overflowX: "hidden",
            paddingLeft: "15px",
          },
        }}
        onClose={handleCloseInByCancel}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        <DialogTitle> {tagData ? "Edit" : "Create"} Tag</DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none" },
          }}
        ></DialogContent>
        <DialogActions>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} className="training-materail">
              <Grid item xs={4}>
                <div style={styles.inputContainer}>
                  <Typography style={styles.label} variant="h6">
                    Tag Name
                    <span style={styles.madatoryMark}>*</span>
                  </Typography>
                  <span>
                    <ReusableInputfield
                      name="tagName"
                      id="tagName"
                      placeholder=" Tag Name"
                      value={formik.values.tagName}
                      onChange={formik.handleChange}
                      size="small"
                      style={styles.inputFleid}
                      error={
                        formik.touched.tagName && Boolean(formik.errors.tagName)
                      }
                      helperText={
                        formik.touched.tagName && formik.errors.tagName
                      }
                    />
                  </span>
                </div>
              </Grid>
              <Grid item xs={4} style={styles.inputContainer}>
                <div style={styles.inputContainer}>
                  <Typography style={styles.label} variant="h6">
                    Description
                  </Typography>
                  <span>
                    <ReusableInputfield
                      name="description"
                      id="description"
                      placeholder="Description"
                      value={formik.values.description}
                      style={styles.inputFleid}
                      onChange={formik.handleChange}
                      size="small"
                    />
                  </span>
                  <div></div>
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                style={SxStyles.createCourseStyle.createCourseGrid}
              >
                <InputLabel
                  className="InputLabel"
                  style={SxStyles.createCourseStyle.createCourseSpan}
                >
                  Select Type
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>
                <Custom.ReusableDropdown
                  name="type"
                  id="type"
                  placeholder="Select Type"
                  displayEmpty
                  options={typeDropdownValue}
                  onChange={formik.handleChange}
                  value={formik.values.type}
                  className="batchmuipopober"
                  size="small"
                  style={{
                    height: 40,
                    fontFamily: "Poppins",
                    width: "256px",
                  }}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                ></Custom.ReusableDropdown>
              </Grid>
              <Grid
                item
                xs={3.8}
                style={SxStyles.createCourseStyle.createCourseGrid}
              >
                <InputLabel
                  className="InputLabel"
                  style={SxStyles.createCourseStyle.createCourseSpan}
                >
                  Status
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>
                <Custom.ReusableDropdown
                  name="status"
                  id="status"
                  placeholder="Select Status"
                  displayEmpty
                  options={stausDropdownValue}
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  className="batchmuipopober"
                  size="small"
                  style={{
                    height: 40,
                    fontFamily: "Poppins",
                    width: "256px",
                  }}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                ></Custom.ReusableDropdown>
              </Grid>
            </Grid>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ReusableButton
                size="medium"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={styles.cancelButton}
                onClick={handleCloseInByCancel}
              />
              <ReusableButton
                className="addbutton"
                type="submit"
                size="large"
                variant="contained"
                buttonName={tagData ? "Update" : "Add"}
                style={AddBtn}
              ></ReusableButton>
            </div>
          </form>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
const CategoryEdit = ({ handleCloseModal, getAllCategories, categoryData, handleEditCategory }) => {
  const styles = SxStyles.styles;
  const handleCloseInByCancel = () => {
    handleCloseModal(false);
    setOpen(false);
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const formik = useFormik({
    initialValues: {
      categoryName: "",
      description: "",
      status: "",
      type: "",
    },
    validationSchema: CreateCategoryValidation,
    onSubmit: (values) => {
      handleCategoryCreation();
    },
  });
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (categoryData?.length > 0) {
      formik.setFieldValue("categoryName", categoryData[0].name);
      formik.setFieldValue("description", categoryData[0].description);
      formik.setFieldValue(
        "type",
        categoryData[0].type === "Unit"
          ? 0
          : categoryData[0].type === "Mandatory Training"
          ? 1
          : categoryData[0].type === "Program"
          ? 3
          : 2
      );
      formik.setFieldValue(
        "status",
        categoryData[0].status === "Active" ? 1 : 0
      );
    }
  }, []);

  const handleCategoryCreation = () => {
    if (categoryData) {
      return handleEditCategory(formik.values);
    }
    let categoryDetails = {
      category_name: formik.values.categoryName,
      description: formik.values.description,
      status: formik.values.status,
      category_type: formik.values.type,
    };
    API.AddCategory(categoryDetails).then((respose) => {
      if (respose?.response?.api_status === 200) {
        setNotify({
          isOpen: true,
          message: respose?.response?.data[0]?.message,
          type: "success",
        });
        getAllCategories(respose?.response?.data[0]?.message);
      } else {
        setNotify({
          isOpen: true,
          message: respose?.response?.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };
  return (
    <>
      <Dialog
        divider
        open={open}
        maxWidth="md"
        aperProps={{
          style: {
            overflowX: "hidden",
            paddingLeft: "15px",
          },
        }}
        onClose={handleCloseInByCancel}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        <DialogTitle> {categoryData ? "Edit" : "Create"} Category</DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none" },
          }}
        ></DialogContent>
        <DialogActions>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} className="training-materail">
              <Grid item xs={4}>
                <div style={styles.inputContainer}>
                  <Typography style={styles.label} variant="h6">
                    Category Name
                    <span style={styles.madatoryMark}>*</span>
                  </Typography>
                  <span>
                    <ReusableInputfield
                      name="categoryName"
                      id="categoryName"
                      placeholder=" Category Name"
                      value={formik.values.categoryName}
                      onChange={formik.handleChange}
                      size="small"
                      style={styles.inputFleid}
                      error={
                        formik.touched.categoryName && Boolean(formik.errors.categoryName)
                      }
                      helperText={
                        formik.touched.categoryName && formik.errors.categoryName
                      }
                    />
                  </span>
                </div>
              </Grid>
              <Grid item xs={4} style={styles.inputContainer}>
                <div style={styles.inputContainer}>
                  <Typography style={styles.label} variant="h6">
                    Description
                  </Typography>
                  <span>
                    <ReusableInputfield
                      name="description"
                      id="description"
                      placeholder="Description"
                      value={formik.values.description}
                      style={styles.inputFleid}
                      onChange={formik.handleChange}
                      size="small"
                    />
                  </span>
                  <div></div>
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                style={SxStyles.createCourseStyle.createCourseGrid}
              >
                <InputLabel
                  className="InputLabel"
                  style={SxStyles.createCourseStyle.createCourseSpan}
                >
                  Select Type
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>
                <Custom.ReusableDropdown
                  name="type"
                  id="type"
                  placeholder="Select Type"
                  displayEmpty
                  options={categoryTypeDropdown}
                  onChange={formik.handleChange}
                  value={formik.values.type}
                  className="batchmuipopober"
                  size="small"
                  style={{
                    height: 40,
                    fontFamily: "Poppins",
                    width: "256px",
                  }}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                ></Custom.ReusableDropdown>
              </Grid>
              <Grid
                item
                xs={3.8}
                style={SxStyles.createCourseStyle.createCourseGrid}
              >
                <InputLabel
                  className="InputLabel"
                  style={SxStyles.createCourseStyle.createCourseSpan}
                >
                  Status
                  <span style={styles.madatoryMark}>*</span>
                </InputLabel>
                <Custom.ReusableDropdown
                  name="status"
                  id="status"
                  placeholder="Select Status"
                  displayEmpty
                  options={stausDropdownValue}
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  className="batchmuipopober"
                  size="small"
                  style={{
                    height: 40,
                    fontFamily: "Poppins",
                    width: "256px",
                  }}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                ></Custom.ReusableDropdown>
              </Grid>
            </Grid>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ReusableButton
                size="medium"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={styles.cancelButton}
                onClick={handleCloseInByCancel}
              />
              <ReusableButton
                className="addbutton"
                type="submit"
                size="large"
                variant="contained"
                buttonName={categoryData ? "Update" : "Add"}
                style={AddBtn}
              ></ReusableButton>
            </div>
          </form>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
export {TagsEdit, CategoryEdit};
