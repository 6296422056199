import { Box, Grid, Typography, IconButton } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "./createUnit.css";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { DataTable } from "../../../../components/Custom/datagrid/datagrid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import ReactLoading from "react-loading";
import Button from "@mui/material/Button";
import ReactPaginate from "react-paginate";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useFormik } from "formik";
import { UploadQuestionsvalidations } from "../../../../Validation/FieldValidatorSchema";
import { SxStyles } from "../../../../styles/styles";
import { API } from "../../../../Networking/API";
import Notification from "../../../../components/Custom/Notification";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import ReusableDelete from "../../../../components/reusable-delete/ReusableDelete";
import UpdateQuizQuestions from "./updateQuizQuestions";

const SheetJSFT = ["csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const QuestionsUpload = () => {
  const styles = SxStyles.styles;
  const [pageCount, setPageCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const inputRef = useRef();
  const [done, setDone] = useState(true);
  const [viewQuestion, setViewQuestion] = useState(false);
  const [question, setQuestion] = useState([]);
  const [editmodal, setEditmodal] = useState(false);
  const [updateQuestionDetails ,setUpdateQuestionDetails] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  let unitId = "";
  if (window.localStorage.getItem("UnitId")) {
    unitId = window.localStorage.getItem("UnitId");
  } else {
    unitId = window.localStorage.getItem("mandateId");
  }
  const [pageLimit, setPageLimit] = useState(10);

  useEffect(() => {
    getQuizQuestions();
  }, [pageCount]);

  const getQuizQuestions = (message) => {
    if(message){
       setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
    }
    const pageNo = pageCount + 1;

    API.getAdminQuestionsUpload(unitId, pageNo).then(({ response }) => {
      setDone(false);

      if (response.data.api_status === 200) {
        const data = response.data.data.rows;
        
        setPageLimit(response.data.data.count);
        const formattedData = data.map((each, index) => ({
          sno: (pageNo - 1) * 10 + (index + 1),
          id: each.question_pk,
          Question: each.question.replace(/�/g, " "),
          CorrectOption: each.ValidOption,
          optionA: each.a,
          optionB: each.b,
          optionC: each.c,
          optionD: each.d,
          optionE:each.e,
          language : each?.language_type
        }));
        setTableData(formattedData);
        setDeleteItems([]);
      } else if (response.data.api_status === 404) {
        setTableData([]);
        setPageCount(0);
      } else {
        setTableData([]);
      }
    });
  };

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: {
      file: "",
    },
    validationSchema: UploadQuestionsvalidations,
    onSubmit: (values) => {
      handleUploadQuestions();
    },
  });

  const handleUploadQuestions = () => {
    setDone(true);
    const { file } = values;
    API.postAdminQuestionUpload(file, unitId).then(({ response }) => {
      inputRef.current.value = null;
      getQuizQuestions();
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: "File Uploaded successfully",
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const csvReport = {
    data: [
      {
        Question: "Which of the following is a programmatic dev tool ? ",
        Answer1: "Work Flow",
        Answer2: "Flow",
        Answer3: "Apex",
        Answer4: "Admin",
        Answer5: "",
        "Correct Option": "Answer3",
        Topic: "",
        "Quiz Type": "MCQ",
        Marks: "",
        Language:""
      },
    ],
    filename: "QuizzQuestions",
  };
  const handleViewClick = (questionId) => {
    setViewQuestion(true);
    const SelectedQuestion = tableData.filter((arr) => arr.id === questionId);
    setQuestion(SelectedQuestion);
  };
  const handleEditModal = (id) => {
    setEditmodal(true);
    const filteredQuestion = tableData.filter((arr) => arr.id === id);
    setUpdateQuestionDetails(filteredQuestion);
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
  };


  const handleClickOpen = (params) => {
    setId(params.row.id);
    setOpen(true);
  };

  const handleCloseByCancel = () => {
    setOpen(false);
    setViewQuestion(false);
  };

  const handleCloseByDelete = (id) => {
    setDone(true);
    API.adminQuizQuestionsDelete(unitId, id).then(({ result }) => {
      const response = result;
      if (response.api_status === 200) {
        if(tableData.length===1) setPageCount(Math.abs(pageCount-1)); 
        else getQuizQuestions();
        setOpen(false);
        setNotify({
          isOpen: true,
          message: "Question Deleted Successfully",
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Question Not Deleted Please Try Again",
          type: "error",
        });
      }
    });
  };
 const handleClose = ()=>{
  setEditmodal(false);
  setUpdateQuestionDetails([]);
 };
  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      width: 65,
    },
    {
      field: "Question",
      headerName: "Question",
      width: 580,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "CorrectOption",
      headerName: "Answer",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <IconButton
            aria-label="view"
            size="small"
            style={{ marginRight: "5px" }}
          >
            <RemoveRedEyeIcon
              color="primary"
              fontSize="inherit"
              style={styles.tableIcon}
              onClick={() => {
                handleViewClick(params.id);
              }}
            />
          </IconButton>
          <IconButton
            aria-label="edit"
            size="small"
            style={{ marginRight: "5px" }}
          >
            <EditIcon
              color="primary"
              fontSize="inherit"
              style={styles.tableIcon}
              onClick={() => {
                handleEditModal(params.id);
              }}
            />
          </IconButton>
          <IconButton aria-label="view" size="small">
            <DeleteOutlineOutlinedIcon
              color="primary"
              fontSize="inherit"
              style={styles.tableIcon}
              onClick={() => handleClickOpen(params)}
            />
          </IconButton>
          <Dialog
            open={open}
            maxWidth="sm"
            onClose={handleCloseByCancel}
            BackdropProps={{
              style: {
                background: "#00000022",
                boxShadow: "0px 0px 0px 0px !important",
              },
            }}
          >
            <DialogTitle></DialogTitle>
            <DialogContent
              style={{ boxShadow: "none" }}
              BackdropProps={{
                style: { boxShadow: "none", transition: "none" },
              }}
            >
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete?
                <span style={{ fontWeight: "bold", marginLeft: "5px" }}></span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                size="medium"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#000936",
                  border: "1px solid #000936",
                  padding: "7px 20px",
                  borderRadius: "4px",
                  marginRight: "8px",
                  textTransform: "none",
                }}
                onClick={handleCloseByCancel}
              />
              <Button
                size="medium"
                variant="contained"
                className="qstnUpldPageBttn"
                buttonName="Delete"
                style={{
                  backgroundColor: "#fb2485",
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#fff",
                  padding: "8px 28px",
                  borderRadius: "4px",
                  textTransform: "none",
                  marginRight: "10px",
                }}
                onClick={() => handleCloseByDelete(id)}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={viewQuestion}
            maxWidth="md"
            onClose={handleCloseByCancel}
            className="edit_modal"
            BackdropProps={{
              style: {
                background: "#00000022",
                boxShadow: "0px 0px 0px 0px !important",
              },
            }}
          >
            <DialogTitle></DialogTitle>
            <DialogContent
              style={{ boxShadow: "none" }}
              BackdropProps={{
                style: { boxShadow: "none", transition: "none" },
              }}
            >
              <DialogContentText id="alert-dialog-slide-description">
                <div>
                  <h2 style={{ color: "black" }}>{question[0]?.Question}</h2>
                  <p>A.{question[0]?.optionA}</p>
                  <p>B.{question[0]?.optionB}</p>
                  <p>C.{question[0]?.optionC}</p>
                  <p>D.{question[0]?.optionD}</p>
                  <h4 style={{ color: "black" }}>
                    Correct Option:
                    <span style={{ paddingLeft: "5px", fontSize: "18px" }}>
                      {question[0]?.CorrectOption}
                    </span>
                  </h4>
                </div>
                <span style={{ fontWeight: "bold", marginLeft: "5px" }}></span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                size="medium"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#000936",
                  border: "1px solid #000936",
                  padding: "7px 20px",
                  borderRadius: "4px",
                  marginRight: "8px",
                  textTransform: "none",
                  marginBottom:"10px"
                }}
                onClick={handleCloseByCancel}
              />
            </DialogActions>
          </Dialog>
        </div>
      ),
    },
  ];

  const onSelectionModelChange = (selectionModel) => {
    setDeleteItems(selectionModel);
  };

  const handleDeleteAll = () => {
    setOpenDelete(true);
  };
  const handleDeleteItems = () => {
    const payLoad = JSON.stringify({
      unit_id: unitId,
      question_pk: deleteItems,
    });
    API.adminQuizQuestionsDeleteAll(payLoad).then((response) => {
      if (response?.response?.api_status === 200) {
        setOpenDelete(false);
        setNotify({
          isOpen: true,
          message: response.response.data[0].message,
          type: "success",
        });
        getQuizQuestions();
      } else {
        setNotify({
          isOpen: true,
          message: response.response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handlePageClick = async (e, pageNumber) => {
    pageNumber = e.selected;
    setPageCount(pageNumber);
  };

  return (
    <>
      <Box
        className="training-material-container"
        style={styles.assignemntHieght}
      >
        <form onSubmit={handleSubmit}>
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <Grid item xs={4} style={{...styles.fileContainer,marginLeft:"0px"}}>
              <div style={styles.inputContainer}>
                <Typography style={styles.label} variant="h6">
                  Upload Questions
                  <span style={styles.madatoryMark}>*</span>
                </Typography>
                <span>
                  <input
                    name="file"
                    id="file"
                    type="file"
                    class="upload-box"
                    accept={SheetJSFT}
                    style={styles.fileInput}
                    ref={inputRef}
                    onChange={(e) => handleFileChange(e, setFieldValue)}
                  />
                </span>
                <Typography style={styles.note} variant="p">
                  Only .csv format allowed &nbsp;
                  <span>
                    {errors.file && (
                      <Typography style={styles.madatoryMark} variant="p">
                        {errors.file}
                      </Typography>
                    )}
                  </span>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={1.8} style={styles.uploadQuizAddBtn}>
              <ReusableButton
                size="large"
                variant="outlined"
                buttonName="Upload"
                type="submit"
                style={styles.button}
              ></ReusableButton>
               <ReusableButton
                size="large"
                variant="outlined"
                buttonName="Add Question"
                type="button"
                onClick={()=>setEditmodal(true)}
                style={{...styles.button , marginLeft :"10px"}}
              ></ReusableButton>
            </Grid>
            <Grid
              item
              style={{ paddingTop: "6.2%", paddingLeft: "2.5%" }}
              xs={2.5}
            >
              <CSVLink
                style={{
                  color: "#1976d2",
                  display: "flex",
                  alignItems: "center",
                }}
                {...csvReport}
              >
                <DownloadIcon
                  color="primary"
                  fontSize="medium"
                  style={{
                    marginRight: "5px",
                  }}
                />
                Download Sample
              </CSVLink>
            </Grid>
          </Grid>
        </form>
        {done ? (
          <Box sx={{ flexGrow: 1 }} style={styles.loadingContainer}>
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <>
            {tableData.length > 0 ? (
              <>
                <Box style={{ marginTop: "40px" }}>
                  <ReusableButton
                    disabled={deleteItems.length === 0}
                    style={{
                      ...styles.saveForLaterBtn,
                      marginBottom: "1%",
                      cursor: deleteItems.length === 0 ? "" : "pointer",
                      color: deleteItems.length === 0 ? "#fff" : "#fff",
                      backgroundColor:
                        deleteItems.length === 0 ? "grey" : "#FB2485",
                    }}
                    onClick={() => handleDeleteAll()}
                    buttonName="Delete"
                  />
                  <DataTable
                    rows={tableData}
                    columns={columns}
                    rowHeight={40}
                    autoHeight
                    pageSize={10}
                    hideFooter={true}
                    hideFooterPagination={true}
                    hideFooterSelectedRowCount={true}
                    className="assigntable"
                    checkboxSelection={true}
                    onSelectionModelChange={onSelectionModelChange}
                  />
                  {pageLimit > 9 ? (
                    <Box>
                      <ReactPaginate
                        previousLabel={<>&laquo;</>}
                        nextLabel={<>&raquo;</>}
                        pageCount={Math.ceil(pageLimit / 10)}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                        forcePage={pageCount}
                      />
                    </Box>
                  ) : null}
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <Typography variant="h6" style={styles.UploadQuizErrMsg}>
                    No Data Found
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      {openDelete && (
        <ReusableDelete
          handleDeleteTag={handleDeleteItems}
          handleDeleteClose={handleDeleteClose}
        />
      )}  
  {
    editmodal && (<UpdateQuizQuestions updateQuestionDetails={updateQuestionDetails[0]} unitId={unitId} handleClose={handleClose} getQuizQuestions={getQuizQuestions}/>)
  }
    </>
  );
};

export default QuestionsUpload;
