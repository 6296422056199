// import { borderRadius } from "@mui/system";

const DatGridStyles = {
  root: {
    borderRadius: "18px !important",
  },
  "& .MuiDataGrid-footerContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center !important",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "0.875rem",
  },
  "& .MuiDataGrid-autoHeight": {
    borderRadius: "18px !important",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderRadius: "12px 12px 0px 0px",
    backgroundColor: "lavender",
    color: "#000936",
  },
};

const DialogBoxStyle = {
  "& .MuiModal-backdrop": {
    backgroundColor: "rgba(0, 0, 0, 0.112) !important",
  },
};
const containerStyle={
  display: "flex", alignItems: "center",marginBottom:"12px" 
};
const headingStyle={
  marginTop: "0",
  marginBottom: "0"
};
const paragraphStyle={
  marginTop: "0px", fontSize: "16px",marginBottom:"0",paddingLeft:"5px"
};
const createCourseStyle = {
  mainContainerCard: {
    marginTop: "2%",
    Width: "100%",
    borderRadiusTopLeft: 2,
    marginBottom: "7%",
  },
  backBtn: {
    backgroundColor: "white",
    color: "#676767",
    padding: "11px 17.4px 11px 17px",
    textTransform: "none",
    borderRadius: 6,
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex-start",
  },
  createCourseDiv: {
    display: "flex",
    width: "100%",
    backgroundColor: "#F0F0F0",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 24,
    paddingLeft: "3%",
    paddingBottom: "1.5%",
    paddingTop: "1.5%",
  },
  mainGrid: {
    display: "flex",
    justifyContent: "start",
    marginTop: "0.5%",
    padding: "20px",
    marginLeft:"8px"
    // width: "103%",
  },
  trainingGrid: {
    display: "flex",
    marginLeft: "2%",
    marginTop: "0.5%",
  },

  createCourseGrid: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  createDurationGrid: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    // marginTop: "24px",
  },
  createCourseSpan: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    marginBottom: "8px",
  },
  aboutCourseBox: {
    width: "94%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: "3.5%",
  },
  aboutCourseh1: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#0A0A0A",
  },
  aboutCourseTextArea: {
    width: "100%",
    height: "14vh",
    boxSizing: "border-box",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "#0A0A0A",
    fontSize: "16px",
    padding: "5px 0px 0px 10px",
  },
  unitsParentBox: {
    marginTop: "1%",
    width: "94%",
    marginLeft: "3%",
    marginBottom: "3%",
  },
  unitsParentDiv: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 18,
    marginBottom: "7px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  unitsSpan: { paddingTop: "4%", marginLeft: "1%" },
  addUnitButton: {
    backgroundColor: "#fb2485",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#fff",
    borderRadius: "4px",
    textTransform: "none",
  },
  addCancelDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "2.5%",
    marginRight: "3.3%",
  },
  addCancelDivCourse: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "2.5%",
    marginRight: "1.6%",
  },
  cancelButton: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#000936",
    border: "1px solid #000936",
    padding: "7px 20px",
    borderRadius: "4px",
    marginRight: "10px",
    marginBottom: "25px",
    textTransform: "none",
    width: "134px",
  },
  modalCancelBtn: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#000936",
    border: "1px solid #000936",
    padding: "7px 20px",
    borderRadius: "4px",
    marginRight: "10px",
    textTransform: "none",
    width: "134px",
  },
  createUnitButton: {
    backgroundColor: "#fb2485",
    marginRight: "30px",
    marginBottom: "25px",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    marginLeft: "1%",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#fff",
    padding: "8px 30px",
    borderRadius: "4px",
    textTransform: "none",
  },
  loadingBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  mainCreateCourseSpan: {
    fontSize: 22,
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#ffffff",
  },
  training_mat_header: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#02215b",
    display: "contents",
    marginLeft: "15px",
  },
};

const styles = {
  label: {
    fontSize: "15px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    marginBottom: "10px",
  },
  labelMedia: {
    fontSize: "15px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    marginBottom: "10px",
  },
  label1: {
    fontSize: "15px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },

  inputContainerValidator: {
    display: "flex",
    flexDirection: "column",
    // marginTop: "20px",
  },

  fileContainer: {
    paddingTop: "25px",
    marginLeft: "20px",
  },
  fileContainerFiles: {
    paddingTop: "25px",
    // marginLeft: "20px",
  },

  inputAndFileContainerValidator: {
    display: "flex",
    flexDirection: "column",
    // marginTop: "20px",
    // paddingTop: "25px",
  },
  inputAndFileContainerValidatorPrePreq: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },
  madatoryMark: {
    color: "red",
    fontFamily: "Poppins",
    fontSize: "11px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    paddingTop:"20px"
  },
  fileInput: {
    fontSize: "0.8em",
    fontFamily: "Poppins",
    height: "41px",
  },
  note: {
    fontFamily: "Poppins",
    fontSize: "11px",
    marginTop: "3px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    width: "58vh",
  },
  note1: {
    fontFamily: "Poppins",
    fontSize: "11px",
    marginTop: "3px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    width: "auto",
  },
  button: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "white",
    backgroundColor: "#FB2485",
    padding: "8px 20px",
    borderRadius: "4px",
    // marginRight: "10px",
    marginTop: "25px",
    border: "none",
    textTransform: "none",
  },
  updateButton: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "white",
    backgroundColor: "#FB2485",
    padding: "8px 40px",
    borderRadius: "4px",
    // marginRight: "10px",
    marginTop: "25px",
    border: "none",
    textTransform: "none",
  },
  buttonDisabled: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "white",
    backgroundColor: "grey",
    padding: "8px 20px",
    borderRadius: "4px",
    // marginRight: "10px",
    marginTop: "25px",
    border: "none",
    textTransform: "none",
  },
  updateButtonDisabled: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "white",
    backgroundColor: "grey",
    padding: "8px 40px",
    borderRadius: "4px",
    // marginRight: "10px",
    marginTop: "25px",
    border: "none",
    textTransform: "none",
  },
  addbuttonTrainingMaterialFiles: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "white",
    backgroundColor: "#FB2485",
    padding: "8px 40px",
    borderRadius: "4px",
    // marginTop: "30px",
    border: "none",
    textTransform: "none",
  },
  addbuttonTrainingMaterial: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "white",
    backgroundColor: "#FB2485",
    padding: "8px 40px",
    borderRadius: "4px",
    marginTop: "30px",
    border: "none",
    textTransform: "none",
  },
  addbuttonTrainingMaterial1: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "white",
    backgroundColor: "#FB2485",
    padding: "8px 40px",
    borderRadius: "4px",
    marginTop: "44.5px",
    height: "38px",
    border: "none",
    textTransform: "none",
  },
  addbuttonPrerequisite: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "white",
    backgroundColor: "#FB2485",
    padding: "10px 20px",
    borderRadius: "4px",
    marginTop: "20px",
    border: "none",
    textTransform: "none",
    marginLeft: "26px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "2%",
    marginTop: "2%",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    height: "24vh",
  },

  tableContainer: { marginTop: "30px" },
  tableBgColor: {
    backgroundColor: "white",
  },
  tableErrorMsg: {
    marginTop: "30px",
    backgroundColor: "#fcfbfb",
    color: "#9a9a9a",
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#000936",
    border: "1px solid #000936",
    padding: "8px 30px",
    borderRadius: "4px",
    marginRight: "10px",
    marginBottom: "25px",
    textTransform: "none",
    width: "138px",
  },
  saveForLaterBtn: {
    backgroundColor: "#001139",
    marginBottom: "25px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#fff",
    padding: "8px 30px",
    borderRadius: "4px",
    textTransform: "none",
    width: "152px",
  },
  saveForLaterBtnDisabled: {
    backgroundColor: "grey",
    marginBottom: "25px",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#fff",
    padding: "8px 30px",
    borderRadius: "4px",
    textTransform: "none",
  },
  createBtn: {
    backgroundColor: "#fb2485",
    marginBottom: "25px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#fff",
    padding: "8px 30px",
    textTransform: "none",
    marginLeft: "10px",
    borderRadius: "7px",
    width: "136px",
  },
  modalAddButton: {
    backgroundColor: "#fb2485",
    // marginBottom: "25px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#fff",
    padding: "8px 30px",
    textTransform: "none",
    marginLeft: "10px",
    borderRadius: "7px",
    width: "136px",
  },
  createBtnDisabled: {
    backgroundColor: "grey",
    marginBottom: "25px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#fff",
    padding: "8px 30px",
    marginLeft: "10px",
    borderRadius: "4px",
    textTransform: "none",
  },
  inputFleid: {
    height: "41px",
    // width: "200px",
  },
  tableAnchorTag: { display: "flex", flexDirection: "row" },
  tableSpanText: { color: "#676767", textDecoration: "none" },
  tableIcon: {
    minWidth: "14px !important",
    color: "blue !important",
  },
  dialogBoxContainer: {
    background: "#00000022",
    boxShadow: "0px 0px 0px 0px !important",
  },
  descriptionField: {
    width: "99%",
    height: "17vh",
    boxSizing: "border-box",
    border: "1px solid #ccc",
    borderRadius: "4px",
    paddingLeft: "12px",
    paddingTop: "9px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "#0A0A0A",
    fontSize: "14px",
  },
  createBtnDialog: {
    backgroundColor: "#fb2485",
    marginRight: "30px",
    marginBottom: "25px",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAalign: "center",
    color: "#fff",
    padding: "8px 28px",
    borderRadius: "4px",
    textTransform: "none",
  },
  gridContainer: { marginBottom: "20px" },
  addBtn: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "end",
    paddingRight: "3%",
  },
  assignemntHieght: { minHeight: "170vh" },
  dropdownWidth: {
    height: 41,
    width: "100%",
    fontFamily: "Poppins",
  },
  disableInputField: {
    // border: "1px solid #B9B9B9",
    borderRadius: "5px",
    width: "100%",
  },
  descriptionErrMsg: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "red",
    fontSize: "12px",
  },
  uploadQuizAddBtn: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    paddingTop: "1%",
  },
  uploadAssignParamsButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginTop: "30px",
  },
  pagination: {
    display: "flex",
    backgroundColor: "black",
    justifyContent: "center",
    margin: "20px 0px 0px 0px",
  },
  UploadQuizErrMsg: {
    textAlign: "center",
    color: "balck",
    fontWeight: "600",
    paddingBottom: "30px",
    fontSize: "16px",
    marginTop: "10%",
  },
  backBtn: {
    backgroundColor: "white",
    color: "#676767",
    padding: "11px 17.4px 11px 17px",
    textTransform: "none",
    borderRadius: 6,
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "20px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  assignmentLoadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "30vh",
  },
  headerContainer: {
    marginBottom: "20px",
  },
  ArrowDown: {
    width: "30%",
    height: "50%",
    color: "#1976d2",
    cursor: "pointer",
  },
  UnitTagsStyle: {
    fontStyle: "normal",
    fontWeight: "400 ",
    fontSize: "11px ",
    lineHeight: "11px",
    color: "#02215B ",
    margin: "0px 1% 0px 0% ",
    textTransform: "capitalize ",
    background: "#E5E9EF ",
    borderRadius: "2px ",
    padding: "6px 7px ",
    display: "inline-block",
    fontFamily: "Poppins",
  },
  preRequisitesEditDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  editIcon: {
    color: "#1976d2",
    marginRight: "0%",
    fontSize: "19px",
    cursor: "pointer",
  },
};

export const SxStyles = {
  DatGridStyles,
  DialogBoxStyle,
  createCourseStyle,
  containerStyle,
  headingStyle,
  paragraphStyle,
  styles,
};
