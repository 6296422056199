import * as React from "react";
import { Box,  Button } from "@mui/material";
import "./DashBoard.css";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { API } from "../../../Networking/API";
import ReactLoading from "react-loading";
import { Custom } from "../../../components/Custom/Custom";
import { MyForm } from "../../../components/MyForm";
import { makeStyles } from "@material-ui/core";
import BasicCard from "../../../components/Card";
import Typography from "@mui/material/Typography";
import Avatar from "@material-ui/core/Avatar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import calender from "../../../assets/Images/calender.png";
import Score from "../../../assets/Images/score.svg";
import { ReactComponent as Inprogress } from "../../../assets/Images/Time-Waiting.svg";
import { ReactComponent as InprogressWhite } from "../../../assets/Images/time-watiing-white.svg";
import { ReactComponent as Completed } from "../../../assets/Images/completed.svg";
import { ReactComponent as CompletedWhite } from "../../../assets/Images/completed-white.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const getDepartment = [
  { id: "1", title: "All" },
  { id: "2", title: "Courses" },
  { id: "3", title: "Programs" },
  { id: "4", title: "Mandatory Trainings" },
];

const styles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    marginBottom: "8%",
  },
  inputStyle: {
    "& .MuiInputBase-fullWidth": {
      width: "53vw",
      height: "44px",
    },
  },
  ".MuiInputBase-root": {
    color: "#fff !important",
  },
  label: {
    color: "#fff !important",
    fontSize: "16px",
    marginTop: "-10px",
    fontFamily: "poppins",
    transform: " translate(14px, 20px) scale(1) !important",
    borderColor: "#fb2485 !important",
  },
}));

const initialValues = {
  sessionName: "",
};
function DashBoard() {
  const [done, setDone] = useState(false);
  const [filter, setFilter] = useState("All");
  const [coursepageNumber, setCoursePageNumber] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [trainpageNumber, setTrainPageNumber] = useState(1);
  const [programPageNumber, setprogramPageNumber] = useState(1);
  const classes = styles();
  //eslint-disable-next-line
  const [allUnits, setAllUnits] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [totalCourses, setTotalCourses] = useState(null);
  const [totalTrainings, setTotalTrainings] = useState(null);
  const [totalPrograms, setTotalPrograms] = useState(null);
  const [totalUnits, setTotalUnits] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoadCourse, setIsLoadCourse] = useState(false);
  //eslint-disable-next-line
  const [isLoadUnit, setIsLoadUnit] = useState(false);
  const [isLoadTraining, setIsLoadTraining] = useState(false);
  //eslint-disable-next-line
  const [currentDeletionId, setCurrentDeletionId] = useState(0);
  //eslint-disable-next-line
  const [type, setType] = useState("");
  const [totalCompleted, setTotalComleted] = useState("");
  const [totalInprogress, setTotalInprogress] = useState("");
  const [overalScore, setOveralScore] = useState("");
  const [statusCheck, setStatusCheck] = useState("");
  const [entryType, setEntryType] = useState(null);
  const [orgRole, setOrgRole] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [isProgressClick, setIsProgressClick] = useState();
  const [isCompleteClick, setIsCompleteClick] = useState();
  const [mandateTraining, setMandateTraining] = useState([
    {
      id: 1,
      title: "POSH1 Training",
      description: "Prevention of Sexual Harassment at Workplace.",
      duration: 60,
      userActivityTrack: true,
      tags: [
        {
          id: 1,
          tag_name: "POSH",
        },
      ],
    },
    {
      id: 2,
      title: "POSH2 Training",
      description: "Prevention of Sexual Harassment at Workplace.",
      duration: 30,
      userActivityTrack: true,
      tags: [
        {
          id: 1,
          tag_name: "POSH",
        },
      ],
    },
  ]);
 
  const { values,  handleInputChange } =
    MyForm(initialValues, true);

  const handleSearch = () => {
    setIsLoadCourse(true);
    setIsLoadUnit(true);
    var searchWord = values.sessionName.toLowerCase().trim();
    if (searchWord.length !== 0) {
      setSearchKey(searchWord);
      setPageNumber(1);
      setCoursePageNumber(1);
      setTrainPageNumber(1);
      setprogramPageNumber(1);
      getCompletedData(searchWord, 1, statusCheck);
    } else {
      setSearchKey("");
      setPageNumber(1);
      setCoursePageNumber(1);
      setTrainPageNumber(1);
      setprogramPageNumber(1);
      getCompletedData("", 1, statusCheck);
    }
  };
  const getCompletedData = (searchWord, pageNum, status) => {
    getAllUnits(searchWord, pageNum, status);
    getAllCourses(searchWord, pageNum, status);
    getAllTrainings(searchWord, pageNum, status);
    getAllPrograms(searchWord, pageNum, status);
  };

  useEffect(() => {
    if (open === false) {
      getAllUnits("", 1);
      getAllCourses("", 1);
      getAllTrainings("", 1);
      getAllPrograms("", 1);
    }
  }, [open]);

  const getAllUnits = async (searchValue, pageNum, status) => {
    let search = searchValue;
    let limit = "12";
    let page = pageNum;
    let _status = "";
    if (status && status !== undefined && status !== "") {
      _status = status;
    }
    const param = `?search=${search}&limit=${limit}&page=${page}&status=${_status}`;
    await API.getAllUserUnits(param).then(({ response }) => {
      if (response.data.api_status === 200) {
        setAllUnits(response.data.data.rows);
        setTotalUnits(response.data.data.count);
      } else {
        setAllUnits([]);
        setTotalUnits(0);
      }
      setDone(true);
      setIsLoadUnit(false);
    });
  };
  const getAllCourses = async (searchValue, pageNum, status) => {
    let search = searchValue;
    let limit = "4";
    let page = pageNum;
    let _status = "";
    if (status && status !== undefined && status !== "") {
      _status = status;
    }
    const param = `?search=${search}&limit=${limit}&page=${page}&status=${_status}`;
    await API.getAllUserCourses(param).then(({ response }) => {
      if (response.data.api_status === 200) {
        setAllCourses(response.data.data.rows);
        setTotalCourses(response.data.data.count);
      } else {
        setAllCourses([]);
        setTotalCourses(0);
      }
      setIsLoadCourse(false);
    });
  };
  const getAllTrainings = async (searchValue, pageNum, status) => {
    let search = searchValue;
    let limit = "4";
    let page = pageNum;
    let _status = "";
    if (status && status !== undefined && status !== "") {
      _status = status;
    }
    const param = `?search=${search}&limit=${limit}&page=${page}&status=${_status}`;
    await API.getAllUserTraining(param).then(({ response }) => {
      if (response.data.api_status === 200) {
        setMandateTraining(response.data.data.rows);
        setTotalTrainings(response.data.data.count);
      } else {
        setMandateTraining([]);
        setTotalTrainings(0);
      }
      setIsLoadTraining(false);
    });
  };
  const getAllPrograms = async (searchValue, pageNum, status) => {
    let search = searchValue;
    let limit = "4";
    let page = pageNum;
    let _status = "";
    if (status && status !== undefined && status !== "") {
      _status = status;
    }
    const param = `?search=${search}&limit=${limit}&page=${page}&status=${_status}`;
    await API.getAllUserPrograms(param).then(({ response }) => {
      if (response.data.api_status === 200) {
        setPrograms(response.data.data.rows);
        setTotalPrograms(response.data.data.count);
      } else {
        setPrograms([]);
        setTotalPrograms(0);
      }
      setIsLoadTraining(false);
    });
  };

  const handleInputChangeFilter = (e) => {
    setFilter(e.target.value);
    setPageNumber(1);
    setCoursePageNumber(1);
    setTrainPageNumber(1);
    setprogramPageNumber(1);
    getCompletedData(searchKey, 1, statusCheck);
  };

  const onPrevious = (e) => {
    if (e === "course") {
      if (coursepageNumber > 1) {
        setCoursePageNumber(coursepageNumber - 1);
        getAllCourses(searchKey, coursepageNumber - 1);
      }
    } else if (e === "units") {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
        getAllUnits(searchKey, pageNumber - 1);
      }
    } else if (e === "train") {
      if (trainpageNumber > 1) {
        setTrainPageNumber(trainpageNumber - 1);
        getAllTrainings(searchKey, trainpageNumber - 1);
      }
    } else {
      if (programPageNumber > 1) {
        setprogramPageNumber(programPageNumber - 1);
        getAllPrograms(searchKey, programPageNumber - 1);
      }
    }
  };
  // const onNext = () => {
  //   let Num =
  //     filter === "All"
  //       ? totalCourses > totalUnits
  //         ? Math.ceil(totalCourses / 4)
  //         : Math.ceil(totalUnits / 4)
  //       : filter === "Courses"
  //       ? Math.ceil(totalCourses / 4)
  //       : Math.ceil(totalUnits / 4);
  //   if (pageNumber < Num) {
  //     setPageNumber(pageNumber + 1);
  //     getAllUnits(searchKey, pageNumber + 1);
  //     getAllCourses(searchKey, pageNumber + 1);
  //     getAllTrainings(searchKey, pageNumber + 1);
  //   }
  // };
  const onNext = (e) => {
    let Num;
    if (e === "course") {
      Num = Math.ceil(totalCourses / 4);
      if (coursepageNumber < Num) {
        setCoursePageNumber(coursepageNumber + 1);
        getAllCourses(searchKey, coursepageNumber + 1);
      }
    } else if (e === "units") {
      Num = Math.ceil(totalUnits / 12);
      if (pageNumber < Num) {
        setPageNumber(pageNumber + 1);
        getAllUnits(searchKey, pageNumber + 1);
      }
    } else if (e === "train") {
      Num = Math.ceil(totalTrainings / 4);
      if (trainpageNumber < Num) {
        setTrainPageNumber(trainpageNumber + 1);
        getAllTrainings(searchKey, trainpageNumber + 1);
      }
    } else {
      Num = Math.ceil(totalPrograms / 4);
      if (programPageNumber < Num) {
        setprogramPageNumber(programPageNumber + 1);
        getAllPrograms(searchKey, programPageNumber + 1);
      }
    }
  };

  const openModal = () => {
    setOpen(true);
  };

  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
  // }));


  const getAllCompletedCount = async () => {
    await API.GetDashboardCounts().then(({ response }) => {
      if (response.data.api_status === 200) {
        setTotalComleted(
          response.data.data.course_completed +
            response.data.data.unit_completed +
            response.data.data.mandatory_completed
        );

        setTotalInprogress(
          Number(response.data.data?.course_inprogress) +
            Number(response.data.data?.unit_inprogress) +
            Number(
              response.data.data?.mandatory_inprogress
                ? response.data.data?.mandatory_inprogress
                : 0
            )
        );
      } else {
        setTotalComleted(0);
        setTotalInprogress(0);
      }
    });
  };

  const getAllCompleteScore = async () => {
    await API.GetDashboardScore().then(({ response }) => {
      if (response.data.api_status === 200) {
        setOveralScore(response.data.data.overall_score);
      } else {
        setOveralScore(0);
      }
    });
  };

  useEffect(() => {
    getAllCompletedCount();
    getAllCompleteScore();

    //To get Entry Type
    const getCookieValue = (name) => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return cookieValue;
        }
      }
      return null;
    };

    const entryTypeCookie = getCookieValue("entryType");

    if (entryTypeCookie !== null) {
      const parsedEntryType = JSON.parse(entryTypeCookie);
      setEntryType(parsedEntryType);
    }

    //To get Role

    const getRole = (name) => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() === name) {
          return cookieValue;
        }
      }
      return null;
    };
    const orgiRole = getRole("orginalRole");
    // console

    if (orgiRole !== null) {
      const parsedEntryType = orgiRole;
      setOrgRole(parsedEntryType);
    }
  }, []);
  const onStatusCheck = async (str) => {
    if (str === "completed") {
      setStatusCheck(str);
      getCompletedData("", 1, str);
      setIsCompleteClick(true);
      setIsProgressClick(false);
    } else if (str === "inprogress") {
      setStatusCheck(str);
      getCompletedData("", 1, str);
      setIsProgressClick(true);
      setIsCompleteClick(false);
    } else {
      setStatusCheck(str);
      getCompletedData("", 1, str);
      setIsCompleteClick(false);
      setIsProgressClick(false);
    }
  };
  return (
    <div className={classes.root}>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <Grid
            container
            style={{ width: " 95%", margin: " 0px auto", marginTop: "50px" }}
          >
            <Box className="create-box">
              {/* <h1
                className="masterdata-heading"
                style={{ marginTop: "1%", textAlign: "left" }}
              >
                Hello! Michael John.
              </h1> */}
              <Typography
                variant="div"
                sx={{
                  float: "right",
                  marginRight: "15px !important",
                  background: "#FB2485 !important",
                  color: "#FFFFFF !important",
                  borderRadius: "6px",
                }}
              ></Typography>
            </Box>
          </Grid>

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            style={{ width: " 95%", margin: " 0px auto", marginTop: "10px" }}
          >
            <Grid item xs={2} sm={3} md={3} style={{ paddingRight: "10px" }}>
              <Grid lg={12}>
                <Card
                  sx={{
                    display: "flex",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={calender}
                    alt="Live from space album cover"
                    className="img-status"
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography
                        component="div"
                        variant="h5"
                        className="count-text"
                      >
                       {totalPrograms}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        className="status-text"
                      >
                        Enrolled
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={3} md={3} style={{ paddingLeft: "10px" }}>
              <Grid lg={12}>
                <Card
                  sx={{
                    display: "flex",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={Score}
                    alt="Live from space album cover"
                    className="img-status2"
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography
                        component="div"
                        variant="h5"
                        className="count-text"
                      >
                        {overalScore}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        className="status-text"
                      >
                        Score
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={3} md={3}>
              <Grid md={12}>
                <Card
                  sx={{
                    display: "flex",
                    padding: "20px",
                    borderRadius: "10px",
                    backgroundColor: isProgressClick ? "#fb2485" : "#fff",
                  }}
                  onClick={() => {
                    onStatusCheck(isProgressClick ? "" : "inprogress");
                  }}
                  className="cardlist"
                >
                  {isProgressClick ? (
                    <InprogressWhite
                      className="img-white"
                      alt="Live from space album cover"
                    />
                  ) : (
                    <Inprogress
                      className="img-status"
                      alt="Live from space album cover"
                    />
                  )}
                  {/* </SvgIcon> */}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography
                        component="div"
                        variant="h5"
                        className="count-text"
                        sx={{
                          color: isProgressClick
                            ? "#fff !important"
                            : "#fb2485",
                        }}
                      >
                        {totalInprogress}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        className="status-text"
                        sx={{
                          color: isProgressClick
                            ? "#fff !important"
                            : "#fb2485",
                        }}
                      >
                        In Progress
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={3} md={3}>
              <Grid md={12}>
                <Card
                  sx={{
                    display: "flex",
                    padding: "20px",
                    borderRadius: "10px",
                    backgroundColor: isCompleteClick ? "#fb2485" : "#fff",
                  }}
                  onClick={() => {
                    onStatusCheck(isCompleteClick ? "" : "completed");
                  }}
                  className="cardlist"
                >
                  {isCompleteClick ? (
                    <CompletedWhite
                      // htmlColor="#fff"
                      className="img-white"
                      alt="Live from space album cover"
                    />
                  ) : (
                    <Completed
                      // htmlColor="#fff"
                      className="img-status"
                      alt="Live from space album cover"
                    />
                  )}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography
                        component="div"
                        variant="h5"
                        className="count-text"
                        sx={{
                          color: isCompleteClick
                            ? "#fff !important"
                            : "#fb2485",
                        }}
                      >
                        {totalCompleted}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        className="status-text"
                        sx={{
                          color: isCompleteClick
                            ? "#fff !important"
                            : "#fb2485",
                        }}
                      >
                        Completed
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Box className="create-box">
            <h1 className="masterdata-heading" style={{ marginTop: "1%" }}>
              My Learnings
            </h1>
            <Typography
              variant="div"
              sx={{
                float: "right",
                marginRight: "15px !important",
                background: "#FB2485 !important",
                color: "#FFFFFF !important",
                borderRadius: "6px",
              }}
            ></Typography>
          </Box>

          <Box className="dashboard-box">
            <Grid container>
              <Grid item xs={2.5}>
                <Custom.Dropdown
                  name="sessions"
                  label="Filtered By"
                  value={filter}
                  onChange={handleInputChangeFilter}
                  options={getDepartment}
                  size="small"
                  className="mentorclassName"
                  style={{
                    height: 40,
                    alignItems: "center",
                    display: "flex",
                    fontFamily: "Poppins",
                    "& .MuiPopover-paper": {
                      height: "19.5vh",
                      minWidth: "180px",
                    },
                  }}
                ></Custom.Dropdown>
              </Grid>
              <Grid item xs={0.25}></Grid>
              <Grid item xs={7.5}>
                <Custom.Input
                  name="sessionName"
                  type="text"
                  placeholder="Search by name"
                  size="small"
                  className={classes.inputStyle}
                  onChange={handleInputChange}
                  onKeyPress={(e)=>{if(e.key === 'Enter' || e.key === 13){
                    handleSearch();
                    e.preventDefault();
                  }}}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={1.0}>
                <Typography align="center">
                  <Button
                    onClick={handleSearch}
                    variant="contained"
                    className="searchbtn"
                  >
                    Search
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {isLoadCourse ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              {/* Programs */}

              {filter === "All" || filter === "Programs" ? (
                <Box style={{ width: "96%", marginLeft: "1.9%" }}>
                  <Typography
                    variant="div"
                    style={{ float: "left" }}
                    className="Courseleft"
                  >
                    {programs && programs.length > 0 ? (
                      <h1
                        className="masterdata-heading"
                        style={{ textAlign: "left" }}
                      >
                        Programs
                      </h1>
                    ) : (
                      ""
                    )}
                  </Typography>
                  {programs.length > 0 && Math.ceil(totalPrograms / 4) > 1 ? (
                    <Typography
                      variant="div"
                      style={{ float: "right" }}
                      className="paginationright-user"
                    >
                      <Avatar
                        className="avatar"
                        onClick={() => {
                          onPrevious("program");
                        }}
                        style={{ paddingLeft: "2.5%" }}
                      >
                        <ArrowBackIosIcon />
                      </Avatar>
                      <Typography className="paginationcount">
                        {programPageNumber}/{Math.ceil(totalPrograms / 4)}
                      </Typography>
                      <Avatar
                        className="avatar"
                        style={{ float: "left" }}
                        onClick={() => {
                          onNext("program");
                        }}
                      >
                        <ArrowForwardIosIcon />
                      </Avatar>
                    </Typography>
                  ) : (
                    ""
                  )}

                  {isLoadTraining ? (
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={"#fb2485"}
                      height={50}
                      width={50}
                    />
                  ) : (
                    ""
                  )}
                  {programs && programs.length > 0 ? (
                    <>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        {programs?.map((item, key) => {
                          if (item?.userBatchRoles.length > 0) {
                            let role = item.userBatchRoles[0]?.role?.role_name;
                            return (
                              <Grid item xs={12} sm={6} md={3} key={key}>
                                <BasicCard
                                  heading={"Programs"}
                                  path={
                                    ["Program Participant", "Trainee"].includes(
                                      role
                                    )
                                      ? "/user/bootcamp/home/"
                                      : role === "Mentor"
                                      ? "/user/bootcamp/mentor/home/"
                                      : role === "Trainer"
                                      ? "/user/bootcamp/trainer/home/"
                                      : null
                                  }
                                  id={item.program_id}
                                  status={item.status}
                                  title={item.program_name}
                                  description={item.about_program}
                                  time={item.duration}
                                  setOpen={setOpen}
                                  setCurrentDeletionId={setCurrentDeletionId}
                                  setType={setType}
                                  openModal={openModal}
                                  dotHide={"ok"}
                                  userType={"user"}
                                  batchType={item.batch_type}
                                  orgRole={orgRole}
                                  firstIndexRole={role}
                                  data={item?.userBatchRoles}
                                />
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    </>
                  ) : trainpageNumber === 1 ? (
                    <></>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                ""
              )}

              {/* Courses */}
              {entryType !== undefined && entryType === 1 ? (
                <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
                  <Typography
                    variant="div"
                    style={{ float: "left" }}
                    className="Courseleft"
                  >
                    {(filter === "All" || filter === "Courses") &&
                    allCourses &&
                    allCourses.length > 0 ? (
                      <h1
                        className="masterdata-heading"
                        style={{ textAlign: "left" }}
                      >
                        Courses
                      </h1>
                    ) : (
                      ""
                    )}
                  </Typography>
                  {(filter === "All" || filter === "Courses") &&
                  allCourses.length > 0 &&
                  Math.ceil(totalCourses / 4) > 1 ? (
                    <Typography
                      variant="div"
                      style={{ float: "right" }}
                      className="paginationright-user"
                    >
                      <Avatar
                        className="avatar"
                        onClick={() => {
                          onPrevious("course");
                        }}
                        style={{ paddingLeft: "2.5%" }}
                      >
                        <ArrowBackIosIcon />
                      </Avatar>
                      <Typography className="paginationcount">
                        {coursepageNumber}/{Math.ceil(totalCourses / 4)}
                      </Typography>
                      <Avatar
                        className="avatar"
                        style={{ float: "left" }}
                        onClick={() => {
                          onNext("course");
                        }}
                      >
                        <ArrowForwardIosIcon />
                      </Avatar>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {isLoadCourse ? (
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={"#fb2485"}
                      height={50}
                      width={50}
                    />
                  ) : (
                    ""
                  )}
                  {(filter === "All" || filter === "Courses") &&
                  allCourses &&
                  allCourses.length > 0 ? (
                    <>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        {allCourses?.map((item, key) => (
                          <Grid item xs={12} sm={6} md={3} key={key}>
                            <BasicCard
                              heading={"Course"}
                              path={"/user/courses"}
                              id={item.id}
                              status={item.userActivityTrack}
                              title={item.course_name}
                              description={item.about_course}
                              units={item.units_count}
                              time={item.duration}
                              tags={item.tagDetails}
                              setOpen={setOpen}
                              setCurrentDeletionId={setCurrentDeletionId}
                              setType={setType}
                              openModal={openModal}
                              dotHide={"ok"}
                              userType={"user"}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  ) : (filter === "All" || filter === "Courses") &&
                    coursepageNumber === 1 ? (
                    <></>
                  ) : (
                    ""
                  )}
                </Box>
              ) : null}

              {/* Mandatory Training */}

              {filter === "All" || filter === "Mandatory Trainings" ? (
                <Box style={{ width: "96%", marginLeft: "1.9%" }}>
                  <Typography
                    variant="div"
                    style={{ float: "left" }}
                    className="Courseleft"
                  >
                    {mandateTraining && mandateTraining.length > 0 ? (
                      <h1
                        className="masterdata-heading"
                        style={{ textAlign: "left" }}
                      >
                        Mandatory Trainings
                      </h1>
                    ) : (
                      ""
                    )}
                  </Typography>
                  {mandateTraining.length > 0 &&
                  Math.ceil(totalTrainings / 4) > 1 ? (
                    <Typography
                      variant="div"
                      style={{ float: "right" }}
                      className="paginationright-user"
                    >
                      <Avatar
                        className="avatar"
                        onClick={() => {
                          onPrevious("train");
                        }}
                        style={{ paddingLeft: "2.5%" }}
                      >
                        <ArrowBackIosIcon />
                      </Avatar>
                      <Typography className="paginationcount">
                        {trainpageNumber}/{Math.ceil(totalTrainings / 4)}
                      </Typography>
                      <Avatar
                        className="avatar"
                        style={{ float: "left" }}
                        onClick={() => {
                          onNext("train");
                        }}
                      >
                        <ArrowForwardIosIcon />
                      </Avatar>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {isLoadTraining ? (
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={"#fb2485"}
                      height={50}
                      width={50}
                    />
                  ) : (
                    ""
                  )}
                  {mandateTraining && mandateTraining.length > 0 ? (
                    <>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        {mandateTraining?.map((item, key) => (
                          <Grid item xs={12} sm={6} md={3} key={key}>
                            <BasicCard
                              heading={"Mandatory Training"}
                              path={"/user/mandatoryTraining"}
                              id={item.id}
                              status={item.userMandatoryTrainingTrack}
                              title={item.topic}
                              description={item.description}
                              time={item.duration}
                              setOpen={setOpen}
                              setCurrentDeletionId={setCurrentDeletionId}
                              setType={setType}
                              openModal={openModal}
                              dotHide={"ok"}
                              userType={"user"}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  ) : trainpageNumber === 1 ? (
                    <></>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                ""
              )}

              {/* Units */}
              {/* 
              {entryType !== undefined && entryType === 1 ? (
                <>
                  {filter === "All" || filter === "Units" ? (
                    <Box sx={{ width: "96%", marginLeft: 3, marginRight: 3 }}>
                      <Typography
                        variant="div"
                        style={{ float: "left" }}
                        className="Courseleft"
                      >
                        {allUnits && allUnits.length > 0 ? (
                          <h1
                            className="masterdata-heading"
                            style={{ textAlign: "left" }}
                          >
                            Units
                          </h1>
                        ) : (
                          ""
                        )}
                      </Typography>
                      {allUnits &&
                      allUnits.length > 0 &&
                      Math.ceil(totalUnits / 12) > 1 ? (
                        <Typography
                          variant="div"
                          style={{ float: "right" }}
                          className="paginationright-user"
                        >
                          <Avatar
                            className="avatar"
                            onClick={() => {
                              onPrevious("units");
                            }}
                            style={{ paddingLeft: "2.5%" }}
                          >
                            <ArrowBackIosIcon />
                          </Avatar>
                          <Typography className="paginationcount">
                            {pageNumber}/{Math.ceil(totalUnits / 12)}
                          </Typography>
                          <Avatar
                            className="avatar"
                            style={{ float: "left" }}
                            onClick={() => {
                              onNext("units");
                            }}
                          >
                            <ArrowForwardIosIcon />
                          </Avatar>
                        </Typography>
                      ) : (
                        ""
                      )}
                      {isLoadUnit ? (
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={"#fb2485"}
                          height={50}
                          width={50}
                        />
                      ) : (
                        ""
                      )}
                      {allUnits && allUnits.length > 0 ? (
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          {allUnits?.map((item, key) => (
                            <Grid item xs={12} sm={6} md={3} key={key}>
                              <BasicCard
                                heading={"Unit"}
                                path={"/user/courses/units"}
                                id={item.id}
                                status={item.userActivityTrack}
                                title={item.topic}
                                description={item.description}
                                tags={item.tagDetails}
                                time={item.duration}
                                setOpen={setOpen}
                                setCurrentDeletionId={setCurrentDeletionId}
                                setType={setType}
                                openModal={openModal}
                                dotHide={"ok"}
                                userType={"user"}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ) : pageNumber === 1 ? (
                        <></>
                      ) : (
                        ""
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              ) : null} */}
            </>
          )}
        </>
      )}
    </div>
  );
}
export default React.memo(DashBoard);
