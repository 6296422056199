import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton } from "@material-ui/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { SxStyles } from "../../../styles/styles";
import EditIcon from "@mui/icons-material/Edit";
import TableCell from "@material-ui/core/TableCell";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ReusableRememberMe } from "../remember-me/remember-me.jsx";


const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

const styles = SxStyles.styles;

const RowHandler = SortableHandle(({ sno, isChecked, handleSelectedBox }) => (
  <Handle className="handle">
    <span style={{ fontSize: "14px", fontWeight: "800", marginRight: "10px" }}>
      ::
    </span>
    <ReusableRememberMe
      checked={isChecked}
      onChange={(e) => handleSelectedBox(sno - 1)}
    />
  </Handle>
));

const TableRows = ({
  sno,
  first,
  second,
  third,
  fourth,
  five,
  className,
  id,
  IsActivity,
  handleDeleteInpre,
  handleViewClick,
  handleEditClick,
  handleDownArrow,
  handleSelectedBox,
  handleSelectAll,
  handleUpArrow,
  link,
  disabled,
  isDisabled,
  courseId,
  isChecked,
}) => {
  return (
    <tr style={{ cursor: "default" }}>
      <TableCell
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
          whiteSpace: "nowrap",
        }}
      >
        <RowHandler
          sno={sno}
          first={first}
          second={second}
          third={third}
          fourth={fourth}
          isChecked={isChecked}
          handleSelectedBox={handleSelectedBox}
        />
      </TableCell>
      <TableCell
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
          whiteSpace: "nowrap",
        }}
      >
        {first}
      </TableCell>
      <TableCell
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
          paddingRight: "0px !important",
          whiteSpace: "nowrap",
        }}
      >
        {link ? (
          <a
            href={second}
            target="_blank"
            rel="noreferrer"
            style={styles.tableSpanText}
          >
            {second}
          </a>
        ) : (
          second
        )}
      </TableCell>
      <TableCell
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
          paddingLeft: "0px !important",
          whiteSpace: "nowrap",
        }}
      >
        {link && third !== "N/A" ? (
          <a
            href={`${third}`}
            target="_blank"
            rel="noreferrer"
            style={styles.tableSpanText}
          >
            {third}
          </a>
        ) : (
          "N/A"
        )}
      </TableCell>
      {fourth ? (
        <TableCell
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "400",
            color: "#000000",
            whiteSpace: "nowrap",
          }}
        >
          {fourth}
        </TableCell>
      ) : null}
      {IsActivity ? (
        <></>
      ) : (
        <TableCell style={{ textAlign: "center" }}>
          <>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {courseId ? null : (
                <>
                  <Tooltip title="View">
                    <IconButton
                      disabled={isDisabled}
                      aria-label="edit"
                      size="small"
                    >
                      <RemoveRedEyeIcon
                        disabled={isDisabled}
                        color={isDisabled ? "disabled" : "primary"}
                        fontSize="inherit"
                        style={styles.tableIcon}
                        onClick={() => {
                          handleViewClick(sno);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton
                      disabled={isDisabled}
                      aria-label="edit"
                      size="small"
                    >
                      <EditIcon
                        disabled={isDisabled}
                        color={isDisabled ? "disabled" : "primary"}
                        fontSize="inherit"
                        style={styles.tableIcon}
                        onClick={() => {
                          handleEditClick(sno);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              <Tooltip title="Delete">
                <IconButton
                  disabled={isDisabled}
                  aria-label="edit"
                  size="small"
                >
                  <DeleteOutlineIcon
                    disabled={isDisabled}
                    color={isDisabled ? "disabled" : "primary"}
                    fontSize="inherit"
                    style={styles.tableIcon}
                    onClick={() => {
                      handleDeleteInpre(id);
                    }}
                  />
                </IconButton>
              </Tooltip>
              <ArrowUpwardIcon
                disabled={isDisabled}
                color={isDisabled ? "disabled" : "primary"}
                style={{
                  width: "20px",
                  height: "0%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleUpArrow(id);
                }}
              />
              <ArrowDownwardIcon
                disabled={isDisabled}
                color={isDisabled ? "disabled" : "primary"}
                style={{
                  width: "20px",
                  height: "0%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleDownArrow(id);
                }}
              />
            </span>
          </>
        </TableCell>
      )}
    </tr>
  );
};

export default TableRows;
