import ReusableButton from "../reusable-button/reusable-button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { SxStyles } from "../../styles/styles";
import {
  CancelBtn,
  DeleteBtn,
} from "../../pages/admin/admin-layout/adminStyles.js";

const ReusableDelete = ({ handleDeleteTag, handleDeleteClose }) => {
  return (
    <Dialog
      open={true}
      className="dialogBox"
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={SxStyles.DialogBoxStyle}
    >
      <DialogTitle></DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{ color: "#000" }}
        >
          Are you sure you want to delete ?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <ReusableButton
          size="large"
          variant="outlined"
          className="candidateDltepageCancelBttn"
          buttonName="Cancel"
          style={CancelBtn}
          onClick={() => handleDeleteClose()}
        />
        <ReusableButton
          size="large"
          variant="contained"
          className="candidateDltepageDeleteBttn"
          buttonName="Delete"
          style={{ ...DeleteBtn, marginBottom: "25px" }}
          onClick={() => handleDeleteTag()}
        >
          Delete
        </ReusableButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableDelete;
