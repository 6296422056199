import React from "react";

import { TextField } from "@material-ui/core";

export default function Input(props) {
  const {
    name,
    id,
    label,
    value,
    onChange,
    size,
    placeholder,
    onKeyPress,
    maxLength,
    error,
    disabled,
    InputProps,
    rows,
    style,
    className,
    helperText,
    type,
    fullWidth,
    multiline,
    maxRows,
  } = props;
  return (
    <TextField
      type={type}
      id={id}
      style={style}
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      size={size}
      placeholder={placeholder}
      disabled={disabled}
      InputProps={InputProps}
      autoComplete="off"
      className={className}
      helperText={helperText}
      onKeyDown={onKeyPress}
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
      fullWidth={fullWidth}
      maxLength={maxLength}
      error={error}
      {...(error && { error: true, helperText: error })}
    ></TextField>
  );
}
