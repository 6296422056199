import { useState, useEffect, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PinkFilled from "../../../../assets/Images/checkPink.svg";
import PinkUnFilled from "../../../../assets/Images/pinkunfilled.svg";
import TickWhite from "../../../../assets/Images/greyTickMark.png";
import FinalQuizTick from "../../../../assets/Images/FinalQuizTick.png";
import FinalQuizCircle from "../../../../assets/Images/FinalQuizCircle.png";
import { Box, CircularProgress, Typography } from "@mui/material";
import { MandatoryTraningHandler } from "../../../../handler/mandatoryTraningHandler";
import { FamilyRestroomRounded } from "@mui/icons-material";
import { API } from "../../../../Networking/API";

export default function MandatoryTraningListItems({
  MandatoryTraining,
  setLinks,
  setIsQuizEnabled,
  setQuizIndex,
  setQuizData,
  videoTotalTime,
  currentVideoTime,
  selectedTrainingModule,
  isQuizEnabled,
  setMandatoryTraining,
  videoLink,
  setVideoLink,
  enableFinalQuiz,
  setSelected,
  selected,
  setCurrentIndex,
  parentVideoId,
  setParentVideoId,
  setPrimaryValue,
  primaryValue,
  setDataQuestions,
  setisQuizStarted,
  setIsFinalQuizEnabled,
  existingArray,
  onPageRequest,
  setCurrentVideoTime,
  user_id,
  // contentType,
}) {
  /*********************Declaration of UseState to store values */
  const [selectedModule, setSelectedModule] = useState([]);
  const [status, setStatus] = useState({ id: "", value: false });
  const [expanded, setExpanded] = useState("panel1");
  const [TraningPercentage, setTraningPercentage] = useState(0);
  const [totalModules, setTotalModules] = useState(MandatoryTraining);
  const [selectedVideoId, setSelectedVedioId] = useState("");
  const [isVideoCompleted, setIsVideoCompleted] = useState(false);
  const [currentSeconds, setCurrentSeconds] = useState(0);

  const userTrackId = localStorage.getItem("userUnitTrackId");
  useEffect(() => {
    setSelectedVedioId(parentVideoId);
  }, [parentVideoId]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const primaryKey = MandatoryTraining.training_material.find(
    (items) => items.material_pk === selectedVideoId
  );

  var myObject = {};

  useEffect(() => {
    setSelectedVedioId(
      parentVideoId
        ? parentVideoId
        : MandatoryTraining.training_material[0].material_pk
    );
    setLinks(
      parentVideoId
        ? parentVideoId
        : MandatoryTraining.training_material[0].material_pk
    );
    setSelected(
      parentVideoId
        ? parentVideoId
        : MandatoryTraining.training_material[0].material_pk
    );
  }, [MandatoryTraining]);
  useEffect(() => {
    setCurrentSeconds(selectedTrainingModule?.duration * 60);
  }, [selectedTrainingModule]);

  useEffect(() => {
    if (
      (primaryKey?.media_type === "FILES" ||
        primaryKey?.media_type === "PPT") &&
      !window.localStorage.getItem(`${user_id}-${primaryKey.material_pk}`)
    ) {
      if (
        primaryKey?.content_type === "video/mp4"
      ) {
        let percentage = Math.round((currentVideoTime * 100) / videoTotalTime);

        setTraningPercentage(percentage);

        if (percentage === 100) {
          setCurrentVideoTime(0);
          const primaryKey = MandatoryTraining.training_material.find(
            (items) => items.material_pk === selectedVideoId
          );
          window.localStorage.setItem(
            `${user_id}-${primaryKey.material_pk}`,
            percentage
          );

          existingArray.push(primaryKey.material_pk);
          localStorage.setItem("myArray", JSON.stringify(existingArray));

          setTraningPercentage(0);
          setQuizData(selectedTrainingModule);

          if (primaryKey?.trainigMaterialQuiz) {
            setisQuizStarted(true);
            setIsQuizEnabled(true);
          }
        }
      } else {
        let totalSeconds = selectedTrainingModule?.duration * 60;
        totalSeconds > 0 &&
          currentSeconds > 0 &&
          setTimeout(() => {
            setCurrentSeconds(currentSeconds - 1);
          }, 1000);

        setTraningPercentage(
          ((totalSeconds - currentSeconds) / totalSeconds) * 100
        );

        const percentageHolder =
          ((totalSeconds - currentSeconds) / totalSeconds) * 100;

        if (percentageHolder === 100) {
          setCurrentVideoTime(0);

          const primaryKey = MandatoryTraining.training_material.find(
            (items) => items.material_pk === selectedVideoId
          );

          window.localStorage.setItem(
            `${user_id}-${primaryKey.material_pk}`,
            percentageHolder
          );

          existingArray.push(primaryKey.material_pk);
          localStorage.setItem("myArray", JSON.stringify(existingArray));

          setTraningPercentage(0);
          setQuizData(selectedTrainingModule);

          if (primaryKey?.trainigMaterialQuiz) {
            setisQuizStarted(true);
            setIsQuizEnabled(true);
          }
        }
      }
    } else if (
      videoTotalTime !== 0 &&
      selected === selectedVideoId &&
      (primaryKey?.media_type !== "FILES" ||
        primaryKey?.media_type !== "PPT") &&
      !window.localStorage.getItem(`${user_id}-${primaryKey?.material_pk}`)
    ) {
      let percentage = Math.round((currentVideoTime * 100) / videoTotalTime);

      setTraningPercentage(percentage);

      if (percentage === 100) {
        setCurrentVideoTime(0);
        const primaryKey = MandatoryTraining.training_material.find(
          (items) => items.material_pk === selectedVideoId
        );
        window.localStorage.setItem(
          `${user_id}-${primaryKey.material_pk}`,
          percentage
        );

        existingArray.push(primaryKey.material_pk);
        localStorage.setItem("myArray", JSON.stringify(existingArray));

        setTraningPercentage(0);
        setQuizData(selectedTrainingModule);

        if (primaryKey?.trainigMaterialQuiz) {
          setisQuizStarted(true);
          setIsQuizEnabled(true);
        }
      }
    } else if (
      videoTotalTime !== 0 &&
      selected === selectedVideoId &&
      (primaryKey?.media_type !== "FILES" ||
        primaryKey?.media_type !== "PPT") &&
      window.localStorage.getItem(`${user_id}-${primaryKey?.material_pk}`)
    ) {
      let percentage = Math.round((currentVideoTime * 100) / videoTotalTime);
      setTraningPercentage(percentage);

      if (percentage === 100) {
        setCurrentVideoTime(0);
        const primaryKey = MandatoryTraining.training_material.find(
          (items) => items.material_pk === selectedVideoId
        );
        window.localStorage.setItem(
          `${user_id}-${primaryKey.material_pk}`,
          percentage
        );

        existingArray.push(primaryKey.material_pk);
        localStorage.setItem("myArray", JSON.stringify(existingArray));

        setTraningPercentage(0);
        setQuizData(selectedTrainingModule);

        if (primaryKey?.trainigMaterialQuiz) {
          setisQuizStarted(true);
          setIsQuizEnabled(true);
        }
      }
    }
  }, [
    videoTotalTime,
    currentVideoTime,
    currentSeconds,
    selectedTrainingModule,
    primaryKey,
  ]);

  useEffect(() => {
    if (window.localStorage.getItem(`${user_id}-${primaryKey?.material_pk}`)) {
      localStorage.setItem(
        `${user_id}-${primaryKey?.material_pk}_isCompleted`,
        true
      );
    }
  }, [TraningPercentage]);

  /**********************To check weather the Module is disabled or not */
  const IsModuleDisabled = (ModuleId, isTraningModuleCompleted) => {
    if (isTraningModuleCompleted) return false;
    else if (ModuleId === selectedModule[0]?.ModuleId) return false;
    return true;
  };

  const checkClickDisabled = (ModuleId, isCompleted) => {
    if (isQuizEnabled) return false;
    else if (ModuleId === selectedModule[0]?.ModuleId) return true;
    else if (isCompleted) return true;
    else return false;
  };

  const HanldeModuleClick = (
    _visible,
    traningVideoLink,
    selectedVideoId,
    index
  ) => {
    if (_visible) {
      onPageRequest(index);
    }
  };

  const isModuleSelected = (selectedId) => {
    if (selected === selectedId) return true;
    else return false;
  };

  const getVisibleData = (index, allData) => {
    if (index === 0) {
      return true;
    } else if (index > 0) {
      let dataValue = localStorage.getItem(
        `${user_id}-${primaryKey?.material_pk}_isCompleted`
      );

      var FinalData = allData.findIndex(function (obj) {
        return obj.material_pk === selected;
      });

      let beforeIndex = allData[index - 1].material_pk;
    }
  };

  let previousRecords = [];

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          padding: "0px",
        }}
      >
        {totalModules.training_material.map((traningModules, index) => {
          let IsDiabled = IsModuleDisabled(
            traningModules.material_pk,
            traningModules.isCompleted
          );
          previousRecords.push({
            isCompleted: localStorage.getItem(
              `${user_id}-${traningModules?.material_pk}_isCompleted`
            ),
          });
          let _visible =
            index === 0
              ? true
              : index > 0 && previousRecords[index - 1].isCompleted
              ? true
              : false;

          return (
            <ListItem
              style={{
                width: "100%",
                padding: 0,
                margin: 0,
                backgroundColor: "#FFFFFF",
                borderBottom: "1.5px solid #F3F3F3",
              }}
            >
              <ListItemButton
                disableRipple={true}
                sx={{
                  background: isModuleSelected(
                    traningModules.material_pk,
                    traningModules.isCompleted
                  )
                    ? "#02215B"
                    : "#fff",

                  color: isModuleSelected(
                    traningModules.material_pk,
                    traningModules.isCompleted
                  )
                    ? "#fff"
                    : "black",
                  "&:hover": {
                    background: isModuleSelected(
                      traningModules.material_pk,
                      traningModules.isCompleted
                    )
                      ? "#02215B"
                      : "#fff",
                    color: isModuleSelected(
                      traningModules.material_pk,
                      traningModules.isCompleted
                    )
                      ? "#fff"
                      : "black",
                  },
                }}
                onClick={() =>
                  HanldeModuleClick(
                    _visible,
                    traningModules.material_url,
                    traningModules.material_pk,
                    index
                  )
                }
              >
                <ListItemIcon
                  style={{ minWidth: "30px", position: "relative" }}
                >
                  <CircularProgress
                    variant="determinate"
                    sx={{
                      color:
                        (selectedVideoId || parentVideoId) ===
                        traningModules.material_pk
                          ? "#FB2485"
                          : IsDiabled
                          ? "lightgrey"
                          : "black",
                    }}
                    thickness={3}
                    size={25}
                    value={
                      selectedVideoId === traningModules.material_pk
                        ? TraningPercentage
                        : 100
                    }
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={6}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src={
                        localStorage.getItem(
                          `${user_id}-${traningModules.material_pk}_isCompleted`
                        )
                          ? PinkFilled
                          : TickWhite
                      }
                      alt=""
                    />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  style={{
                    padding: "0px",

                    color: isModuleSelected(
                      traningModules.material_pk,
                      traningModules.isCompleted
                    )
                      ? "#fff"
                      : "black",
                  }}
                >
                  <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                    {traningModules.material_name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
