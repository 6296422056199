import React, { useState, useRef, useEffect } from "react";
import { DraggableList } from "react-draggable-list";

// import TableRowsMandate from "./mandatoryDraggableRows.jsx";
// import styled from "styled-components";
import { SortableContainer,  } from "react-sortable-hoc";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Tooltip } from "@mui/material";
import {  IconButton } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
// import arrayMove from "../../../handler/draggableHandler";
// import arrayMove from "../../../handler/draggableHandler.js";
// import { makeStyles } from '@material-ui/core/styles';
// import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input.jsx";
import { API } from "../../../Networking/API.js";
// import TableRow from '@material-ui/core/TableRow';

// const MyTableWrapper = styled.div`
//   overflow: auto;
//   max-height: 300px;
//   .SNo {
//     width: 9% !important;
//   }
//   .Name {
//     width: 18% !important;
//   }
//   .VideoLink {
//     width: 20% !important;
//   }
//   .Material {
//     width: 16% !important;
//   }
//   .Actions {
//     width: 15% !important;
//   }
//   .MuiTableCell-root {
//     padding-right: 36px;
//   }
//   .Actions {
//     text-align: right !important;
//   }
//   .MuiTableCell-root {
//     max-width: 280px;
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }
// `;

// const MyTableWrapper = styled.div`
//   overflow: auto;
//   max-height: 300px;
// `;

// const SortableCont = SortableContainer(({ children }) => {
//   return (
//     <tbody helperClass="helperContainerClass modal-helper-container">
//       {children}
//     </tbody>
//   );
// });
// const useStyles = makeStyles({
//   table: {
//     minWidth: 200,
//   },
// });

const MandatoryImageSlide = ({
  draggableData,
  pageIndex,
  pageLimit,
  setDraggableData,
  handleViewClick,
  setNotify,
  link,
  dataTableHeader,
  IsActivity,
  disabled,
  OpenTheEditBox,
  setAddedImage,
  SlideId,
}) => {
  const data = draggableData;
  const Item = ({ item, itemSelected, dragHandleProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
    
    const [descriptionValue, setDescriptionValue] = useState(item.second);
    const [editId, setEditId] = useState(0);
    // const [ListData, setListData] = useState(list);
    const [ListDescription, setListDescription] = useState(item.second);
    
    
    const IsDisabled = (Id) => {
      if (editId === Id) return false;
      else return true;
    };
    const SaveFile = (elementId) => {
      API.AddSingleImageForSlide(
        window.localStorage.getItem("mandateId"),
        elementId,
        SlideId,
        descriptionValue
      )
        .then(({ response }) => {
          
          if (response.api_status !== 200) {
            setNotify({
              isOpen: true,
              message: response.error[0].error_msg,
              type: "error",
            });
          } else {
            setEditId(0);
            setAddedImage((prev) => !prev);
            setNotify({
              isOpen: true,
              message: "Data Updated Sucessfully",
              type: "success",
            });
          }
          // if
        })
        .catch(() => {
          setNotify({
            isOpen: true,
            message: "Something went wrong, Please try again",
            type: "error",
          });
        });
    };

    const handleDeleteInpre = (ElementId) => {
      API.DeleteSlideElement(SlideId, ElementId).then((response) => {
        
        if (response.api_status === 200)
          setList((prev) => prev.filter((data) => data.id !== ElementId));
      });
    };
    return (
      <div
        className="disable-select"
        style={{
          border: "1px solid grey",
          margin: "4px",
          padding: "10px",
          display: "flex",
          //   justifyContent: "space-around",
          background: "#fff",
          userSelect: "none",
        }}
      >
        <div
          className="disable-select dragHandle"
          style={{
            fontWeight: "800",
            transform: "rotate(90deg)",
            width: "20px",
            // height: "20px",
            fontSize: "22px",

            // backgroundColor: "black",
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            
            e.target.style.backgroundColor = "blue";
            document.body.style.overflow = "hidden";
            onTouchStart(e);
          }}
          onMouseDown={(e) => {
            
            document.body.style.overflow = "hidden";
            onMouseDown(e);
          }}
          onTouchEnd={(e) => {
            e.target.style.backgroundColor = "black";
            document.body.style.overflow = "visible";
          }}
          onMouseUp={() => {
            document.body.style.overflow = "visible";
          }}
        >
          ::
        </div>
        <div
          style={{
            marginLeft: "10px",
            marginRight: "20px",
            display: "flex",
            alignItems: "center",
          }}
        ></div>
        <div
          style={{
            height: "52px",
            width: "274px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: "20px",
          }}
        >
          {item.first}
        </div>
        <div style={{ height: "42px", width: "280px" }}>
          <ReusableInputfield
            //   value={item.second}
            onChange={(event) => {
              setListDescription(event.target.value);
              setDescriptionValue(event.target.value);
            }}
            disabled={IsDisabled(item.id)}
            value={ListDescription}
            style={{ height: "0px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip
              title={
                <img
                  src={item.first}
                  style={{ height: "300px", width: "500px" }}
                  alt="Img"
                />
              }
            >
              <IconButton
                //   disabled={isDisabled}
                aria-label="edit"
                size="small"
              >
                <RemoveRedEyeIcon
                  //   disabled={isDisabled}
                  color={"primary"}
                  fontSize="inherit"
                  //   style={styles.tableIcon}
                  //   onClick={() => {
                  //     handleViewClick(id);
                  //   }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip>
              <IconButton
                //   disabled={isDisabled}
                onClick={() => setEditId(item.id)}
                aria-label="edit"
                size="small"
              >
                <EditIcon
                  fontSize="inherit"
                  color={IsDisabled(item.id) ? "disabled" : "primary"}
                  style={{ width: "18px" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="save">
              <IconButton
                //   disabled={isDisabled}
                onClick={() => SaveFile(item.id)}
                disabled={IsDisabled(item.id)}
                aria-label="save"
                size="small"
              >
                <SaveIcon
                  //   disabled={isDisabled}
                  //
                  color={IsDisabled(item.id) ? "disabled" : "primary"}
                  fontSize="inherit"
                  //   style={styles.tableIcon}
                  //   onClick={() => {
                  //     handleViewClick(id);
                  //   }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                disabled={IsDisabled(item.id)}
                aria-label="edit"
                size="small"
              >
                <DeleteOutlineIcon
                  disabled={IsDisabled(item.id)}
                  color={IsDisabled(item.id) ? "disabled" : "primary"}
                  fontSize="inherit"
                  //   style={styles.tableIcon}
                  onClick={() => {
                    handleDeleteInpre(item.id);
                  }}
                />
              </IconButton>
            </Tooltip>
          </span>
        </div>
      </div>
    );
  };
  const [list, setList] = useState(data);

  const containerRef = useRef();

  const _onListChange = (newList) => {
    setList(newList);
  };
  
  useEffect(() => {
    if (list.length > 0) {
      const listIds = list.map((data) => {
        return data.id;
      });
      API.ReorderImageInSlide(listIds, SlideId).then(({ response }) => {
        
      });
    }
  }, [list]);

  return (
    <div className="App" style={{ width: "100%" }}>
      <div
        ref={containerRef}
        style={{ touchAction: "pan-y", background: "beige" }}
      >
        <DraggableList
          itemKey="id"
          template={Item}
          list={list}
          onMoveEnd={(newList) => _onListChange(newList)}
          container={() => containerRef.current}
        />
        {/* {list.map((item) => (
        <Item item={item} />
      ))} */}
      </div>
    </div>
  );
};

export default MandatoryImageSlide;

// import React, { useState, useCallback } from "react";
// import { SortableContainer, SortableElement } from "react-sortable-hoc";
// import styled from "styled-components";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";

// const MyTableWrapper = styled.div`
//   overflow: auto;
//   max-height: 300px;
// `;

// const ModalContent = styled.div`
//   max-height: 100%;
//   overflow: hidden;
// `;

// const SortableCont = SortableContainer(({ children }) => {
//   return <TableBody>{children}</TableBody>;
// });

// const SortableItem = SortableElement(({ value }) => {
//   return (
//     <TableRow>
//       <TableCell>{value}</TableCell>
//     </TableRow>
//   );
// });

// const MandatoryImageSlide = () => {
//   const [tableData, setTableData] = useState(["Row 1", "Row 2", "Row 3"]);

//   const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
//     setTableData((data) => {
//       const newData = [...data];
//       newData.splice(newIndex, 0, newData.splice(oldIndex, 1)[0]);
//       return newData;
//     });
//   }, []);

//   return (
//     <ModalContent>
//       <MyTableWrapper>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Draggable Table</TableCell>
//             </TableRow>
//           </TableHead>
//           <SortableCont onSortEnd={onSortEnd} useDragHandle>
//             {tableData.map((value, index) => (
//               <SortableItem key={`item-${index}`} index={index} value={value} />
//             ))}
//           </SortableCont>
//         </Table>
//       </MyTableWrapper>
//     </ModalContent>
//   );
// };

// export default MandatoryImageSlide;
