import React, { useState, useCallback } from "react";
import TableRowsMandate from "./mandatoryDraggableRows.jsx";
import styled from "styled-components";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "../../../handler/draggableHandler.js";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import ReusableButton from "../../../components/reusable-button/reusable-button.jsx";
import { SxStyles } from "../../../styles/styles";
import { API } from "../../../Networking/API.js";
import Notification from "../../../components/Custom/Notification";
import ReusableDelete from "../../../components/reusable-delete/ReusableDelete";
import { ReusableRememberMe } from "../../../components/Custom/remember-me/remember-me.jsx";
const MyTableWrapper = styled.div`
  .SNo {
    width: 9% !important;
  }
  .Name {
    width: 18% !important;
  }
  .VideoLink {
    width: 20% !important;
  }
  .Material {
    width: 16% !important;
  }
  .Actions {
    width: 15% !important;
  }
  .MuiTableCell-root {
    padding-right: 36px;
  }
  .Actions {
    text-align: right !important;
  }
  .MuiTableCell-root {
    max-width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});
const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

const SortableItem = SortableElement((props) => (
  <TableRowsMandate {...props} />
));

const DataTableDragMandate = ({
  draggableData,
  pageIndex,
  pageLimit,
  setDraggableData,
  handleDeleteInpre,
  handleEditModal,
  handleViewClick,
  link,
  dataTableHeader,
  IsActivity,
  disabled,
  OpenTheEditBox,
  deleteId,
  getTrainingMaterial,
}) => {
  let pageNo = pageIndex + 1;
  let startIndex = (pageNo - 1) * pageLimit;
  let endIndex = pageNo * pageLimit - 1;
  const [selectAll, setSelectAll] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openDelete, setOpenDelete] = useState(false);
  const styles = SxStyles.styles;
  if (pageIndex > 0) {
    pageIndex++;
  }

  const handleUpArrow = (selectedId) => {
    if (!disabled) {
      let indexValue;
      draggableData.map((data, index) => {
        if (data.id === selectedId) {
          indexValue = index;
        }
        return data;
      });
      let oldIndex = indexValue;
      let newIndex = indexValue - 1;
      if (oldIndex !== 0) {
        setDraggableData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
      }
    }
  };

  const handleDeleteAll = () => {
    setOpenDelete(true);
  };

  const handleSelectedBox = (selectedId) => {
    const updatedData = draggableData.map((data, index) => {
      if (index === selectedId) {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      }
      return data;
    });
    const checkedItems = updatedData.filter((data) => data.isChecked === true);
    if (checkedItems.length === 0) 
    handleSelectAll(false);
  else{
    setDraggableData(updatedData);
    setSelectAll(true);
  }
  };

  const handleDeleteItems = () => {
    const checkedItems = draggableData.filter(
      (data) => data.isChecked === true
    );
    const trainingMaterialsId = checkedItems.map((data) => data.id);
    const payLoad = JSON.stringify({
      topic_id: deleteId,
      material_id: trainingMaterialsId,
    });
    API.deleteAllTrainingMaterial(payLoad).then((response) => {
      if (response.response.api_status === 200) {
      setOpenDelete(false);
      handleSelectAll(false);
        getTrainingMaterial(response.response.data[0].message);
      } else {
        setNotify({
          isOpen: true,
          message: response.response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleSelectAll = (value) => {
    const updatedData = draggableData.map((data, index) => ({
      ...data,
      isChecked: value,
    }));
    setSelectAll(value);
    setDraggableData(updatedData);
  };
  const handleDownArrow = (selectedId) => {
    if (!disabled) {
      let indexValue;
      draggableData.map((data, index) => {
        if (data.id === selectedId) {
          indexValue = index;
        }
      });
      let oldIndex = indexValue;
      let newIndex = indexValue + 1;

      setDraggableData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
    }
  };

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setDraggableData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);
  const classes = useStyles();

  return (
    <div>
      <ReusableButton
        disabled={!selectAll}
        style={{
          ...styles.saveForLaterBtn,
          marginBottom: "1%",
          cursor: !selectAll ? "" : "pointer",
          color: !selectAll ? "#fff" : "#fff",
          backgroundColor: !selectAll ? "grey" : "#FB2485",
        }}
        onClick={handleDeleteAll}
        buttonName="Delete"
      />
    <MyTableWrapper>
      <Table className={classes.table}>
        <TableHead style={{ background: "#f1f1f1" }}>
          <TableRow className="table-row">
            {dataTableHeader.map((data) => {
              return (
                <>
                  <TableCell
                    style={{
                      textAlign: "center",
                      color: "02215B",
                      fontSize: "16px",
                      fontWeight: "600",
                      width: data.type !== "string" ? "1%" : undefined,
                    }}
                    className={
                      typeof data === "string" &&
                      `${data.replace(" ", "").replace(".", "")}`
                    }
                    onChange={(e)=>handleSelectAll(e.target.checked)}
                  >
                    {data=== 'checkBox' ? <ReusableRememberMe checked={selectAll}/> : data}
                  </TableCell>
                </>
              );
            })}
          </TableRow>
        </TableHead>

        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={["30%", "50%"]}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {draggableData.map((value, index) => {
            if (index >= startIndex && index <= endIndex) {
              return (
                <SortableItem
                  key={`item-${index}`}
                  index={index}
                  sno={index + 1}
                  first={value.first}
                  second={value.second}
                  third={value.third}
                  fourth={value.fourth}
                  five={value.five}
                  sixth={value.sixth}
                  id={value.id}
                  description={value.description}
                  type={value.type}
                  handleDeleteInpre={handleDeleteInpre}
                  handleEditModal={handleEditModal}
                  handleSelectedBox={handleSelectedBox}
                  handleViewClick={handleViewClick}
                  handleUpArrow={handleUpArrow}
                  handleDownArrow={handleDownArrow}
                  link={link}
                  disabled={disabled}
                  isDisabled={value.isChecked}
                  IsActivity={IsActivity}
                  OpenTheEditBox={OpenTheEditBox}
                  isChecked={value.isChecked}
                  handleSelectAll={handleSelectAll}
                />
              );
            }
          })}
        </SortableCont>
      </Table>
    </MyTableWrapper>
    <Notification notify={notify} setNotify={setNotify} />
      {openDelete && (
        <ReusableDelete
          handleDeleteTag={handleDeleteItems}
          handleDeleteClose={handleDeleteClose}
        />
      )}
    </div>
  );
};

export default DataTableDragMandate;
