const userEmailExists = (ExistingUserData, InputEmail) =>{
        return new Promise(function(resolve, reject){
          let IfEmailExist =  ExistingUserData.some(function (userData) {
                return userData.email === InputEmail;
              
            });
            resolve(IfEmailExist);
        });
};

const userPhoneNoExist = (ExistingUserData, InputPhoneNo) => {
    return new Promise(function(resolve, reject){
          
        let IfEmailExist =  ExistingUserData.some(function (userData) {
              return userData.phone === InputPhoneNo;
            
          });
          resolve(IfEmailExist);
      });
};

export const ValidationHelper =  {userEmailExists, userPhoneNoExist};