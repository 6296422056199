import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Typography, Avatar, Tooltip } from "@mui/material";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { Custom } from "../../../components/Custom/Custom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import "./Profile.css";
import { makeStyles } from "@material-ui/core";
import { MyForm } from "../../../components/MyForm";
import Notification from "../../../components/Custom/Notification";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

import { API } from "../../../Networking/API";

const styles = makeStyles((theme) => ({
  phoneW: {
    "& .MuiOutlinedInput-inputAdornedStart ": {
      width: "187px",
    },
  },
}));
const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  department: "",
  designation: "",
  phone: "",
};

export default function UserProfile() {
  const classes = styles();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [done, setDone] = useState(undefined);
  const [pic, setPic] = useState(undefined);
  const [image, setImage] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [userDetails, setUserDetails] = useState(initialValues);
  const [showIcons, setShowIcons] = useState(false);
  const fileInputRef = useRef(null);
  const handleMouseEnter = () => {
    setShowIcons(true);
  };

  const handleMouseLeave = () => {
    setShowIcons(false);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleChangeNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setUserDetails({ ...userDetails, [e.target.name]: value });
    validate({ [e.target.name]: value });
  };

  const handleOnChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    validate({ [e.target.name]: e.target.value });
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("firstname" in userDetails)
      code.firstname = userDetails.firstname ? "" : "First Name is required";
    if ("lastname" in userDetails)
      code.lastname =
        userDetails.lastname.length !== 0 ? "" : "Last Name is required";
    setErrors({
      ...code,
    });
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);

  const getUserDetails = async () => {
    API.getProfileInfo().then(({ response }) => {
      if (response.data.api_status === 200) {
        const details = response.data.data;
        setImage(details.user_profile);
        setFirstName(
          `${details.first_name} ${
            details.last_name !== null ? details.last_name : ""
          }`
        );
        setUserDetails({
          ...userDetails,
          firstname: details.first_name,
          phone: details.phonenumber,
          lastname: details.last_name,
          department: details.department,
          designation: details.designation,
          email: details.user_email,
          userId: details.user_pk,
        });
        setDone(true);
      }
    });
  };

  var { firstname, lastname, phone, email, department, designation } =
    userDetails;

  const UpdateDetails = async () => {
    if (validate()) {
      setDone(undefined);
      API.UserProfileUpdate(
        phone,
        firstname,
        lastname,
        designation,
        department,
        pic
      ).then(({ response }) => {
        if (response.api_status === 200) {
          getUserDetails();
          setDone(true);
          setNotify({
            isOpen: true,
            message: "Profile Updated Successfully",
            type: "success",
          });
        }
      });
    }
  };

  const handleUploadPhoto = (e) => {
    fileInputRef.current.click();
  };


  const updateProfileImage = (picture ,type) => {
    API.updateProfileImage(picture, userDetails.userId, type).then((res) => {
      if (res?.response?.api_status === 200) {
        setNotify({
          isOpen: true,
          message: "Profile Updated Successfully",
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    });
  };


  const photoUpload = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    setPic(event.target.files[0]);
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
    updateProfileImage(event.target.files[0],'update');
  };

  return (
    <>
      {userDetails !== undefined ? (
        <Box style={{ padding: "36px", marginBottom: "50px" }}>
          <Grid
            container
            style={{
              backgroundColor: "white",
              marginLeft: "100px",
              marginTop: "20px",
              borderRadius: "10px",
              padding: "0px !important",
              height: "70vh",
              width: "70vw",
            }}
          >
            {!done ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginBottom: "0px",
                      alignItems: "center",
                      marginLeft: "7.5%",
                      marginTop: "1.5%",
                    }}
                  >
                    <Typography>
                      <b>{`${firstName.toUpperCase()}`}</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <Grid
                    Item
                    xs={12}
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          ref={fileInputRef}
                          onChange={(e) => photoUpload(e)}
                        />
                        {image === null || image === undefined ? (
                          <AccountBoxIcon
                            style={{
                              height: "230px",
                              width: "215px",
                              color: "#02215b",
                            }}
                          />
                        ) : (
                          <div style={{ height: "200px", width: "200px" }}>
                            <Avatar
                              src={image}
                              alt="Profile pic"
                              sx={{ width: 200, height: 200 }}
                              style={{ maxWidth: "100%", height: "100%" }}
                              className="profile-icon"
                            />
                          </div>
                        )}
                      </IconButton>

                      {(showIcons && image) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            top: "46%",
                            width: "65%",
                            position: "absolute",
                          }}
                        >
                          <Tooltip title="Upload">
                            <IconButton
                              style={{
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                              }}
                              onClick={(e) => handleUploadPhoto(e)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          
                            <Tooltip title="Remove">
                              <IconButton
                                style={{
                                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                                }}
                                onClick={() => {
                                  setPic(null);
                                  setImage(null);
                                  updateProfileImage();
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                       
                        </div>
                      )}
                    </>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={9}
                  md={9}
                  lg={9}
                  style={{
                    paddingLeft: "30px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      First Name
                    </Typography>
                    <span>
                      <Custom.Input
                        id="outlined-read-only-input"
                        name="firstname"
                        placeholder="First Name"
                        value={firstname === "null" ? "NA" : firstname}
                        fullWidth={false}
                        size="small"
                        onChange={handleOnChange}
                        error={errors.firstname}
                        className={"inputbox"}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#000",
                        }}
                        variant="h6"
                      >
                        Last Name
                      </Typography>
                      <span>
                        <Custom.Input
                          name="lastname"
                          placeholder="Last Name"
                          value={lastname === "null" ? "NA" : lastname}
                          fullWidth={false}
                          size="small"
                          className={"inputbox"}
                          onChange={handleOnChange}
                          error={errors.lastname}
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#000",
                      }}
                      variant="h6"
                    >
                      Email Address
                    </Typography>
                    <span>
                      <Custom.Input
                        name="email"
                        value={email === "null" ? "NA" : email}
                        placeholder="example@email.com"
                        size="small"
                        fullWidth={false}
                        className={"inputbox"}
                        disabled
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#000",
                      }}
                      variant="h6"
                    >
                      Department
                    </Typography>
                    <span>
                      <Custom.Input
                        name="department"
                        value={department === "null" ? "NA" : department}
                        onChange={handleOnChange}
                        fullWidth={false}
                        size="small"
                        placeholder="Department"
                        className={"inputbox"}
                      ></Custom.Input>
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#000",
                      }}
                      variant="h6"
                    >
                      Designation
                    </Typography>
                    <span>
                      <Custom.Input
                        name="designation"
                        placeholder="Designation"
                        fullWidth={false}
                        value={designation === "null" ? "NA" : designation}
                        onChange={handleOnChange}
                        size="small"
                        className={"inputbox"}
                      />
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#000",
                      }}
                      variant="h6"
                    >
                      Phone Number
                    </Typography>
                    <span>
                      <Custom.Phone
                        className={classes.phoneW}
                        name="phone"
                        label="NA"
                        value={
                          phone === null || phone === "null" ? "NA" : phone
                        }
                        onChange={handleChangeNumber}
                        fullWidth={false}
                        size="small"
                      />
                    </span>
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginLeft: "20px",
                  }}
                >
                  <QuestionMarkIcon
                    className="questionMark"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <Typography
                    style={{
                      marginTop: 0,
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 300,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#000",
                    }}
                    variant="h6"
                    startIcon={<InfoIcon style={{ color: "#fb2485" }} />}
                  >
                    Contact Admin/HR for support
                  </Typography>
                </div>
                <Grid item xs={4}></Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ReusableButton
                    size="large"
                    variant="outlined"
                    className="pcancel"
                    buttonName="Cancel"
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAalign: "center",
                      color: "#000936",
                      border: "1px solid #000936",
                      padding: "7px 20px",
                      borderRadius: "4px",
                      marginRight: "18px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      navigate("/trainee/home/");
                    }}
                  />
                  <ReusableButton
                    size="medium"
                    variant="contained"
                    type="submit"
                    className="updateBtn"
                    buttonName="update"
                    style={{
                      backgroundColor: "#fe1c7f",
                      fontFamily: "Poppins",
                      fontSize: "0.8em",
                      fontWeight: 200,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#fff",
                      padding: "12px 35px 12px 35px",
                      borderRadius: "4px",
                    }}
                    onClick={() => UpdateDetails()}
                  />
                </div>
              </>
            )}
          </Grid>
          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      ) : null}
    </>
  );
}
