import { Grid } from "@mui/material";
import { Custom } from "../../../components/Custom/Custom";
import { IconButton, InputLabel, makeStyles } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import EditIcon from "@mui/icons-material/Edit";
import Notification from "../../../components/Custom/Notification";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import ReactPaginate from "react-paginate";
import { API } from "../../../Networking/API";
import { CategoryEdit } from "./TagsEdit";
import ReusableDelete from "../../../components/reusable-delete/ReusableDelete";
import { SxStyles } from "../../../styles/styles";
import ReactLoading from "react-loading";

const inputStyle = makeStyles(() => ({
  root: {
    overflowY: "auto",
    marginBottom: "8%",
  },
  inputStyle: {
    "& .MuiInputBase-fullWidth": {
      height: "44px",
    },
  },
  ".MuiInputBase-root": {
    color: "#fff !important",
  },
  label: {
    color: "#fff !important",
    fontSize: "16px",
    marginTop: "-10px",
    fontFamily: "poppins",
    transform: " translate(14px, 20px) scale(1) !important",
    borderColor: "#fb2485 !important",
  },
}));

const Categories = () => {
  const classes = inputStyle();
  const [limit, setLimit] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [draggableData, setDraggableData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState([]);
  const styles = SxStyles.styles;
  const [categoryType, setCategoryType] = useState("");
  const [categoryStatus, setCategoryStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const typeDropdownValue = [
    { key: 0, title: "Unit", value: 0 },
    { key: 1, title: "Mandatory Training", value: 1 },
    { key: 2, title: "Course", value: 2 },
    { key: 3, title: "Program", value: 3 }
  ];

  const stausDropdownValue = [
    { key: 0, title: "Active", value: 1 },
    { key: 1, title: "InActive", value: 0 },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Category Name",
      width: 580,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "Category Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "Category Type",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <ReusableButton
            size="large"
            className="qstnUpldPageBttn"
            startIcon={
              <>
                <IconButton
                  aria-label="delete"
                  disabled={selectAll.length !== 0}
                  onClick={() => handleEditClick(params)}
                >
                  <EditIcon
                    color={selectAll.length ===0 ? "primary" : "grey"}
                    fontSize="small"
                  />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteClick(params)}
                  disabled={selectAll.length !== 0}
                >
                  <DeleteOutlineOutlinedIcon
                    color={selectAll.length === 0 ? "primary" : "grey"}
                    fontSize="small"
                  />
                </IconButton>
              </>
            }
          />
        </div>
      ),
    },
  ];
  useEffect(() => {
    getAllCategories();
  }, [categoryType, categoryStatus, searchInput, pageCount]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  function formatCategoryData(data) {
    const categoryTypes = {
      0: "Unit",
      1: "Mandatory Training",
      2: "Course",
      3: "Program",
    };
    return data?.map((category, index) => ({
      id: category.id,
      name: category.name,
      status: category.status === 1 ? "Active" : "InActive",
      type: categoryTypes[category.type] || "",
      description: category.description,
    }));
  }

  const onSelectionModelChange = (selectionModel) => {
    setSelectAll(selectionModel);
  };
  

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setSelectAll([]);
  };

  const handleDeleteCategory = () => {
    setOpenDelete(false);
    const payLoad ={
      ids:selectAll
    };
    API.DeleteCategory(payLoad).then((response) => {
      if (response.response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: response?.response.data[0]?.message,
          type: "success",
        });
        getAllCategories();
      }
      else{
        setNotify({
          isOpen: true,
          message: response?.response.error[0]?.error_msg,
          type: "error",
        });
        setSelectAll([]);
      }
    });
  };

  const handleDeleteClick = (params) => {
    setOpenDelete(true);
    const updateRow = [params.id];
    setSelectAll(updateRow);
  };

  const CreateCategory = () => {
    setOpenEdit(true);
  };

  const handleCloseModal = () => {
    setOpenEdit(false);
  };

  const handlePageClick = (e) => {
    setPageCount(e.selected);
  };

  const handleCategoryTypeChange = (e) => {
    setCategoryType(e.target.value);
    setPageCount(0);
  };

  const handleCategoryStatusChange = (e) => {
    setCategoryStatus(e.target.value);
    setPageCount(0);
  };

  const handleEditClick = (params) => {
    setOpenEdit(true);
    const data = [{ ...params.row }];
    setCategoryData(data);
  };

  const handleEditCategory = (updateRowData) => {
    const updateRow = categoryData[0];
    let categoryDetails = {
      category_name: updateRowData.categoryName,
      description: updateRowData.description,
      status: updateRowData.status,
      category_type: updateRowData.type,
    };
    API.EditCategory(categoryDetails, updateRow.id).then((response) => {
      if (response.response?.api_status === 200) {
        setNotify({
          isOpen: true,
          message: response?.response?.data[0]?.message,
          type: "success",
        });
        getAllCategories();
      } else {
        setNotify({
          isOpen: true,
          message: response?.response?.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };

  const getAllCategories = (message) => {
    setLoading(true);
    if (message) {
      setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
    }
    API.getAllCategories(categoryType, 10, categoryStatus, pageCount + 1, searchInput).then(
      ({ response }) => {
        if (response.data.api_status === 200) {
          const CategoryData = response?.data.data.rows?.map((categoryValue) => {
            return {
              id: categoryValue.id,
              name: categoryValue.category_name,
              status: categoryValue.status,
              type: categoryValue.category_type,
              description: categoryValue.description,
            };
          });
          const formattedData = formatCategoryData(CategoryData);
          setLimit(response.data.data.count);
          setDraggableData(formattedData);
          setAllCategories(CategoryData);
          setLoading(false);
          setSelectAll([]);
          setCategoryData([]);
          setOpenEdit(false);
        }
      }
    );
  };
  return (
    <>
      <div>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingTop: "20px",
          }}
          spacing={8}
        >
          <Grid item xs={3.5}>
            <Custom.Input
              name="category"
              type="text"
              placeholder="Search by Category name"
              size="small"
              className={classes.inputStyle}
              value={searchInput}
              fullWidth={true}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Grid>
          <Grid item xs={3} style={SxStyles.createCourseStyle.createCourseGrid}>
            <InputLabel
              className="InputLabel"
              style={{
                ...SxStyles.createCourseStyle.createCourseSpan,
                marginTop: "-31px",
              }}
            >
              Select Type
            </InputLabel>
            <Custom.ReusableDropdown
              name="type"
              id="type"
              placeholder="Select Type"
              displayEmpty
              options={typeDropdownValue}
              onChange={(e) => handleCategoryTypeChange(e)}
              value={categoryType}
              className="batchmuipopober"
              size="small"
              style={{
                height: 40,
                fontFamily: "Poppins",
              }}
            ></Custom.ReusableDropdown>
          </Grid>
          <Grid item xs={3} style={SxStyles.createCourseStyle.createCourseGrid}>
            <InputLabel
              className="InputLabel"
              style={{
                ...SxStyles.createCourseStyle.createCourseSpan,
                marginTop: "-31px",
              }}
            >
              Status
            </InputLabel>
            <Custom.ReusableDropdown
              name="status"
              id="status"
              placeholder="Select Status"
              displayEmpty
              options={stausDropdownValue}
              onChange={(e) => handleCategoryStatusChange(e)}
              value={categoryStatus}
              className="batchmuipopober"
              size="small"
              style={{
                height: 40,
                fontFamily: "Poppins",
              }}
            ></Custom.ReusableDropdown>
          </Grid>
          <Grid item xs={1.5}>
            <ReusableButton
              size="large"
              variant="contained"
              className="candidateDltepageDeleteBttn"
              buttonName={
                typeof categoryType === "number" ||
                typeof categoryStatus === "number" ||
                searchInput
                  ? "Reset"
                  : "Create"
              }
              style={{
                backgroundColor: "#fb2485",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAalign: "center",
                color: "#fff",
                padding: "8px 28px",
                borderRadius: "4px",
                textTransform: "none",
              }}
              onClick={() => {
                if (
                  typeof categoryType === "number" ||
                  typeof categoryStatus === "number" ||
                  searchInput
                ) {
                  setPageCount(0);
                  setCategoryStatus("");
                  setCategoryType("");
                  setSearchInput("");
                } else CreateCategory();
              }}
            ></ReusableButton>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "40px" }}>
          {loading ? (
            <div
              style={{
                zIndex: 999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                width: "90%",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </div>
          ) : (
            <>
              <div style={{ zIndex: 0, position: "relative" }}>
                <ReusableButton
                  disabled={selectAll.length === 0}
                  style={
                    selectAll.length
                      ? {
                          ...styles.saveForLaterBtn,
                          marginBottom: "8px",
                          cursor: "pointer",
                          backgroundColor: "#fb2485",
                        }
                      : {
                          ...styles.saveForLaterBtn,
                          marginBottom: "8px",
                          backgroundColor: "grey",
                        }
                  }
                  onClick={() => setOpenDelete(true)}
                  buttonName="Delete All"
                />
                <DataTable
                  rows={draggableData}
                  columns={columns}
                  rowHeight={40}
                  autoHeight
                  pageSize={10}
                  hideFooter={true}
                  hideFooterPagination={true}
                  hideFooterSelectedRowCount={true}
                  className="assigntable"
                  checkboxSelection={true}
                  onSelectionModelChange={onSelectionModelChange}
                />
              </div>
              {limit > 9 ? (
                <Box>
                  <ReactPaginate
                    previousLabel={<>&laquo;</>}
                    nextLabel={<>&raquo;</>}
                    pageCount={Math.ceil(limit / 10)}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    initialPage={pageCount}
                  />
                </Box>
              ) : null}
            </>
          )}
        </Box>
        {openEdit && (
          <CategoryEdit
            handleCloseModal={handleCloseModal}
            getAllCategories={getAllCategories}
            categoryData={categoryData?.length ? categoryData : undefined}
            handleEditCategory={handleEditCategory}
          />
        )}
      </div>
      {openDelete && (
        <ReusableDelete
          handleDeleteTag={handleDeleteCategory}
          handleDeleteClose={handleDeleteClose}
        />
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
export default Categories;
