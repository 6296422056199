import React, { useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuItemsAdmin from "../menu-items-admin/menu-items-admin";
import "./admin-layout.css";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { AdminPath } from "../../../constants/userPath";
import Cookies from "js-cookie";
import { AcademyCSS } from "./adminStyles";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
const stylesDrawer = makeStyles((theme) => ({
  openDrawer: {
    "& .MuiPaper-root": {
      backgroundColor: "#000936",
    },
  },
}));

const mdTheme = createTheme();

function AdminHomePage(props) {
  const classes = stylesDrawer();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const UpdatedAdminActive =
    location.pathname === AdminPath.ADMIN_CREATE_UNIT ||
    location.pathname === AdminPath.ADMIN_CREATE_COURSE ||
    location.pathname === AdminPath.ADMIN_CREATE_PROGRAM;

  const configurationActive =
    location.pathname === AdminPath.ADMIN_CREATE_CONFIGURATIONS;
    
  const onClickLogout = () => {
    Cookies.remove("jwt_token");
    Cookies.remove("user_role");
    navigate("/");
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          style={{ backgroundColor: "#000936" }}
          open={open}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
            className="column"
          >
            <IconButton
              edge="start"
              color="inherit"
              size="large"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                // marginRight: "12px",
                paddingRight: "15px !important",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon className="menuiconBtn" />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ display: { xs: "none", sm: "block" } }}
              style={AcademyCSS}
            >
              ABSYZ Academy
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={onClickLogout}
                color="inherit"
              >
                <LogoutIcon className="logoutbtn" />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open} className={classes.openDrawer}>
          <Toolbar
            style={{
              backgroundColor: "#000936",
              display: "flex",
              justifyContent: "end",
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
              backgroundColor: "#000936",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon
                style={{
                  color: "white",
                }}
              />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MenuItemsAdmin
              UpdatedAdminActive={UpdatedAdminActive}
              configurationActive={configurationActive}
            />
          </List>
        </Drawer>
        
        <Box
          component="main"
          // className='layout-size'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "hidden !important",
          }}
        >
          <Toolbar />
          <Container
            className="adminlayout"
            maxWidth="xxl"
            sx={{ minheight: "40vh", padding: "0px !important" }}
          >
            <Outlet />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AdminHomePage;
