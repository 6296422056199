import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { EditOutlined } from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { FormGroup } from "@mui/material";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Custom } from "../Custom/Custom";

function Row(props) {
  const {
    row,
    handleOpenModal,
    disabled,
    setUnitId,
    handleDeleteModal,
    programLocal,
    setEditedDetails,
    handleUnitNavigate,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [Expand, setExpand] = React.useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = React.useState(false);
  // const [selectedRadio, setSelectedRadio] = React.useState("manual");
  const [selectedRadio, setSelectedRadio] = React.useState();
  const [isSwitchEnabled, setIsSwitchEnabled] = React.useState(false);
  const [startDatevalue, setStartDatevalue] = React.useState();
  const [startTime, setstartTime] = React.useState();
  const [endDatevalue, setEndDatevalue] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const [AssignmentselectedRadio, setAssignmentSelectedRadio] =
    React.useState();
  const [AssignmentisSwitchEnabled, setAssignmentIsSwitchEnabled] =
    React.useState(false);
  const [AssignmentstartDatevalue, setAssignmentStartDatevalue] =
    React.useState();
  const [AssignmentstartTime, setAssignmentstartTime] = React.useState();
  const [AssignmentendDatevalue, setAssignmentEndDatevalue] = React.useState();
  const [AssignmentendTime, setAssignmentEndTime] = React.useState();
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedRadio(value);
    // If "Automatic" is selected, disable the switch
    setIsSwitchDisabled(value === "Automatic");
    if (value === "Automatic") {
      setIsSwitchEnabled(true); // Enable and set the switch to true
    } else {
      setIsSwitchEnabled(false); // Disable the switch
    }
  };
  const handleAssignmentRadioChange = (event) => {
    const value = event.target.value;
    setAssignmentSelectedRadio(value);
    // If "Automatic" is selected, disable the switch
    setAssignmentIsSwitchEnabled(value === "Automatic");
    if (value === "Automatic") {
      setAssignmentIsSwitchEnabled(true); // Enable and set the switch to true
    } else {
      setAssignmentIsSwitchEnabled(false); // Disable the switch
    }
  };

  const getFormatTime = (time) => {
    if (time.includes("AM") || time.includes("PM")) {
      // just checking Am an dpm this table is used in bootcamp and programs
      return time;
    } else {
      const [hours, minutes] = time.split(":");
      const parsedHours = parseInt(hours, 10);
      const suffix = parsedHours >= 12 ? "PM" : "AM";
      const hours12 =
        parsedHours === 0
          ? 12
          : parsedHours > 12
          ? parsedHours - 12
          : parsedHours;
      return `${hours12}:${minutes} ${suffix}`;
    }
  };

  const handleChangedate = (date) => {
    console.log(date, "date in on click of calender");
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Typography
            style={{
              fontSize: "15px",
              fontWeight: "400",
              lineHeight: "24px",
              fontFamily: "Poppins",
              display: "flex",
            }}
            className="classprogram"
          >
            {/* <div>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setExpand(!Expand)}
              >
                {Expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </div> */}
            <div
              onClick={() => {
                props.handleUnitNavigate(row.id);
              }}
            >
              {row.Program}
            </div>
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          style={{
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            fontFamily: "Poppins",
          }}
        >
          {row.Date}
        </TableCell>
        <TableCell
          align="right"
          style={{
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            fontFamily: "Poppins",
          }}
        >
          {getFormatTime(row.StartTime).split(":")[0] === "NaN"
            ? "N/A"
            : getFormatTime(row.StartTime)}
          {/* {row.StartTime} */}
        </TableCell>
        <TableCell
          align="right"
          style={{
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            fontFamily: "Poppins",
          }}
        >
          {getFormatTime(row.EndTime).split(":")[0] === "NaN"
            ? "N/A"
            : getFormatTime(row.EndTime)}
          {/* {row.EndTime} */}
        </TableCell>
        <TableCell
          align="right"
          style={{
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            fontFamily: "Poppins",
          }}
        >
          {row.Duration}
        </TableCell>
        <TableCell
          align="right"
          style={{
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            fontFamily: "Poppins",
          }}
        >
          {row.Trainer}
        </TableCell>
        <TableCell
          align="right"
          style={{
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            fontFamily: "Poppins",
          }}
        >
          {row.Type}
        </TableCell>
        <TableCell
          align="right"
          style={{
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            fontFamily: "Poppins",
          }}
        >
          {row.Status}
        </TableCell>
        <TableCell align="right">
          <span className="delete-button">
            <IconButton
              aria-label="delete"
              size="small"
              disabled={disabled || Array.isArray(programLocal)}
            >
              {" "}
              <DeleteOutlineIcon
                color={
                  disabled || Array.isArray(programLocal)
                    ? "disabled"
                    : "primary"
                }
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                }}
                onClick={() => {
                  handleDeleteModal(row);
                }}
              />
            </IconButton>
            <IconButton
              disabled={disabled || Array.isArray(programLocal)}
              onClick={() => {
                handleOpenModal(row.Program, row.id);
                setEditedDetails([row]);

                setUnitId(row.id);
              }}
            >
              <EditOutlined
                color={
                  disabled || Array.isArray(programLocal)
                    ? "disabled"
                    : "primary"
                }
                fontSize="small"
                style={{
                  minWidth: "14px !important",
                  marginLeft: "2%",
                }}
              />
            </IconButton>
          </span>
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "None" }}
          colSpan={12}
        >
          <Collapse in={Expand} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ width: "150px", fontSize: "15px" }}
                      >
                        Training Material
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={selectedRadio}
                        onChange={handleRadioChange}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <FormControlLabel
                          value="Automatic"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 20,
                                },
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "16px" }}
                            >
                              Automatic
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="manual"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 20,
                                },
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "16px" }}
                            >
                              Manual
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      <FormGroup>
                        <FormControlLabel
                          required
                          control={
                            <Switch
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  color: "#FB2485",
                                },
                                "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled":
                                  {
                                    color: "#FB2485",
                                  },
                                "&:hover": {
                                  backgroundColor: alpha("#000936"),
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                  {
                                    backgroundColor: "#000936",
                                  },
                              }}
                              checked={isSwitchEnabled} // Set the checked state of the switch
                              onChange={() =>
                                setIsSwitchEnabled(!isSwitchEnabled)
                              } // Toggle switch state
                              disabled={selectedRadio === "Automatic"} // Disable the switch if "Automatic" is selected
                            />
                          }
                          label="Visible"
                        />
                      </FormGroup>
                      {isSwitchEnabled && selectedRadio === "Automatic" ? (
                        <>
                          <Custom.DatePicker
                            name="startDate"
                            id="startDate"
                            value={
                              startDatevalue !== null ||
                              startDatevalue !== undefined
                                ? startDatevalue
                                : new Date()
                            }
                            // value={""}
                            onChange={handleChangedate}
                            style={{
                              width: "160px",
                            }}
                            size="small"
                          />
                          <input
                            type="time"
                            style={{
                              height: "6.7vh",
                              width: "6vw",
                              marginTop: "1px",
                              border: "0.5px solid  #B0B0B0",
                              borderRadius: 4,
                              fontFamily: "poppins",
                              fontSize: 14,
                              paddingLeft: "12px",
                              marginLeft: "7px",
                            }}
                            name="toTimeOfQuiz"
                            value={startTime}
                            onChange={(e) => setstartTime(e.target.value)}
                            placeholder="Start Time"
                          />
                          &nbsp; To &nbsp;
                          <Custom.DatePicker
                            name="endDate"
                            id="endDate"
                            value={
                              endDatevalue !== null ||
                              endDatevalue !== undefined
                                ? endDatevalue
                                : new Date()
                            }
                            onChange={handleChangedate}
                            style={{ width: "160px" }}
                            size="small"
                          />
                          <input
                            type="time"
                            style={{
                              height: "6.7vh",
                              width: "6vw",
                              marginTop: "1px",
                              border: "0.5px solid  #B0B0B0",
                              borderRadius: 4,
                              fontFamily: "poppins",
                              fontSize: 14,
                              paddingLeft: "12px",
                              marginLeft: "7px",
                            }}
                            name="toTimeOfQuiz"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            placeholder="Start Time"
                          />
                        </>
                      ) : null}
                    </FormControl>
                  </TableRow>
                </TableHead>
                
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
      {/* <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "None" }}
          colSpan={6}
        >
          <Collapse in={Expand} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ paddingLeft: "20px", paddingRight: "50px" }}
                      >
                        Assignment
                      </FormLabel>
                      <RadioGroup
                        style={{ paddingLeft: "36px" }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        // defaultValue="manual"
                      >
                        <FormControlLabel
                          value="Automatic"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label="Automatic"
                        />
                        <FormControlLabel
                          value="manual"
                          control={
                            <Radio
                              sx={{
                                // color: "#FB2485",
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label="manual"
                        />
                      </RadioGroup>
                    </FormControl>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
      {/* <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "None" }}
          colSpan={12}
        >
          <Collapse in={Expand} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ width: "150px", fontSize: "15px" }}
                      >
                        Assignment
                      </FormLabel>
                      <RadioGroup
                        style={{ display: "flex", flexDirection: "row" }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        // defaultValue="manual"
                        value={selectedRadio}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="Automatic"
                          control={
                            <Radio
                              sx={{
                                // fontSize: "14px",
                                "& .MuiSvgIcon-root": {
                                  fontSize: 20,
                                },
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "16px" }}
                            >
                              Automatic
                            </Typography>
                          }
                          // sx={{ fontSize: "10px" }}
                        />
                        <FormControlLabel
                          value="manual"
                          control={
                            <Radio
                              sx={{
                                // color: "#FB2485",
                                "& .MuiSvgIcon-root": {
                                  fontSize: 20,
                                },
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "16px" }}
                            >
                              Manual
                            </Typography>
                          }
                          // sx={{ fontSize: "14px" }}
                        />
                      </RadioGroup>
                      <FormGroup>
                        <FormControlLabel
                          required
                          control={
                            <Switch
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  color: "#FB2485",
                                },
                                "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled":
                                  {
                                    color: "#FB2485",
                                  },
                                "&:hover": {
                                  backgroundColor: alpha("#000936"),
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                  {
                                    backgroundColor: "#000936",
                                  },
                              }}
                              checked={isSwitchEnabled} // Set the checked state of the switch
                              onChange={() =>
                                setIsSwitchEnabled(!isSwitchEnabled)
                              } // Toggle switch state
                              disabled={selectedRadio === "Automatic"} // Disable the switch if "Automatic" is selected
                            />
                          }
                          label="Visible"
                        />
                      </FormGroup>
                      {isSwitchEnabled && selectedRadio === "Automatic" ? (
                        <>
                          <Custom.DatePicker
                            name="startDate"
                            id="startDate"
                            value={
                              startDatevalue !== null ||
                              startDatevalue !== undefined
                                ? startDatevalue
                                : new Date()
                            }
                            // value={""}
                            onChange={handleChangedate}
                            style={{
                              width: "160px",
                              // paddingTop: "8.5px",
                              // paddingBottom: "8.5px",
                              // "&.MuiOutlinedInput-input": {
                              //   paddingTop: "10.5px",
                              //   paddingBottom: "10.5px",
                              // },
                              // "&.MuiOutlinedInput-inputMarginDense": {
                              //   padding: "18.5px 10px;",
                              // },
                            }}
                            // error={
                            //   formik.touched.startDate &&
                            //   Boolean(formik.errors.startDate)
                            // }
                            // helperText={
                            //   formik.touched.startDate && formik.errors.startDate
                            // }
                            // disabled={disabled}
                            // minDate={formik.values.startDate}
                            size="small"
                          />
                          <input
                            type="time"
                            style={{
                              height: "6.7vh",
                              width: "6vw",
                              marginTop: "1px",
                              border: "0.5px solid  #B0B0B0",
                              borderRadius: 4,
                              fontFamily: "poppins",
                              fontSize: 14,
                              paddingLeft: "12px",
                              marginLeft: "7px",
                            }}
                            name="toTimeOfQuiz"
                            value={startTime}
                            onChange={(e) => setstartTime(e.target.value)}
                            placeholder="Start Time"
                          />
                          &nbsp; To &nbsp;
                          <Custom.DatePicker
                            name="endDate"
                            id="endDate"
                            value={
                              endDatevalue !== null ||
                              endDatevalue !== undefined
                                ? endDatevalue
                                : new Date()
                            }
                            // value={""}
                            onChange={handleChangedate}
                            style={{ width: "160px" }}
                            // error={
                            //   formik.touched.startDate &&
                            //   Boolean(formik.errors.startDate)
                            // }
                            // helperText={
                            //   formik.touched.startDate && formik.errors.startDate
                            // }
                            // disabled={disabled}
                            // minDate={formik.values.startDate}
                            size="small"
                          />
                          <input
                            type="time"
                            style={{
                              height: "6.7vh",
                              width: "6vw",
                              marginTop: "1px",
                              border: "0.5px solid  #B0B0B0",
                              borderRadius: 4,
                              fontFamily: "poppins",
                              fontSize: 14,
                              paddingLeft: "12px",
                              marginLeft: "7px",
                            }}
                            name="toTimeOfQuiz"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            placeholder="Start Time"
                          />
                        </>
                      ) : null}
                    </FormControl>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={Expand} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ paddingLeft: "20px", paddingRight: "50px" }}
                      >
                        Quiz
                      </FormLabel>
                      <RadioGroup
                        style={{ paddingLeft: "86px" }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        // defaultValue="manual"
                      >
                        <FormControlLabel
                          value="Automatic"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label="Automatic"
                        />
                        <FormControlLabel
                          value="manual"
                          control={
                            <Radio
                              sx={{
                                // color: "#FB2485",
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label="manual"
                        />
                      </RadioGroup>
                    </FormControl>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
      {/* <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "None" }}
          colSpan={12}
        >
          <Collapse in={Expand} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ width: "150px", fontSize: "15px" }}
                      >
                        Quiz
                      </FormLabel>
                      <RadioGroup
                        style={{ display: "flex", flexDirection: "row" }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        // defaultValue="manual"
                        value={selectedRadio}
                        onChange={handleRadioChange}
                        // style={{

                        // }}
                      >
                        <FormControlLabel
                          value="Automatic"
                          control={
                            <Radio
                              sx={{
                                // fontSize: "14px",
                                "& .MuiSvgIcon-root": {
                                  fontSize: 20,
                                },
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "16px" }}
                            >
                              Automatic
                            </Typography>
                          }
                          // sx={{ fontSize: "10px" }}
                        />
                        <FormControlLabel
                          value="manual"
                          control={
                            <Radio
                              sx={{
                                // color: "#FB2485",
                                "& .MuiSvgIcon-root": {
                                  fontSize: 20,
                                },
                                "&.Mui-checked": {
                                  color: "#FB2485",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "16px" }}
                            >
                              Manual
                            </Typography>
                          }
                          // sx={{ fontSize: "14px" }}
                        />
                      </RadioGroup>
                      <FormGroup>
                        <FormControlLabel
                          required
                          control={
                            <Switch
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  color: "#FB2485",
                                },
                                "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled":
                                  {
                                    color: "#FB2485",
                                  },
                                "&:hover": {
                                  backgroundColor: alpha("#000936"),
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                  {
                                    backgroundColor: "#000936",
                                  },
                              }}
                              checked={isSwitchEnabled} // Set the checked state of the switch
                              onChange={() =>
                                setIsSwitchEnabled(!isSwitchEnabled)
                              } // Toggle switch state
                              disabled={selectedRadio === "Automatic"} // Disable the switch if "Automatic" is selected
                            />
                          }
                          label="Visible"
                        />
                      </FormGroup>
                      {isSwitchEnabled && selectedRadio === "Automatic" ? (
                        <>
                          <Custom.DatePicker
                            name="startDate"
                            id="startDate"
                            value={
                              startDatevalue !== null ||
                              startDatevalue !== undefined
                                ? startDatevalue
                                : new Date()
                            }
                            // value={""}
                            onChange={handleChangedate}
                            style={{
                              width: "160px",
                              // paddingTop: "8.5px",
                              // paddingBottom: "8.5px",
                              // "&.MuiOutlinedInput-input": {
                              //   paddingTop: "10.5px",
                              //   paddingBottom: "10.5px",
                              // },
                              // "&.MuiOutlinedInput-inputMarginDense": {
                              //   padding: "18.5px 10px;",
                              // },
                            }}
                            // error={
                            //   formik.touched.startDate &&
                            //   Boolean(formik.errors.startDate)
                            // }
                            // helperText={
                            //   formik.touched.startDate && formik.errors.startDate
                            // }
                            // disabled={disabled}
                            // minDate={formik.values.startDate}
                            size="small"
                          />
                          <input
                            type="time"
                            style={{
                              height: "6.7vh",
                              width: "6vw",
                              marginTop: "1px",
                              border: "0.5px solid  #B0B0B0",
                              borderRadius: 4,
                              fontFamily: "poppins",
                              fontSize: 14,
                              paddingLeft: "12px",
                              marginLeft: "7px",
                            }}
                            name="toTimeOfQuiz"
                            value={startTime}
                            onChange={(e) => setstartTime(e.target.value)}
                            placeholder="Start Time"
                          />
                          &nbsp; To &nbsp;
                          <Custom.DatePicker
                            name="endDate"
                            id="endDate"
                            value={
                              endDatevalue !== null ||
                              endDatevalue !== undefined
                                ? endDatevalue
                                : new Date()
                            }
                            // value={""}
                            onChange={handleChangedate}
                            style={{ width: "160px" }}
                            // error={
                            //   formik.touched.startDate &&
                            //   Boolean(formik.errors.startDate)
                            // }
                            // helperText={
                            //   formik.touched.startDate && formik.errors.startDate
                            // }
                            // disabled={disabled}
                            // minDate={formik.values.startDate}
                            size="small"
                          />
                          <input
                            type="time"
                            style={{
                              height: "6.7vh",
                              width: "6vw",
                              marginTop: "1px",
                              border: "0.5px solid  #B0B0B0",
                              borderRadius: 4,
                              fontFamily: "poppins",
                              fontSize: 14,
                              paddingLeft: "12px",
                              marginLeft: "7px",
                            }}
                            name="toTimeOfQuiz"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            placeholder="Start Time"
                          />
                        </>
                      ) : null}
                    </FormControl>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
      <TableRow>
        {row.Units ? (
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  className="unitheading"
                >
                  Units
                </Typography>
                <Table
                  size="small"
                  aria-label="purchases"
                  style={{
                    border: "1px solid #f5f3f3",
                    width: "116%",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Duration</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.Units.map((units) => (
                      <TableRow key={units.id}>
                        <TableCell component="th" scope="row">
                          <Typography
                            className="classprogram"
                            // onClick={() => {
                            //   handleOpenModal(units.name, units.id);
                            // }}
                          >
                            {units.name}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ width: "68%" }}>
                          {units.Description}
                        </TableCell>
                        <TableCell>15min</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        ) : null}
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  return (
    <TableContainer
      component={Paper}
      style={{ boxShadow: "none", border: "1px solid rgb(189 197 206)" }}
    >
      <Table aria-label="collapsible table" style={{ boxShadow: "none" }}>
        <TableHead>
          <TableRow style={{ backgroundColor: "#f1f1f1" }}>
            <TableCell
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
            >
              Program name
            </TableCell>
            <TableCell
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
              align="right"
            >
              Date
            </TableCell>
            <TableCell
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
              align="right"
            >
              StartTime
            </TableCell>
            <TableCell
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
              align="right"
            >
              EndTime
            </TableCell>
            <TableCell
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
              align="right"
            >
              Unit Duration
            </TableCell>
            <TableCell
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
              align="right"
            >
              Trainer
            </TableCell>
            <TableCell
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
              align="right"
            >
              Type
            </TableCell>
            <TableCell
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
              align="right"
            >
              Status
            </TableCell>
            <TableCell
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Poppins",
                fontStyle: "normal",
              }}
              align="right"
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <Row
              key={row.name}
              row={row}
              handleOpenModal={props.handleOpenModal}
              disabled={props.disabled}
              handleDeleteModal={props.handleDeleteModal}
              setUnitId={props.setUnitId}
              programLocal={props.programLocal}
              setEditedDetails={props.setEditedDetails}
              handleUnitNavigate={props.handleUnitNavigate}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
