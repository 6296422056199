import React, { useState, useCallback } from "react";
import TableRows from "./TableRow";
import styled from "styled-components";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "../../../handler/draggableHandler";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { Link } from "react-router-dom";
import { ReusableRememberMe } from "../remember-me/remember-me";
import ReusableButton from "../../reusable-button/reusable-button";
import { SxStyles } from "../../../styles/styles";
import { API } from "../../../Networking/API";
import Notification from "../../Custom/Notification";
import ReusableDelete from "../../reusable-delete/ReusableDelete";

const MyTableWrapper = styled.div`
  .SNo {
    width: 9% !important;
  }
  .Name {
    width: 18% !important;
  }
  .VideoLink {
    width: 20% !important;
  }
  .Material {
    width: 16% !important;
  }
  .Actions {
    width: 15% !important;
  }
  .MuiTableCell-root {
    padding-right: 36px;
  }
  .Actions {
    // text-align: center !important;
  }
  .MuiTableCell-root {
    max-width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});
const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

const SortableItem = SortableElement((props) => <TableRows {...props} />);

const DataTableDrag = ({
  draggableData,
  pageIndex,
  pageLimit,
  setDraggableData,
  handleDeleteInpre,
  handleViewClick,
  link,
  dataTableHeader,
  IsActivity,
  handleEditClick,
  disabled,
  deleteId,
  getPreRequisites,
  type
}) => {
  let pageNo = pageIndex + 1;
  const [selectAll, setSelectAll] = useState(false);
  let startIndex = (pageNo - 1) * pageLimit;
  let endIndex = pageNo * pageLimit - 1;
  if (pageIndex > 0) {
    pageIndex++;
  }
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openDelete, setOpenDelete] = useState(false);
  const courseId = localStorage.getItem("courseId");
  const styles = SxStyles.styles;
  const handleUpArrow = (selectedId) => {
    if (!disabled) {
      let indexValue;
      draggableData.map((data, index) => {
        if (data.id === selectedId) {
          indexValue = index;
        }
        return data;
      });
      let oldIndex = indexValue;
      let newIndex = indexValue - 1;
      if (oldIndex !== 0) {
        setDraggableData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
      }
    }
  };

  const handleDownArrow = (selectedId) => {
    if (!disabled) {
      let indexValue;
      draggableData.map((data, index) => {
        if (data.id === selectedId) {
          indexValue = index;
        }
        return data;
      });
      let oldIndex = indexValue;
      let newIndex = indexValue + 1;

      setDraggableData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
    }
  };
  const handleDeleteAll = () => {
    setOpenDelete(true);
  };

  const handleSelectedBox = (selectedId) => {
    const updatedData = draggableData.map((data, index) => {
      if (index === selectedId) {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      }
      return data;
    });
    const checkedItems = updatedData.filter((data) => data.isChecked === true);
    if (checkedItems.length === 0) handleSelectAll(false);
    else {
      setDraggableData(updatedData);
      setSelectAll(true);
    }
  };

  const handleDeleteItems = () => {
    const checkedItems = draggableData.filter(
      (data) => data.isChecked === true
    );
    const preReqIds = checkedItems.map((data) => data.id);
    const payLoad = JSON.stringify({
      unit_id: deleteId,
      prerequisite_id: preReqIds,
    });
    if(type){
      getPreRequisites(preReqIds);
      setOpenDelete(false);
    }
   else{
    API.deleteAllAdminPreRequisite(payLoad).then((response) => {
      if (response.response.api_status === 200) {
        setOpenDelete(false);
        setSelectAll(false);
        getPreRequisites(response.response.data[0].message);
      } else {
        setNotify({
          isOpen: true,
          message: response.response.error[0].error_msg,
          type: "error",
        });
      }
    });
   }
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleSelectAll = (value) => {
    const updatedData = draggableData.map((data, index) => ({
      ...data,
      isChecked: value,
    }));
    setSelectAll(value);
    setDraggableData(updatedData);
  };

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setDraggableData((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);
  const classes = useStyles();

  return (
    <div>
      <ReusableButton
        disabled={!selectAll}
        style={{
          ...styles.saveForLaterBtn,
          marginBottom: "1%",
          cursor: !selectAll ? "" : "pointer",
          color: !selectAll ? "#fff" : "#fff",
          backgroundColor: !selectAll ? "grey" : "#FB2485",
        }}
        onClick={handleDeleteAll}
        buttonName="Delete"
      />
      <MyTableWrapper>
        <Table className={classes.table}>
          <TableHead style={{ background: "#f1f1f1" }}>
            <TableRow className="table-row">
              {dataTableHeader.map((data) => {
                return (
                  <>
                    <TableCell
                      style={{
                        textAlign: "center",
                        color: "02215B",
                        fontSize: "16px",
                        fontWeight: "600",
                        width: data.type !== "string" ? "1%" : undefined,
                      }}
                      className={
                        typeof data === "string" &&
                        `${data.replace(" ", "").replace(".", "")}`
                      }
                      onChange={(e)=>handleSelectAll(e.target.checked)}
                    >
                      {data === 'checkBox' ? <ReusableRememberMe checked={selectAll} /> :data}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>

          <SortableCont
            onSortEnd={onSortEnd}
            axis="y"
            lockAxis="y"
            lockToContainerEdges={true}
            lockOffset={["30%", "50%"]}
            helperClass="helperContainerClass"
            useDragHandle={true}
          >
            {draggableData.map((value, index) => {
              if (index >= startIndex && index <= endIndex) {
                return (
                  <SortableItem
                    key={`item-${index}`}
                    index={index}
                    sno={index + 1}
                    first={
                      courseId ? (
                        <Link
                          to="/home/createunit"
                          onClick={() =>
                            localStorage.setItem("UnitId", value.id)
                          }
                          style={{ color: "#0078d4", textDecoration: "none" }}
                        >
                          {value.first}
                        </Link>
                      ) : (
                        value.first
                      )
                    }
                    second={value.second}
                    third={value.third}
                    fourth={value.fourth}
                    five={value.five}
                    sixth={value.sixth}
                    id={value.id}
                    handleDeleteInpre={handleDeleteInpre}
                    handleViewClick={handleViewClick}
                    handleUpArrow={handleUpArrow}
                    handleDownArrow={handleDownArrow}
                    handleEditClick={handleEditClick}
                    handleSelectedBox={handleSelectedBox}
                    link={link}
                    disabled={disabled}
                    isDisabled={value.isChecked}
                    IsActivity={IsActivity}
                    courseId={courseId}
                    handleSelectAll={handleSelectAll}
                    isChecked={value.isChecked}
                  />
                );
              }
            })}
          </SortableCont>
        </Table>
      </MyTableWrapper>
      <Notification notify={notify} setNotify={setNotify} />
      {openDelete && (
        <ReusableDelete
          handleDeleteTag={handleDeleteItems}
          handleDeleteClose={handleDeleteClose}
        />
      )}
    </div>
  );
};

export default DataTableDrag;
