import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./training-session-tabs.css";
import gavrav from "../../../assets/Images/Group 390.png";
import UploadIcon from "@mui/icons-material/Upload";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RButton from "../../../components/Custom/button/button";
import Cookies from "js-cookie";
import CustomDataGrid from "../../../components/reusable-datagrid/CustomDataGrid";
import { API } from "../../../Networking/API";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Custom } from "../../../components/Custom/Custom";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ReactLoading from "react-loading";
import { addDays, addMinutes, isAfter, parse } from "date-fns";
import Notification from "../../../components/Custom/Notification";
import { MyForm } from "../../../components/MyForm";
import { FileUploadErrorMsg } from "../../../constants/errorMsg";
import { TextareaAutosize } from "@mui/material";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import PdfContentExtractor from "../../users/user-courses-details/user-training-materialview";
import PdfContentExtractorTraining from "./training-sesseion-prerequiest";
import VideoPlayerTrainee from "./training-session-video-player";
import ReusableButton from "../../../components/Custom/button/button";

const styles = makeStyles((theme) => ({
  disablebtn: {
    "& .Mui-disabled": {
      color: "#ffffff",
    },
  },
}));

const initialValues = {
  feedback: "",
  radioopt: "",
};

const column = [
  {
    field: "Information",
    headerName: "Information",
    headerClassName: "super-app-theme--header",
    className: "quiz-class-column",
    flex: 1,
  },
  {
    field: "Status",
    headerName: "Status",
    headerClassName: "super-app-theme--header",
    flex: 1,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const {
    uploadStatus,
    scheduledata,
    assignmentData,
    trainerdata,
    quizArray,
    prerequiste,
    quizdata,
    quizResultStatus,
    startQuizButton,
    feedbackStatus,
    quizDetails,
    quizDetailsAfterResults,
    trainermaterial,
    getTraineeTabDetails,
    resultLoaded,
    setResultLoaded,
  } = props;
  const ref = useRef();
  const [upload, setUpload] = useState(true);
  const [del, setDel] = useState();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [lastModified, setLastModified] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [quizAltButton, setQuizAltButton] = useState(true);
  const [viewQuizStatusButton, setViewQuizStatusButton] = useState(true);
  const [status, setStatus] = useState(0);
  const [time, setTime] = useState(0);
  const [data, setData] = useState([]);
  const [radio, setRadio] = useState(false);
  const [loadingf, setLoadingF] = useState(undefined);
  const [radioValue, setRadioValue] = useState("");
  const [feedbackStatussub, setFeedbackStatusSub] = useState();
  const [links, setLinks] = useState(prerequiste[0]);
  const [isActive, setIsActive] = useState(true);
  const [isDueDate, setIsDueDate] = useState(null);
  const [activationTime, setActivationTime] = useState("");
  const [taskStarted, setTaskStarted] = useState(false);

  const [trainingMaterialLinks, setTrainingMaterialLinks] = useState(
    trainermaterial[0]
  );
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [VideoTotalTime, setVideoTotalTime] = useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const location = useLocation();
  const id = location.state.data.id || location.state.data;
  const [file, setFile] = useState({});
  const [files, setFiles] = useState([]);
  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("feedback" in inputValues)
      code.feedback = inputValues.feedback ? "" : "Feedback is required";
    setErrors({
      ...code,
    });
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setRadio(false);
  };

  useEffect(() => {
    getCurrentTab();
    getFeedById();
  }, []);
  const getCurrentTab = async () => {
    if (localStorage.getItem("TrainSessionTabs")) {
      const CurrentTab = await localStorage.getItem("TrainSessionTabs");
      if (CurrentTab) {
        setValue(parseInt(CurrentTab));
      }
    }
  };
  useEffect(() => {
    if (scheduledata) {
      // const targetDateStr = "Tuesday 31, August 2023";
      // const targetTimeStr = "12:44 PM";
      const targetDateStr = scheduledata.training_date;
      const targetTimeStr = scheduledata.to_time;

      const targetDateTime = parse(
        `${targetDateStr} ${targetTimeStr}`,
        "EEEE dd, MMMM yyyy hh:mm aa",
        new Date()
      );

      const bufferMinutes = 30;
      const deactivateTime = addMinutes(targetDateTime, bufferMinutes);

      const currentTime = new Date();
      if (isAfter(currentTime, deactivateTime)) {
        setIsActive(false);

        let options = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
          timeZone: "Asia/Kolkata",
        };

        const reactivationTime = addDays(deactivateTime, 2); // Reactivate after 2 days
        let formattedDate = reactivationTime.toLocaleString("en-IN", options);
        setActivationTime(formattedDate);

        setTimeout(() => {
          setIsDueDate(true);
        }, reactivationTime - currentTime);
      }
    }
  }, []);

  const getFeedById = async (id) => {
    try {
      API.getFeed(scheduledata.schedule_pk, scheduledata.trainer_id).then(
        ({ response }) => {
          if (response.api_status === 200) {
            setFeedbackStatusSub(response.data[0].feedback_status);
          }
          if (response.api_status === 500) {
            setRadioValue("");
            setRadio(false);
          }
          setLoadingF(false);
        }
      );
    } catch (e) {}
  };

  const onSubmitEdit = async (e, id) => {
    e.preventDefault();

    if (radioValue === "") {
      setRadio(true);
    } else {
      setRadio(false);
    }

    if (validate() && radio === false) {
      setLoadingF(true);
      try {
        API.postFeed(
          scheduledata.schedule_pk,
          scheduledata.trainer_id,
          radioValue,
          values.feedback
        ).then(({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            getFeedById();
            setRadioValue("");
            setRadio(false);
            setNotify({
              isOpen: true,
              message: "FeedBack Submitted",
              type: "success",
            });
          }
          if (resultInJson.api_status === 404) {
            setRadioValue("");
            setRadio(false);
            setNotify({
              isOpen: true,
              message: "FeedBack Not Submitted",
              type: "error",
            });
          }
          if (resultInJson.api_status === 500) {
            // getScheduleById(id);
            // setShowPre(false);
            setNotify({
              isOpen: true,
              message: "Feedback Already Submitted",
              type: "error",
            });
            setRadioValue("");
            setRadio(false);
          }
        });
      } catch (e) {}
      resetForm();
    }
  };

  useEffect(() => {
    if (assignmentData[0] !== undefined) {
      getAssignmentData();
    }
  }, []);
  // Cookies.set("quizdata", JSON.stringify(quizdata[0]));

  const HandleStartQuiz = () => {
    Cookies.set("quizdata", JSON.stringify(quizdata[0]));
  };

  const TabClosing = () => {
    setQuizAltButton(false);
    localStorage.removeItem("minsecs");
    localStorage.removeItem("countdownfromapi");
  };
  const closeTab = () => {
    TabClosing();
  };
  const QuizStatusFunction = () => {
    setResultLoaded(false);
    getTraineeTabDetails();
  };

  const getAssignmentData = async () => {
    try {
      API.getAssignment(assignmentData[0].asn_pk).then(({ response }) => {
        const resultInJson = response;
        if (resultInJson.api_status === 200) {
          setFiles(resultInJson.data[0].attachments);
          setLastModified(resultInJson.data[0].last_update);
          setDel(undefined);
          setStatus(resultInJson.data[0].astmt_status);
          setTime(resultInJson.data[0].remain_time);
          setData(resultInJson.data[0]);
          if (resultInJson.data[0].astmt_status === 0) {
            setSubmissionStatus(false);
            setTaskStarted(false);
          } else {
            setSubmissionStatus(true);
          }
          if (resultInJson.data[0].attachments.length > 0) {
            setTaskStarted(true);
          }
          setLoading(false);
          setTaskStarted(true);
          // setStatusDone(true);
        } else if (resultInJson.api_status === 404) {
          setFiles([]);
          setLoading(false);
          // setStatusDone(true);
          setSubmissionStatus(false);
          setTaskStarted(false);

          setTime(resultInJson.error[0].time_remain);
        } else {
          setFiles([]);
          setLoading(false);
          // setStatusDone(true);
          setSubmissionStatus(false);
          setTaskStarted(false);
        }
      });
    } catch (e) {}
  };

  const handleupload = (event) => {
    setFile(event.target.files[0]);
    setUpload(true);
    setErrorStatus(false);
  };

  const handlechangeupload = async (e) => {
    e.preventDefault();
    ref.current.value = "";
    setUpload(false);
    try {
      if (!file.size) {
        setErrorMessage(FileUploadErrorMsg.FILE_SELECT_MSG);
        setErrorStatus(true);
        setLoading(false);
      } else if (files.length < 3) {
        API.uploadAssignmentInTraineeSection(
          assignmentData[0].asn_pk,
          file
        ).then(({ response }) => {
          const resultInJson = response;

          if (resultInJson.api_status === 200) {
            setFile([]);
            setDel(undefined);
            getAssignmentData();
            e.target.value = "";
            setNotify({
              isOpen: true,
              message: "Assignment Uploded Successfully",
              type: "success",
            });
          } else {
            setErrorMessage(resultInJson.error[0].error_msg);
            setErrorStatus(true);
            setLoading(false);
          }
        });
      }
    } catch (e) {
      setErrorMessage(FileUploadErrorMsg.FILE_VALID);
      setErrorStatus(true);
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("TrainSessionTabs", newValue);
  };

  const datas = [
    { id: 1, Information: "Number Questions", Status: quizDetails.noOFques },
    {
      id: 2,
      Information: "Time Duration",
      Status: `${quizDetails.count} Mins `,
    },
    { id: 3, Information: "Attempts", Status: 1 },
  ];

  const handleSubmitAll = async (e) => {
    e.preventDefault();
    try {
      API.traineeAsssignmentSubmition(assignmentData[0].asn_pk).then(
        ({ response }) => {
          const resultInJson = response;
          if (resultInJson.api_status === 200) {
            // setSubmitLoading(false)
            getAssignmentData();
          } else {
            setNotify({
              isOpen: true,
              message: "File Not Submitted",
              type: "error",
            });
            setSubmitLoading(false);
          }
        }
      );
    } catch (e) {}
  };

  const handleDelete = async (id) => {
    try {
      API.deleteAssignment(id).then(({ result }) => {
        const resultInJson = result;
        if (resultInJson.api_status === 200) {
          getAssignmentData();
        }
      });
    } catch (e) {}
  };
  const handlePreClick = (id) => {
    let selectedPreq = prerequiste.find((item) => item.pre_req_pk === id);
    setLinks(selectedPreq);
  };
  const handleTrainingClick = (id) => {
    const selectedTrainingMaterial = trainermaterial.find(
      (item) => item.id === id
    );

    setTrainingMaterialLinks(selectedTrainingMaterial);
  };

  const handleStartAssignment = () => {
    API.startAssignment(assignmentData[0].asn_pk).then(({ response }) => {
      const resultInJson = response;
      if (resultInJson.data.api_status === 200) {
        setNotify({
          isOpen: true,
          message: resultInJson.data.data[0].message,
          type: "success",
        });
        setTaskStarted(true);
      } else {
        setNotify({
          isOpen: true,
          message: "Try Again",
          type: "error",
        });
        setTaskStarted(false);
      }
    });
  };

  return (
    <>
      <>
        <Box
          sx={{
            // bgcolor: "white",
            marginTop: 5,
            marginBottom: 15,
            height: "auto",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ borderRight: 2, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="About Trainer"
                className="tabs-item "
                style={{ borderTopLeftRadius: "12px" }}
                {...a11yProps(0)}
              />
              <Tab
                label="Training Material"
                className="tabs-item"
                {...a11yProps(1)}
              />
              <Tab
                label="Pre-Requisite"
                className="tabs-item"
                {...a11yProps(2)}
              />
              <Tab
                label="Assignment"
                className="tabs-item"
                // disabled={isActive}
                {...a11yProps(3)}
              />
              <Tab
                label="Quiz"
                className="tabs-item"
                // disabled={isActive}
                {...a11yProps(4)}
              />
              <Tab
                label="Feedback"
                className="tabs-item"
                style={{ borderTopRightRadius: "12px" }}
                {...a11yProps(5)}
              />
            </Tabs>
          </Box>

          {/* //About Trainer */}
          <TabPanel
            value={value}
            index={0}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {trainerdata ? (
              <>
                <div className="about-trainer">
                  <div className="section1-about-trainer">
                    <div className="image-div-about-trainer">
                      <img
                        src={gavrav}
                        style={{ height: "75px" }}
                        alt="trainerimage"
                      />
                    </div>
                    <div className="details-about-trainer">
                      <h3 className="name">{trainerdata.user_name}</h3>
                      <p className="desigination">
                        {trainerdata.work_posiotion}
                      </p>
                      <p className="experience">
                        {trainerdata.work_experience !== null
                          ? `${trainerdata.work_experience} years of experience`
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="section2-about-trainer">
                    <div className="section2-about-trainer-sub">
                      {trainerdata.user_description !== "null" ? (
                        <p>{trainerdata.user_description}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="section3-about-trainer">
                    {trainerdata.certificates === null ? (
                      <div className="sectio3-inner-trainer">
                        {trainerdata.certificates !== null ? (
                          <div className="section3-sub-about-trainer">
                            <div className="circular-about-trainer">
                              <span className="number-about-trainer">
                                {trainerdata.certificates}x
                              </span>
                            </div>
                            <div className="description2">
                              <span>Certifications Achieved</span>
                            </div>
                          </div>
                        ) : null}
                        <div className="section3-sub2-about-trainer">
                          <div className="circular-about-trainer">
                            <span className="number2-about-trainer">
                              {trainerdata.sessions_assigned}
                            </span>
                          </div>
                          <div className="description2">
                            <span>Sessions Assigned</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="sectio3-inner-trainer"
                          style={{ marginTop: "30px", marginBottom: "0px" }}
                        >
                          <div className="section3-sub2-about-trainer">
                            <div className="circular-about-trainer">
                              <span className="number2-about-trainer">
                                {trainerdata.sessions_assigned}
                              </span>
                            </div>
                            <div className="description2">
                              <span>Sessions Assigned</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <p style={{ backgroundColor: "white" }}>No Data Found</p>
              </>
            )}
          </TabPanel>

          {/* {trainermaterial !== undefined ? ( */}
          <>
            <TabPanel
              value={value}
              index={1}
              style={{
                backgroundColor: "#fff",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <Box>
                {trainermaterial !== "" || undefined ? (
                  <>
                    <p className="download-text">
                      {trainermaterial[0].description}
                    </p>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        {trainermaterial.map((item) => {
                          return (
                            <Button
                              className="preReq-button"
                              style={{
                                // backgroundColor:
                                //   trainingMaterialLinks.id === item.id
                                //     ? "rgb(0, 9, 54)"
                                //     : "white",
                                fontWeight:
                                  trainingMaterialLinks.id === item.id
                                    ? "700"
                                    : "500",
                                color:
                                  trainingMaterialLinks.id === item.id
                                    ? "rgb(0, 9, 54)"
                                    : "#666666",
                                margin: "18px 0px 0px 18px",
                              }}
                              onClick={() => handleTrainingClick(item.id)}
                            >
                              {item.name}
                            </Button>
                          );
                        })}
                      </Grid>
                      <Grid item xs={9}>
                        <VideoPlayerTrainee
                          selectedTrainingModule={trainingMaterialLinks}
                          setVideoTotalTime={setVideoTotalTime}
                          setCurrentVideoTime={setCurrentVideoTime}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <p style={{ backgroundColor: "white" }}>No Data Found</p>
                  </>
                )}
              </Box>
            </TabPanel>
          </>
          {/* ) : null} */}

          {/* {prerequiste !== undefined ? ( */}
          <>
            <TabPanel
              value={value}
              index={2}
              style={{
                backgroundColor: "#fff",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <div className="Pre-Requisite ">
                <div className="section1-Pre-Requisite "></div>
                <div className="section2-Pre-Requisite ">
                  {scheduledata &&
                  scheduledata.preq_descp != "" &&
                  scheduledata.preq_descp != null ? (
                    <>
                      <span>
                        <b>Description:</b>
                      </span>
                      <p
                        className="description-text"
                        dangerouslySetInnerHTML={{
                          __html: scheduledata.preq_descp,
                        }}
                      />
                    </>
                  ) : null}
                  {prerequiste && prerequiste.length > 0 ? (
                    <>
                      {/* <p className="description-text">
                        {prerequiste[0]?.prereq_description}
                      </p> */}
                      <p>
                        <b>Complete Below Modules :</b>
                      </p>

                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          {prerequiste.map((item) => {
                            return (
                              <Button
                                className="preReq-button"
                                onClick={() => handlePreClick(item.pre_req_pk)}
                                style={{
                                  // backgroundColor:
                                  //   links.pre_req_pk === item.pre_req_pk
                                  //     ? "rgb(0, 9, 54)"
                                  //     : "white",
                                  fontWeight:
                                    links.pre_req_pk === item.pre_req_pk
                                      ? "700"
                                      : "500",
                                  color:
                                    links.pre_req_pk === item.pre_req_pk
                                      ? "rgb(0, 9, 54)"
                                      : "#7f7d7d",
                                  margin: "18px 0px 0px 18px",
                                }}
                              >
                                {item.module_name}
                              </Button>
                            );
                          })}
                        </Grid>

                        <Grid item xs={10}>
                          <PdfContentExtractorTraining link={links} />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {scheduledata &&
                      scheduledata.preq_descp != "" &&
                      scheduledata.preq_descp != null ? null : (
                        <p style={{ backgroundColor: "white" }}>
                          No Data Found
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </TabPanel>
          </>

          {/* Assignment tab */}
          <TabPanel
            value={value}
            index={3}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {assignmentData.length > 0 ? (
              <>
                {taskStarted === false ? (
                  <Box
                    sx={{
                      height: "20vh",
                      display: "flex",
                      jusfityContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ReusableButton
                      buttonName="Start Test"
                      style={{
                        margin: "auto",
                        borderRadius: "4px",
                        backgroundColor: "#fb2485",
                        fontFamily: "Helvetica",
                        fontSize: "16px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "#fff",
                        padding: "10px 20px",
                        textTransform: "capitalize",
                        border: "none",
                      }}
                      onClick={handleStartAssignment}
                      variant="outlined"
                      size="medium"
                    />
                  </Box>
                ) : (
                  <>
                    {assignmentData.length !== 0 &&
                    assignmentData[0].status === 1 ? (
                      <div className="assignment">
                        <div className="section1-assignment">
                          <div className="section1-sub-assignment">
                            <p style={{ textTransform: "capitalize" }}>
                              <b>{assignmentData[0].topic}</b>
                            </p>
                          </div>

                          <br />
                          {/* <span className="duration">End Time : </span>
                          <span>&nbsp;{activationTime}</span> */}

                          {assignmentData[0].as_description !== null ? (
                            <p
                              className="description-text"
                              dangerouslySetInnerHTML={{
                                __html: assignmentData[0].as_description,
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="section2-assignment">
                          <Box className="container-section2-assignment">
                            <div className="section2-sub-assignment">
                              <div className="submission">
                                <p>Submission status </p>
                                <p style={{ fontSize: "14px" }}>
                                  <b>
                                    {!submissionStatus && uploadStatus === 0
                                      ? "TimeOut"
                                      : !submissionStatus
                                      ? "In Progress"
                                      : status === 1
                                      ? "Submitted"
                                      : "Assessed"}
                                  </b>
                                </p>
                              </div>
                              {/* <div className="time-remaining">
                                <p>Time remaining</p>
                                <p style={{ fontSize: "14px" }}>
                                  <b>{time}</b>
                                </p>
                              </div> */}
                              <div className="last-modified">
                                <p>Last Modified</p>
                                <p style={{ fontSize: "14px" }}>
                                  <b>
                                    {lastModified !== undefined
                                      ? lastModified
                                      : "-"}
                                  </b>
                                </p>
                              </div>
                              <div className="Score Achived">
                                <p>Score Achieved</p>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                >
                                  <b>
                                    {status === 2
                                      ? `${data.score_achived} of ${data.total_score}`
                                      : "NA"}
                                  </b>
                                </p>
                              </div>
                            </div>
                          </Box>
                        </div>
                        <div className="section3-assignment">
                          <Box>
                            <h3>
                              <b>Assignment Attachments</b>
                            </h3>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 15,
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: -5,
                                }}
                              >
                                <input
                                  type="file"
                                  name="productImage"
                                  className={
                                    !submissionStatus
                                      ? files.length >= 3
                                        ? "upload-box-disabled"
                                        : "upload-box1"
                                      : "upload-box-disabled"
                                  }
                                  // disabled={
                                  //   isDueDate === true
                                  //     ? isDueDate
                                  //     : uploadStatus > 0
                                  //     ? !submissionStatus
                                  //       ? files.length >= 3
                                  //         ? true
                                  //         : false
                                  //       : true
                                  //     : true
                                  // }
                                  disabled={
                                    uploadStatus > 0
                                      ? !submissionStatus
                                        ? files.length >= 3
                                          ? true
                                          : false
                                        : true
                                      : true
                                  }
                                  onChange={handleupload}
                                  ref={ref}
                                  style={{
                                    height: "43px",

                                    fontSize: "0.8em",
                                    fontFamily: "Poppins",
                                    width: "330px",
                                  }}
                                />
                                <RButton
                                  buttonName={
                                    !loading ? (
                                      "Upload"
                                    ) : (
                                      <CircularProgress size={16} />
                                    )
                                  }
                                  variant={"contained"}
                                  startIcon={loading ? null : <UploadIcon />}
                                  // disabled={
                                  //   isDueDate === true
                                  //     ? isDueDate
                                  //     : uploadStatus > 0
                                  //     ? !loading
                                  //       ? !submissionStatus
                                  //         ? files.length >= 3
                                  //           ? true
                                  //           : false
                                  //         : true
                                  //       : true
                                  //     : true
                                  // }
                                  disabled={
                                    uploadStatus > 0
                                      ? !loading
                                        ? !submissionStatus
                                          ? files.length >= 3
                                            ? true
                                            : false
                                          : true
                                        : true
                                      : true
                                  }
                                  className={
                                    uploadStatus > 0
                                      ? !loading
                                        ? !submissionStatus
                                          ? files.length >= 3
                                            ? "button-assignment-disabled"
                                            : "button-assignment"
                                          : "button-assignment-disabled"
                                        : "button-assignment-disabled"
                                      : "button-assignment-disabled"
                                  }
                                  onClick={(e) => {
                                    setLoading(true);
                                    handlechangeupload(e);
                                  }}
                                />
                              </div>
                              {errorStatus ? (
                                <p className="error">{`*${errorMessage}`}</p>
                              ) : (
                                <p className="error2">
                                  Only .png, .jpg and .jpeg .docx .pdf format
                                  allowed!
                                </p>
                              )}
                            </div>
                          </Box>
                          <Box
                            sx={{
                              marginTop: "30px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              flexWrap: "wrap",
                            }}
                          >
                            {files.length !== 0
                              ? files.map((item, index) => (
                                  <>
                                    {/* {Object.keys(file).length!==0 ? */}
                                    <Box className="buttonBox">
                                      <a
                                        className="attachment"
                                        href={item.asn_link}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                          textDecorationLine: "none",
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <p className="attachment-title">
                                          {`Attachment file ${index + 1}`}
                                        </p>
                                      </a>
                                      <IconButton
                                        aria-label="delete"
                                        color="primary"
                                        disabled={del === index}
                                      >
                                        {!submissionStatus ? (
                                          <DeleteOutlineOutlinedIcon
                                            className="delete-outlined"
                                            // color="disabled"

                                            onClick={() => {
                                              setDel(index);
                                              handleDelete(item.asmt_pk);
                                            }}
                                          />
                                        ) : (
                                          <a
                                            href={item.asn_link}
                                            target="_blank"
                                            style={{
                                              textDecorationLine: "none",
                                              color: "#2089d9",
                                              marginTop: "7px",
                                            }}
                                            rel="noreferrer"
                                          >
                                            <VisibilityOutlinedIcon />
                                          </a>
                                        )}
                                      </IconButton>
                                    </Box>
                                    {/* :null} */}
                                  </>
                                ))
                              : null}
                          </Box>
                          {!submissionStatus && files.length > 0 ? (
                            <Box style={{ textAlign: "right" }}>
                              <RButton
                                buttonName={
                                  !SubmitLoading ? (
                                    "Submit"
                                  ) : (
                                    <CircularProgress size={16} color="#fff" />
                                  )
                                }
                                variant={"contained"}
                                className={"button-assignment2"}
                                onClick={(e) => {
                                  setSubmitLoading(true);
                                  handleSubmitAll(e);
                                }}
                              />
                            </Box>
                          ) : null}
                          {status === 2 ? (
                            <Box style={{ marginTop: "20px" }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Box
                                    style={{
                                      background: "#02215b",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      padding: "10px",
                                      borderTopLeftRadius: "20px",
                                      borderTopRightRadius: "20px",
                                    }}
                                  >
                                    <Grid item xs={6}>
                                      <span
                                        style={{
                                          paddingLeft: "25px",
                                          color: "#fff",
                                        }}
                                      >
                                        Parameters
                                      </span>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <span
                                        style={{
                                          paddingLeft: "25px",
                                          color: "#fff",
                                        }}
                                      >
                                        Marks
                                      </span>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <span
                                        style={{
                                          paddingRight: "25px",
                                          color: "#fff",
                                        }}
                                      >
                                        Scores Achieved
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      display={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          // paddingRight: "25px",
                                          color: "#fff",
                                        }}
                                      >
                                        Remarks
                                      </span>
                                    </Grid>
                                  </Box>
                                  {data.score.map((item, index) => {
                                    return (
                                      <>
                                        {/* <ListItem
                                          key={index}
                                          secondaryAction={
                                            <div className={"scoreBox"}>
                                              {item.AssignmentScore.score}
                                            </div>
                                          }
                                          disablePadding
                                        >
                                          <Grid item xs={7}>
                                            <Box
                                              role={undefined}
                                              // onClick={handleToggle(value)}
                                              dense
                                              style={{ maxWidth: "80%" }}
                                            >
                                              <ListItemText
                                                primary={item.params_name}
                                                className="parameter-list"
                                                style={{ paddingLeft: "22px" }}
                                              />
                                            </Box>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <Box
                                              role={undefined}
                                              // onClick={handleToggle(value)}
                                              dense
                                              style={{ maxWidth: "80%" }}
                                            >
                                              <ListItemText
                                                primary={item.marks}
                                                className="parameter-list"
                                                style={{ paddingLeft: "40px" }}
                                              />
                                            </Box>
                                          </Grid>
                                        </ListItem> */}
                                        <ListItem disablePadding>
                                          <Grid container alignItems="center">
                                            <Grid item xs={6}>
                                              <Box
                                                role={undefined}
                                                dense
                                                style={{ maxWidth: "80%" }}
                                              >
                                                <ListItemText
                                                  primary={item.params_name}
                                                  className="parameter-list"
                                                  style={{
                                                    paddingLeft: "22px",
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              // style={{
                                              //   paddingRight: "20px",
                                              // }}
                                            >
                                              <Box
                                                role={undefined}
                                                dense
                                                // style={{ maxWidth: "80%" }}
                                              >
                                                <ListItemText
                                                  primary={item.marks}
                                                  className="parameter-list"
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                              <Box role={undefined} dense>
                                                <ListItemText
                                                  primary={
                                                    item.AssignmentScore.score
                                                  }
                                                  style={{
                                                    width: "73.3px",
                                                    height: "34px",
                                                    padding:
                                                      "5px 30.8px 5px 30.8px",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#e9e9e9",
                                                  }}
                                                  // className="parameter-list"
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                              <Box
                                                // style={{
                                                //   display: "flex",
                                                //   alignItems: "center",
                                                //   justifyContent: "center",
                                                // }}
                                                role={undefined}
                                                dense
                                              >
                                                <TextareaAutosize
                                                  style={{
                                                    height: "38px",
                                                    fontSize: "14px",
                                                  }}
                                                  value={
                                                    item.AssignmentScore.remarks
                                                  }
                                                  disabled={true}
                                                />
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </ListItem>
                                        <Divider
                                          style={{
                                            marginLeft: "15px",
                                            marginRight: "15px",
                                            backgroundColor: "#bfbfbf",
                                          }}
                                        />
                                      </>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </Box>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <p style={{ backgroundColor: "white" }}>No Data Found</p>
                    )}
                  </>
                )}
              </>
            ) : (
              <p style={{ backgroundColor: "white" }}>No Data Found</p>
            )}
          </TabPanel>

          {/* Quiz Tab */}

          <TabPanel
            value={value}
            index={4}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <div className="quiz">
              {quizArray.length > 0 && quizArray[0].status === 1 ? (
                <>
                  <div className="section1-quiz">
                    <p>
                      <b>{quizdata[0].topic}</b>
                    </p>
                    {quizAltButton === true && quizResultStatus === 0 ? (
                      <>
                        <p>Quiz Starts From : {quizdata[0].fromTime}</p>
                      </>
                    ) : (
                      <></>
                    )}

                    {quizResultStatus === 0 ? (
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            "& .super-app-theme--header": {
                              backgroundColor: "#d8d8d8",
                              color: "#02215b",
                              fontSize: "16px",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          <DataTable
                            rows={datas}
                            className="datatable-style-quiz"
                            columns={column}
                            hideFooter={true}
                            hideFooterSelectedRowCount={true}
                            hideFooterPagination={true}
                            autoHeight
                            rowHeight={50}
                            // style={{paddingLeft: "100px"}}
                          />
                        </Box>

                        <div style={{ marginLeft: "10px" }}>
                          <p>Note:</p>
                          <p>
                            1. Please make sure your internet connection is
                            stable.
                          </p>
                          {/* <p>2. Once you start the quiz, <b>Do not try to switch tabs or reload or leave the page</b>, else you might lose the changes.</p> */}
                          <p>
                            2. Quiz will be automatically submitted for any
                            browser actions / when it's timed out.
                          </p>
                        </div>
                      </>
                    ) : quizDetailsAfterResults.totalquest > 0 ? (
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            "& .super-app-theme--header": {
                              backgroundColor: "#d8d8d8",
                              color: "#02215b",
                              fontSize: "16px",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          <CustomDataGrid
                            rowData={{
                              totalquest: quizDetailsAfterResults.totalquest,
                              correctquest:
                                quizDetailsAfterResults.correctquest,
                              percentagescored: `${quizDetailsAfterResults.percentagescored}%`,
                            }}
                            rowQuestionsData={{
                              quizQuestions:
                                quizDetailsAfterResults.quizQuestions,
                            }}
                            rowTypes={[
                              "Number Questions",
                              "Correct Answers",
                              "Percentage",
                            ]}
                          />
                        </Box>
                      </>
                    ) : (
                      // <>
                      //   <Box
                      //     sx={{
                      //       width: '100%',
                      //       '& .super-app-theme--header': {
                      //         backgroundColor: '#d8d8d8',
                      //         color: '#02215b',
                      //         fontSize: '16px',
                      //         fontWeight: 'bold',
                      //       },
                      //     }}
                      //   >
                      //     <CustomDataGrid
                      //       rowData={{
                      //         totalquest: quizDetailsAfterResults.totalquest,
                      //         correctquest:
                      //           quizDetailsAfterResults.correctquest,
                      //         percentagescored:
                      //           quizDetailsAfterResults.percentagescored,

                      //       }}
                      //       rowQuestionsData={{
                      //         quizQuestions:
                      //           quizDetailsAfterResults.quizQuestions,
                      //       }}
                      //       rowTypes={[
                      //         'Number Questions',
                      //         'Correct Answers',
                      //         'Percentage'

                      //       ]}
                      //     />
                      //   </Box>
                      // </>
                      <>
                        <p style={{ backgroundColor: "white" }}>
                          You have already completed the quiz. Result will be
                          Update soon.
                        </p>
                      </>
                    )}
                  </div>
                  {quizResultStatus !== 0 ? ( //change the === to !== for it work as per requirement
                    <></>
                  ) : (
                    <>
                      {startQuizButton === false ? (
                        <></>
                      ) : (
                        <>
                          {quizAltButton === true && quizResultStatus === 0 ? (
                            <>
                              <div className="button-start-quiz">
                                <span
                                  onClick={closeTab}
                                  // style={{
                                  //   pointerEvents: isDueDate
                                  //     ? "none"
                                  //     : "default",
                                  // }}
                                >
                                  <Link
                                    // onClick={closeTab}
                                    to="/user/bootcamp/home/quizQuestions"
                                    state={{ data: quizdata }}
                                    style={{
                                      textDecoration: "none",
                                      color: "none",
                                    }}
                                    target="_blank"
                                  >
                                    <RButton
                                      buttonName="Start Quiz"
                                      className={"button-start-quiz-sub"}
                                      style={{
                                        textTransform: "none",
                                        // background: isDueDate
                                        //   ? "#9E9E9E "
                                        //   : "#fb2485",
                                        backgroundColor: "#fb2485",
                                      }}
                                      // disabled={
                                      //   isDueDate === true ? isDueDate : false
                                      // }
                                      onClick={HandleStartQuiz}
                                    />
                                  </Link>
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              {viewQuizStatusButton ? (
                                <>
                                  <div className="button-start-quiz">
                                    <span onClick={QuizStatusFunction}></span>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : quizResultStatus === 0 ? (
                <>
                  <p style={{ backgroundColor: "white" }}>No Data Found</p>
                </>
              ) : quizDetailsAfterResults.totalquest > 0 &&
                quizArray[0].status === 1 ? (
                <Box
                  sx={{
                    width: "100%",
                    "& .super-app-theme--header": {
                      backgroundColor: "#d8d8d8",
                      color: "#02215b",
                      fontSize: "16px",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <CustomDataGrid
                    rowData={{
                      totalquest: quizDetailsAfterResults.totalquest,
                      correctquest: quizDetailsAfterResults.correctquest,
                      percentagescored: `${quizDetailsAfterResults.percentagescored}%`,
                    }}
                    rowQuestionsData={{
                      quizQuestions: quizDetailsAfterResults.quizQuestions,
                    }}
                    rowTypes={[
                      "Number Questions",
                      "Correct Answers",
                      "Percentage",
                    ]}
                  />
                </Box>
              ) : (
                <>
                  <p style={{ backgroundColor: "white" }}>No Quiz Found.</p>
                </>
              )}
            </div>
          </TabPanel>

          {/* Feedback Tab */}

          <TabPanel
            value={value}
            index={5}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {loadingf ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <>
                {feedbackStatussub === 1 ? (
                  <p>You're Feedback Is Submitted Successfully.</p>
                ) : (
                  <>
                    <div className="feedBack">
                      <div className="section1-feedback">
                        <FormControl>
                          <p style={{ fontFamily: "poppins" }}>
                            How would you rate this course?
                          </p>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Outstanding"
                              control={<Radio />}
                              label="Outstanding."
                              // name="radioopt"
                              onChange={handleRadioChange}
                            />
                            <FormControlLabel
                              value="Good"
                              control={<Radio />}
                              label="Good."
                              // name="radioopt"
                              onChange={handleRadioChange}
                            />
                            <FormControlLabel
                              value="Satisfactory"
                              control={<Radio />}
                              label="Satisfactory."
                              // name="radioopt"
                              onChange={handleRadioChange}
                            />
                            <FormControlLabel
                              value="Unsatisfactory"
                              control={<Radio />}
                              label="Unsatisfactory."
                              // name="radioopt"
                              onChange={handleRadioChange}
                            />
                          </RadioGroup>
                          {radio ? (
                            <span style={{ color: "red" }}>
                              Atleast One Option Should Be Selected.
                            </span>
                          ) : null}
                        </FormControl>
                      </div>
                      <Grid container>
                        <Grid item xs={12}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              minWidth: "85vw",
                              marginTop: "10px",
                            }}
                          >
                            <Custom.Input
                              name="feedback"
                              placeholder="You can write upto 1000 words.."
                              value={values.feedback}
                              onChange={handleInputChange}
                              error={errors.feedback}
                              size="small"
                              multiline
                              rows={6}
                              maxLength={1000}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <div
                        className="section3-feedback"
                        style={{ marginRight: "0px" }}
                      >
                        <RButton
                          buttonName={"Submit"}
                          className={"section3-feedback-sub"}
                          onClick={onSubmitEdit}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {/* } */}
          </TabPanel>
        </Box>
      </>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
