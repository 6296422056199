import * as yup from "yup";
import {
  LoginErrorMessages,
  AdminCollabErrorMessage,
  MaterialsErrorMessage,
  AssignmentErrorMessages,
  QuizErrorMessages,
  UploadQuestionsErrMsg,
  CreateCourseError,
  CreateUnit,
  FeedbackErrorMsg,
  CreateMandateTrainings,
  CreatTag,
  CreateCategory
} from "../constants/errorMsg";
// import {userEmailExists} from "./ValidationHelper";
import { ValidationHelper } from "./validationHelper";
import { min } from "date-fns";

const LoginvalidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email(LoginErrorMessages.EMAIL_ERROR_MSG)
    .required(LoginErrorMessages.REQUIRED_EMAIL),
  password: yup
    .string("Enter your password")
    .required(LoginErrorMessages.REQUIRED_PASSWORD),
});

const createCourseValidationSchema = yup.object({
  courseName: yup
    .string("Enter course name")
    .required(CreateCourseError.COURSE_ERROR_MSG),
  technology: yup
    .string("Enter Technology")
    .required(CreateCourseError.TECHNOLOGY_ERROR_MSG),
  difficultyLevel: yup
    .string("Enter difficulty level")
    .required(CreateCourseError.DIFFICULTY_ERROR_MSG),
  activeDate: yup
    .date("Enter Active Date")
    .required(CreateCourseError.DATE_ERROR_MSG),
  description: yup
    .string("Enter description of course")
    .required(CreateCourseError.DESCRIPTION_ERR_MSG),
});

const createProgramValidationSchema = yup.object().shape({
  programName: yup
    .string("Enter course name")
    .required("Course name is required"),
  technology: yup.string("Enter Technology"),
  TraningType: yup.string("Enter Traning Type"),
  description: yup.string("Enter program description"),

  // startDate: yup.date().default(() => new Date()),
  // endDate: yup
  //   .date()
  //   .when(
  //     "startDate",
  //     (startDate, schema) => startDate && schema.min(startDate)
  //   ),
  startDate: yup.date(),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End date can't be before start date"),
  description: yup
    .string("Please add description")
    .required("Please add Batch description"),
});

// export { createProgramValidationSchema };

const CreateUnitValidation = yup.object({
  unitName: yup
    .string("Enter your Unit Name")
    .required(CreateUnit.UNIT_NAME_ERROR),
  Technology: yup
    .string("Enter Technology")
    .required(CreateUnit.UNIT_TECHNOLOGY_ERROR),
  Difficulty: yup
    .string("Enter Difficulty level")
    .required(CreateUnit.UNIT_DIFFICULTY_ERROR),
  Active: yup
    .string("Status is required")
    .required(CreateUnit.UNIT_ACTIVE_STATUS_ERROR),
  Tag: yup.mixed("Please Enter the Tag").required(CreateUnit.UNIT_TAG_ERROR),
  selectType: yup
    .string("Please Select Type")
    .required(CreateUnit.UNIT_SELECT_TYPE_ERROR),
  passPercentage: yup
    .number("Please Enter Percentage")
    .required(CreateUnit.UNIT_PERCENTAGE_ERROR)
    .test(function (value) {
      if (parseInt(value) > 100) return false;
      return true;
    }),
  session: yup
    .string("Add Session")
    .required(CreateUnit.UNIT_ABOUT_SESSION_ERROR),
}); 

const CreateTagValidation = yup.object({
  tagName: yup
    .string("Enter Tag Name")
    .required(CreatTag.TAG_NAME_ERROR),
    status: yup
    .string("Select Status")
    .required(CreatTag.TAG_STATUS_ERROR),
    type: yup
    .string("Select Type")
    .required(CreatTag.TAG_TYPE_ERROR),
});

const CreateCategoryValidation = yup.object({
  categoryName: yup
    .string("Enter Tag Name")
    .required(CreateCategory.CATEGORY_NAME_ERROR),
    status: yup
    .string("Select Status")
    .required(CreateCategory.CATEGORY_STATUS_ERROR),
    type: yup
    .string("Select Type")
    .required(CreateCategory.CATEGORY_TYPE_ERROR),
});

const CreateAddingMarterialsvalidations = yup.object().shape({
  fileName: yup
    .string(MaterialsErrorMessage.FILE_NAME_ERR_MSG)
    .required(MaterialsErrorMessage.FILE_NAME_ERR_MSG),
  // description: yup.string(),
  videoLink: yup.string(),
  file: yup
    .mixed()
    .test(
      "file-or-link",
      "Select a file or add a video link",
      function (value) {
        const { videoLink } = this.parent;
        return (!!value && !videoLink) || (!value && !!videoLink);
      }
    ),
  duration: yup.number().required(QuizErrorMessages.DURATION_ERR_MSG),
});

const CreateTrainingMaterialvalidations = yup.object().shape({
  fileName: yup
    .string(MaterialsErrorMessage.FILE_NAME_ERR_MSG)
    .required(MaterialsErrorMessage.FILE_NAME_ERR_MSG),
  videoLink: yup.string(),
  file: yup
    .mixed()
    .test(
      "file-or-link",
      "Select a file or add a video link",
      function (value) {
        return !!value || !!this.parent.videoLink;
      }
    ),
  duration: yup.number().required(QuizErrorMessages.DURATION_ERR_MSG),
});

const CreateTrainingMaterialPPTValidations = yup.object().shape({
  fileName: yup
    .string(MaterialsErrorMessage.FILE_NAME_ERR_MSG)
    .required(MaterialsErrorMessage.FILE_NAME_ERR_MSG),
  pptLink: yup
    .string(MaterialsErrorMessage.PPT_LINK_ERR_MSG)
    .required(MaterialsErrorMessage.PPT_LINK_ERR_MSG),
  duration: yup.number().required(QuizErrorMessages.DURATION_ERR_MSG),
});

const AdminColloabValidationSchema = async (
  inputValues,
  errors,
  values,
  setErrors,
  userTableData
) => {
  let IfEmailExist = await ValidationHelper.userEmailExists(
    userTableData,
    inputValues.email
  );
  let IfPhoneExist = await ValidationHelper.userPhoneNoExist(
    userTableData,
    inputValues.phone
  );
  // const validate = (inputValues = values) => {
  let code = { ...errors };
  if ("firstname" in inputValues)
    code.firstname = inputValues.firstname ? "" : "First Name is required";
  if ("certificates" in inputValues) {
    code.certificates =
      (inputValues.certificates ? "" : "Certificates is required") ||
      (inputValues.certificates < 15
        ? ""
        : "Please Enter Valid Number of Certification");
  }
  if ("college" in inputValues)
    code.college = inputValues.college ? "" : "College is required";
  if ("description" in inputValues)
    code.description = inputValues.description ? "" : "Description is required";
  if ("email" in inputValues)
    code.email =
      inputValues.email && /$^|.+@.+..+/.test(inputValues.email)
        ? IfEmailExist
          ? "User email already exists"
          : ""
        : "Email is not valid";
  if ("phone" in inputValues)
    code.phone =
      inputValues.phone.length > 9
        ? IfPhoneExist
          ? "User already exists"
          : ""
        : "Maximum 10 numbers required";
  if ("department" in inputValues)
    code.department =
      inputValues.department.length !== 0 ? "" : "Role is required";

  if ("batch" in inputValues)
    code.batch = inputValues.batch.length !== 0 ? "" : "Batch is required";

  if ("designation" in inputValues)
    code.designation = inputValues.designation ? "" : "Designation is required";
  if ("experience" in inputValues)
    code.experience =
      (inputValues.experience ? "" : "Experience is required") ||
      (inputValues.experience <= 30 ? "" : "Please Enter Valid Experience");
  setErrors({
    ...code,
  });

  if (inputValues === values) return Object.values(code).every((x) => x === "");
};

const Assignmentvalidations = yup.object({
  assignmentName: yup.string().required(AssignmentErrorMessages.ASN_NAME),
  description: yup.string(),
  // .required(AssignmentErrorMessages.DESCRIPTION_ERR_MSG),
});

const QuizValidations = yup.object({
  quiz: yup.string().required(QuizErrorMessages.QUIZ_ERR_MSG),
  TotalQuestions: yup.string(),
  numberOfQuestions: yup
    .number()
    .required(QuizErrorMessages.NO_OF_QUS_ERR_MSG)
    .test(
      "is-less-than-or-equal-to-totalNoofquestions",
      "No of questions should not be greater than Total no of questions",
      function (value) {
        const TotalQuestions = this.parent.TotalQuestions;
        return value <= TotalQuestions;
      }
    ),
  duration: yup.number().required(QuizErrorMessages.DURATION_ERR_MSG),
  //passPercentage: yup.number().required(QuizErrorMessages.PERCENTAGE_ERR_MSG),
  description: yup.string().required(QuizErrorMessages.DESCRIPTION_ERR_MSG),
});

const UploadQuestionsvalidations = yup.object().shape({
  file: yup.mixed().required(UploadQuestionsErrMsg.File_Err_Msg),
});

const UpdateQuestionsvalidations = yup.object().shape({
  CorrectOption: yup.string().required(UploadQuestionsErrMsg.CORRECTOPTION),
  Question: yup.string().required(UploadQuestionsErrMsg.QUESTION),
  language : yup.mixed().required(UploadQuestionsErrMsg.LANGUAGE),
  optionA: yup.string().required(UploadQuestionsErrMsg.OPTIONA),
  optionB: yup.string().required(UploadQuestionsErrMsg.OPTIONB),
  // optionC: yup.string().required(false),
  // optionD: yup.string().required(false),
  // optionE: yup.string().required(false),
});

const feedbackValidations = yup.object({
  radioopt: yup.string().required(FeedbackErrorMsg.RADIO_BTN_ERR_MSG),
  feedback: yup.string().required(FeedbackErrorMsg.FEEDBACK_ERR_MSG),
});

const ChangePasswordValidations = yup.object().shape({
  oldPassword: yup.string().required("Password is required!"),
  newPassword: yup
    .string()
    .min(6, "Password is too short!")
    .max(20, "Password is too long!")
    .required("Password is required!")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
      "Password must contain at least 8 characters, one letter, and one number!"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match!")
    .required("Confirm Password is required!"),
});

const CreateMandateTrainingSchema = yup.object({
  trainingName: yup
    .string("Enter your Unit Name")
    .required(CreateMandateTrainings.TRAINING_NAME_ERROR),
  attempts: yup
    .string("Enter Technology")
    .required(CreateMandateTrainings.ATTEMPTS_ERROR),
  activeDate: yup
    .string("Status is required")
    .required(CreateMandateTrainings.DATE_ERROR_MSG),
  aboutSession: yup.string("Add Session").optional(),
});

const CreateMandateIntroduction = yup.object({
  media: yup.string("enter media").required("Media is Required"),
  slideName: yup.string("Enter slide name").required("Slide Name is Required"),
  columns: yup.number().required("please enter number of columns"),
});
const AddImageMandateValidation = yup.object({
  imageFile: yup
    .string("Enter your Unit Name")
    .required(CreateMandateTrainings.TRAINING_NAME_ERROR),
  // Description: yup
  //   .string("enter Description")
  //   .required("Description is Required"),
  // columns: yup.number().required("please enter number of columns"),
});

export {
  LoginvalidationSchema,
  AdminColloabValidationSchema,
  CreateUnitValidation,
  CreateTagValidation,
  CreateCategoryValidation,
  CreateAddingMarterialsvalidations,
  CreateTrainingMaterialvalidations,
  CreateTrainingMaterialPPTValidations,
  Assignmentvalidations,
  QuizValidations,
  UploadQuestionsvalidations,
  UpdateQuestionsvalidations,
  createCourseValidationSchema,
  feedbackValidations,
  ChangePasswordValidations,
  createProgramValidationSchema,
  CreateMandateTrainingSchema,
  CreateMandateIntroduction,
  AddImageMandateValidation,
};
