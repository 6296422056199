import React from "react";
import "./user-unit-details.css";
import VideoPlayer from "./MandatoryTraningLayout/mandatoryTraningVideoPlayer";
import { ConsoleHandler } from "logging-library";

function PdfContentExtractor(props) {
  const { link, setCurrentVideoTime, setVideoTotalTime } = props;
  

  
  const module_url = link?.module_url; //ppt link - image/png
  
  const videoLink = link?.link || link?.video_link; //png link - image/png
  const contentType = link?.content_type;
  const url = `https://docs.google.com/viewer?embedded=true&url=${module_url}&toolbar=0&navpanes=0&scrollbar=0`;
  const microLink = `https://view.officeapps.live.com/op/view.aspx?src=${module_url}`;
  const microLinkEmb = `https://view.officeapps.live.com/op/embed.aspx?src=${module_url}`;
  let renderMaterial;

  const urlGoo = `https://docs.google.com/`;
  // const checkModuleUrl = (moduleUrl) => {
  //   
  // };

  if (contentType === "image/png" || contentType === "image/jpeg") {
    

    if (module_url?.startsWith(urlGoo)) {
      
      renderMaterial = (
        <a href={module_url} target="_blank" rel="noreferrer" download="">
          <img
            style={{
              display: "block",
              justifyContent: "center",
              margin: "0px auto",
            }}
            src={link.link}
            alt=""
          />
        </a>
      );
    } else {
      const sharePointUrl = "https://absyzoffice-my.sharepoint.com/";
      if (module_url.startsWith(sharePointUrl)) {
        
        renderMaterial = (
          <iframe
            style={{
              height: "60vh",
              width: "55vw",
              backgroundColor: "white",
              content: "inherit",
              overflowY: "scroll",
              paddingRight: "2%",
              cursor: "default",
            }}
            color="white"
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(
              module_url
            )}&embedded=true`}
            title="Material"
          />
        );
      } else {
        renderMaterial = (
          <a href={microLink} target="_blank" rel="noreferrer" download="">
            <img
              style={{
                display: "block",
                justifyContent: "center",
                margin: "0px auto",
              }}
              src={link.link}
              alt=""
            />
          </a>
        );
      }
    }
  } else if (module_url?.startsWith(urlGoo) && contentType === null) {
    <iframe
      style={{
        height: "60vh",
        width: "55vw",
        backgroundColor: "white",
        content: "inherit",
        overflowY: "scroll",
        paddingRight: "2%",
        cursor: "default",
      }}
      color="white"
      src={module_url}
      title="Material"
    />;
  } else if (contentType === "PPT") {
    renderMaterial = (
      <a href={link.module_url} target="_blank" rel="noreferrer">
        {link.link ? (
          <img
            style={{ display: "flex", justifyContent: "center" }}
            src={link.link}
            alt=""
          />
        ) : (
          link.material_name
        )}
      </a>
    );
  } else if (contentType === null && videoLink?.startsWith(urlGoo)) {
    
    const filePPT = `https://docs.google.com/viewer?embedded=true&url=${videoLink}&toolbar=0&navpanes=0&scrollbar=0`;

    renderMaterial = (
      <iframe
        style={{
          height: "60vh",
          width: "55vw",
          backgroundColor: "white",
          content: "inherit",
          overflowY: "scroll",
          paddingRight: "2%",
          cursor: "default",
        }}
        color="white"
        src={filePPT}
        title="Material"
      />
    );
  } else if (contentType === null) {
    
    renderMaterial = videoLink ? (
      <iframe
        className="iframe-tag"
        style={{
          height: "60vh",
          width: "55vw",
          backgroundColor: "white",
          content: "inherit",
          overflowY: "auto",
          overflowX: "auto",
          cursor: "default",
          frameBorder: "1",
        }}
        contentEditable={false}
        color="white"
        src={
          videoLink
            ? videoLink.replace(
                "https://www.youtube.com/watch?v=",
                "https://www.youtube.com/embed/"
              )
            : ""
        }
        sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
        type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        title="Material"
      />
    ) : (
      <VideoPlayer
        videoLink={module_url}
        setCurrentVideoTime={setCurrentVideoTime}
        setVideoTotalTime={setVideoTotalTime}
      />
    );
  } else if (contentType === "application/pdf") {
    
    
    
    renderMaterial = (
      <iframe
        style={{
          height: "60vh",
          width: "55vw",
          backgroundColor: "white",
          content: "inherit",
          overflowY: "scroll",
          paddingRight: "2%",
          cursor: "default",
        }}
        color="white"
        src={module_url}
        title="Material"
      />
    );
  } else if (contentType === "video/mp4") {
    
    return (
      <VideoPlayer
        videoLink={videoLink}
        setCurrentVideoTime={setCurrentVideoTime}
        setVideoTotalTime={setVideoTotalTime}
      />
    );
  } else if (contentType === null && module_url) {
    <VideoPlayer
      videoLink={module_url}
      setCurrentVideoTime={setCurrentVideoTime}
      setVideoTotalTime={setVideoTotalTime}
    />;
  } else {
    
    renderMaterial = (
      <iframe
        style={{
          height: "60vh",
          width: "55vw",
          backgroundColor: "white",
          content: "inherit",
          overflowY: "scroll",
          paddingRight: "2%",
          cursor: "default",
        }}
        color="white"
        src={microLinkEmb}
        title="Material"
      />
    );
  }
  return (
    <div style={{ height: "62vh", overflow: "auto" }}>{renderMaterial}</div>
  );
}

export default PdfContentExtractor;
