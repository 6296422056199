export const MandatoryTraningHandler = (
  setTraningPercentage,
  totalModules,
  selectedModule,
  setTotalModules,
  selectedTime,
  setQuizIndex,
  setIsQuizEnabled
) => {
  let selectedTimeInSeconds = selectedTime[0].totalTime * 60;

  // Set initial percentage from local storage if available
  if (localStorage.getItem(`${selectedModule[0].ModuleId}-TraningModule`)) {
    setTraningPercentage(
      parseInt(
        localStorage.getItem(`${selectedModule[0].ModuleId}-TraningModule`),
        10
      )
    );
  }

  const interval = setInterval(() => {
    let updatedData;
    setTraningPercentage((prevPercentage) => {
      if (prevPercentage >= 100) {
        clearInterval(interval);

        // });
        UpdateTheTraningData(
          totalModules,
          setTotalModules,
          selectedModule,
          setQuizIndex,
          setIsQuizEnabled
        );
        return 0;
      }

      const updatedPercentage =
        prevPercentage + (1 / selectedTimeInSeconds) * 100;

      // Store updated percentage in local storage
      localStorage.setItem(
        `${selectedModule[0].ModuleId}-TraningModule`,
        updatedPercentage
      );

      return updatedPercentage;
    });
  }, 1000);

  return () => {
    clearInterval(interval);
  };
};
const UpdateTheTraningData = (
  totalModules,
  setTotalModules,
  selectedModule,
  setQuizIndex,
  setIsQuizEnabled
) => {
  window.localStorage.removeItem(`${selectedModule[0].ModuleId}-TraningModule`);

  /*******************If Traning Percent of a particuar Module is 100 set that module as completed and store it in local storage so that if something happens to retive the previous percentage */
  // setTotalModules((prevState) => {
  let updatedData = totalModules.map((data) => {
    const updatedTraningModules = data.traningModules.map((module, index) => {
      if (module.ModuleId === selectedModule[0]?.ModuleId) {
        setQuizIndex(index);
        setIsQuizEnabled(true);
        return { ...module, traningModuleCompleted: true };
      }
      return module;
    });
    return { ...data, traningModules: updatedTraningModules };
  });
  setTotalModules(updatedData);
};
