import ReusableTabs from "../../../components/reusable-tabs/ReusableTabs";
import Categories from "./Categories";
import Tags from "./Tags";

const tabsData = [
  {
    label: "Tags",
    children: <Tags />,
  },
  {
    label: "Categories",
    children: <Categories  />,
  },
];

const Configuration = () => <ReusableTabs tabsData={tabsData} />;

export default Configuration;
