import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { green } from "@mui/material/colors";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import { Menu } from "@mui/material";
import CompletedIcon from "../../assets/Images/Vector.svg";
import MenuItem from "@mui/material/MenuItem";
import TimerIcon from "../../assets/Images/Vector2.svg";
import ongoing from "../../assets/Images/onGoing.svg";
import Cookies from "js-cookie";
import { useContext } from "react";
import useProfileContext from "../../context/useProfileContext";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
const styles = makeStyles((theme) => ({
  courseHeading: {
    fontStyle: "normal",
    fontWeight: "600 !important",
    fontSize: "13px",
    width: "100%",
    height: "45px",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Poppins",
    textTransform: "capitalize !important",
    padding: "10px 0px 10px 15px",
  },
  courseTitle: {
    fontStyle: "normal",
    fontWeight: "800 !important",
    fontSize: "16px",
    padding: "5% 0% 2.5% 5%  !important",
    lineHeight: "24px",
    color: "#000000",
    textTransform: "capitalize !important",
    fontFamily: "Poppins",
  },
  courseDesc: {
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "0.8rem !important",
    // lineHeight: "20px",
    color: "#292929",
    width: "90% !important",
    marginLeft: "5% !important",
    textTransform: "capitalize !important",
    marginBottom: "4% !important",
    // height: "17vh !important",
    fontFamily: "Poppins !important",
  },
  CourseTags: {
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "12px",
    color: "#02215B !important",
    margin: "0px 1% 0px 0% !important",
    textTransform: "capitalize !important",
    background: "#E5E9EF !important",
    borderRadius: "2px !important",
    padding: "2px 4px !important",
    display: "inline-block",
    fontFamily: "Poppins",
  },
  unitsCls: {
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    color: "#292929 !important",
    marginLeft: "4% !important",
    fontFamily: "Poppins",
  },
  timeCls: {
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    color: "#292929 !important",
    marginRight: "4% !important",
    fontFamily: "Poppins",
  },
}));

const BasicCard = ({
  heading,
  title,
  description,
  tags,
  units,
  time,
  status,
  ActiveStatus,
  id,
  path,
  setOpen,
  setCurrentDeletionId,
  setType,
  openModal,
  dotHide,
  userType,
  batchType,
  orgRole,
  firstIndexRole,
  data,
}) => {
  let navigate = useNavigate();
  const classes = styles();
  const buttonSx = {
    bgcolor: green[500],
    "&:hover": {
      bgcolor: green[700],
    },
  };
  const {userDetails, setUserDetails} = useContext(useProfileContext);
  const _user = userType ? userType : "";
  let statusText = "";
  if (heading === "Program") {
    statusText =
      status === 1 ? "Scheduled" : status === 2 ? "Completed" : "On Going";
  } else if (heading === "Mandatory Training") {
    statusText =
      _user === "user"
        ? status === 2
          ? "Completed"
          : status === 3
          ? "Day Lock"
          : status === 1
          ? "In progress"
          : "Ready to use"
        : status === 1
        ? "Ready to use"
        : status === 2
        ? "Under Devlopment"
        : "Ready to use";
  } else {
    statusText =
      _user === "user"
        ? status === 2
          ? "Completed"
          : status === 1
          ? "In progress"
          : ""
        : heading === "Unit"
        ? status === 2
          ? "Under Development"
          : status === 1 && ActiveStatus === 1
          ? "Ready to use"
          : "Under Development"
        : status === 2
        ? "Under Development"
        : status === 1
        ? "Ready to use"
        : "Under Development";
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const _dotHide = dotHide && dotHide == "ok" ? false : true;
  let popup = Boolean(anchorEl);

  const handleClick = async (event) => {
    setType(heading);
    setCurrentDeletionId(id);
    setAnchorEl(event.currentTarget);
    if (popup) {
      setAnchorEl(null);
    }
  };
  React.useEffect(() => {
    if (window.localStorage.getItem("courseId")) {
      window.localStorage.removeItem("courseId");
    }
    if (window.localStorage.getItem("UnitId")) {
      window.localStorage.removeItem("UnitId");
    }
    if (window.localStorage.getItem("programId")) {
      window.localStorage.removeItem("programId");
    }
    if (window.localStorage.getItem("mandateId")) {
      window.localStorage.removeItem("mandateId");
    }
  }, []);
  const handleClose = () => {
    openModal();
    setAnchorEl(null);
  };

  const handleUnitClick = async () => {
  //   const  userRole = Cookies.get("orginalRole");
  if(orgRole !== "Admin"){
    const filteredRoles =data?.filter((role)=>role.role.role_name === orgRole);
   if(filteredRoles?.length){
    Cookies.set("orginalRole",filteredRoles[0]?.role.role_name);
    setUserDetails({...userDetails,userRole: filteredRoles[0]?.role.role_name === "Program Participant" ? 'Trainee' : filteredRoles[0]?.role.role_name});
   }
   else{
     setUserDetails({...userDetails,userRole: firstIndexRole === "Program Participant" ? 'Trainee' : firstIndexRole});
   } 
  }
    
    if (heading === "Unit") {
      (async () => {
        await localStorage.setItem("UnitId", id);
        if (window.localStorage.getItem("courseId")) {
          window.localStorage.removeItem("courseId");
        }
        window.localStorage.removeItem("userUnitTrackId");
        window.localStorage.removeItem("mandateId");
        window.localStorage.removeItem("userUnitTrackId");
      })();
    } else if (heading === "Program") {
      (async () => {
        await localStorage.setItem("programId", id);
        window.localStorage.removeItem("mandateId");
        window.localStorage.removeItem("userUnitTrackId");

        if (batchType === 0 || batchType === 2) {
          window.localStorage.setItem("IsBootCamp", true);
        } else {
          window.localStorage.setItem("IsBootCamp", false);
        }
      })();
    } else if (heading === "Mandatory Training") {
      (async () => {
        await localStorage.setItem("mandateId", id);
        window.localStorage.removeItem("courseId");
        window.localStorage.removeItem("programId");
      })();
    } else {
      (async () => {
        await localStorage.setItem("courseId", id);
        if (window.localStorage.getItem("UnitId")) {
          window.localStorage.removeItem("UnitId");
        }
        window.localStorage.removeItem("userUnitTrackId");
        window.localStorage.removeItem("mandateId");
      })();
    }

    if (
      (batchType === 0 || batchType === 2 || batchType === 1) &&
      orgRole !== "Admin"
    ) {
      Cookies.set("batch_id", id, {
        expires: 10,
      });
      navigate(path);

      let isSwitch = data.length;
      if (isSwitch > 1) {
        localStorage.setItem("isSwitch", true);
        var uniqueRoleNames = Array.from(
        new Set(data.map((user) => user.role.role_name))
        );
        var uniqueRoleNamesJSON = JSON.stringify(uniqueRoleNames);
        localStorage.setItem("uniqueRoleNamesData", uniqueRoleNamesJSON);
      }
      localStorage.setItem("batchType", batchType);
    } else if (heading === "Program" && orgRole === "Admin") {
      navigate(path);
    } else if (
      heading === "Course" ||
      heading === "Mandatory Training" ||
      heading === "Unit"
    ) {
      navigate(path);
    }
  };
  return (
    <Card
      sx={{
        width: "100%",
        minHeight: "36.5vh",
        borderRadius: "10px",
        padding: "0px",
        position: "relative",
      }}
    >
      <Tooltip title={title} placement="top" arrow>
        <div
          style={{
            backgroundColor:
              heading === "Course"
                ? "#FB2485"
                : heading === "Program" || heading === "Programs"
                ? batchType === 0
                  ? "#4CAF50"
                  : batchType === 2
                  ? "#9C27B0"
                  : "#009DB5"
                : heading === "Mandatory Training"
                ? "#FF7A00"
                : "#03AFED",
            // color: "#FFF",
            color:
              heading === "Course" || heading === "Program"
                ? "#FFF"
                : heading === "Mandatory Training"
                ? "#fff"
                : "#FFF",
            whiteSpace: "nowrap", // Prevent line breaks
            overflow: "hidden", // Hide overflowing content
            textOverflow: "ellipsis", // Add ellipsis for truncated text
          }}
          className={classes.courseHeading}
          gutterBottom
        >
          {title?.length > 30 ? title.substring(0, 30) + "..." : title}
          {_dotHide ? (
            <IconButton
              onClick={handleClick}
              style={{ background: "transparent" }}
            >
              <MoreHorizIcon
                style={{
                  color:
                    heading === "Course" ||
                    heading === "Program" ||
                    heading === "Mandatory Training"
                      ? "white"
                      : "white",
                }}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={popup}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleClose()}>Delete</MenuItem>
              </Menu>
            </IconButton>
          ) : (
            ""
          )}
        </div>
      </Tooltip>
      <Box
        onClick={handleUnitClick}
        style={{
          cursor: "pointer",
          height: "29vh",
          // cursor:
          //   heading === "Programs" && batchType === 1 && orgRole !== "Admin"
          //     ? "no-drop"
          //     : "pointer",
        }}
      >
        <Tooltip title={description} placement="top" arrow>
          <Typography
            className={classes.courseDesc}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              marginTop: "15px",
            }}
          >
            {description}
          </Typography>
        </Tooltip>
        <div
          style={{
            position: "absolute",
            bottom: "5%",
            paddingBottom: "15px",
            marginBottom: "5px",
            marginLeft: "5%",
            width: "100%",
            left: "-1%",
          }}
        >
          {tags?.map((txt, key) => (
            <Typography
              key={key}
              numberOfLines={1}
              className={classes.CourseTags}
            >
              {txt.tag_name}
            </Typography>
          ))}
        </div>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
            position: "absolute",
            bottom: "2px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "52%",
              float: "left",
              paddingLeft: "3%",
            }}
          >
            {units ? (
              <Typography
                variant="body1"
                className={classes.unitsCls}
                style={{ paddingRight: "10%" }}
              >
                {units ? "Units" + " " + units : ""}
              </Typography>
            ) : null}
            {batchType === 0 ? null : (
              <Typography variant="body1" className={classes.timeCls}>
                {time}
              </Typography>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "4%",
              paddingBottom: "2%",
            }}
          >
            {_user === "user" ? (
              status !== 0 ? (
                <img
                  style={{ height: 15, width: 15 }}
                  alt=""
                  src={status === 2 ? CompletedIcon : TimerIcon}
                />
              ) : (
                ""
              )
            ) : heading === "Unit" ? ( //Units Status display
              <img
                style={{ height: 15, width: 15 }}
                alt=""
                src={
                  status === 1 && ActiveStatus === 1 ? CompletedIcon : TimerIcon
                }
              />
            ) : heading === "Program" ? ( // Programme Status displaying
              <img
                style={{ height: 15, width: 15 }}
                alt=""
                src={
                  status === 1
                    ? TimerIcon
                    : status === 2
                    ? CompletedIcon
                    : ongoing
                }
              />
            ) : heading === "Mandatory Training" ? (
              <img
                style={{ height: 15, width: 15 }}
                alt="icon"
                src={
                  status === 1
                    ? CompletedIcon
                    : status === 2
                    ? TimerIcon
                    : TimerIcon
                }
              />
            ) : (
              <img
                style={{ height: 15, width: 15 }}
                alt=""
                src={status === 1 ? CompletedIcon : TimerIcon}
              />
            )}

            <span
              style={{
                fontSize: 11,
                color:
                  _user === "user"
                    ? status === 2
                      ? "green"
                      : "#CE8C2A"
                    : heading === "Unit"
                    ? status === 1 && ActiveStatus === 1
                      ? "green"
                      : "#CE8C2A"
                    : heading === "Program"
                    ? status === 1
                      ? "#CE8C2A"
                      : "green"
                    : status === 1
                    ? "green"
                    : "#CE8C2A",
                paddingLeft: "5px",
              }}
            >
              {statusText}
            </span>
          </div>
        </Box>
      </Box>
    </Card>
  );
};
export default BasicCard;
