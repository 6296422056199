// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/Images/expback.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card-img{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n\n.fgfgf{\n    position: relative;\n\n}\n.css-o69gx8-MuiCardMedia-root{\n    width: 280px !important;\n}\n\n.MuiCardMedia-root \t.MuiCardMedia-img{\n    background-blend-mode: luminosity;\n}", "",{"version":3,"sources":["webpack://./src/pages/trainee/trainee-session-details/quiz.css"],"names":[],"mappings":"AAAA;IACI,yDAA2D;IAC3D,4BAA4B;IAC5B,sBAAsB;AAC1B;;;AAGA;IACI,kBAAkB;;AAEtB;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".card-img{\n    background-image: url(\"../../../assets/Images/expback.png\");\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n\n.fgfgf{\n    position: relative;\n\n}\n.css-o69gx8-MuiCardMedia-root{\n    width: 280px !important;\n}\n\n.MuiCardMedia-root \t.MuiCardMedia-img{\n    background-blend-mode: luminosity;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
