import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import "./menu-items-admin.css";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import EventRepeatRoundedIcon from "@mui/icons-material/EventRepeatRounded";

export default function MenuItemsAdmin(props) {
  const { UpdatedAdminActive, mandatoryTrainingActive } = props;
  return (
    <div className="nav">
      <ListItem style={{ padding: "0px" }}>
        <ListItemText style={{ color: "#FFF" }}>
          <NavLink
            to="/home/courses"
            className={({ isActive }) =>
              isActive
                ? "link-active"
                : UpdatedAdminActive
                ? "link-active"
                : "link"
            }
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Master Data" placement="right" arrow>
                <EventRepeatRoundedIcon />
              </Tooltip>
            </ListItemIcon>
            Master Data
          </NavLink>
        </ListItemText>
      </ListItem>

      
      <ListItem style={{ padding: "0px" }}>
        <ListItemText style={{ color: "#FFF" }}>
          <NavLink
            to="/home/createConfigurations"
            className={({ isActive }) =>
              isActive
                ? "link-active"
                : mandatoryTrainingActive
                ? "link-active"
                : "link"
            }
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Configurations" placement="right" arrow>
                <SettingsSuggestIcon />
              </Tooltip>
            </ListItemIcon>
            Configurations
          </NavLink>
        </ListItemText>
      </ListItem>
    </div>
  );
}
