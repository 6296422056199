import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import ReusableButton from "../../../components/Custom/button/button";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { SxStyles } from "../../../styles/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Notification from "../../../components/Custom/Notification";
import { API } from "../../../Networking/API";
import videoLinkValidator from "../../../helper/videoLinkValidator";
const Filetype = (props) => {
  const { data, handleClose,getTrainingMaterial } = props;
  const[checked,setChecked]=useState(false);
  let mandateId = "";
  if (window.localStorage.getItem("UnitId")) {
    mandateId = window.localStorage.getItem("UnitId");
  } else {
    mandateId = window.localStorage.getItem("mandateId");
  }
  const [linkfilled, setLinkFilled] = useState(false);
  const [fileData, setFileData] = useState({
    filename: data.filename,
    duration: data.duration,
    videoLink: data.videoLink==="N/A" ? "" : data.videoLink,
    material: data.material,
  });
  const [updateMaterial, setUpdateMaterial] = useState(fileData.videoLink.length==="N/A");
  const inputRef = React.useRef();
  
  const styles = SxStyles.styles;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const SheetJSFT = ["pdf,ppt,pptx,docx,doc"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
  const handleAddButtonClick = () => {
   if(!fileData.filename==="" || (!fileData.videoLink && (!fileData.material  || fileData.material === "N/A" )) || !fileData.duration==="") {
       return setNotify({
            isOpen: true,
            message: "Please fill mandatory fields",
            type: "error",
          });
    }
    if(!fileData.material){
    if(!videoLinkValidator(fileData.videoLink)){
      return setNotify({
        isOpen: true,
        message: "Please Give Valid Videolink",
        type: "error",
      });
    }
  }
    setChecked(true);
    API.updateTrainingMaterial(
       data.id,
       fileData.filename,
       fileData.material,
       fileData.videoLink==="N/A" ? "" : fileData.videoLink,
       mandateId,
       fileData.duration,
).then(({ response }) => {
        if (response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: response?.data[0]?.message,
            type: "success",
          });
          getTrainingMaterial(window.localStorage.getItem("mandateId"));
          setUpdateMaterial(false);
          setTimeout(()=>{
            handleClose();
          },1000);
        } else {
          setNotify({
            isOpen: true,
            message: response?.data[0]?.message,
            type: "error",
          });
        }
      });
  };
  const handleChange = (event) => { 
    const { name, value, files } = event.target;
    if(files) {
      fileData.material = files[0];
      setLinkFilled(typeof fileData.material==="object");
    } 
    else fileData[name] = value;
    setFileData({...fileData,filename:fileData.filename,duration:fileData.duration});

  
  };
  useEffect(() => {
    if (data.material.length === 0) {
      setUpdateMaterial(true);
    }
   else if(data.videoLink.length>1 && data.videoLink!=="N/A"){
     setUpdateMaterial(true);
   }
  
    
  }, [updateMaterial]);



  useEffect(()=>{
    if(fileData.videoLink?.length && fileData.videoLink!=="N/A"){
      setLinkFilled(false);
    }
    else if(typeof fileData.material==="object" || fileData.material?.length){
      setLinkFilled(true);
    }
   
     else  setLinkFilled(false);
    
  },[linkfilled]);
  
  return (
    <div>
      <h2 style={{ color: "black" }}>Edit Files:</h2>
      <Grid container spacing={2}>
        <Grid item xs={2.4} sm={3} md={3} lg={3.5}>
          <div style={styles.inputContainer}>
            <Typography style={styles.label} variant="h6">
              Name
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            <span>
              <ReusableInputfield
                className="name-Training-material"
                name="filename"
                id="Name"
                placeholder="Name"
                value={fileData.filename}
                onChange={(e)=>handleChange(e)}
                size="small"
                style={styles.inputFleid}
              />
            </span>
          </div>
        </Grid>
        <Grid item xs={2.3} sm={3} md={3} lg={3.5} style={styles.inputContainer}>
          <div style={styles.inputContainer}>
            <Typography style={styles.label} variant="h6">
              Video Link
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            <span>
              <ReusableInputfield
                className="name-Training-material"
                name="videoLink"
                id="videoLink"
                placeholder="Video Link"
                value={fileData.videoLink}
                style={{
                  height: "41px",
                  width: "100%",
                }}
                onChange={(e)=>handleChange(e)}
                disabled={linkfilled ? true : false}
              />
            </span>
          </div>
        </Grid>

        <Grid
          item
          xs={3.3}
          sm={3}
          md={3}
          lg={3.5}
        >
          <div style={styles.inputContainer}>
            <Typography style={styles.label} variant="h6">
              {updateMaterial ? "  Upload Materials" : "Material"}
              {updateMaterial && <span style={styles.madatoryMark}>*</span>}
            </Typography>
            <span>
              {updateMaterial ? (
                <input
                  type="file"
                  name="file"
                  id="file"
                  class="upload-box"
                  accept={SheetJSFT}
                  ref={inputRef}
                  style={{
                    pointerEvents:
                      fileData.videoLink.length===0 ? "visible" : "none",
                    fontSize: "0.8em",
                    fontFamily: "Poppins",
                    height: "38px",
                    position: "relative",
                    opacity: fileData.videoLink.length===0 ? 1: 0.5,
                    width: "220px",
            
                  }}
                  onChange={(e)=>handleChange(e)}
                />
              ) : (
                <div style={{ display: "flex" }}>
                  <ReusableInputfield
                    name="pdffile"
                    id="pdffile"
                    // className="name-Training-material"
                    value={
                      fileData.material.length > 19
                        ? `${fileData.material.slice(0, 17  )}...`
                        : fileData.material
                    }
                    readOnly={true}
                    size="small"
                    style={{height:"41px !important"}}
                  />
                  <IconButton
                    aria-label="delete"
                    style={{
                      position: "relative",
                      right: "15%",
                      paddingTop: "8px",
                    }}
                    onClick={() => {
                        fileData.material="";
                      setLinkFilled(false);
                      setUpdateMaterial(true);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              )}
            </span>
            {updateMaterial && (
              <Typography style={styles.note} variant="p">
                Only .pdf .ppt .docx .pptx format allowed
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={2.3} sm={3} md={3} lg={3.5} style={styles.inputContainer}>
          <div style={styles.inputContainer}>
            <Typography style={styles.label} variant="h6">
              Duration(in mins)
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            <span>
              <ReusableInputfield
                name="duration"
                id="duration"
                placeholder="Duration"
                value={fileData.duration}
                style={{
                  height: "41px",
                  width: "100%",
                }}
                type="text"
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "8px 12px", width: "90%" },
                  },
                }}
                onChange={(e)=>handleChange(e)}
              />
            </span>
          </div>
        </Grid>
        <Box
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            marginRight: "10px",
          }}
        >
          <ReusableButton
            size="medium"
            variant="outlined"
            buttonName="Cancel"
            style={styles.cancelButton}
            onClick={handleClose}
          />
          <ReusableButton
            size="medium"
            variant="contained"
            buttonName={checked ? "Updating.." : "Update"}
            style={styles.createBtnDialog}
            onClick={handleAddButtonClick}
          />
        </Box>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default Filetype;
