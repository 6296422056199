import { Box, Typography, IconButton } from "@material-ui/core";
import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import { SxStyles } from "../../../../styles/styles";
import "./createUnit.css";
import { ReusableInputfield } from "../../../../components/reusable-input/reusable-input";
import { API } from "../../../../Networking/API";
import Notification from "../../../../components/Custom/Notification";
import { useFormik } from "formik";
import { CreateAddingMarterialsvalidations } from "../../../../Validation/FieldValidatorSchema";
import { useNavigate } from "react-router";
import ReactLoading from "react-loading";
import DataTableDrag from "../../../../components/Custom/draggable/dataTableDraggable";
import ReactPaginate from "react-paginate";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { EditDescriptionHelper } from "../../../../helper/unitHelper";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Editor from "./editor";
import videoLinkValidator from "../../../../helper/videoLinkValidator";

const SheetJSFT = ["pdf,ppt,pptx,docx,doc"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const PreRequisite = (props) => {
  const { isUnit, unitData } = props;
  const styles = SxStyles.styles;
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [preReqId, setPreReqId] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [done, setDone] = useState(true);
  const [limit, setLimit] = useState(4);
  const [draggableData, setDraggableData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [disableDesc, setDisableDesc] = useState(false);
  const [description, setDescription] = useState("");
  const [richTextdescription, setRichTextDescription] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [linkfilled, setLinkFilled] = useState(false);
  const [extractedText, setExtractedText] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [updateMaterial, setUpdateMaterial] = useState(false);
  const [viewId, setViewId] = useState(null);
  const [state, setState] = React.useState();
  const [quizMandate, setQuizMandate] = useState(
    unitData?.pre_requisites_status
  );
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loaderForSaveLaterPR, setLoaderForSaveLaterPR] = useState(false);
  const [loaderForPublishPR, setLoaderForPublishPR] = useState(false);
  let unitId = "";
  if (window.localStorage.getItem("UnitId")) {
    unitId = window.localStorage.getItem("UnitId");
  } else {
    unitId = window.localStorage.getItem("mandateId");
  }
  const dataTableHeader = [
   "checkBox",
    "Name",
    "Video Link",
    "Material",
    "Duration",
    "Actions",
  ];
  let navigate = useNavigate();

  const inputRef = useRef();

  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    handleChange,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      fileName: "",
      videoLink: "",
      file: "",
      duration: "",
    },
    validationSchema: CreateAddingMarterialsvalidations,
    onSubmit: (values) => {
      setLinkFilled(false);
      if (!openEdit) return handleAddPrerequisites();
      handleUpdateMaterial();
    },
  });

  useEffect(() => {
    getPreRequisites();
  }, []);
  const getPreRequisites = (message) => {
    if (message) {
      setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
    }
    API.getAdminPrerequisites(unitId).then(({ response }) => {
      setDone(false);
      if (response.data.api_status === 200) {
        const data = response.data.data.pre_requisite;
        setDeleteId(response.data.data.id);
        setFieldValue(
          "description",
          response.data.data.pre_requisites_description
        );
        setDescription(response.data.data.pre_requisite);
        setDisableDesc(response.data.data.pre_requisites_description !== null);
        const formattedData = data.map((each) => ({
          id: each.pre_req_pk,
          preRequisiteName: each.module_name,
          PreRequisiteMaterial:
            each.module_url !== null && each.module_url !== ""
              ? each.module_url
              : "N/A",
          PreRequisiteLink:
            each.link !== null && each.link !== "" ? each.link : "N/A",
        }));
        const FinalFormData = data.map((each) => ({
          id: each.pre_req_pk,
          first: each.module_name,
          second: each.link !== null && each.link !== "" ? each.link : "N/A",
          third:
            each.module_url !== null && each.module_url !== ""
              ? each.module_url
              : "N/A",
          fourth: each.duration,
          isChecked: false,
        }));
        setDraggableData(FinalFormData);
        setTableData(formattedData);
      } else {
        setTableData([]);
      }
    });
  };

  useEffect(() => {
    updatePreRequisites();
  }, [draggableData]);

  const updatePreRequisites = () => {
    const sortableIds = draggableData.map((x) => x.id);
    API.AdminPreRequisitesReorder(unitId, sortableIds).then(
      ({ response }) => {}
    );
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
    setLinkFilled(true);
  };

  const checkDescription = () => {
    const regex = /<p.*?>(.*?)<\/p>/g;
    const matches = richTextdescription.match(regex);
    if (matches) {
      const textContents = matches?.map((match) =>
        match.replace(/<\/?p>/g, "")
      );
      if (!textContents[0]) {
        setNotify({
          isOpen: true,
          message: "description can't be empty",
          type: "error",
        });
        return false;
      }
    }
    return true;
  };

  const handleCloseInByCancel = () => {
    setFieldValue("file", "");
    setFieldValue("fileName", "");
    setFieldValue("videoLink", "");
    setFieldValue("duration", "");
    setOpen(false);
    setOpenEdit(false);
  };

  const handleDeleteInpre = (params) => {
    setPreReqId(params);
    setOpen(true);
  };

  const handleViewClick = (params) => {
    const newdata = draggableData.filter((arr, index) => index + 1 === params);
    if (newdata[0].second === "N/A") {
      const material = newdata[0].third;
      window.open(material, "_blank");
    } else {
      const material = newdata[0].second;
      window.open(material, "_blank");
    }
  };

  const handlePageClick = (data) => {
    setPageIndex(data.selected);
    setPageCount(data.selected);
  };

  const handleCloseDelete = (id) => {
    setDone(true);
    setOpen(false);
    API.deleteAdminPreRequisite(id, unitId).then(({ result }) => {
      const response = result;
      getPreRequisites();
      setPageCount(0);
      setPageIndex(0);
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: "PreRequisite Deleted Successfully",
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "PreRequisite Not Deleted Please Try Again",
          type: "error",
        });
      }
    });
  };

  const editPreDescription = () => {
    if (!checkDescription()) return;

    EditDescriptionHelper(
      richTextdescription,
      setNotify,
      unitId,
      setDisableDesc,
      setState
    );
  };

  const showVideoValidation = () => {
    setNotify({
      isOpen: true,
      message: "Please Provide Valid Video Link",
      type: "error",
    });
  };

  const handleAddPrerequisites = () => {
    const { fileName, videoLink, file, duration } = values;
    if (videoLink) {
      const isValid = videoLinkValidator(videoLink);
      if (!isValid) return showVideoValidation();
    }
    if (!checkDescription()) return;
    // Validate duration to ensure it's not negative
    const parsedDuration = parseInt(duration, 10);
    if (isNaN(parsedDuration) || parsedDuration < 0 || parsedDuration === -0) {
      setNotify({
        isOpen: true,
        message: "Duration must be a non-negative number.",
        type: "error",
      });
      return;
    }
    setDone(true);

    API.addAdminPrerequisites(
      unitId,
      fileName,
      videoLink,
      file,
      richTextdescription,
      duration
    ).then(({ response }) => {
      resetForm();
      getPreRequisites();
      setDisableDesc(true);
      inputRef.current.value = null;
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };
  const handleEditClick = (id) => {
    setOpenEdit(true);
    setViewId(id);
    const updateData = draggableData[id - 1];
    setPreReqId(updateData.id);
    values.fileName = updateData.first;
    values.duration = updateData.fourth;
    values.videoLink = updateData.second === "N/A" ? "" : updateData.second;
    values.file = updateData.third === "N/A" ? "" : updateData.third;
    if (values.file.length !== 0) {
      setLinkFilled(true);
      setUpdateMaterial(false);
    } else {
      setLinkFilled(false);
      setUpdateMaterial(true);
    }
  };

  const handleUpdateMaterial = () => {
    if (values.videoLink) {
      const isValid = videoLinkValidator(values.videoLink);
      if (!isValid) return showVideoValidation();
    }
    API.editAdminPrerequisites(
      unitId,
      values.fileName,
      values.videoLink,
      values.file,
      richTextdescription,
      values.duration,
      preReqId
    )
      .then((response) => {
        if (response?.response?.api_status === 200) {
          resetForm();
          setOpenEdit(false);
          setNotify({
            isOpen: true,
            message: response.response?.data[0]?.message,
            type: "success",
          });
          setUpdateMaterial(false);
          getPreRequisites();
        } else {
          setNotify({
            isOpen: true,
            message: response.response.error[0].error_msg,
            type: "error",
          });
        }
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message: "Please try again",
          type: "error",
        });
      });
  };

  const handleSaveForLaterButton = () => {
    if (quizMandate === undefined || quizMandate === null) {
      return setNotify({
        isOpen: true,
        message: "Please Select required field.",
        type: "error",
      });
    }

    if (quizMandate) {
      if (draggableData.length === 0) {
        if (Object.keys(errors).length > 0 || !checkDescription()) {
          setNotify({
            isOpen: true,
            message: "Please add atleast one Pre-requsite.",
            type: "error",
          });
          return;
        }
      }
    }

    setLoaderForSaveLaterPR(true);
    API.AdminSaveForLaterBtn(unitId).then(({ response }) => {
      if (response.data.api_status === 200) {
        setLoaderForSaveLaterPR(false);
        setNotify({
          isOpen: true,
          message: response.data.data[0].message,
          type: "success",
        });
      } else {
        setLoaderForSaveLaterPR(false);
        setNotify({
          isOpen: true,
          message: "Something went wrong, Please try again later",
          type: "error",
        });
      }
    });
  };

  const handleCreateButton = () => {
    setLoaderForPublishPR(true);
    if (isUnit) {
      API.finalUnitCreation(unitId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setLoaderForPublishPR(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setLoaderForPublishPR(false);
          setNotify({
            isOpen: true,
            message: response.data.error[0].error_msg,
            type: "error",
          });
        }
      });
    } else {
      API.finalAdminCreationPublish(unitId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setLoaderForPublishPR(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setLoaderForPublishPR(false);
          setNotify({
            isOpen: true,
            message: response.data.error[0].error_msg,
            type: "error",
          });
        }
      });
    }
  };

  const handleChangeQuiz = async (e) => {
    let _status;
    if (e.target.value === "true") {
      _status = true;
    } else {
      _status = false;
    }
    setQuizMandate(_status);
    API.AdminPreRequisiteStatusChange(_status, unitId).then(
      ({ response }) => {}
    );
  };
  return (
    <>
      <Box
        className="training-material-container"
        style={styles.assignemntHieght}
      >
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <FormControl>
              <Typography style={styles.label1}>
                Is Pre-Requisite Required
                <span style={styles.madatoryMark}>*</span>
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="isCheckAssignment"
                id="isCheckAssignment"
                value={quizMandate}
                style={{ marginLeft: "0px", marginTop: "5px" }}
                onChange={handleChangeQuiz}
              >
                <FormControlLabel
                  value={true}
                  className="radiobtn"
                  control={<Radio style={{ color: "rgb(155 158 206)" }} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  className="radiobtn"
                  control={<Radio style={{ color: "rgb(155 158 206)" }} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {quizMandate ? (
            <>
              <div style={styles.inputContainer}>
                <div style={styles.preRequisitesEditDiv}>
                  <Typography style={styles.label}>
                    Pre-Requisite Details
                    <span style={styles.madatoryMark}>*</span>
                  </Typography>

                  {disableDesc ? (
                    <EditIcon
                      style={styles.editIcon}
                      onClick={() => setDisableDesc(false)}
                    />
                  ) : (
                    <DoneIcon
                      style={styles.editIcon}
                      onClick={() => editPreDescription()}
                    />
                  )}
                </div>

                <Editor
                  heading="prerequiste"
                  name="description"
                  id="sessdescriptionion"
                  value={values.description}
                  disabled={disableDesc}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  size="small"
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  extractedText={extractedText}
                  setExtractedText={setExtractedText}
                  readOnly={disableDesc}
                  setState={setState}
                  richTextdescription={richTextdescription}
                  state={state}
                  setDescription={setRichTextDescription}
                />
              </div>
              <Grid container spacing={2} className="training-materail">
                <Grid item xs={2.4}>
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Name
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    <span>
                      <ReusableInputfield
                        name="fileName"
                        id="fileName"
                        placeholder="Name"
                        value={values.fileName}
                        onChange={handleChange}
                        size="small"
                        style={styles.inputFleid}
                        error={touched.fileName && Boolean(errors.fileName)}
                        helperText={touched.fileName && errors.fileName}
                      />
                    </span>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={2.4}
                  style={
                    errors.file
                      ? styles.inputAndFileContainerValidator
                      : styles.inputContainer
                  }
                >
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Video Link
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    <span>
                      <ReusableInputfield
                        name="videoLink"
                        id="videoLink"
                        placeholder="Video Link"
                        value={values.videoLink}
                        style={styles.inputFleid}
                        onChange={handleChange}
                        disabled={linkfilled ? true : false}
                        error={touched.file && Boolean(errors.file)}
                        helperText={touched.file && errors.file}
                        size="small"
                      />
                    </span>
                    <div></div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3.3}
                  style={
                    errors.file
                      ? styles.inputAndFileContainerValidatorPrePreq
                      : styles.fileContainer
                  }
                >
                  <div style={styles.inputContainer}>
                    <Typography style={styles.label} variant="h6">
                      Upload Materials
                      <span style={styles.madatoryMark}>*</span>
                    </Typography>
                    <span>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        class="upload-box"
                        accept={SheetJSFT}
                        ref={inputRef}
                        style={{
                          pointerEvents: values.videoLink ? "none" : "visible",
                          fontSize: "0.8em",
                          fontFamily: "Poppins",
                          height: "41px",
                          position: "relative",
                          opacity: values.videoLink ? 0.5 : 1,
                          bottom: "3px",
                        }}
                        onChange={(e) => handleFileChange(e, setFieldValue)}
                      />
                    </span>
                    <Typography style={styles.note} variant="p">
                      Only .pdf .ppt .docx .pptx format allowed
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={2.4}
                  style={SxStyles.createCourseStyle.createCourseGrid}
                >
                  <span style={SxStyles.createCourseStyle.createCourseSpan}>
                    Duration(in mins)
                    <span style={styles.madatoryMark}>*</span>
                  </span>
                  <ReusableInputfield
                    name="duration"
                    id="duration"
                    placeholder="Duration"
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      handleChange({
                        target: {
                          name: "duration",
                          value: numericValue,
                        },
                      });
                    }}
                    size="small"
                    style={styles.inputFleid}
                    value={values.duration}
                    type="text"
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        style: { padding: "8px 14px" },
                      },
                    }}
                    error={touched.duration && Boolean(errors.duration)}
                    helperText={touched.duration && errors.duration}
                  />
                </Grid>

                <Grid item xs={1} style={styles.fileContainer}>
                  <ReusableButton
                    type="submit"
                    size="large"
                    variant="outlined"
                    buttonName="Add"
                    style={styles.addbuttonPrerequisite}
                  ></ReusableButton>
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
        </form>
        {done ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              height: "30vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : quizMandate ? (
          <>
            {draggableData.length > 0 ? (
              <>
                <Box style={styles.tableContainer}>
                  <DataTableDrag
                    draggableData={draggableData}
                    setDraggableData={setDraggableData}
                    handleDeleteInpre={handleDeleteInpre}
                    handleViewClick={handleViewClick}
                    pageIndex={pageIndex}
                    pageLimit={limit}
                    handleEditClick={handleEditClick}
                    link={true}
                    dataTableHeader={dataTableHeader}
                    deleteId={deleteId}
                    getPreRequisites={getPreRequisites}
                  />
                  {/* {draggableData.length > limit ? (
                    <ReactPaginate
                      previousLabel={<>&laquo;</>}
                      nextLabel={<>&raquo;</>}
                      pageCount={Math.ceil(draggableData.length / limit)}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={pageCount}
                    />
                  ) : null} */}
                </Box>
              </>
            ) : (
              <>
                <Box style={styles.tableErrorMsg}>
                  No Pre-requisites found. Please Add a Pre-requsite
                </Box>
              </>
            )}
          </>
        ) : (
          ""
        )}

        <Box style={styles.btnContainer}>
          <Box style={styles.buttonContainer}>
            <ReusableButton
              onClick={() => navigate("/home/courses")}
              size="large"
              variant="outlined"
              buttonName="Cancel"
              style={styles.cancelButton}
              disabled={
                loaderForSaveLaterPR || loaderForPublishPR ? true : false
              }
            ></ReusableButton>
            <ReusableButton
              onClick={handleSaveForLaterButton}
              className="addbutton"
              size="large"
              variant="contained"
              endIcon={
                !loaderForSaveLaterPR ? (
                  ""
                ) : (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )
              }
              buttonName={!loaderForSaveLaterPR ? "Save for Later" : "Saving"}
              style={
                !loaderForPublishPR
                  ? styles.saveForLaterBtn
                  : styles.saveForLaterBtnDisabled
              }
              disabled={loaderForPublishPR ? true : false}
            ></ReusableButton>
            <ReusableButton
              onClick={handleCreateButton}
              type="submit"
              size="large"
              variant="contained"
              endIcon={
                !loaderForPublishPR ? (
                  ""
                ) : (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )
              }
              buttonName={!loaderForPublishPR ? "Publish" : "Publishing"}
              style={
                !loaderForSaveLaterPR
                  ? styles.createBtn
                  : styles.createBtnDisabled
              }
              disabled={loaderForSaveLaterPR ? true : false}
            ></ReusableButton>
          </Box>
        </Box>
      </Box>
      <Dialog
        divider
        open={open}
        maxWidth="sm"
        onClose={handleCloseInByCancel}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none" },
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
            <span style={{ fontWeight: "bold", marginLeft: "5px" }}></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            buttonName="Cancel"
            style={styles.cancelButton}
            onClick={handleCloseInByCancel}
          />
          <Button
            size="medium"
            variant="contained"
            className="qstnUpldPageBttn"
            buttonName="Delete"
            style={styles.createBtnDialog}
            onClick={() => handleCloseDelete(preReqId)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* edit pop up */}
      <Dialog
        divider
        open={openEdit}
        maxWidth="md"
        PaperProps={{
          style: {
            overflowX: "hidden",
            paddingLeft: "15px",
          },
        }}
        onClose={handleCloseInByCancel}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        <DialogTitle> Edit Pre-requisite</DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none" },
          }}
        ></DialogContent>
        <DialogActions>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className="training-materail">
              <Grid item xs={4}>
                <div style={styles.inputContainer}>
                  <Typography style={styles.label} variant="h6">
                    Name
                    <span style={styles.madatoryMark}>*</span>
                  </Typography>
                  <span>
                    <ReusableInputfield
                      name="fileName"
                      id="fileName"
                      placeholder="Name"
                      value={values.fileName}
                      onChange={handleChange}
                      size="small"
                      style={styles.inputFleid}
                      error={touched.fileName && Boolean(errors.fileName)}
                      helperText={touched.fileName && errors.fileName}
                    />
                  </span>
                </div>
              </Grid>
              <Grid
                item
                xs={3.5}
                style={
                  errors.file
                    ? styles.inputAndFileContainerValidator
                    : styles.inputContainer
                }
              >
                <div style={styles.inputContainer}>
                  <Typography style={styles.label} variant="h6">
                    Video Link
                    <span style={styles.madatoryMark}>*</span>
                  </Typography>
                  <span>
                    <ReusableInputfield
                      name="videoLink"
                      id="videoLink"
                      placeholder="Video Link"
                      value={values.videoLink}
                      style={styles.inputFleid}
                      onChange={handleChange}
                      disabled={linkfilled ? true : false}
                      error={touched.file && Boolean(errors.file)}
                      helperText={touched.file && errors.file}
                      size="small"
                    />
                  </span>
                  <div></div>
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                style={
                  errors.file
                    ? updateMaterial
                      ? { ...styles.inputAndFileContainerValidatorPrePreq }
                      : {
                          ...styles.inputAndFileContainerValidatorPrePreq,
                          marginTop: "0px !important",
                        }
                    : styles.fileContainer
                }
              >
                <div style={styles.inputContainer}>
                  <Typography style={styles.label} variant="h6">
                    {updateMaterial ? "  Upload Materials" : "Material"}
                    {updateMaterial && (
                      <span style={styles.madatoryMark}>*</span>
                    )}
                  </Typography>
                  <span>
                    {updateMaterial ? (
                      <input
                        type="file"
                        name="file"
                        id="file"
                        class="upload-box"
                        accept={SheetJSFT}
                        ref={inputRef}
                        style={{
                          pointerEvents: values.videoLink ? "none" : "visible",
                          fontSize: "0.8em",
                          fontFamily: "Poppins",
                          height: "41px",
                          position: "relative",
                          opacity: values.videoLink ? 0.5 : 1,
                          bottom: "3px",
                        }}
                        onChange={(e) => handleFileChange(e, setFieldValue)}
                      />
                    ) : (
                      <div style={{ display: "flex" }}>
                        <ReusableInputfield
                          name="pdffile"
                          id="pdffile"
                          value={
                            values.file.length > 19
                              ? `${values.file.slice(0, 20)}...`
                              : values.file
                          }
                          readOnly={true}
                          size="small"
                        />
                        <IconButton
                          aria-label="delete"
                          style={{
                            position: "relative",
                            right: "15%",
                            paddingTop: "8px",
                          }}
                          onClick={() => {
                            values.file = "";
                            setLinkFilled(false);
                            setUpdateMaterial(true);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </div>
                    )}
                  </span>
                  {updateMaterial && (
                    <Typography style={styles.note} variant="p">
                      Only .pdf .ppt .docx .pptx format allowed
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                style={SxStyles.createCourseStyle.createCourseGrid}
              >
                <span style={SxStyles.createCourseStyle.createCourseSpan}>
                  Duration(in mins)
                  <span style={styles.madatoryMark}>*</span>
                </span>
                <ReusableInputfield
                  name="duration"
                  id="duration"
                  placeholder="Duration"
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                    handleChange({
                      target: {
                        name: "duration",
                        value: numericValue,
                      },
                    });
                  }}
                  size="small"
                  style={styles.inputFleid}
                  value={values.duration}
                  type="text"
                  InputProps={{
                    autoComplete: "off",
                    inputProps: {
                      style: { padding: "8px 14px" },
                    },
                  }}
                  error={touched.duration && Boolean(errors.duration)}
                  helperText={touched.duration && errors.duration}
                />
              </Grid>
            </Grid>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ReusableButton
                size="medium"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={styles.cancelButton}
                onClick={handleCloseInByCancel}
              />
              <Button
                size="medium"
                variant="contained"
                className="qstnUpldPageBttn"
                buttonName="Update"
                type="submit"
                style={styles.createBtnDialog}
              >
                Update
              </Button>
            </div>
          </form>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default PreRequisite;
