import {
  React,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import { API } from "../../../../Networking/API";
import "./createProgram.css";
import Notification from "../../../../components/Custom/Notification";
import Container from "@mui/material/Container";
import moment from "moment";
import Card from "@mui/material/Card";
import { ReusableInputfield } from "../../../../components/reusable-input/reusable-input";
import { Custom } from "../../../../components/Custom/Custom";
import AddCourseUnitModal from "../masterDataModal/addCourseUnitModal";
import { SxStyles } from "../../../../styles/styles";
import { useFormik } from "formik";
import { createProgramValidationSchema } from "../../../../Validation/FieldValidatorSchema";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { UnitAddtionHandler } from "../../../../handler/addCourseHandler";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CollapsibleTable from "../../../../components/reusable-datagrid/ProgramDataGridAccordian";
import {
  GetProgramHelper,
  uploadParticipant,
  uploadBootCampParticipant,
  GetBootCampData,
} from "../../../../helper/programHelper";
import {  IconButton, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DataTableHandler } from "../../../../handler/dataTableHandler";
import Autocomplete from "@mui/material/Autocomplete";
import { InputLabel } from "@material-ui/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { makeStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ReusableDelete from "../../../../components/reusable-delete/ReusableDelete";
let st = {
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
};
const SheetJSFT = ["csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
const stylesp = makeStyles((theme) => ({
  phoneW: {
    "& .MuiOutlinedInput-inputAdornedStart ": {
      width: "187px",
      height: "36px",
    },
  },
}));
const CreateProgram = () => {
  let navigate = useNavigate();
  const classes = stylesp();
  const styles = SxStyles.styles;
  const [courseData, setCoursedata] = useState([]);
  const inputRef = useRef();
  const [BootCampDate, setBootCampDate] = useState(null);
  const [programLocal, setProgramLocal] = useState(
    localStorage.getItem("programId") ? localStorage.getItem("programId") : []
  );
  const [isBootCamp, setIsBootCamp] = useState(
    localStorage.getItem("IsBootCamp")
  );
  useEffect(() => {
    setIsBootCamp(localStorage.getItem("IsBootCamp"));
  }, [localStorage.getItem("IsBootCamp")]);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [addUnitDialog, setAddUnitDialog] = useState(false);
  const [selectTypeCheck, setSelectTypeCheck] = useState(null);
  const [singleParticipant, setSingleParticipant] = useState(false);
  const [singleMentor, setSingleMentor] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [pageCount, setPageCount] = useState(0);
  const[pageCountMentor,setPageCountMentor]=useState(0);
  const [edit, setEdit] = useState(false);
  const [selectedUnitData, setselectedUnitData] = useState(() => new Set());
  const [checkboxId, setCheckboxId] = useState(() => new Set());
  const [courseDifficultyLevel, setCourseDifficultyLevel] = useState(0);
  const [totalUnitData, setTotalUnitData] = useState([]);
  const [removeUnit, setRemoveUnit] = useState(false);
  const [unitSelectedTag, setUnitSelectedTag] = useState("");
  const [programDate, setProgramDate] = useState();
  const [search, setSearch] = useState();
  const [trainers, setTrainers] = useState();
  const [BackupTrainer, setBackupTrainer] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [selectedTrainer, setSelectedTrainer] = useState();
  const [unitId, setUnitId] = useState();
  const [courseIdInEdit, setCourseId] = useState();
  const [participantsProgram, setParticipantProgram] = useState();
  const [mentorProgram, setMentorProgram] = useState([]);
  const [mentorDetails, setMentorDetails] = useState([]);
  const [fieldValue, setFieldValue] = useState();
  const [fieldValueMentor, setFieldValueMentor] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [isSucccessUpload, setIsSuccessUpload] = useState();
  const [trainerAddLoader, setTrainerAddLoader] = useState(false);
  const [selectType, setSelectType] = useState([]);
  const [participantDetails, setParticipantDetails] = useState([]);
  const [selectAll, setSelectAll] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectId, setSelectId] = useState(0);
  const [view, setView] = useState(false);
  const [pageLimit, setPageLimit] = useState(2);
  const[pageLimitProgram,setPageLimitProgram]=useState(2);
  const[pageLimitMentor,setPageLimitMentor]=useState(10);
  const [mentorView, setMentorView] = useState(false);
  const [editParticipantPopup, setEditParticipantPopup] = useState(false);
  const [mentorpk, setMentorpk] = useState();
  const [participantpk, setParticipantpk] = useState();
  const [editMentorPopup, setEditMentorPopup] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [traineeData, setTraineeData] = useState({
    traineeFirstName: "",
    traineeLastName: "",
    traineeEmail: "",
    traineeDesignation: "",
    traineePhoneNumber: "",
    traineeCollege: "",
  });
  const [mentorData, setMentorData] = useState({
    traineeFirstName: "",
    traineeLastName: "",
    traineeEmail: "",
    traineeDesignation: "",
    mentorPhoneNumber: "",
    traineeCollege: "",
    mentorFirstName: "",
    mentorLastName: "",
    mentorEmail: "",
  });
  const handleClickOpenParticipantModal = (id, programId) => {
    setOpenParticipantModal(true);
    setSelectId(id);
  };
  const emptyTraineeData = () => {
    setTraineeData({
      traineeFirstName: "",
      traineeLastName: "",
      traineeEmail: "",
      traineeDesignation: "",
      traineePhoneNumber: "",
      traineeCollege: "",
    });
  };
  const emptyMentorData = () => {
    setMentorData({
      traineeFirstName: "",
      traineeLastName: "",
      traineeEmail: "",
      traineeDesignation: "",
      mentorPhoneNumber: "",
      traineeCollege: "",
      mentorFirstName: "",
      mentorLastName: "",
      mentorEmail: "",
    });
  };
  const handleCloseEditPartcipantPopup = () => {
    setEditParticipantPopup(false);
    emptyTraineeData();
  };
  const handleCloseEditMentorPopup = () => {
    setEditMentorPopup(false);
    emptyMentorData();
  };
  const handleCloseByCancelParticipantModal = () => {
    setOpenParticipantModal(false);
  };

  const handleCloseMentorPopup = () => {
    setMentorView(false);
  };
  const handleCloseViewPopUp = () => {
    setView(false);
  };

  const onSelectionModelChange = (selected) => {
    if (value === 2) {
      const updatedMentorsList = newMentorList.filter((data) =>
        selected.includes(data.id)
      );
      const newArray = updatedMentorsList.map((data) => ({
        mentor_id: data.MentorId,
        trainee_id: data.TraineeId,
      }));
      setSelectAll(newArray);
    } else setSelectAll(selected);
  };

  const handleDeleteClose = () => {
    if(value ===2) setSelectAll([]);
    setOpenDelete(false);
  };

  const handleDeleteTag = () => {
    if (value === 2) {
      const payLoad = {
        batch_id: localStorage.getItem("programId"),
        data: selectAll,
      };
      API.AdminDeleteMentor(payLoad).then(({ response }) => {
        if (response.api_status === 200) {
          if(mentorProgram?.rows.length===1) setPageCountMentor(Math.abs(pageCountMentor-1));
          else getMentorOfProgram();
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
          setOpenDelete(false);
          // getMentorOfProgram();
          setSelectAll([]);
        } else {
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
          setOpenMentorModal(false);
        }
      });
    } else {
      const payLoad = {
        batch_id: parseInt(programLocal),
        user_ids: selectAll,
      };
      API.deleteAdminMultipleProgramCandidates(payLoad).then((response) => {
        if (response.response.api_status === 200) {
          if(participantsProgram?.rows.length===1) setPageCount(Math.abs(pageCount-1));
          else getParticipantsOfProgram();
          setNotify({
            isOpen: true,
            message: response.response.data[0].message,
            type: "success",
          });
          setOpenDelete(false);
          setSelectAll([]);
          getParticipantsOfBootCamp();
        } else {
          setNotify({
            isOpen: true,
            message: response.response.error[0].error_msg,
            type: "error",
          });
        }
      });
    }
  };

  const handleDeleteParticipant = async (paramsId, programLocal) => {
    if (isBootCamp === "false") {
      API.deleteAdminProgramCandidate(paramsId, programLocal).then(
        ({ response }) => {
          if (response.api_status === 200) {
            if(participantsProgram?.rows.length===1) setPageCount(Math.abs(pageCount-1));
          else getParticipantsOfProgram();
            setOpenParticipantModal(false);
            setSelectId(0);
            handleGetProgram(programLocal);
            setNotify({
              isOpen: true,
              message: "Candidate deleted successfully",
              type: "success",
            });
          } else {
            setOpenParticipantModal(false);
            handleGetProgram(programLocal);
            setNotify({
              isOpen: true,
              message: response.error[0].error_msg,
              type: "error",
            });
          }
        }
      );
    } else {
      API.deleteAdminBootCamp(paramsId, programLocal).then((response) => {
        if (response.result.api_status === 200) {
          if(participantsProgram?.rows.length===1) setPageCount(Math.abs(pageCount-1));
          else getParticipantsOfBootCamp();
          setOpenParticipantModal(false);
          handleGetProgram(programLocal);
          setNotify({
            isOpen: true,
            message: "Candidate deleted successfully",
            type: "success",
          });
        } else {
          setOpenParticipantModal(false);
          handleGetProgram(programLocal);
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
        }
      });
    }
  };
  const handleEditMentor = (id, pid) => {
    setEditMentorPopup(true);
    setSingleMentor(false);
    setUserid(id);
    const mentorsList = mentorProgram?.rows?.filter((arr) => arr.user_pk === id);
    setMentorpk(mentorsList[0]?.traineeDetails?.amt_pk);
    setMentorDetails(mentorsList);
  };
  const handleEditPartcipant = (id, pid) => {
    setEditParticipantPopup(true);
    setSingleParticipant(false);
    setUserid(id);
    const viewParticipantsDetails = participantsProgram?.rows?.filter(
      (arr, index) => arr.user_pk === id
    );
    setParticipantpk(viewParticipantsDetails[0]?.traineeDetails?.amt_pk);
    setParticipantDetails(viewParticipantsDetails);
  };
  useEffect(() => {
    if (participantDetails.length > 0) {
      setTraineeData({
        traineeFirstName: participantDetails[0]?.first_name,
        traineeLastName: participantDetails[0]?.last_name,
        traineeEmail: participantDetails[0]?.user_email,
        traineeDesignation: participantDetails[0]?.department,
        traineePhoneNumber: participantDetails[0]?.phonenumber,
        traineeCollege: participantDetails[0]?.trainee_colleage,
      });
    }
  }, [participantDetails]);

  useEffect(() => {
    if (mentorDetails.length > 0) {
      setMentorData({
        traineeFirstName: mentorDetails[0]?.first_name,
        traineeLastName: mentorDetails[0]?.last_name,
        traineeEmail: mentorDetails[0]?.user_email,
        traineeDesignation: mentorDetails[0]?.department,
        mentorPhoneNumber: mentorDetails[0]?.traineeDetails?.mentorDetails?.phonenumber,
        traineeCollege: mentorDetails[0]?.trainee_colleage,
        mentorFirstName:
          mentorDetails[0]?.traineeDetails?.mentorDetails?.first_name,
        mentorLastName:
          mentorDetails[0]?.traineeDetails?.mentorDetails?.last_name,
        mentorEmail:
          mentorDetails[0]?.traineeDetails?.mentorDetails?.user_email,
      });
    }
  }, [mentorDetails]);

  const handleDeleteModal = (param) => {
    if (programTableData.length !== 1) {
      setParams(param);
      setOpenModal(true);
    } else {
      return setNotify({
        isOpen: true,
        message: "Unit or Course Must be 1 item",
        type: "error",
      });
    }
  };
 const handlePageClick=(e,pageNumber)=>{
     pageNumber=e.selected;
     setPageCount(pageNumber);
 };
const handlePageClickMentor=(e,pageNumber)=>{
       pageNumber=e.selected;
       setPageCountMentor(pageNumber);
};
 useEffect(()=>{
  if(formik?.values?.selectType===0) getParticipantsOfProgram();
 if(formik?.values?.selectType===1 || formik?.values?.selectType===2)getParticipantsOfBootCamp();
 },[pageCount]);

 useEffect(()=>{
  if(formik?.values?.selectType===0 || formik?.values?.selectType===1 || formik?.values?.selectType===2) getMentorOfProgram();
 },[pageCountMentor]);

useEffect(()=>{
if(isBootCamp==="false") getCategories(3,1000,1,1,"");
},[]);


  const handleProgramUnitorCourseDelete = async (
    programId,
    courseId,
    unitId
  ) => {
    if (isBootCamp === "false") {
      API.AdminDeleteProgramUnitCourse(programId, courseId, unitId).then(
        ({ response }) => {
          if (response.api_status === 200) {
            setOpenModal(false);
            setNotify({
              isOpen: true,
              message: "Unit or Course deleted successfully",
              type: "success",
            });
            handleGetProgram(programLocal);
          } else {
            setOpenModal(false);
            setNotify({
              isOpen: true,
              message: response.error[0].error_msg,
              type: "error",
            });
          }
        }
      );
    } else {
      DeleteBootCampUnit(unitId);
    }
  };

  const handleViewMentor = (id, pid) => {
    setMentorView(true);
    setProgramLocal(pid);
    const mentorsList = mentorProgram?.rows?.filter((arr) => arr.user_pk === id);
    setMentorDetails(mentorsList);
  };
  const handleView = (id) => {
    setView(true);
    const viewParticipantsDetails = participantsProgram?.rows?.filter(
      (arr, index) => arr.user_pk === id
    );
    setParticipantDetails(viewParticipantsDetails);
  };
  const columns = [
    {
      field: "Name",
      headerName: "Trainee Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Email",
      headerName: "Trainee Email ID",
      width: 230,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Designation",
      headerName: "Designation",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <ReusableButton
            size="large"
            className="qstnUpldPageBttn"
            startIcon={
              <>
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => handleView(params.id, programLocal)}
                  disabled={selectAll.length !== 0}
                >
                  <RemoveRedEyeIcon
                    fontSize="inherit"
                    style={styles.tableIcon}
                    color={selectAll.length === 0 ? "primary" : "grey"}
                  />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  disabled={selectAll.length !== 0}
                  onClick={() =>
                    handleEditPartcipant(params.row.id, programLocal)
                  }
                >
                  <EditIcon
                    color={selectAll.length === 0 ? "primary" : "grey"}
                    fontSize="small"
                  />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    handleClickOpenParticipantModal(params.id, programLocal)
                  }
                  disabled={selectAll.length !== 0}
                >
                  <DeleteOutlineOutlinedIcon
                    color={selectAll.length === 0 ? "primary" : "grey"}
                    fontSize="small"
                  />
                </IconButton>
              </>
            }
          />
        </div>
      ),
    },
  ];
  const programPartcipantSampleCsv = {
    data: [
      {
        EMAIL: "",
      },
    ],
    filename: "sampleProgramParticipantsFile",
  };
  const participantsSampleCsv = {
    data: [
      {
        "First Name": "",
        "Last Name": "",
        Email: "",
        "Phone Number": "",
        Department: "",
        Collage: "",
      },
    ],
    filename: "sampleParticipantsFile",
  };
  const mentorSampleCsv = {
    data: [
      {
        Trainee_name: "",
        Trainee_Email: "",
        Phone_Number: "",
        College: "",
        Mentor_Email: "",
        Mentor_name: "",
      },
    ],
    filename: "sampleMentorFile",
  };
  const partcipantsSheets =
    selectedValue === 0 ? programPartcipantSampleCsv : participantsSampleCsv;
  

  const Mentorscolumns = [
    {
      field: "MentorName",
      headerName: "Mentor Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "MentorEmail",
      headerName: "Mentor Email ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Name",
      headerName: "Trainee Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Email",
      headerName: "Trainee Email ID",
      width: 230,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <ReusableButton
            size="large"
            className="qstnUpldPageBttn"
            startIcon={
              <>
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => handleViewMentor(params.row.id)}
                  disabled={selectAll.length !== 0}
                >
                  <RemoveRedEyeIcon
                    fontSize="inherit"
                    style={styles.tableIcon}
                    color={selectAll.length === 0 ? "primary" : "grey"}
                  />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  disabled={selectAll.length !== 0}
                  onClick={() => handleEditMentor(params.row.id, programLocal)}
                >
                  <EditIcon
                    color={selectAll.length === 0 ? "primary" : "grey"}
                    fontSize="small"
                  />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setSelectAll([
                      {
                        mentor_id: params.row.MentorId,
                        trainee_id: params.row.TraineeId,
                      },
                    ]);
                    setOpenDelete(true);
                  }}
                  disabled={selectAll.length !== 0}
                >
                  <DeleteOutlineOutlinedIcon
                    color={selectAll.length === 0 ? "primary" : "grey"}
                    fontSize="small"
                  />
                </IconButton>
              </>
            }
          />
        </div>
      ),
    },
  ];
  const newParticipant = participantsProgram?.rows?.map((item, index) => {
    return {
      id: item.user_pk,
      sno: index + 1,
      Name:
        item.first_name && item.last_name
          ? item.first_name + " " + item.last_name
          : item.first_name,
      Email: item.user_email,
      Designation: item.department ? item.department : "N/A",
    };
  });

  const newMentorList =
    mentorProgram &&
    mentorProgram?.rows?.map((item, index) => {
      return {
        id: item.user_pk,
        sno: index + 1,
        Name:
          item.first_name && item.last_name
            ? item.first_name + item.last_name
            : item.first_name,
        Email: item.user_email,
        Designation: item.department !== null ? item.department : "NA",
        MentorId: item?.traineeDetails?.mentor_id,
        TraineeId: item?.traineeDetails?.trainee_id,
        MentorEmail: item?.traineeDetails?.mentorDetails?.user_email,
        MentorName: item?.traineeDetails?.mentorDetails?.first_name,
      };
    });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [done, setDone] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [unitPageCount, setUnitPageCount] = useState(0);
  const [loaderForSaveLaterCourse, setloaderForSaveLaterCourse] =
    useState(false);
  const [loaderForPublishCourse, setloaderForPublishCourse] = useState(false);
  const [programId, setProgramId] = useState("");
  const [userid, setUserid] = useState();
  const [programName, setProgramName] = useState("");
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [programData, setProgramData] = useState();
  const [programTableData, setProgramTableData] = useState([]);
  const [value, setValue] = useState(0);
  const [openParticipantModal, setOpenParticipantModal] = useState(false);
  const [openMentorModal, setOpenMentorModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [params, setParams] = useState({});
  const [selectedEventDetials, setSelectedEventDetails] = useState([]);
  const [editedDetails, setEditedDetails] = useState([]);
  const [IsBootVisible, setIsBootVisible] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [schedulepk, setSchedulepk] = useState();
  const [descriptionu, setDescription] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TraningType = [
    {
      key: 1,
      value: "EXTERNAL",
      title: "EXTERNAL",
    },
    {
      key: 2,
      value: "INTERNAL",
      title: "INTERNAL",
    },
  ];

  const DropdownValue = [
    { key: 1, title: "Bootcamp", value: 1 },
    { key: 0, title: "Program", value: 0 },
    { key: 2, title: "Hackathon", value: 2 },
  ];

  const handleFileChange = async (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue(file);
  };

  const handleFileChangeMentor = async (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValueMentor(file);
  };
  useEffect(() => {
    if (Array.isArray(programLocal)) {
      setDisabled(false);
      setIsCreate(true);
    } else {
      handleGetProgram(programLocal);
      setDisabled(true);
    }
    if (isBootCamp && isBootCamp === "false") {
      formik.values.selectType = 0;
      setSelectedValue(0);
      setSelectTypeCheck(0);
    } else if (isBootCamp && isBootCamp === "true") {
      formik.values.selectType = 1;
      setSelectTypeCheck(1);
    setSelectedValue(1);
    }
  }, [programLocal, isBootCamp]);

  const getAllTrainer = async (search) => {
    const data = await API.getUsersTrainer(search);
    setTrainers(data.response.data.data);
    setBackupTrainer(data.response.data.data);
  };

  const getCategories = (
    categoryType,
    limit,
    categoryStatus,
    page,
    searchInput
  ) => {
    API.getAllCategories(
      categoryType,
      limit,
      categoryStatus,
      page,
      searchInput
    ).then((response) => {
      if (response.response.data.api_status === 200) {
        const categories = response?.response?.data?.data?.rows?.map((item) => {
          return {
            key: item.id,
            title: item.category_name,
            value: item.category_name,
          };
        });
        setCategories(categories);
      }
    });
  };

  const getParticipantsOfProgram = async () => {
    let pageNo=pageCount+1;
    const response = await API.getParticipants(programLocal,pageNo);
    if (isBootCamp === "false") {
      const updatedData = response.response.data.data;
      setParticipantProgram(updatedData);
      setPageLimitProgram(response.response.data.data?.count);
    }
  };
  const getMentorOfProgram = async () => {
    const pageNo=pageCountMentor+1;
   const id= localStorage.getItem("programId");
    const result = await API.getAllMentors(id,pageNo);
    setPageLimitMentor(result.response.data.data.count);
    const updatedData = result.response.data.data;
    setMentorProgram(updatedData);
  };

  const getParticipantsOfBootCamp = async () => {
    let pageNo=pageCount+1;
    const response = await API.getBootCampParticipants(programLocal,pageNo);
    const updatedData = response.response.data.data;
    setParticipantProgram(updatedData);
    setPageLimit(response.response.data.data.count);
  };
  const handleChangeNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setTraineeData({ ...traineeData, [e.target.name]: value });
  };

  const handleChangeMentorNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMentorData({ ...mentorData, [e.target.name]: value });
  };

  const handleInputChange = (e) => {
    setTraineeData({
      ...traineeData,
      [e.target.name]: e.target.value,
    });
  };
  const handleInputChangeMentor = (e) => {
    setMentorData({
      ...mentorData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmitParticipant = (e) => {
    e.preventDefault();
    const {
      traineeFirstName,
      traineeLastName,
      traineePhoneNumber,
      traineeDesignation,
      traineeCollege,
    } = traineeData;
    const containsOnlyLetters = /^[a-zA-Z]+$/.test(traineeCollege);
    if (traineeDetailsValidation()) {
      return setNotify({
        isOpen: true,
        message: "Please Enter Mandatory Fields",
        type: "error",
      });
    }
    else if(traineePhoneNumber.length<10){
      return setNotify({
        isOpen: true,
        message: "Please Enter Valid PhoneNumber",
        type: "error",
      });
    }

else if (!containsOnlyLetters) {
  return setNotify({
    isOpen: true,
    message: "Trainee College Must be Valid",
    type: "error",
  });
}
    API.updateParticipant(
      traineeFirstName,
      traineeLastName,
      traineePhoneNumber,
      traineeCollege,
      traineeDesignation,
      userid
    ).then(({ response }) => {
      if (response.statusCode === 200) {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "success",
        });
        getParticipantsOfBootCamp();
        setEditParticipantPopup(false);
      } else {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "error",
        });
      }
    });
  };
  const handleSubmitMentor = (e) => {
    e.preventDefault();
    const { mentorFirstName, mentorLastName, mentorPhoneNumber } =
    mentorData;
    if (
      !mentorFirstName ||
      !mentorLastName ||
      !mentorPhoneNumber
    ) {
      return setNotify({
        isOpen: true,
        message: "Please Enter Mandatory Fields",
        type: "error",
      });
    }
    else if(mentorPhoneNumber.length<10){
      return setNotify({
        isOpen: true,
        message: "Please Enter Valid PhoneNumber",
        type: "error",
      });
    };
    const id = mentorDetails[0].traineeDetails.mentor_id;
    API.updateMentor(
      mentorFirstName,
      mentorLastName,
      mentorPhoneNumber,
      parseInt(id)
    ).then(({ response }) => {
      if (response.statusCode === 200) {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "success",
        });
        getMentorOfProgram();
        setEditMentorPopup(false);
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    getAllTrainer();
    if(formik?.values?.selectType===0){
      getParticipantsOfProgram();
      getMentorOfProgram();
    }
    if(formik?.values?.selectType===1 || formik?.values?.selectType===2){
      getParticipantsOfBootCamp();
      getMentorOfProgram();
    }
  
  }, []);
  const formik = useFormik({
    initialValues: {
      programName: courseData.length > 0 ? courseData.data.course_name : "",
      technology: courseData.length > 0 ? courseData.data.technology : "",
      startDate: new Date(),
      endDate: new Date(),
      duration: 0,
      description: courseData.length > 0 ? courseData.data.about_course : "",
      TraningType: "",
    },
    validationSchema: createProgramValidationSchema,
    onSubmit: (values) => {
      if (edit === false) {
        if (selectTypeCheck === 0) {
          handleAddProgram();
        } else handleAddBootCamp();
      } else {
        selectedValue === 0 && editProgram();
        selectedValue === 1 && editBootCamp();
        selectedValue === 2 && editBootCamp();
      }
    },
  });

  const editBootCamp = async () => {
    const id=localStorage.getItem("programId");
    setIsCreate(false);
    setDisabled(false);
    setloaderForSaveLaterCourse(true);
    let CourseUnitId = [];
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units.map((unitsData) => {
          CourseUnitId.push(unitsData.unitid);
        }),
      };
    });
    let UnitIds = [];
    UnitIds = selectedUnits.map((programUnitId) => {
      return programUnitId.id;
    });
    let combinedArray = UnitIds.concat(CourseUnitId);
    const {
      programName,
      startDate,
      endDate,
      description,
    } = formik.values;
    await API.adminEditBootCamp(
      id,
      programName,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      combinedArray,
      description
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
        setloaderForSaveLaterCourse(false);
        handleGetProgram(programLocal);
        setSelectedCourse([]);
        setSelectedUnits([]);
        setEdit(false);
        setDisabled(true);
      } else {
        setloaderForSaveLaterCourse(false);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      }
    });
  };

  const CategoryId=()=>{
    const selectedCategory=categories.filter((arr,index)=>arr.title===formik.values.technology);
    const {key}=selectedCategory[0];
    return key ;
  };

  const handleGetProgram = async (id, isFirstLoad) => {
    if (isBootCamp === "false" || isFirstLoad === false) {
      const ProgramCourseUnit = await GetProgramHelper(
        id,
        setProgramData,
        formik,
        setSelectedUnits,
        setSelectedCourse,
        setDone,
        setIsSaved,
        setSelectedValue
      );
      let unitData = ProgramCourseUnit.filter(
        (programunitdata) => programunitdata.courseDetails === null
      );
      let courseData = ProgramCourseUnit.filter(
        (ProgramCourseData) => ProgramCourseData.courseDetails !== null
      );
      setSelectedEventDetails([
        ...unitData
          .filter((filteredData) => filteredData.trainer_id !== null)
          .map((IndFilteredData) => {
            return {
              isCourse: false,
              trainer: IndFilteredData.trainerDetails,
              trainerId: IndFilteredData.trainer_id,
              startDate: IndFilteredData.start_date,
              end_time: IndFilteredData.end_time,
              start_time: IndFilteredData.start_time,
              // courseId: IndFilteredData.course_id,
              unitId: IndFilteredData.unit_id,
            };
          }),
        ...courseData
          .filter((filtredCourseData) => filtredCourseData.trainer_id !== null)
          .map((IndFilterdCourseData) => {
            return {
              isCourse: true,
              trainer: IndFilterdCourseData.trainerDetails,
              courseId: IndFilterdCourseData.course_id,
              unitId: IndFilterdCourseData.unit_id,
              startDate: IndFilterdCourseData.start_date,
              end_time: IndFilterdCourseData.end_time,
              trainerId: IndFilterdCourseData.trainer_id,
              start_time: IndFilterdCourseData.start_time,
            };
          }),
      ]);
      setSelectedUnits([
        ...unitData.map((IndUnitData) => {
          return {
            Status:
              IndUnitData.unitProgramDetails.active_status === 1
                ? "Active"
                : "In-Active",
            id: IndUnitData.unit_id,
            unitDuration: IndUnitData.unitProgramDetails.duration,
            unitName: IndUnitData.unitProgramDetails.topic,
            date: IndUnitData.start_date,
            endtime: IndUnitData.end_time,
            starttime: IndUnitData.start_time,
            trainer:
              IndUnitData.trainerDetails !== null
                ? IndUnitData.trainerDetails.first_name !== null
                  ? IndUnitData.trainerDetails.first_name
                  : IndUnitData.trainerDetails.user_email
                : null,
          };
        }),
      ]);

      const arrangedData = courseData.reduce((result, current) => {
        const existingCourse = result.find(
          (course) => course.course_id === current.course_id
        );
        if (existingCourse) {
          existingCourse.units.push({
            topic: current.unitProgramDetails.topic,
            duration: current.unitProgramDetails.duration,
            active_status: current.unitProgramDetails.active_status,
            unit_id: current.unit_id,
            endtime: current.end_time,
            starttime: current.start_time,
            date: current.start_date,
            trainer:
              current.trainerDetails !== null
                ? current.trainerDetails.first_name !== null
                  ? current.trainerDetails.first_name
                  : current.trainerDetails.user_email
                : null,
          });
        } else {
          result.push({
            course_id: current.courseDetails.course_id,
            courseDetails: current.courseDetails,
            units: [
              {
                topic: current.unitProgramDetails.topic,
                duration: current.unitProgramDetails.duration,
                active_status:
                  current.unitProgramDetails.active_status === 1
                    ? "Active"
                    : "In-Active",
                unit_id: current.unit_id,
                endtime: current.end_time,
                starttime: current.start_time,
                date: current.start_date,
                trainer:
                  current.trainerDetails !== null
                    ? current.trainerDetails.first_name !== null
                      ? current.trainerDetails.first_name
                      : current.trainerDetails.user_email
                    : null,
              },
            ],
          });
        }
        return result;
      }, []);
      setSelectedCourse(
        arrangedData.map((IndCourseData) => {
          return {
            courseId: IndCourseData.course_id,
            courseName: IndCourseData.courseDetails.course_name,
            duration: IndCourseData.courseDetails.duration,
            status:
              IndCourseData.courseDetails.status === 1 ? "Active" : "In-Active",

            units: IndCourseData.units.map((unitData) => {
              return {
                isunitSelected: true,
                unitDetails: "Lorem",
                unitTitle: unitData?.topic,
                unitid: unitData?.unit_id,
                status: unitData?.active_status === "Active" ? "Active" : "In-Active",
                duration: unitData?.duration,
                date: unitData?.date,
                endtime: unitData?.endtime,
                starttime: unitData?.starttime,
                trainer: unitData?.trainer,
              };
            }),
          };
        })
      );
    } else if (isBootCamp === "true" || isFirstLoad === true) {
      const BootCampCourseUnit = await GetBootCampData(
        id,
        setProgramData,
        formik,
        setSelectedUnits,
        setSelectedCourse,
        setDone,
        setIsSaved,
        setBootCampDate,
        setSelectedValue
      );
      setDone(false);
      const unitData = BootCampCourseUnit.map((item, index) => {
        return {
          start_date: item.from_date,
          schedule_pk: item.schedule_pk,
          start_time: item.from_time,
          end_time: item.to_time,
          trainer_id: item.trainer_id,
          course_id: null,
          unit_id: item.topic_id,
          trainerDetails: item.trainer_details,
          courseDetails: null,
          backupTrainer: item.backup_trainer_id,
          description: item?.topic_details.description,
          unitDetails: {
            topic: item.topic_details.topic,
            duration: item.topic_details.duration,
            active_status: item.topic_details.active_status,
            schedule_pk: item?.schedule_pk,
          },
        };
      });
      setSelectedEventDetails([
        ...unitData
          .filter((filteredData) => filteredData.trainer_id !== null)
          .map((IndFilteredData) => {
            return {
              isCourse: false,
              trainer: IndFilteredData.trainerDetails,
              trainerId: IndFilteredData.trainer_id,
              backupTrainerId: IndFilteredData.backupTrainer,
              startDate: IndFilteredData.start_date,
              end_time: IndFilteredData.end_time,
              start_time: IndFilteredData.start_time,
              unitId: IndFilteredData.unit_id,
              schedule_pk: IndFilteredData?.schedule_pk,
              description: IndFilteredData?.description,
            };
          }),
      ]);

      setSelectedUnits([
        ...unitData.map((IndUnitData) => {
          return {
            Status:
              IndUnitData.unitDetails.active_status === 1
                ? "Active"
                : "In-Active",
            id: IndUnitData.unit_id,
            unitDuration: IndUnitData.unitDetails.duration,
            unitName: IndUnitData.unitDetails.topic,
            date: IndUnitData.start_date,
            endtime: IndUnitData.end_time,
            starttime: IndUnitData.start_time,
            backupTrainer: IndUnitData.backup_trainer_id,
            schedule_pk: IndUnitData?.schedule_pk,
            description: IndUnitData?.description,
            trainer:
              IndUnitData.trainerDetails !== null
                ? IndUnitData.trainerDetails.first_name !== null
                  ? IndUnitData.trainerDetails.first_name
                  : IndUnitData.trainerDetails.user_email
                : null,
          };
        }),
      ]);
    }
  };

  const DeleteBootCampUnit = async (id) => {
    let UnitIds = [];
    let CourseUnitId = [];
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units
          .filter((data) => data.id !== id)
          .map((unitsData) => {
            CourseUnitId.push(unitsData.unitid);
          }),
      };
    });
    UnitIds = selectedUnits
      .filter((data) => data.id !== id)
      .map((programUnitId) => {
        return programUnitId.id;
      });
    let combinedArray = UnitIds.concat(CourseUnitId);
    const {
      programName,
      startDate,
      endDate,
      description,
    } = formik.values;
    await API.adminEditBootCamp(
      programLocal,
      programName,
      startDate,
      endDate,
      combinedArray,
      description
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setOpenModal(false);
        handleGetProgram(programLocal);
        setNotify({
          isOpen: true,
          message: "Unit or Course deleted sucessfully",
          type: "success",
        });
      } else {
        setOpenModal(false);
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    if (selectedTrainer)
      setBackupTrainer(
        trainers.filter((data) => data.user_pk !== selectedTrainer?.user_pk)
      );
  }, [selectedTrainer]);

  useEffect(() => {
    UnitAddtionHandler(
      setPageCount,
      totalUnitData,
      selectedUnitData,
      setTotalUnitData,
      setCourseDifficultyLevel,
      setAddUnitDialog,
      checkboxId
    );
    setRemoveUnit(false);
  }, [removeUnit]);

  useEffect(() => {
    let TotalDuration = 0;
    if (selectedUnits?.length > 0) {
      selectedUnits.forEach((unitData) => {
        TotalDuration += unitData.unitDuration;
      });
    }
    selectedCourse.forEach((courseData) => {
      TotalDuration += courseData.duration;
    });
    formik.setFieldValue("duration", TotalDuration);
    const { unitData, courseData, CourseUnit } =
      DataTableHandler.AdminProgramDataTableHandler(
        selectedUnits,
        selectedCourse,
        selectedEventDetials
      );
    setProgramTableData([...unitData, ...CourseUnit]);
  }, [selectedCourse, selectedUnits]);
  const selectedEditValue = useMemo(() => {
    setSchedulepk(editedDetails[0]?.schedule_pk || null);
    setDescription(editedDetails[0]?.description || null);
    if (editedDetails.length > 0) {
      if (editedDetails[0].StartTime && editedDetails[0].StartTime !== "N/A") {
        setStartTime(editedDetails[0].StartTime.substring(0, 5));
      } else setStartTime("");
      if (editedDetails[0].EndTime && editedDetails[0].EndTime !== "N/A") {
        setEndTime(editedDetails[0].EndTime.substring(0, 5));
      } else setEndTime("");
      if (editedDetails[0].Date && editedDetails[0].Date !== "N/A") {
        setProgramDate(editedDetails[0].Date);
      }
      if (editedDetails[0].Trainer && editedDetails[0].Trainer !== "N/A") {
        setSelectedTrainer(
          trainers.find(
            (item) =>
              item.first_name === editedDetails[0].Trainer ||
              item.user_email === editedDetails[0].Trainer
          )
        );
        setSearch(
          trainers.find(
            (item) =>
              item.first_name === editedDetails[0].Trainer ||
              item.user_email === editedDetails[0].Trainer
          )
        );
      } else {
        setSelectedTrainer(null);
        setSearch({});
      }
    }
  }, [editedDetails]);

  const handleOpenModal = (title, id) => {
    setProgramId(id);
    setProgramName(title);
    handleClickOpen();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddProgram = async () => {
    const categoryId  =CategoryId();
    setloaderForSaveLaterCourse(true);
    setIsCreate(false);
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units.map((unitsData) => {
          return {
            unitId: unitsData.unitid,
            isUnitSelected: unitsData.isunitSelected,
          };
        }),
      };
    });
    const UnitIds = selectedUnits.map((programUnitId) => {
      return programUnitId.id;
    });
    const {
      programName,
      technology,
      startDate,
      endDate,
      duration,
      description,
      TraningType,
    } = formik.values;
    await API.postProgram(
      programName,
      technology,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      duration,
      description,
      UnitIds,
      CourseIds,
      TraningType,
      categoryId
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setProgramLocal(response.data.program_id);
        localStorage.setItem("programId", response.data.program_id);
        localStorage.setItem("IsBootCamp", false);
        handleGetProgram(response.data.program_id, false);
        setloaderForSaveLaterCourse(false);
        setDisabled(true);
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
        });
      } else {
        setloaderForSaveLaterCourse(false);
        setNotify({
          isOpen: true,
          message: response?.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };


  const handleAddBootCamp = async () => {
    setloaderForSaveLaterCourse(true);
    setIsCreate(false);
    let CourseUnitId = [];
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units.map((unitsData) => {
          
          CourseUnitId.push(unitsData.unitid);
        }),
      };
    });
    let UnitIds = [];
    UnitIds = selectedUnits.map((programUnitId) => {
      return programUnitId.id;
    });
    let combinedArray = UnitIds.concat(CourseUnitId);

    const {
      programName,
      startDate,
      endDate,
      description,
    } = formik.values;
    await API.postBootCamp(
      programName,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      combinedArray,
      description,
      selectedValue
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setProgramLocal(response.data.batch_pk);
        localStorage.setItem("programId", response.data.batch_pk);
        localStorage.setItem("IsBootCamp", true);
        handleGetProgram(response.data.batch_pk, true);
        setSelectedCourse([]);
        setSelectedUnits([]);
        setloaderForSaveLaterCourse(false);
        setDisabled(true);
        setNotify({
          isOpen: true,
          message: response.data?.message,
          type: "success",
        });
      } else {
        setloaderForSaveLaterCourse(false);
        setNotify({
          isOpen: true,
          message: response?.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };
  const editPage = async () => {
    setDisabled(false);
    setEdit(true);
    setloaderForSaveLaterCourse(false);
  };

  const editProgram = async () => {
   const categoryId=CategoryId();
    setIsCreate(false);
    setDisabled(false);
    setloaderForSaveLaterCourse(true);
    const CourseIds = selectedCourse.map((programCourseId) => {
      return {
        CourseId: programCourseId.courseId,
        Units: programCourseId.units.map((unitsData) => {
          return {
            unitId: unitsData.unitid,
            isUnitSelected: unitsData.isunitSelected,
          };
        }),
      };
    });
    const UnitIds = selectedUnits.map((programUnitId) => {
      return programUnitId.id;
    });
    if (formik.values.duration !== 0) {
      const {
        programName,
        technology,
        startDate,
        endDate,
        duration,
        description,
        TraningType,
      } = formik.values;
      await API.adminEditProgram(
        programLocal,
        programName,
        technology,
        duration,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        description,
        UnitIds,
        CourseIds,
        TraningType,
        categoryId
      ).then(({ response }) => {
        if (response.api_status === 200) {
          setloaderForSaveLaterCourse(false);
          setEdit(false);
          setDisabled(true);
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
        } else {
          setloaderForSaveLaterCourse(false);
          setNotify({
            isOpen: true,
            message: response.data[0].message,
            type: "success",
          });
        }
      });
    }
  };
  const uploadProgramParticipant = async () => {
    setPageCount(0);
    if (isBootCamp === "false") {
      await uploadParticipant(
        programLocal,
        fieldValue,
        setNotify,
        setIsSuccessUpload,
        selectTypeCheck
      );
      getParticipantsOfProgram(); 
    } else {
      await uploadBootCampParticipant(
        programLocal,
        fieldValue,
        setNotify,
        setIsSuccessUpload,
        selectTypeCheck,
        getParticipantsOfBootCamp
      );
     getParticipantsOfProgram(); 
      getParticipantsOfBootCamp();
    }
  };

  const handleMentorUpload = async () => {
    await API.AdminAddMentorparticipants(programLocal, fieldValueMentor).then(
      ({ response }) => {
        if (response.api_status === 200) {
          setPageCountMentor(0);
          getMentorOfProgram();
          setNotify({
            isOpen: true,
            message: "Uploaded Mentor successfully",
            type: "success",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Upload Failed",
            type: "error",
          });
        }
      }
    );
  };

  useEffect(() => {
    if (isSucccessUpload && selectedValue === 0) {
      getParticipantsOfProgram();
    } else if (isSucccessUpload && selectedValue === 1) {
      getParticipantsOfBootCamp();
    }
  }, [isSucccessUpload]);

  const createCourse = async () => {
    if (formik.values.duration !== 0) {
      if (isBootCamp === "false") {
        setloaderForPublishCourse(true);
        const id=localStorage.getItem("programId");
        await API.adminCreateProgram(id).then(
          async ({ response }) => {
            if (response.data.api_status === 200) {
              setloaderForPublishCourse(false);
              setTimeout(function () {
                navigate("/home/courses");
              }, 1000);
              setNotify({
                isOpen: true,
                message: response.data.data[0].message,
                type: "success",
              });
            } else {
              setloaderForPublishCourse(false);
              setNotify({
                isOpen: true,
                message: response.data.error[0].error_msg,
                type: "error",
              });
            }
          }
        );
      } else {
        await API.adminCreateBootCamp(programLocal).then(
          async ({ response }) => {
            if (response.data.api_status === 200) {
              setloaderForPublishCourse(false);
              setTimeout(function () {
                navigate("/home/courses");
              }, 1000);
              setNotify({
                isOpen: true,
                message: response.data.data[0].message,
                type: "success",
              });
            } else {
              setloaderForPublishCourse(false);
              setNotify({
                isOpen: true,
                message: response.data.error[0].error_msg,
                type: "error",
              });
            }
          }
        );
      }
    } else {
      setNotify({
        isOpen: true,
        message: "Please add the units for the course",
        type: "error",
      });
    }
  };

  const cancelProgram = async () => {
    navigate("/home/courses");
  };

  const handleTagChangs = (e) => {
    setUnitPageCount(0);
    setUnitSelectedTag(e.target.value);
  };
  const handleAddSingleParticipant = () => {
    setTraineeData({
    traineeFirstName: "",
    traineeLastName: "",
    traineeEmail: "",
    traineeDesignation: "",
    traineePhoneNumber: "",
    traineeCollege: "",
    });
    setEditParticipantPopup(true);
    setSingleParticipant(true);
  };

  const handleAddSingleMentor = () => {
    setMentorData({
      mentorPhoneNumber: "",
      mentorFirstName: "",
      mentorLastName: "",
      mentorEmail: "",
    });
    setSingleMentor(true);
    setEditMentorPopup(true);
  };
  const traineeDetailsValidation = () => {
    if (
      !traineeData.traineeCollege ||
      !traineeData.traineeDesignation ||
      !traineeData.traineeEmail ||
      !traineeData.traineeFirstName ||
      !traineeData.traineeLastName ||
      !traineeData.traineePhoneNumber
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleAddParticipant = async () => {
    const {
      traineeFirstName,
      traineeLastName,
      traineePhoneNumber,
      traineeEmail,
      traineeDesignation,
      traineeCollege,
    } = traineeData;
    const containsOnlyLetters = /^[a-zA-Z]+$/.test(traineeCollege);
    if (traineeDetailsValidation()) {
      return setNotify({
        isOpen: true,
        message: "Please Enter Mandatory Fields",
        type: "error",
      });
    } else if (!emailRegex.test(traineeEmail)) {
      return setNotify({
        isOpen: true,
        message: "Please Enter Valid Email",
        type: "error",
      });
    }
    else if(traineePhoneNumber.length<10){
      return setNotify({
        isOpen: true,
        message: "Please Enter Valid PhoneNumber",
        type: "error",
      });
    }

else if (!containsOnlyLetters) {
  return setNotify({
    isOpen: true,
    message: "Trainee College Must be Valid",
    type: "error",
  });
} 
    
  
    const response = await API.addSingleParticipant(
      traineeFirstName,
      traineeLastName,
      traineeEmail,
      traineePhoneNumber,
      traineeCollege,
      traineeDesignation,
      localStorage.getItem("programId")
    );
    if (response.response?.statusCode === 200) {
      getParticipantsOfBootCamp();
      setNotify({
        isOpen: true,
        message: response?.response?.message,
        type: "success",
      });
      setEditParticipantPopup(false);
      emptyTraineeData();
    } else {
      setNotify({
        isOpen: true,
        message: response?.response?.error[0].error_msg,
        type: "error",
      });
    }
  };
  const handleAddMentor = async () => {
    const {
      mentorFirstName,
      mentorLastName,
      mentorEmail,
      traineeEmail,
      mentorPhoneNumber,
    } = mentorData;
    if (
      !mentorFirstName ||
      !mentorLastName ||
      !mentorEmail ||
      !mentorPhoneNumber
    ) {
      return setNotify({
        isOpen: true,
        message: "Please Enter Mandatory Fields",
        type: "error",
      });
    } else if (
      !emailRegex.test(mentorEmail) ||
      !emailRegex.test(traineeEmail)
    ) {
      return setNotify({
        isOpen: true,
        message: "Please Enter Valid Email",
        type: "error",
      });
    }
    else if(mentorPhoneNumber.length<10){
      return setNotify({
        isOpen: true,
        message: "Please Enter Valid PhoneNumber",
        type: "error",
      });
    };
    const response = await API.addSingleMentor(
      mentorFirstName,
      mentorLastName,
      mentorEmail,
      traineeEmail,
      mentorPhoneNumber,
      localStorage.getItem("programId")
    );
    if (response.response.statusCode === 200) {
      getMentorOfProgram();
      setNotify({
        isOpen: true,
        message: response.response.message,
        type: "success",
      });
      setEditMentorPopup(false);
      emptyMentorData();
    } else {
      setNotify({
        isOpen: true,
        message: response?.response?.error[0].error_msg,
        type: "error",
      });
    }
  };
  const handleAddDetails = async () => {
    if (unitId && isEndTimeValid()) {
      setTrainerAddLoader(true);
      if (isBootCamp === "false") {
        const response = await API.updateProgramWithTrainer(
          Number(programLocal),
          moment(programDate).format().toString().slice(0, 10),
          formik.values.endDate,
          startTime,
          search.user_pk,
          unitId,
          courseIdInEdit,
          endTime
        );
        if (response.response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: response.response.data[0].message,
            type: "success",
          });
          setTrainerAddLoader(false);
          handleClose();
          handleGetProgram(programLocal);
        } else {
          setNotify({
            isOpen: true,
            message: response.response.error[0].error_msg,
            type: "error",
          });
          setTrainerAddLoader(false);
          handleClose();
        }
      } else {
        const response = await API.updateBootCampTrainer(
          Number(programLocal),
          moment(programDate).format().toString().slice(0, 10),
          startTime,
          search.user_pk,
          unitId,
          courseIdInEdit,
          endTime,
          programLocal,
          schedulepk
        );

        if (response.response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: response.response.data[0].message,
            type: "success",
          });
          setTrainerAddLoader(false);
          handleClose();
          handleGetProgram(programLocal);
        } else if (
          response.response.api_status === 422 &&
          response.response.error[0].error_msg ===
            "schedule_pk must be a number"
        ) {
          setNotify({
            isOpen: true,
            message: "Save your changes first!",
            type: "error",
          });
          setTrainerAddLoader(false);
        } else {
          setNotify({
            isOpen: true,
            message: response.response.error[0].error_msg,
            type: "error",
          });
          setTrainerAddLoader(false);
        }
      }
    }
  };

  const handleSelectType = (e) => {
    setSelectTypeCheck(e.target.value);
    if (e.target.value === 0) {
      getCategories(3,1000,1,1,"");
      createProgramValidationSchema.fields.technology =
        createProgramValidationSchema.fields.technology.required(
          "Technology is required"
        );
      createProgramValidationSchema.fields.TraningType =
        createProgramValidationSchema.fields.TraningType.required(
          "Training Type is required"
        );

      createProgramValidationSchema.fields.description =
        createProgramValidationSchema.fields.description.required(
          "Description is required"
        );
    } else {
      createProgramValidationSchema.fields.technology =
        createProgramValidationSchema.fields.technology.notRequired();
      createProgramValidationSchema.fields.TraningType =
        createProgramValidationSchema.fields.TraningType.notRequired();
      createProgramValidationSchema.fields.description =
        createProgramValidationSchema.fields.description.notRequired();
    }
    formik.setErrors({});

    setSelectedValue(e.target.value);
  };
  const validEmail = () => {
    const email = formik.values.trainerEmail;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    return isValidEmail;
  };
  const handleAddButton = () => {
    if (programTableData.length === 0) {
      if (selectTypeCheck === null) {
        return setNotify({
          isOpen: true,
          message: "Please Select Type",
          type: "error",
        });
      } else if (selectTypeCheck === 0) {
        if (
          !formik.values.programName ||
          !formik.values.description ||
          !formik.values.technology ||
          !formik.values.TraningType
        ) {
          return setNotify({
            isOpen: true,
            message: "Please Fill Mandatory Fields",
            type: "error",
          });
        } else if (formik.values.TraningType === "EXTERNAL") {
          if (!formik.values.trainerEmail) {
            return setNotify({
              isOpen: true,
              message: "Please Enter Trainer Email",
              type: "error",
            });
          } else if (!validEmail()) {
            return setNotify({
              isOpen: true,
              message: "Please Enter Valid Trainer Email",
              type: "error",
            });
          } else {
            setAddUnitDialog(true);
          }
        } else {
          setAddUnitDialog(true);
        }
      } else if (selectTypeCheck === 1) {
        if (!formik.values.programName || !formik.values.description) {
          return setNotify({
            isOpen: true,
            message: "Please Fill Mandatory Fields",
            type: "error",
          });
        } else {
          setAddUnitDialog(true);
        }
      } else if (selectTypeCheck === 2) {
        if (!formik.values.programName || !formik.values.description) {
          return setNotify({
            isOpen: true,
            message: "Please Fill Mandatory Fields",
            type: "error",
          });
        } else {
          setAddUnitDialog(true);
        }
      }
    } else {
      setAddUnitDialog(true);
    }
  };
  function isEndTimeValid() {
    if (!startTime || !endTime) {
      return true;
    }

    return endTime >= startTime;
  }
  useEffect(() => {
    setSelectType(DropdownValue);
  }, []);

  const handleUnitNavigate = async (id) => {
    await localStorage.setItem("UnitId", id);
    await localStorage.setItem("Screen", "/home/createProgram");
    navigate("/home/createunit");
  };

  let programCsvRows;

  if (selectedValue !== 0) {
    programCsvRows = participantsProgram?.rows?.map(
      (participant) =>
        `${participant.first_name || ""},${participant.last_name || ""},${
          participant.user_email || ""
        },${participant.phonenumber || ""},${participant.department || ""},${
          participant.trainee_colleage || ""
        }`
    );
  } else {
    programCsvRows = participantsProgram?.rows?.map(
      (participant) => `${participant.user_email || ""}`
    );
  }

  return (
    <>
      {done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <Box className="batch-wrapper">
            <Box className="batch-header">
              <Grid container spacing={2}>
                <Grid item xs={5.5}>
                  <Box>
                    <div>
                      <ReusableButton
                        onClick={() => navigate("/home/courses")}
                        size="small"
                        variant="contained"
                        buttonName="Back"
                        startIcon={<ArrowBackIosIcon />}
                        style={SxStyles.createCourseStyle.backBtn}
                      />
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3.5}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginRight: "",
                  }}
                >
                  {programLocal > 0 ? (
                    <span
                      style={SxStyles.createCourseStyle.mainCreateCourseSpan}
                    >
                      Edit{" "}
                      {selectedValue === 1
                        ? "Bootcamp"
                        : selectedValue === 0
                        ? "Program"
                        : "Hackathon"}
                    </span>
                  ) : (
                    <span
                      style={SxStyles.createCourseStyle.mainCreateCourseSpan}
                    >
                      {selectedValue !== "" ? "create " : ""}
                      {(selectedValue === 1 && "Bootcamp") ||
                        (selectedValue === 0 && "Program") ||
                        (selectedValue === 2 && "Hackathon")}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Container
              component="main"
              maxWidth="xxl"
              style={{
                padding: 0,
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Card
                  variant="outlined"
                  sx={SxStyles.createCourseStyle.mainContainerCard}
                >
                  <Box>
                    {Object.keys(courseData).length > 0 ? (
                      <div style={SxStyles.createCourseStyle.createCourseDiv}>
                        {courseData.data.course_name}
                      </div>
                    ) : (
                      <div
                        style={{
                          ...SxStyles.createCourseStyle.createCourseDiv,
                          textTransform: "capitalize",
                        }}
                      >
                        {programLocal
                          ? formik.values.programName
                          : "Create Training Program"}
                      </div>
                    )}
                    <Grid
                      container
                      spacing={1.5}
                      style={SxStyles.createCourseStyle.mainGrid}
                    >
                      <Grid xs={12} sx={{ padding: "0px 10px" }}>
                        <Grid
                          item
                          xs={3}
                          // style={{ marginRight: "34px" }}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <InputLabel
                            className="InputLabel"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Select Type
                            <span style={styles.madatoryMark}>*</span>
                          </InputLabel>
                          <Custom.ReusableDropdown
                            disabled={programLocal > 0 ? true : false}
                            name="selectType"
                            id="selectType"
                            placeholder="Select Type"
                            displayEmpty
                            options={selectType}
                            onChange={(e) => {
                              handleSelectType(e);
                            }}
                            value={selectedValue}
                            className="batchmuipopober"
                            size="small"
                            style={{
                              height: 40,
                              fontFamily: "Poppins",
                            }}
                          ></Custom.ReusableDropdown>
                        </Grid>
                      </Grid>
                    </Grid>

                    {selectedValue === 0 ? (
                      <Grid container spacing={2} sx={{ padding: "0px 40px" }}>
                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Create Name
                          </span>
                          <ReusableInputfield
                            name="programName"
                            id="programName"
                            disabled={disabled}
                            helperText={
                              formik.touched.programName &&
                              formik.errors.programName
                            }
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                            }}
                            size="small"
                            placeholder="Program Name"
                            value={formik.values.programName}
                            onChange={formik.handleChange}
                            variant="outlined"
                            error={
                              formik.touched.programName &&
                              Boolean(formik.errors.programName)
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Categories
                          </span>

                          <Custom.ReusableDropdown
                            // disabled={IsBootVisible}
                            disabled={disabled}
                            name="technology"
                            id="technology"
                            displayEmpty
                            placeholder="Select Technology"
                            options={categories}
                            onChange={(e)=>{
                              formik.handleChange(e);
                              // setCategoryId(e.target.value);
                            }}
                            value={formik.values.technology}
                            className="batchmuipopober"
                            size="small"
                            style={{
                              height: 42,
                              fontFamily: "Poppins",
                            }}
                            error={
                              formik.touched.technology &&
                              Boolean(formik.errors.technology)
                            }
                            helperText={
                              formik.touched.technology &&
                              formik.errors.technology
                            }
                          ></Custom.ReusableDropdown>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Start Date
                          </span>
                          <Custom.DatePicker
                            name="startDate"
                            id="startDate"
                            value={formik.values.startDate}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.startDate &&
                              Boolean(formik.errors.startDate)
                            }
                            helperText={
                              formik.touched.startDate &&
                              formik.errors.startDate
                            }
                            disabled={disabled}
                            size="small"
                            placeholder="YYYY/MM/DD"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            End Date
                          </span>
                          <Custom.DatePicker
                            name="endDate"
                            id="endDate"
                            value={formik.values.endDate}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.endDate &&
                              Boolean(formik.errors.endDate)
                            }
                            helperText={
                              formik.touched.endDate && formik.errors.endDate
                            }
                            disabled={disabled}
                            minDate={formik.values.startDate}
                            size="small"
                            placeholder="YYYY/MM/DD"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Duration
                          </span>
                          <ReusableInputfield
                            name="duration"
                            id="duration"
                            placeholder="Duration"
                            value={formik.values.duration}
                            size="small"
                            style={st}
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                              readOnly: true,
                              disabled:true,
                              disableUnderline: true,
                            }}
                            variant="outlined"
                            disabled={IsBootVisible}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.duration &&
                              Boolean(formik.errors.duration)
                            }
                            helperText={
                              formik.touched.duration && formik.errors.duration
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Training Type
                          </span>
                          <Custom.ReusableDropdown
                            disabled={disabled}
                            name="TraningType"
                            id="TraningType"
                            displayEmpty
                            placeholder="Select Traning Type"
                            options={TraningType}
                            onChange={formik.handleChange}
                            value={formik.values.TraningType}
                            className="batchmuipopober"
                            size="small"
                            style={{
                              height: 42,
                              fontFamily: "Poppins",
                            }}
                            error={
                              formik.touched.TraningType &&
                              Boolean(formik.errors.TraningType)
                            }
                            helperText={
                              formik.touched.TraningType &&
                              formik.errors.TraningType
                            }
                          ></Custom.ReusableDropdown>
                        </Grid>
                      </Grid>
                    ) : selectedValue === 1 || selectedValue === 2 ? (
                      <Grid container spacing={2} sx={{ padding: "0px 40px" }}>
                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Create Name
                          </span>
                          <ReusableInputfield
                            name="programName"
                            id="programName"
                            disabled={disabled}
                            helperText={
                              formik.touched.programName &&
                              formik.errors.programName
                            }
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                            }}
                            size="small"
                            placeholder={
                              selectedValue === 2
                                ? "Hackathon Name"
                                : "Bootcamp Name"
                            }
                            value={formik.values.programName}
                            onChange={formik.handleChange}
                            variant="outlined"
                            error={
                              formik.touched.programName &&
                              Boolean(formik.errors.programName)
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Start Date
                          </span>
                          <Custom.DatePicker
                            name="startDate"
                            id="startDate"
                            value={formik.values.startDate}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.startDate &&
                              Boolean(formik.errors.startDate)
                            }
                            helperText={
                              formik.touched.startDate &&
                              formik.errors.startDate
                            }
                            disabled={disabled}
                            size="small"
                            placeholder="YYYY/MM/DD"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            End Date
                          </span>
                          <Custom.DatePicker
                            name="endDate"
                            id="endDate"
                            value={formik.values.endDate}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.endDate &&
                              Boolean(formik.errors.endDate)
                            }
                            helperText={
                              formik.touched.endDate && formik.errors.endDate
                            }
                            disabled={disabled}
                            minDate={BootCampDate ? BootCampDate : formik.values.startDate}
                            size="small"
                            placeholder="YYYY/MM/DD"
                          />
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik.errors.endDate}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Duration
                          </span>
                          <ReusableInputfield
                            name="duration"
                            id="duration"
                            placeholder="Duration"
                            value={formik.values.duration}
                            size="small"
                            style={st}
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                              readOnly: true,
                              disabled: true,
                              disableUnderline: true,
                            }}
                            variant="outlined"
                            disabled={IsBootVisible}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.duration &&
                              Boolean(formik.errors.duration)
                            }
                            helperText={
                              formik.touched.duration && formik.errors.duration
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <h1
                            className="required"
                            style={SxStyles.createCourseStyle.aboutCourseh1}
                          >
                            About { selectedValue === 2 ? "Hackathon" : "Bootcamp"}
                          </h1>
                          <ReusableInputfield
                            rows={4}
                            displayEmpty
                            style={{ width: "100%" }}
                            className="description_in_course"
                            name="description"
                            id="description"
                            disabled={disabled}
                            placeholder={`About ${ selectedValue === 2 ? "Hackathon" : "Bootcamp"}`}
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            multiline
                            size="medium"
                            error={
                              formik.touched.description &&
                              Boolean(formik.errors.description)
                            }
                            helperText={
                              formik.touched.description &&
                              formik.errors.description
                            }
                            InputProps={{
                              inputComponent: TextareaAutosize,
                              inputProps: {
                                style: {
                                  resize: "auto",
                                  height: "100%",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : null}

                    <Grid
                      container
                      spacing={1.5}
                      style={SxStyles.createCourseStyle.trainingGrid}
                    >
                      {formik.values.TraningType === TraningType[0].value ? (
                        <Grid
                          item
                          xs={3}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Trainer Email Address
                          </span>
                          <ReusableInputfield
                            name="trainerEmail"
                            id="trainerEmail"
                            disabled={disabled}
                            helperText={
                              formik.touched.trainerEmail &&
                              formik.errors.trainerEmail
                            }
                            InputProps={{
                              autoComplete: "off",
                              inputProps: {
                                style: { padding: "9px 14px" },
                              },
                            }}
                            placeholder="Trainer Email ID"
                            value={formik.values.trainerEmail}
                            onChange={formik.handleChange}
                            size="small"
                            variant="outlined"
                            error={
                              formik.touched.trainerEmail &&
                              Boolean(formik.errors.trainerEmail)
                            }
                          />
                        </Grid>
                      ) : null}
                      <Grid
                        item
                        xs={3.2}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      ></Grid>
                      <Grid
                        item
                        xs={3.4}
                        style={SxStyles.createCourseStyle.createCourseGrid}
                      ></Grid>
                    </Grid>

                    {selectedValue === 0 ? (
                      <Box style={SxStyles.createCourseStyle.aboutCourseBox}>
                        <h1
                          className="required"
                          style={SxStyles.createCourseStyle.aboutCourseh1}
                        >
                          About Program
                        </h1>
                        <ReusableInputfield
                          rows={4}
                          displayEmpty
                          style={{ width: "100%" }}
                          className="description_in_course"
                          name="description"
                          id="description"
                          disabled={disabled}
                          onChange={formik.handleChange}
                          value={formik.values.description}
                          placeholder="About Program"
                          multiline
                          size="medium"
                          error={
                            formik.touched.description &&
                            Boolean(formik.errors.description)
                          }
                          helperText={
                            formik.touched.description &&
                            formik.errors.description
                          }
                          InputProps={{
                            inputComponent: TextareaAutosize,
                            inputProps: {
                              style: {
                                resize: "auto",
                                height: "100%",
                              },
                            },
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "1.4%",
                    }}
                  >
                    <ReusableButton
                      className="addbutton"
                      size="large"
                      variant="contained"
                      onClick={handleAddButton}
                      disabled={disabled || value !== 0}
                      buttonName="Add"
                      style={{
                        backgroundColor:
                          disabled || value !== 0 ? "grey" : "#fb2485",
                        marginBottom: "1%",
                        fontSize: "18px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAalign: "center",
                        color: "#fff",
                        padding: "8px 30px",
                        borderRadius: "4px",
                        textTransform: "none",
                        marginTop: "1%",
                      }}
                    >
                      Add
                    </ReusableButton>
                  </div>

                  <Box styles={{ padding: "0px 20px" }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        width: "93%",
                        margin: "0px auto",
                      }}
                    >
                      <Tabs value={value} onChange={handleChange}>
                        <Tab
                          label="Courses/Units"
                          {...a11yProps(0)}
                          style={{ marginRight: "10px" }}
                        />
                        <Tab
                          label="Participants"
                          {...a11yProps(1)}
                          style={{ marginRight: "10px" }}
                        />
                        <Tab
                          label="Mentors"
                          {...a11yProps(2)}
                          style={{ marginRight: "10px" }}
                        />
                      </Tabs>
                    </Box>

                    {value === 0 ? (
                      <>
                        {programTableData.length !== 0 ? (
                          <>
                            <Box
                              style={{
                                marginTop: "20px",
                                padding: "14px 3.2%",
                                width: "100%",
                              }}
                            >
                              <CollapsibleTable
                                rows={programTableData}
                                handleOpenModal={handleOpenModal}
                                disabled={disabled}
                                handleDeleteModal={handleDeleteModal}
                                setUnitId={setUnitId}
                                programLocal={programLocal}
                                editedDetails={editedDetails}
                                setEditedDetails={setEditedDetails}
                                SelectType={selectedValue}
                                handleUnitNavigate={handleUnitNavigate}
                              />
                            </Box>
                          </>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "150px",
                              backgroundColor: "#f5f5f5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "800",
                              fontSize: "18px",
                            }}
                          >
                            <p>No Data Found.</p>
                          </div>
                        )}
                      </>
                    ) : null}

                    {value === 1 ? (
                      <>
                        <div
                          style={{
                            width: "98%",
                            height: "100%",
                            padding: "0px 20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "2%",
                              marginLeft: "2.5%",
                              marginTop: "2%",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#000",
                                marginLeft: "-7px",
                                marginBottom: "10px",
                              }}
                              variant="h6"
                            >
                              Upload Program Participants.
                            </Typography>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span style={{ marginLeft: "-7px" }}>
                                  <input
                                    name="file"
                                    id="file"
                                    type="file"
                                    disabled={
                                      localStorage.getItem("programId")
                                        ? false
                                        : true
                                    }
                                    class="upload-box"
                                    accept={SheetJSFT}
                                    style={{
                                      fontSize: "0.8em",
                                      fontFamily: "Poppins",
                                      height: "41px",
                                      opacity: localStorage.getItem("programId")
                                        ? 1
                                        : 0.5,
                                    }}
                                    ref={inputRef}
                                    onChange={(e) =>
                                      handleFileChange(e, setFieldValue)
                                    }
                                  />
                                </span>
                                <Typography style={styles.note} variant="p">
                                  Only .csv format allowed &nbsp;
                                </Typography>
                              </div>
                              <ReusableButton
                                className="addbutton"
                                size="large"
                                variant="contained"
                                onClick={() => uploadProgramParticipant()}
                                buttonName="Upload CSV"
                                style={{
                                  backgroundColor: localStorage.getItem(
                                    "programId"
                                  )
                                    ? "#fb2485"
                                    : "grey",
                                  fontSize: "18px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAalign: "center",
                                  color: "#fff",
                                  padding: "0px 15px",
                                  borderRadius: "4px",
                                  textTransform: "none",
                                  height: "40px",
                                  width: "10vw",
                                }}
                              />

                              <ReusableButton
                                className="addbutton"
                                size="large"
                                buttonName="Add Participant"
                                variant="contained"
                                onClick={() => {
                                  handleAddSingleParticipant();
                                }}
                                style={{
                                  ...styles.createBtn,
                                  height: "40px",
                                  width: "13vw",
                                }}
                              ></ReusableButton>

                              <CSVLink
                                style={{
                                  color: "#1976d2",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                {...partcipantsSheets}
                              >
                                <DownloadIcon
                                  color="primary"
                                  fontSize="medium"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />
                                Download Sample CSV
                              </CSVLink>
                            </div>
                          </div>

                          {participantsProgram?.rows?.length ? (
                            <div>
                              <ReusableButton
                                disabled={selectAll.length === 0}
                                style={
                                  selectAll.length
                                    ? {
                                        ...styles.saveForLaterBtn,
                                        marginBottom: "8px",
                                        cursor: "pointer",
                                        backgroundColor: "#fb2485",
                                        marginLeft: "2%",
                                      }
                                    : {
                                        ...styles.saveForLaterBtn,
                                        marginBottom: "8px",
                                        backgroundColor: "grey",
                                        marginLeft: "2%",
                                      }
                                }
                                onClick={() => setOpenDelete(true)}
                                buttonName="Delete All"
                              />
                              <DataGrid
                                style={{
                                  border:
                                    "1px solid rgb(189 197 206) !important",
                                  width: "98%",
                                  margin: "0px 2%",
                                }}
                                columns={columns}
                                rows={newParticipant}
                                hideFooter
                                autoHeight
                                pageSize={10}
                                checkboxSelection={true}
                                onSelectionModelChange={onSelectionModelChange}
                              />
                              {(isBootCamp==="false" ? pageLimitProgram : pageLimit) > 10
                               ? (
                                <Box>
                                  <ReactPaginate
                                    previousLabel={<>&laquo;</>}
                                    nextLabel={<>&raquo;</>}
                                    pageCount={Math.ceil(
                                      (isBootCamp==="false" ? pageLimitProgram : pageLimit) / 10
                                    )}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={
                                      "pagination__link--disabled"
                                    }
                                    activeClassName={"pagination__link--active"}
                                    forcePage={pageCount}
                                  />
                                </Box>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}

                    {value === 2 ? (
                      <div
                        style={{
                          width: "98%",
                          height: "100%",
                          padding: "0px 20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "2%",
                            marginLeft: "2.5%",
                            marginTop: "2%",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#000",
                              marginLeft: "-7px",
                              marginBottom: "10px",
                            }}
                            variant="h6"
                          >
                            Upload Mentor
                          </Typography>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span style={{ marginLeft: "-7px" }}>
                                <input
                                  name="file"
                                  id="file"
                                  type="file"
                                  disabled={
                                    localStorage.getItem("programId")
                                      ? false
                                      : true
                                  }
                                  class="upload-box"
                                  accept={SheetJSFT}
                                  style={{
                                    fontSize: "0.8em",
                                    fontFamily: "Poppins",
                                    height: "41px",
                                    opacity: localStorage.getItem("programId")
                                      ? 1
                                      : 0.5,
                                  }}
                                  ref={inputRef}
                                  onChange={(e) =>
                                    handleFileChangeMentor(
                                      e,
                                      setFieldValueMentor
                                    )
                                  }
                                />
                              </span>
                              <Typography style={styles.note} variant="p">
                                Only .csv format allowed &nbsp;
                              </Typography>
                            </div>
                            <ReusableButton
                              className="addbutton"
                              size="large"
                              variant="contained"
                              onClick={() => handleMentorUpload()}
                              buttonName="Upload CSV"
                              style={{
                                backgroundColor: localStorage.getItem(
                                  "programId"
                                )
                                  ? "#fb2485"
                                  : "grey",
                                // marginTop: "0.7%",
                                fontSize: "18px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAalign: "center",
                                color: "#fff",
                                padding: "0px 15px",
                                borderRadius: "4px",
                                textTransform: "none",
                                height: "40px",
                                width: "10vw",
                              }}
                            />
                            <ReusableButton
                              className="addbutton"
                              size="large"
                              buttonName="Add Mentor"
                              variant="contained"
                              onClick={() => {
                                handleAddSingleMentor();
                              }}
                              style={{
                                ...styles.createBtn,
                                height: "40px",
                                width: "11vw",
                              }}
                            ></ReusableButton>

                            <CSVLink
                              style={{
                                color: "#1976d2",
                                display: "flex",
                                alignItems: "center",
                              }}
                              {...mentorSampleCsv}
                            >
                              <DownloadIcon
                                color="primary"
                                fontSize="medium"
                                style={{
                                  marginRight: "5px",
                                }}
                              />
                              Download Sample CSV
                            </CSVLink>
                          </div>
                        </div>

                        {mentorProgram?.rows?.length ? (
                          <div>
                            <ReusableButton
                              disabled={selectAll.length === 0}
                              style={
                                selectAll.length
                                  ? {
                                      ...styles.saveForLaterBtn,
                                      marginBottom: "8px",
                                      cursor: "pointer",
                                      backgroundColor: "#fb2485",
                                      marginLeft: "2%",
                                    }
                                  : {
                                      ...styles.saveForLaterBtn,
                                      marginBottom: "8px",
                                      backgroundColor: "grey",
                                      marginLeft: "2%",
                                    }
                              }
                              onClick={() => setOpenDelete(true)}
                              buttonName="Delete All"
                            />
                            <DataGrid
                              style={{
                                border: "1px solid rgb(189 197 206) !important",
                                width: "98%",
                                margin: "0px 2%",
                              }}
                              columns={Mentorscolumns}
                              rows={newMentorList}
                              hideFooter
                              autoHeight
                              pageSize={10}
                              checkboxSelection={true}
                              onSelectionModelChange={onSelectionModelChange}
                            />
                            {pageLimitMentor > 10 ? (
                              <Box>
                                <ReactPaginate
                                  previousLabel={<>&laquo;</>}
                                  nextLabel={<>&raquo;</>}
                                  pageCount={Math.ceil(
                                    pageLimitMentor/ 10
                                  )}
                                  onPageChange={handlePageClickMentor}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"pagination__link"}
                                  nextLinkClassName={"pagination__link"}
                                  disabledClassName={
                                    "pagination__link--disabled"
                                  }
                                  activeClassName={"pagination__link--active"}
                                  forcePage={pageCountMentor}
                                />
                              </Box>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>{programName}</DialogTitle>
                      <DialogContent sx={{ overflow: "hidden" }}>
                        <Grid
                          container
                          spacing={1.5}
                          style={SxStyles.createCourseStyle.mainGrid}
                        >
                          <Grid
                            item
                            xs={6}
                            style={SxStyles.createCourseStyle.createCourseGrid}
                          >
                            <span
                              className="required"
                              style={
                                SxStyles.createCourseStyle.createCourseSpan
                              }
                            >
                              Select Date
                            </span>
                            <Custom.DatePicker
                              name="programDate"
                              id="programDate"
                              style={{
                                width: "19vw",
                                marginTop: "3px",
                                height: "6.5vh",
                              }}
                              value={programDate}
                              onChange={(e) => setProgramDate(e.target.value)}
                              disabled={disabled}
                              size="small"
                              placeholder="YYYY/MM/DD"
                              minDate={formik.values.startDate}
                              maxDate={formik.values.endDate}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "0.5%",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              className="required"
                              style={
                                SxStyles.createCourseStyle.createCourseSpan
                              }
                            >
                              Start Time
                            </span>
                            <input
                              type="time"
                              style={{
                                height: "6.5vh",
                                width: "19vw",
                                marginTop: "1px",
                                border: "0.5px solid  #B0B0B0",
                                borderRadius: 4,
                                fontFamily: "poppins",
                                fontSize: 14,
                                paddingLeft: "12px",
                              }}
                              name="toTimeOfQuiz"
                              value={startTime}
                              onChange={(e) => setStartTime(e.target.value)}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            style={SxStyles.createCourseStyle.createCourseGrid}
                          >
                            <span
                              style={
                                SxStyles.createCourseStyle.createCourseSpan
                              }
                            >
                              End Time
                            </span>
                            <input
                              type="time"
                              style={{
                                height: "6.5vh",
                                width: "19vw",
                                border: "0.5px solid  #B0B0B0",
                                borderRadius: 4,
                                fontFamily: "poppins",
                                fontSize: 14,
                                paddingLeft: "12px",
                              }}
                              name="toTimeOfQuiz"
                              value={endTime}
                              onChange={(e) => {
                                setEndTime(e.target.value);
                              }}
                            />
                            {!isEndTimeValid() && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                End time should not be less than start time.
                              </span>
                            )}
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            style={SxStyles.createCourseStyle.createCourseGrid}
                          >
                            <span
                              style={
                                SxStyles.createCourseStyle.createCourseSpan
                              }
                            >
                              Select Trainer
                            </span>
                            <Autocomplete
                              id="Tag"
                              name="Tag"
                              options={trainers}
                              getOptionLabel={(option) =>
                                option.first_name !== null ||
                                (option.first_name !== undefined &&
                                  option.last_name !== null) ||
                                option.last_name !== undefined
                                  ? `${option.first_name} ${option.last_name}`
                                  : option.first_name !== null &&
                                    option.first_name !== undefined
                                  ? option.first_name
                                  : option.user_email
                              }
                              clearIcon={false}
                              sx={{
                                width: "19vw",
                                height: "48px",
                              }}
                              size="small"
                              value={selectedTrainer}
                              onChange={(e, newValue) => {
                                setSearch(newValue);
                                setSelectedTrainer(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  style={{
                                    height: "49px !important",
                                    fontSize: "14px",
                                    paddingBottom: "10px",
                                  }}
                                  placeholder="Select Trainer"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <ReusableButton
                          size="large"
                          variant="outlined"
                          buttonName="Cancel"
                          onClick={() => {
                            handleClose();
                          }}
                          style={SxStyles.createCourseStyle.cancelButton}
                          disabled={
                            loaderForPublishCourse || loaderForSaveLaterCourse
                              ? true
                              : false
                          }
                        >
                          Cancel
                        </ReusableButton>
                        <ReusableButton
                          className="addbutton"
                          size="large"
                          variant="contained"
                          onClick={() => {
                            handleAddDetails();
                          }}
                          endIcon={
                            !trainerAddLoader ? (
                              ""
                            ) : (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            )
                          }
                          buttonName={!trainerAddLoader ? "Add" : "Adding"}
                          style={
                            !trainerAddLoader
                              ? styles.createBtn
                              : styles.createBtnDisabled
                          }
                          disabled={trainerAddLoader ? true : false}
                        >
                          Add
                        </ReusableButton>
                      </DialogActions>
                    </Dialog>
                  </Box>

                  <div style={{ marginTop: "2%" }}>
                    <div style={SxStyles.createCourseStyle.addCancelDiv}>
                      <ReusableButton
                        size="large"
                        variant="outlined"
                        buttonName="Cancel"
                        onClick={cancelProgram}
                        style={SxStyles.createCourseStyle.cancelButton}
                        disabled={
                          loaderForPublishCourse || loaderForSaveLaterCourse
                            ? true
                            : false
                        }
                      >
                        Cancel
                      </ReusableButton>
                      {disabled ? (
                        <ReusableButton
                          size="large"
                          variant="outlined"
                          buttonName="Edit"
                          style={SxStyles.styles.saveForLaterBtn}
                          onClick={editPage}
                          disabled={loaderForPublishCourse ? true : false}
                        />
                      ) : (
                        <ReusableButton
                          size="large"
                          variant="outlined"
                          onClick={formik.handleSubmit}
                          endIcon={
                            !loaderForSaveLaterCourse ? (
                              ""
                            ) : (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            )
                          }
                          buttonName={
                            !loaderForSaveLaterCourse
                              ? "Save for Later"
                              : "Saving"
                          }
                          style={
                            !loaderForPublishCourse
                              ? styles.saveForLaterBtn
                              : styles.saveForLaterBtnDisabled
                          }
                          disabled={loaderForPublishCourse ? true : false}
                        />
                      )}

                      {!isCreate ? (
                        <ReusableButton
                          onClick={createCourse}
                          size="large"
                          variant="contained"
                          endIcon={
                            !loaderForPublishCourse ? (
                              ""
                            ) : (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            )
                          }
                          buttonName={
                            !loaderForPublishCourse ? "Publish" : "Publishing"
                          }
                          style={
                            !loaderForSaveLaterCourse
                              ? styles.createBtn
                              : styles.createBtnDisabled
                          }
                          disabled={loaderForSaveLaterCourse ? true : false}
                        />
                      ) : (
                        <ReusableButton
                          size="large"
                          variant="contained"
                          endIcon={
                            !loaderForPublishCourse ? (
                              ""
                            ) : (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            )
                          }
                          buttonName={
                            !loaderForPublishCourse ? "Create" : "Creating"
                          }
                          style={
                            !loaderForSaveLaterCourse
                              ? styles.createBtn
                              : styles.createBtnDisabled
                          }
                          disabled={loaderForSaveLaterCourse ? true : false}
                        ></ReusableButton>
                      )}
                    </div>
                  </div>
                </Card>
                <AddCourseUnitModal
                  isOpen={addUnitDialog}
                  setIsOpen={setAddUnitDialog}
                  handleTagChangs={handleTagChangs}
                  programSelectedUnit={selectedUnits}
                  programSelectedCourses={selectedCourse}
                  programSetSelectedCourses={setSelectedCourse}
                  programSetSelectedUnit={setSelectedUnits}
                  editBootCamp={editBootCamp}
                />
              </form>
            </Container>
            <Dialog
              open={openModal}
              className="dialogBox"
              keepMounted
              onClose={() => {
                setOpenModal(!openModal);
              }}
              aria-describedby="alert-dialog-slide-description"
              sx={SxStyles.DialogBoxStyle}
            >
              <DialogTitle></DialogTitle>

              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  Are you sure you want to delete ?
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Cancel"
                  style={SxStyles.styles.cancelButton}
                  onClick={() => {
                    setOpenModal(!openModal);
                  }}
                />
                <ReusableButton
                  size="large"
                  variant="contained"
                  className="candidateDltepageDeleteBttn"
                  buttonName="Delete"
                  style={styles.createBtn}
                  onClick={() =>
                    handleProgramUnitorCourseDelete(
                      programLocal,
                      params.CourseId ? params.CourseId : null,
                      params.id
                    )
                  }
                >
                  Delete
                </ReusableButton>
              </DialogActions>
            </Dialog>
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          <Dialog
            open={openParticipantModal}
            className="dialogBox"
            keepMounted
            onClose={handleCloseByCancelParticipantModal}
            aria-describedby="alert-dialog-slide-description"
            sx={SxStyles.DialogBoxStyle}
          >
            <DialogTitle></DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                style={{ color: "#000" }}
              >
                Are you sure you want to delete ?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <ReusableButton
                size="large"
                variant="outlined"
                className="candidateDltepageCancelBttn"
                buttonName="Cancel"
                style={SxStyles.styles.cancelButton}
                onClick={handleCloseByCancelParticipantModal}
              />
              <ReusableButton
                size="large"
                variant="contained"
                className="candidateDltepageDeleteBttn"
                buttonName="Delete"
                style={styles.createBtn}
                onClick={() =>
                  handleDeleteParticipant(selectId, parseInt(programLocal))
                }
              >
                Delete
              </ReusableButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={view}
            className="dialogBox"
            keepMounted
            onClose={handleCloseViewPopUp}
            aria-describedby="alert-dialog-slide-description"
            sx={SxStyles.DialogBoxStyle}
          >
            <div style={{ width: "500px" }}>
              <DialogTitle>
                <h2>Trainee Details:</h2>
              </DialogTitle>

              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>
                      Trainee Name:<span></span>
                    </h4>
                    <p style={SxStyles.paragraphStyle}>
                      {(participantDetails[0]?.first_name !== ("" || null)
                        ? participantDetails[0]?.first_name
                        : "") +
                        " " +
                        (participantDetails[0]?.last_name !== ("" || null)
                          ? participantDetails[0]?.last_name
                          : "")}
                    </p>
                  </div>
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>
                      Trainee Email:<span></span>
                    </h4>
                    <p style={SxStyles.paragraphStyle}>
                      {participantDetails[0]?.user_email !== ""
                        ? participantDetails[0]?.user_email
                        : "N/A"}
                    </p>
                  </div>

                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>
                      Trainee Department:<span></span>
                    </h4>
                    <p style={SxStyles.paragraphStyle}>
                      {participantDetails[0]?.department !== ""
                        ? participantDetails[0]?.department
                        : "N/A"}
                    </p>
                  </div>
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>
                      Trainee Phonenumber:<span></span>
                    </h4>
                    <p style={SxStyles.paragraphStyle}>
                      {participantDetails[0]?.phonenumber !== ""
                        ? participantDetails[0]?.phonenumber
                        : "N/A"}
                    </p>
                  </div>
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>
                      Trainee College:<span></span>
                    </h4>
                    <p style={SxStyles.paragraphStyle}>
                      {participantDetails[0]?.trainee_colleage !== ""
                        ? participantDetails[0]?.trainee_colleage
                        : "N/A"}
                    </p>
                  </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Close"
                  style={SxStyles.styles.cancelButton}
                  onClick={handleCloseViewPopUp}
                />
              </DialogActions>
            </div>
          </Dialog>
          <Dialog
            open={mentorView}
            className="dialogBox"
            keepMounted
            onClose={handleCloseMentorPopup}
            aria-describedby="alert-dialog-slide-description"
            sx={SxStyles.DialogBoxStyle}
          >
            <div style={{ width: "500px" }}>
              <DialogTitle>
                <h2>Mentor Details:</h2>
              </DialogTitle>

              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>Mentor Name:</h4>
                    <p style={SxStyles.paragraphStyle}>
                      {mentorDetails[0]?.traineeDetails?.mentorDetails
                        ?.first_name !== null
                        ? mentorDetails[0]?.traineeDetails?.mentorDetails
                            ?.first_name
                        : "" +
                          " " +
                          (mentorDetails[0]?.traineeDetails?.mentorDetails
                            ?.last_name !==
                            null)
                        ? mentorDetails[0]?.traineeDetails?.mentorDetails
                            ?.last_name
                        : ""}
                    </p>
                  </div>
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>Mentor Email:</h4>
                    <p style={SxStyles.paragraphStyle}>
                      {mentorDetails[0]?.traineeDetails?.mentorDetails
                        ?.user_email !== ""
                        ? mentorDetails[0]?.traineeDetails?.mentorDetails
                            ?.user_email
                        : "N/A"}
                    </p>
                  </div>
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>Mentor Phonenumber:</h4>
                    <p style={SxStyles.paragraphStyle}>
                      {mentorDetails[0]?.traineeDetails?.mentorDetails?.phonenumber !== ""
                        ? mentorDetails[0]?.traineeDetails?.mentorDetails?.phonenumber
                        : "N/A"}
                    </p>
                  </div>
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>Trainee Name:</h4>
                    <p style={SxStyles.paragraphStyle}>
                      {(mentorDetails[0]?.first_name !== null
                        ? mentorDetails[0]?.first_name
                        : "") +
                        " " +
                        (mentorDetails[0]?.last_name !== ("" || null)
                          ? mentorDetails[0]?.last_name
                          : "")}
                    </p>
                  </div>
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>Trainee Email:</h4>
                    <p style={SxStyles.paragraphStyle}>
                      {mentorDetails[0]?.user_email !== ("" || null)
                        ? mentorDetails[0]?.user_email
                        : "N/A"}
                    </p>
                  </div>
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>Trainee Department:</h4>
                    <p style={SxStyles.paragraphStyle}>
                      {mentorDetails[0]?.department !== ""
                        ? mentorDetails[0]?.department
                        : "N/A"}
                    </p>
                  </div>
                  <div style={SxStyles.containerStyle}>
                    <h4 style={SxStyles.headingStyle}>Trainee College:</h4>
                    <p style={SxStyles.paragraphStyle}>
                      {mentorDetails[0]?.trainee_colleage !== ""
                        ? mentorDetails[0]?.trainee_colleage
                        : "N/A"}
                    </p>
                  </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Close"
                  style={SxStyles.styles.cancelButton}
                  onClick={handleCloseMentorPopup}
                />
              </DialogActions>
            </div>
          </Dialog>
          <Dialog
            open={editParticipantPopup}
            className="dialogBox"
            keepMounted
            onClose={handleCloseEditPartcipantPopup}
            aria-describedby="alert-dialog-slide-description"
            sx={SxStyles.DialogBoxStyle}
            maxWidth="md"
          >
            <div>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000", padding: "20px" }}
                >
                  <div>
                    <form onSubmit={handleSubmitParticipant}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <Typography
                            variant="h6"
                            style={{ fontSize: "20px", fontWeight: "bolder" }}
                          >
                            {singleParticipant
                              ? "Add Single Participant/Trainee:"
                              : "Edit Trainee Information:"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            First Name
                          </span>
                          <ReusableInputfield
                            name="traineeFirstName"
                            placeholder="First Name"
                            value={traineeData.traineeFirstName}
                            onChange={(event) => handleInputChange(event)}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Last Name
                          </span>
                          <ReusableInputfield
                            name="traineeLastName"
                            placeholder="Last Name"
                            value={traineeData.traineeLastName}
                            onChange={handleInputChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Email
                          </span>
                          <ReusableInputfield
                            name="traineeEmail"
                            placeholder="Email"
                            value={traineeData.traineeEmail}
                            onChange={handleInputChange}
                            variant="outlined"
                            disabled={singleParticipant ? false : true}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Designation
                          </span>
                          <ReusableInputfield
                            name="traineeDesignation"
                            placeholder="Designation"
                            value={traineeData.traineeDesignation}
                            onChange={handleInputChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Phonenumber
                          </span>
                          <span>
                            <Custom.Phone
                              className={classes.phoneW}
                              name="traineePhoneNumber"
                              label="0000000000"
                              value={traineeData.traineePhoneNumber}
                              // width="240"
                              onChange={handleChangeNumber}
                              // error={errors.phone}
                              fullWidth={false}
                              size="small"
                            />
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            College
                          </span>
                          <ReusableInputfield
                            name="traineeCollege"
                            placeholder="College"
                            value={
                              traineeData?.traineeCollege === null
                                ? ""
                                : traineeData?.traineeCollege
                            }
                            onChange={handleInputChange}
                            variant="outlined"
                          />
                        </Grid>

                        <div
                          style={{
                            display: "flex",
                            marginTop: "20px",
                            textAlign: "right",
                            width: "840px",
                          }}
                        >
                          <Grid item xs={10.2}>
                            <ReusableButton
                              size="large"
                              variant="outlined"
                              className="candidateDltepageCancelBttn"
                              buttonName="Cancel"
                              style={{
                                ...SxStyles.styles.cancelButton,
                                width: "100px",
                              }}
                              onClick={handleCloseEditPartcipantPopup}
                            />
                          </Grid>
                          {singleParticipant ? (
                            <>
                              <Grid item xs={1.8}>
                                <ReusableButton
                                  size="large"
                                  variant="contained"
                                  buttonName="Add"
                                  style={{
                                    ...styles.createBtn,
                                    width: "100px",
                                  }}
                                  onClick={handleAddParticipant}
                                ></ReusableButton>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={1.8}>
                                <ReusableButton
                                  size="large"
                                  type="submit"
                                  variant="contained"
                                  buttonName="Update"
                                  style={{
                                    ...styles.createBtn,
                                    width: "100px",
                                  }}
                                ></ReusableButton>
                              </Grid>
                            </>
                          )}
                        </div>
                      </Grid>
                    </form>
                  </div>
                </DialogContentText>
              </DialogContent>
            </div>
          </Dialog>
          <Dialog
            open={editMentorPopup}
            className="dialogBox"
            keepMounted
            onClose={handleCloseEditMentorPopup}
            aria-describedby="alert-dialog-slide-description"
            sx={SxStyles.DialogBoxStyle}
            maxWidth="md"
          >
            <div>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000", padding: "20px" }}
                >
                  <div>
                    <form onSubmit={handleSubmitMentor}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <Typography
                            variant="h6"
                            style={{ fontSize: "20px", fontWeight: "bolder" }}
                          >
                            {singleMentor
                              ? "Add Single Mentor:"
                              : "Edit Mentor Information:"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Mentor First Name
                          </span>
                          <ReusableInputfield
                            name="mentorFirstName"
                            placeholder="First Name"
                            value={mentorData.mentorFirstName}
                            onChange={handleInputChangeMentor}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Mentor Last Name
                          </span>
                          <ReusableInputfield
                            name="mentorLastName"
                            placeholder="Last Name"
                            value={mentorData.mentorLastName}
                            onChange={handleInputChangeMentor}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Mentor Email
                          </span>
                          <ReusableInputfield
                            name="mentorEmail"
                            placeholder="Email"
                            value={mentorData.mentorEmail}
                            onChange={handleInputChangeMentor}
                            disabled={singleMentor ? false : true}
                            variant="outlined"
                          />
                        </Grid>
                        {singleMentor ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={
                                SxStyles.createCourseStyle.createCourseGrid
                              }
                            >
                              <span
                                className="required"
                                style={
                                  SxStyles.createCourseStyle.createCourseSpan
                                }
                              >
                                Trainee Email
                              </span>
                              <ReusableInputfield
                                name="traineeEmail"
                                placeholder="Email"
                                value={mentorData.traineeEmail}
                                onChange={handleInputChangeMentor}
                                variant="outlined"
                              />
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "11.5px",
                                  marginTop: "1px",
                                }}
                              >
                                Note:Add Recently added participant Email
                              </p>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )}
                        <Grid
                          item
                          xs={4}
                          style={SxStyles.createCourseStyle.createCourseGrid}
                        >
                          <span
                            className="required"
                            style={SxStyles.createCourseStyle.createCourseSpan}
                          >
                            Mentor Phonenumber
                          </span>
                          <span>
                            <Custom.Phone
                              className={classes.phoneW}
                              name="mentorPhoneNumber"
                              label="0000000000"
                              value={mentorData.mentorPhoneNumber}
                              // width="240"
                              onChange={handleChangeMentorNumber}
                              // error={errors.phone}
                              fullWidth={false}
                              size="small"
                            />
                          </span>
                        </Grid>

                        <div
                          style={{
                            display: "flex",
                            marginTop: "20px",
                            textAlign: "right",
                            width: "850px",
                          }}
                        >
                          <Grid item xs={10.2}>
                            <ReusableButton
                              size="large"
                              variant="outlined"
                              className="candidateDltepageCancelBttn"
                              buttonName="Cancel"
                              style={{
                                ...SxStyles.styles.cancelButton,
                                width: "100px",
                              }}
                              onClick={handleCloseEditMentorPopup}
                            />
                          </Grid>

                          {singleMentor ? (
                            <>
                              <Grid item xs={1.8}>
                                <ReusableButton
                                  size="large"
                                  variant="contained"
                                  buttonName="Add"
                                  style={{
                                    ...styles.createBtn,
                                    width: "100px",
                                  }}
                                  onClick={handleAddMentor}
                                ></ReusableButton>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={1.8}>
                                <ReusableButton
                                  type="submit"
                                  size="large"
                                  variant="contained"
                                  buttonName="Update"
                                  style={{
                                    ...styles.createBtn,
                                    width: "100px",
                                  }}
                                ></ReusableButton>
                              </Grid>
                            </>
                          )}
                        </div>
                      </Grid>
                    </form>
                  </div>
                </DialogContentText>
              </DialogContent>
            </div>
          </Dialog>
        </>
      )}
      {openDelete && (
        <ReusableDelete
          handleDeleteTag={handleDeleteTag}
          handleDeleteClose={handleDeleteClose}
        />
      )}
    </>
  );
};
export default CreateProgram;
