import React, { useState } from "react";
import "./App.css";
import Routers from "./Navigation/router";
import useProfileContext from "./context/useProfileContext";

function App() {
  const [userDetails , setUserDetails] = useState({userRole:''});
  return (
    <div className="App">
      <useProfileContext.Provider value={{userDetails,setUserDetails}}>
      <Routers />
      </useProfileContext.Provider>
    </div>
  );
}
export default App;
