import React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  makeStyles,
  InputLabel,
  Typography,
  Box,
  Paper,
} from "@material-ui/core";
import { API } from "../../../Networking/API";
import { useFormik } from "formik";
import { AddImageMandateValidation } from "../../../Validation/FieldValidatorSchema";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import ReusableButton from "../../../components/Custom/button/button";
import { SxStyles } from "../../../styles/styles";
import ReactLoading from "react-loading";
import MandatoryImageSlide from "./mandatoryImageSlide";
import { GetSlideImage } from "../../../helper/mandatoryHelper";
import Notification from "../../../components/Custom/Notification";
// import { resolve } from "url";
const styles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    width: "120vh",
    maxWidth: "200vh",
    height: "80vh",
  },
}));

const SlideImageEditDialog = ({ openPopup, setopenPopup, SlideId }) => {
  const inputRef = useRef();
  const [overAllData, setOverAllData] = useState([]);
  const [ImagesData, setImagesData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [AddedImage, setAddedImage] = useState(false);
  const [isFieldEnabled, setIsFieldEnabled] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const SheetJSFT = ["png,jpeg, mp4"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  const classes = styles();
  const handlePopupClose = () => {
    setopenPopup(false);
    formik.resetForm();
    setOverAllData([]);
    setImagesData([]);
  };
  
  useEffect(() => {
    setLoader(true);
    GetSlideImage(SlideId, setLoader, setNotify)
      .then((data) => {
        
        if (data.length > 0) {
          setOverAllData(data);
          setImagesData(
            data[0].traningSlides.map((data) => {
              return {
                id: data.id,
                slide_Id: data[0]?.slide_id,
                first: data.image,
                second: data.description,
              };
            })
          );
        } else {
          setOverAllData([]);
          setImagesData([]);
        }
      })
      .catch((error) => {});
  }, [SlideId, AddedImage, openPopup]);

  const formik = useFormik({
    initialValues: {
      imageFile: "",
      Description: "",
    },
    validationSchema: AddImageMandateValidation,
    onSubmit: (values) => {
      handleUnitCreation(values);
      
    },
  });
  let inputStyle = SxStyles.styles;
  
  const handleUnitCreation = (values) => {
    API.AddImageForSlide(
      window.localStorage.getItem("mandateId"),
      values.imageFile,
      values.Description,
      SlideId
    )
      .then(({ response }) => {
        inputRef.current.value = null;
        formik.resetForm();
        
        if (response.api_status !== 200) {
          setNotify({
            isOpen: true,
            message: response.error[0].error_msg,
            type: "error",
          });
        }
        // if
        setAddedImage(!AddedImage);
      })
      .catch(() => {
        inputRef.current.value = null;
        setNotify({
          isOpen: true,
          message: "Something went wrong, Please try again",
          type: "error",
        });
      });
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("imageFile", file);
    setIsFieldEnabled(!isFieldEnabled);
  };
  

  return (
    <Dialog
      open={openPopup}
      //   onClose={handlePopupClose()}
      onClose={handlePopupClose}
      //   style={{ width: "100vh" }}
      //   maxWidth="sm"
      //   style={style}
      classes={{ paper: classes.main }}
    >
      <DialogTitle style={{ paddingLeft: "0px" }}>
        <div>
          <Typography
            variant="h6"
            component="div"
            style={{ fontFamily: "Georgia, serif", marginBottom: "10px" }}
          >
            Group Of Images
          </Typography>
        </div>
      </DialogTitle>
      <Grid
        container
        spacing={2}
        style={{ paddingLeft: "20px", overflowX: "hidden", height: "100%" }}
      >
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "row",
            // marginBottom: "40px",
          }}
        >
          <Grid
            item
            xs={12}
            lg={5}
            md={8}
            sm={6}
            style={{
              display: "flex",
              alignItems: "left",
              flexDirection: "column",
            }}
          >
            <div style={{ textAlign: "left" }}>
              {" "}
              <InputLabel className="InputLabel" style={inputStyle.label}>
                Upload File
              </InputLabel>
            </div>
            <input
              type="file"
              name="file"
              id="file"
              class="upload-box"
              ref={inputRef}
              accept={SheetJSFT}
              style={{
                fontSize: "0.8em",
                fontFamily: "Poppins",
                height: "40px",
                position: "relative",
                bottom: "3px",
                marginTop: "10px",
                // width:"100%"
              }}
              onChange={(e) => handleFileChange(e, formik.setFieldValue)}
            />
            <span style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
              {formik.errors.imageFile}
            </span>
          </Grid>
          <Grid
            item
            xs={10}
            lg={4}
            md={2}
            sm={5}
            style={{ marginLeft: "30px" }}
          >
            <InputLabel className="InputLabel" style={inputStyle.label}>
              Description
            </InputLabel>
            <ReusableInputfield
              name="Description"
              id="Description"
              disabled={isFieldEnabled}
              // placeholder="Duration"
              // disabled={mandateId === null ? false : isDisable}
              size="small"
              value={formik.values.Description}
              // error={
              //   formik.touched.Description && Boolean(formik.errors.Description)
              // }
              helperText={
                formik.touched.Description && formik.errors.Description
              }
              // type="number"
              onChange={formik.handleChange}
              InputProps={{
                autoComplete: "off",
                inputProps: {
                  style: { padding: "10px 12px", width: "90%" },
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              //   alignItems: "center",
              marginLeft: "2%",
              paddingTop: "2%",
            }}
          >
            <ReusableButton
              className="addbutton"
              size="large"
              variant="contained"
              disabled={isFieldEnabled}
              //   onClick={() => uploadProgramParticipant()}
              buttonName="Add"
              type="submit"
              style={{
                // backgroundColor: localStorage.getItem("mandateId")
                //   ? "#fb2485"
                //   : "grey",
                backgroundColor: "#fb2485",
                marginTop: "20%",
                fontSize: "14px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAalign: "center",
                color: "#fff",
                padding: "0px 2px",
                borderRadius: "4px",
                textTransform: "none",
                height: "40px",
                width: "10vw",
              }}
            />
          </Grid>
        </form>
        {loader ? (
          <Box
            style={{
              height: "40vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <>
            {
              <>
                {overAllData.length === 0 ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: "1%",
                      height: "40vh",
                    }}
                  >
                    <Paper className="master-session-paper">
                      <span className="mentor-error-text">
                        No Images Uploaded !
                      </span>
                    </Paper>
                  </Box>
                ) : (
                  <MandatoryImageSlide
                    style={{ marginTop: "40px" }}
                    draggableData={ImagesData}
                    setDraggableData={setImagesData}
                    setNotify={setNotify}
                    SlideId={SlideId}
                    setAddedImage={setAddedImage}
                  />
                )}
              </>
            }
          </>
        )}
        <Box
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            height: "10%",
          }}
        >
          <ReusableButton
            className="addbutton"
            size="large"
            variant="contained"
            //   onClick={() => uploadProgramParticipant()}
            buttonName="Cancel"
            type="Cancel"
            style={{
              // backgroundColor: localStorage.getItem("mandateId")
              //   ? "#fb2485"
              //   : "grey",
              backgroundColor: "#000936",
              marginBottom: "8%",
              fontSize: "14px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#fff",
              padding: "0px 2px",
              borderRadius: "4px",
              textTransform: "none",
              height: "40px",
              width: "10vw",
            }}
            onClick={() => handlePopupClose()}
          />
        </Box>

        <Notification notify={notify} setNotify={setNotify} />
      </Grid>
    </Dialog>
  );
};

export default SlideImageEditDialog;
