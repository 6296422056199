/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Notification from "../../../../components/Custom/Notification";
import MandatoryTraningTabPanel from "../MandatoryTraningLayout/mandatoryTraningTabpanel";
import MandateUserQuiz from "../MandatoryTraningLayout/MandatoryTraningQuiz/MandatoryTrainingFinalQuiz/mandate-user-quiz";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MandatoryTrainingTabs(props) {
  const { mandatetrainingData, user_id } = props;

  const [value, setValue] = useState(0);
  const [isFinalQuizEnabled, setIsFinalQuizEnabled] = useState(false);
  const [enableQuizTab, setEnableQuiz] = useState(true);
  const [MandateTrainingDetails, setMandateTrainingDetails] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const userUnitTrackId = localStorage.getItem("userUnitTrackId");

  //

  const ClearLocalStorage = () => {
    MandateTrainingDetails[0]?.traningModules?.forEach((data) => {
      localStorage.removeItem(
        `${data.ModuleId}_${MandateTrainingDetails[0].mainId}`
      );
    });
  };

  const handleSetFinalQuiz = (type) => {
    setValue(1);
    setEnableQuiz(true);
    if (type === "final") setIsFinalQuizEnabled(true);
  };

  const handleTabChange = (event, newValue) => {
    if (isFinalQuizEnabled) return setValue(1);
    if (enableQuizTab) return setValue(0);
    setValue(newValue);
  };
  

  return (
    <>
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 15,
          height: "auto",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ borderRight: 2, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Training Material"
              className="tabs-item"
              style={{
                textTransform: "capitalize",
              }}
              {...a11yProps(0)}
            />

            <Tab
              label="Quiz"
              className="tabs-item"
              style={{
                textTransform: "capitalize",
              }}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        <>
          <TabPanel
            value={value}
            index={0}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {mandatetrainingData.length !== 0 ? (
              <Box>
                <MandatoryTraningTabPanel
                  user_id={user_id}
                  handleSetFinalQuiz={handleSetFinalQuiz}
                  setEnableQuiz={setEnableQuiz}
                  setMandateTrainingDetails={setMandateTrainingDetails}
                  mandatetrainingData={mandatetrainingData}
                />
              </Box>
            ) : (
              <>
                <p
                  style={{
                    backgroundColor: "white",
                    fontSize: "20px",
                    justifyContent: "center",
                    fontWeight: "600",
                    height: "20vh",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  No Data Found
                </p>
              </>
            )}
          </TabPanel>
        </>

        <TabPanel
          value={value}
          index={1}
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <MandateUserQuiz
            userUnitTrackId={userUnitTrackId}
            ClearLocalStorage={ClearLocalStorage}
            handleSetFinalQuiz={handleSetFinalQuiz}
          />
        </TabPanel>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default MandatoryTrainingTabs;
