// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/Images/adminLoginPageBg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OverallDiv {\n    /* background-image: url(../../assets/Images/adminLoginPageBg.png); */\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  \n    background-repeat: no-repeat;\n    background-size: cover;\n    height: 100vh;\n  }\n\n  .LoaderContent{\n    height: 100%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .AuthenticationLoader{\n    font-family: \"Poppins\";\n    font-size: 2em;\n    font-weight: 300;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: normal;\n    letter-spacing: normal;\n    text-align: left;\n    color: #fff;\n  }", "",{"version":3,"sources":["webpack://./src/pages/users/userConnect/connectLogin.css"],"names":[],"mappings":"AAAA;IACI,qEAAqE;IACrE,yDAAkE;;IAElE,4BAA4B;IAC5B,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,WAAW;EACb","sourcesContent":[".OverallDiv {\n    /* background-image: url(../../assets/Images/adminLoginPageBg.png); */\n    background-image: url(../../../assets/Images/adminLoginPageBg.png);\n  \n    background-repeat: no-repeat;\n    background-size: cover;\n    height: 100vh;\n  }\n\n  .LoaderContent{\n    height: 100%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .AuthenticationLoader{\n    font-family: \"Poppins\";\n    font-size: 2em;\n    font-weight: 300;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: normal;\n    letter-spacing: normal;\n    text-align: left;\n    color: #fff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
