import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { Custom } from "../../../components/Custom/Custom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MyForm, Form } from "../../../components/MyForm";
import EditIcon from "@mui/icons-material/Edit";
import { AdminColloabValidationSchema } from "../../../Validation/FieldValidatorSchema";
import Notification from "../../../components/Custom/Notification";
import Cookies from "js-cookie";
import "./candidates-count.css";
import { API } from "../../../Networking/API";
import { InputCss } from './adminStyles';
import { TraineeCol } from './adminStyles';
import { CancelBtn } from './adminStyles';

const initialValues = {
  firstname: "",
  email: "",
  department: "",
  designation: "",
  phone: "",
  experience: "",
  certificates: "",
  college: "",
  departmentName: "",
};

export default function Editpopup(props) {
  const { open, id, handleClose, departments, getIdValue, batchId } = props;
  const [user, setUser] = useState(initialValues);
  const [showField, setShowField] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [Bootcamp, setBootcamp] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [batchID, setBatchID] = useState();
  const [filter, setFilter] = useState("");
  const [batches, setBatches] = useState();
  const [batchIDDD, setBatchIDDD] = useState();
  const [filterDD, setFilterDD] = useState("");
  const [batchesDD, setBatchesDD] = useState([]);

  const validate = (inputValues = values) => {
    return AdminColloabValidationSchema(
      inputValues,
      errors,
      values,
      setErrors,
      tableData
    );
  };
  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const handleInputChangePh = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace(/\D/g, "");

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
  };

  const userPhoneExists = (phone) => {
    return tableData.some(function (el) {
      return el.phone == phone;
    });
  };
  useEffect(() => {
    getUserById(id);
  }, [id]);
  const {
    firstname,
    email,
    department,
    designation,
    phone,
    experience,
    certificates,
    college,
    departmentName,
  } = values;
  useEffect(() => {
    getTraineeCollegeFied();
  }, [department]);
  const getTraineeCollegeFied = () => {
    if (department === 16) {
      setShowField(true);
    } else {
      setShowField(false);
    }
  };

  const getUserById = async (id) => {
    API.getAdminCandidateById(id).then(({ response }) => {
      if (response.data.api_status === 200) {
        const data = response.data.data[0].users_details[0];
        const text = data.batchid.charAt(1);
        setValues({
          ...values,
          firstname: data.user_name,
          email: data.user_email,
          department: data.sum_role_bits,
          departmentName: data.role_name,
          designation: data.work_posiotion,
          phone: data.phonenumber,
          experience: data.work_experience,
          certificates: data.certificates,
          college: data.trainee_colleage,
          Bootcamp: data.role_pk,
        });
        setBootcamp(Number(text));
        getTraineeCollegeFied();
      }
    });
  };

  useEffect(() => {
    getBatches();
  }, []);

  const getBatches = async () => {
    API.getBatchesDetails().then(({ response }) => {
      if (response.data.api_status === 200) {
        const batchesData = response.data.data[0].bacthes;
        const updatedData = batchesData.map((each) => ({
          key: each.batch_pk,
          value: each.batch_pk,
          title: each.batch_name,
          start_date: each.start_date,
          end_date: each.end_date,
        }));
        setBootcamp();
        setBatches(updatedData);
        setBatchesDD(updatedData);
        setFilter(updatedData[0].title);
        setFilterDD(updatedData[0].title);
        setBatchID(updatedData[0].key);
        setBatchIDDD();
      } else {
        setBatches([]);
        setBatchesDD([]);
      }
    });
  };

  const handlebootcampValue = (e) => {
    setBootcamp(e.target.value);
  };

  const onSubmitEdit = async (id) => {
    API.putAdminCandidateById(
      id,
      Bootcamp,
      firstname,
      email,
      designation,
      college,
      phone,
      experience,
      certificates,
      department
    ).then(({ response }) => {
      if (response.api_status === 200) {
        handleClose();
        getIdValue(batchId);
        setNotify({
          isOpen: true,
          message: "Candidate Details Updated Successfully",
          type: "success",
        });
      } else {
        handleClose();
        getIdValue(batchId);
        setNotify({
          isOpen: true,
          message: "Candidate Details Cannot Be Updated",
          type: "error",
        });
      }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ padding: "16px", overflowY: "hidden !important" }}
      >
        <Form>
          <DialogTitle>Edit Collaborator Record </DialogTitle>
          <DialogContent>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={InputCss}
                    >
                      Full Name
                    </Typography>
                    <span style={{ marginLeft: "-7px" }}>
                      <Custom.Input
                        name="firstname"
                        placeholder="Full Name"
                        value={firstname}
                        onChange={handleInputChange}
                        error={errors.firstname}
                        size="small"
                      />
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={InputCss}
                      variant="h6"
                    >
                      Email Address
                    </Typography>
                    <span style={{ marginLeft: "-7px" }}>
                      <Custom.Input
                        name="email"
                        value={email}
                        placeholder="example@email.com"
                        onChange={handleInputChange}
                        error={errors.email}
                        size="small"
                      />
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={InputCss}
                      variant="h6"
                    >
                      Designation
                    </Typography>
                    <span style={{ marginLeft: "-7px" }}>
                      <Custom.Input
                        name="designation"
                        placeholder="Designation"
                        onChange={handleInputChange}
                        value={designation}
                        error={errors.designation}
                        size="small"
                      />
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={InputCss}
                      variant="h6"
                    >
                      Phone Number
                    </Typography>
                    <span style={{ marginLeft: "-7px" }}>
                      <Custom.Phone
                        name="phone"
                        label="0000000000"
                        value={values.phone}
                        onChange={handleInputChangePh}
                        error={errors.phone}
                        size="small"
                        type="text"
                      />
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={InputCss}
                      variant="h6"
                    >
                      Experience
                    </Typography>
                    <span style={{ marginLeft: "-7px" }}>
                      <Custom.Input
                        type={"number"}
                        size="small"
                        value={experience || ""}
                        name="experience"
                        isOpen={true}
                        focus
                        label="In Years"
                        error={errors.experience}
                        onChange={handleInputChange}
                        InputProps={{ inputProps: { max: 30 } }}
                      />
                    </span>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={InputCss}
                      variant="h6"
                    >
                      Certificates
                    </Typography>
                    <span style={{ marginLeft: "-7px" }}>
                      <Custom.Input
                        label="In Nos"
                        type={"number"}
                        size="small"
                        name="certificates"
                        onChange={handleInputChange}
                        value={certificates || ""}
                        error={errors.certificates}
                        InputProps={{ inputProps: { max: 30 } }}
                      />
                    </span>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={InputCss}
                      variant="h6"
                    >
                      Batch Name
                    </Typography>
                    <span style={{ marginLeft: "-7px" }}>
                      <Custom.ReusableDropdown
                        name="Bootcamp"
                        value={Bootcamp}
                        onChange={handlebootcampValue}
                        options={batchesDD}
                        error={errors.batch}
                        size="small"
                        style={{
                          height: 40,
                          fontFamily: "Poppins",
                        }}
                      ></Custom.ReusableDropdown>
                    </span>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={InputCss}
                      variant="h6"
                    >
                      Role
                    </Typography>
                    <span style={{ marginLeft: "-7px" }}>
                      <Custom.Dropdown3
                        name="department"
                        label="Select"
                        value={department}
                        onChange={handleInputChange}
                        options={departments}
                        error={errors.department}
                        size="small"
                        style={{
                          height: 40,
                          alignItems: "center",
                          display: "flex",
                          color: "black",
                        }}
                      ></Custom.Dropdown3>
                    </span>
                  </div>
                </Grid>

                {showField === true ? (
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={TraineeCol}
                        variant="h6"
                      >
                        Trainee College
                      </Typography>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Input
                          name="college"
                          placeholder="College Name"
                          value={college}
                          onChange={handleInputChange}
                          error={errors.college}
                          size="small"
                        />
                      </span>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={6}></Grid>
                )}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <ReusableButton
              onClick={handleClose}
              size="large"
              variant="outlined"
              buttonName="Cancel"
              style={CancelBtn}
            >
              Cancel
            </ReusableButton>
            <ReusableButton
              onClick={() => onSubmitEdit(id)}
              size="large"
              variant="contained"
              buttonName="Add"
              style={{
                backgroundColor: "#fb2485",
                marginRight: "30px",
                marginBottom: "40px",
                width: "98px",
                height: "38px",
                borderRadius: 4,
              }}
            >
              Add
            </ReusableButton>
          </DialogActions>
        </Form>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
