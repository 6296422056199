/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { ReusableCards } from "../../../components/Custom/cards/cards";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Custom } from "../../../components/Custom/Custom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MyForm, Form } from "../../../components/MyForm";
import IconButton from "@mui/material/IconButton";
import ReactLoading from "react-loading";
import EditIcon from "@mui/icons-material/Edit";
import Editpopup from "./editpopup";
import Notification from "../../../components/Custom/Notification";
import { AdminColloabValidationSchema } from "../../../Validation/FieldValidatorSchema";
import AddCollabModal from "./admin-AddCollabModal";
import { API } from "../../../Networking/API";
import "./candidates-count.css";
import { DataTableHandler } from "../../../handler/dataTableHandler";
import { SxStyles } from "../../../styles/styles";
import Cookies from "js-cookie";
import {
  CancelBtn,
  DeleteBtn,
  OkBtn,
  CardCss,
  CanditBtn,
  CandMainDiv,
  CollabHead,
} from "./adminStyles";

const initialValues = {
  firstname: "",
  email: "",
  department: "",
  designation: "",
  phone: "",
  experience: "",
  certificates: "",
  college: "",
  description: "",
  batch: "",
};

const CandidatesList = (props) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openokModel, setOpenOkModal] = useState(false);
  const [editId, setEditId] = useState();
  const [id, setId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [done, setDone] = useState(undefined);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [onboardingCandidates, setOnboardingCandidates] = useState("");
  const [registeredCandidates, setRegisteredCandidates] = useState("");
  const [departments, setDepartments] = useState([]);
  const [showField, setShowField] = useState(false);
  const [showFieldDesp, setShowFieldDesp] = useState(false);
  const [batchID, setBatchID] = useState();
  const [filterData, setFilterData] = useState("");
  const [batchesData, setBatchesData] = useState([]);

  useEffect(() => {
    if (batchID !== undefined) {
      getIdValue(batchID);
    }
  }, [batchID]);

  useEffect(() => {
    getBatches();
  }, []);

  const getBatches = async () => {
    API.getBatchesDetails().then(({ response }) => {
      if (response.data.api_status === 200) {
        const responseFromBatches = response.data.data[0].bacthes;
        const updatedData = responseFromBatches.map((each) => ({
          key: each.batch_pk,
          value: each.batch_pk,
          title: each.batch_name,
          start_date: each.start_date,
          end_date: each.end_date,
        }));
        setBatchesData(updatedData);
        setFilterData(updatedData[0].key);
        setBatchID(updatedData[0].key);
      } else {
        setBatchesData([]);
      }
    });
  };

  const handlebootcampValue = (e) => {
    var value = batchesData.filter(function (item) {
      return item.key === e.target.value;
    });
    setBatchID(value[0].batch_pk);
    setFilterData(value[0].key);
  };

  const validate = (inputValues = values) => {
    return AdminColloabValidationSchema(
      inputValues,
      errors,
      values,
      setErrors,
      tableData
    );
  };
  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);

  const userRecordExists = (key, type) => {
    return tableData.some(function (el) {
      return type === "email" ? el.email === key : el.phone == key;
    });
  };

  const handleInputChangePh = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace(/\D/g, "");

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
  };
  useEffect(() => {
    getTraineeCollegeFied();
    getTrainerDescriptionField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const getTraineeCollegeFied = () => {
    if (values.department === 16) {
      setShowField(true);
    } else {
      setShowField(false);
    }
  };
  const getTrainerDescriptionField = () => {
    if (values.department === 8) {
      setShowFieldDesp(true);
    } else {
      setShowFieldDesp(false);
    }
  };

  const FirstName = values.firstname;
  const Email = values.email;
  const Designation = values.designation;
  const Phone = values.phone;
  const Experience = values.experience;
  const Certificates = values.certificates;
  const Department = values.department;
  const College = null || values.college;
  const Description = null || values.description;
  // const StatusCheck = null || values.Status;
  const Batch = null || values.batch;

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      editable: true,
      width: 30,
      borderRadius: "14px 0px 0px 0px",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "firstname",
      headerName: "Collaborator Name",
      // flex: 1,
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "email",
      headerName: "Email",
      //   flex: 1,
      width: 230,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "experience",
      headerName: "Experience",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "certificates",
      headerName: "Certificates",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <span className="editbutton">
            <IconButton aria-label="edit" size="small">
              <EditIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                  color: "blue !important",
                }}
                onClick={() => handleEdit(params.id)}
              />
            </IconButton>
          </span>

          <span className="delete-button">
            <IconButton aria-label="delete" size="small">
              {" "}
              <DeleteOutlineIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                }}
                onClick={() => {
                  {
                    {
                      params.row.Status == "Registered"
                        ? handleClickOpenModal(params.id, "")
                        : handleClickOpenModal(params.id, "ok");
                    }
                  }
                }}
              />
            </IconButton>

            <Dialog
              open={openModal}
              className="dialogBox"
              keepMounted
              onClose={handleCloseByCancelModal}
              aria-describedby="alert-dialog-slide-description"
              sx={SxStyles.DialogBoxStyle}
            >
              <DialogTitle></DialogTitle>

              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  Are you sure you want to delete ?
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Cancel"
                  style={CancelBtn}
                  onClick={handleCloseByCancelModal}
                />
                <ReusableButton
                  size="large"
                  variant="contained"
                  className="candidateDltepageDeleteBttn"
                  buttonName="Delete"
                  style={DeleteBtn}
                  onClick={() => handleDelete(id)}
                >
                  Delete
                </ReusableButton>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openokModel}
              className="dialogBox"
              keepMounted
              onClose={handleCloseBysetOpenOkModal}
              sx={SxStyles.DialogBoxStyle}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle></DialogTitle>

              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  You cant Delete Registered User!
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Ok"
                  style={OkBtn}
                  onClick={handleCloseBysetOpenOkModal}
                />
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];

  const handleClickOpenModal = (id, type) => {
    setId(id);
    if (type === "ok") {
      setOpenOkModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const handleCloseBysetOpenOkModal = () => {
    setOpenOkModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    getDepartments();
  };

  const handleEdit = (id) => {
    setOpenEdit(true);
    getDepartments();
    setEditId(id);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleClose = () => {
    setOpen(true);
  };
  const handleCloseByCancel = () => {
    resetForm();
    setOpen(false);
  };

  const getDepartments = async () => {
    API.getAdminDepartmentss().then(({ response }) => {
      const getDepartmentCollection = response.data.data[0].roles;
      const updatedData = getDepartmentCollection.map((each) => ({
        key: each.role_pk,
        value: each.role_pk,
        title: each.role_name,
      }));
      setDepartments(updatedData);
    });
  };

  const getIdValue = async (batches) => {
    API.getAdminCandidates(batches).then(async ({ response }) => {
      if (response.data.api_status === 200) {
        const Userdata = response.data.data[0].users_details;
        const filter = response.data.data[0].filter;
        setOnboardingCandidates(Userdata.length);
        setRegisteredCandidates(filter["registered"]);
        const updatedData = await DataTableHandler.AdminDataTableHandler(
          Userdata
        );
        setTableData(updatedData);
        setDone(true);
      } else {
        setTableData([]);
        setDone(true);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    API.postAdminAddCandidate(
      FirstName,
      Email,
      Designation,
      Phone,
      Experience,
      Certificates,
      Department,
      College,
      Description,
      Batch,
      batchID
    ).then(({ response }) => {
      if (response.api_status === 200) {
        API.postAdminAddCandidateMail(FirstName, Email, Department).then(
          ({ response }) => {
            if (response.api_status === 200) {
              setNotify({
                isOpen: true,
                message: "Candidate added successfully",
                type: "success",
              });
              getIdValue(batchID);
              resetForm();
              setOpen(false);
            } else if (response.api_status === 502) {
              getIdValue();
              resetForm();
              setOpen(false);
              setNotify({
                isOpen: true,
                message: "Email Already Exists",
                type: "error",
              });
            } else if (response.api_status === 400) {
              getIdValue();
              resetForm();
              setOpen(false);
              setNotify({
                isOpen: true,
                message: `${response.error[0].error_msg}`,
                type: "error",
              });
            } else {
              setNotify({
                isOpen: true,
                message: "Candidate cannot be added",
                type: "error",
              });
              getIdValue(batchID);
              setOpen(true);
            }
          }
        );
      }
    });
  };

  const handleDelete = async (paramsId) => {
    API.deleteAdminCandidate(paramsId).then(({ result }) => {
      const resultInJson = result;
      if (resultInJson.api_status === 200) {
        getIdValue();
        setOpenModal(false);
        setNotify({
          isOpen: true,
          message: "Candidate deleted successfully",
          type: "success",
        });
      } else {
        getIdValue();
        setOpenModal(false);
        setNotify({
          isOpen: true,
          message: "Candidate  Cannot Be Deleted",
          type: "error",
        });
      }
    });
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <Box
          component="main"
          className="adminslayout"
          sx={{
            flexGrow: 1,
            padding: "0px 30px 0px 30px !important",
            marginBottom: "220px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} style={{ padding: "30px" }}>
              <Grid item xs={2}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  // paddingRight: '25px',
                }}
              >
                <ReusableCards
                  className="onboardCandidates"
                  // sx={{ minHeight: 55 }}
                  text={"Collaborators"}
                  value={onboardingCandidates}
                  style={CardCss}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReusableCards
                  className="registeredcand"
                  text={"Registered Collaborators"}
                  value={
                    registeredCandidates === undefined
                      ? `0/${onboardingCandidates}`
                      : `${registeredCandidates}/${onboardingCandidates}`
                  }
                  style={CardCss}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }} style={{ marginTop: "0px" }}>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={4}
                style={{ textAlign: "center", paddingRight: "20px" }}
              >
                <h4 style={CollabHead}>Collaborators</h4>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  textAlign: "end",
                  margin: "auto",
                  paddingRight: "40px",
                }}
              >
                <ReusableButton
                  size="small"
                  className="addCandidateBtn"
                  style={CanditBtn}
                  variant="contained !important"
                  onClick={handleClickOpen}
                  startIcon={<PersonAddIcon />}
                  buttonName="Add Collaborator"
                />
              </Grid>
            </Grid>
          </Box>

          <div style={CandMainDiv}>
            <Box
              sx={{
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#f1f1f1",
                  color: "#02215b",
                  fontSize: "16px",
                  paddingBottom: "20px",
                },
              }}
            >
              <DataTable
                rows={tableData}
                columns={columns}
                pageSize={10}
                rowHeight={40}
                rowsPerPageOptions={[10]}
                autoHeight
                width="95%"
                className="dataTableCandidatePage"
                hideFooterSelectedRowCount={true}
                sx={SxStyles.DatGridStyles}
              />
            </Box>
          </div>

          <Dialog
            open={open}
            onClose={handleClose}
            style={{ padding: "16px", overflowY: "hidden !important" }}
          >
            <Form onSubmit={handleSubmit}>
              <AddCollabModal
                FirstName={FirstName}
                handleInputChange={handleInputChange}
                errors={errors}
                Email={Email}
                Designation={Designation}
                handleInputChangePh={handleInputChangePh}
                Phone={Phone}
                filterDD={filterData}
                handlebootcampValue={handlebootcampValue}
                batchesDD={batchesData}
                Department={Department}
                departments={departments}
                showField={showField}
                College={College}
                showFieldDesp={showFieldDesp}
                Description={Description}
                handleCloseByCancel={handleCloseByCancel}
                handleClose={handleClose}
              />
            </Form>
          </Dialog>

          <Editpopup
            open={openEdit}
            id={editId}
            handleClose={handleEditClose}
            getIdValue={getIdValue}
            departments={departments}
            batchId={batchID}
          />
          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      )}
    </>
  );
};

export default CandidatesList;
