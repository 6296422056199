import React, { useEffect, useState, useMemo } from "react";
import { Custom } from "../../../../components/Custom/Custom";
import ReusableButton from "../../../../components/reusable-button/reusable-button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Dialog,
  Tabs,
  Tab,
  Typography,
  Grid,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { API } from "../../../../Networking/API";
import { DropDownHandler } from "../../../../handler/dropDownHandler";
import {
  UnitSearchHandler,
} from "../../../../handler/addCourseHandler";
import {
  GetAllUnitHelper,
  GetAllCourseHelper,
} from "../../../../helper/unitHelper";
import { AddCourseCheckBoxHandler } from "../../../../handler/addCourseHandler";
import { SxStyles } from "../../../../styles/styles";
import {
  InputCss,
  UnitTopic,
  CourseUnitHeader,
} from "../../admin-layout/adminStyles";
import { CourseHandler } from "../../../../handler/addCourseHandler";
import ReactPaginate from "react-paginate";
import ReactLoading from "react-loading";
import { unitCheckBoxHandler } from "../../../../handler/addCourseHandler";
import { courseUnitCheckboxHandler } from "../../../../handler/addCourseHandler";
import { CheckBoxDisableHandler } from "../../../../handler/addCourseHandler";
import Notification from "../../../../components/Custom/Notification";

const AddCourseUnitsModal = (props) => {
  const styles = SxStyles.styles;
  const {
    isOpen,
    setIsOpen,
    programSelectedUnit,
    programSelectedCourses,
    programSetSelectedCourses,
    programSetSelectedUnit,
  } = props;

  /*****************************************Decleration of all the states ***************/
  const [value, setValue] = React.useState(0);
  const [selectedCourse, setSelectedCourse] = useState(programSelectedCourses);
  const [options, setOptions] = useState();
  const [allUnits, setAllUnits] = useState();
  //eslint-disable-next-line
  const [pageLimit, setPageLimit] = useState();
  const [checkboxId, setCheckboxId] = useState(() => new Set());
  const [selectedUnitData, setselectedUnitData] = useState(() => new Set());
  const [courseTags, setCourseTags] = useState("");
  const [courseDifficulty, setCourseDifficulty] = useState("");
  const [allCourseData, setAllCourseData] = useState([]);
  const [courseCount, setCourseCount] = useState(0);
  const [unitCount, setUnitCount] = useState(0);
  const [coursePageIndex, setCoursePageIndex] = useState(0);
  const [unitPageIndex, setUnitPageIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [selctedUnitFromCourseIds, setSelctedUnitFromCourseIds] = useState([]);
  const [selectedCoursewithUnits, setSelectedCoursewithUnits] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  /*****************To get all the unit in the start and based on search details */
  const getAllUnit = async (index) => {
    let isSelectedDifficultyLevel = await UnitSearchHandler(courseDifficulty);
    let isSeletedTag = await UnitSearchHandler(courseTags);
    GetAllUnitHelper(
      index ? index : unitPageIndex,
      4,
      setAllUnits,
      setUnitCount,
      isSelectedDifficultyLevel,
      courseDifficulty,
      isSeletedTag,
      courseTags,
      setLoader
    );
  };

  /*****************To get all the course in the start and based on search details */

  const getAllCourse = async (index) => {
    let isSelectedDifficultyLevel = await UnitSearchHandler(courseDifficulty);
    let isSeletedTag = await UnitSearchHandler(courseTags);
    GetAllCourseHelper(
      index ? index : coursePageIndex,
      4,
      setAllCourseData,
      setPageLimit,
      isSelectedDifficultyLevel,
      courseDifficulty,
      isSeletedTag,
      courseTags,
      setCourseCount,
      setLoader
    );
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  /***********Get all the difficulty level and the unit when the page is loaded for the Modal */
  useEffect(() => {
    getAllDifficultyLevels();
    getAllUnit();
  //eslint-disable-next-line
  }, []);

  /****************UseEffect to get all the course based on the tab value and based on the search field  */
  useEffect(() => {
    if (value === 0) {
      getAllCourse();
    } else {
      getAllUnit();
    }
     //eslint-disable-next-line
  }, [courseDifficulty, courseTags, unitPageIndex, coursePageIndex]);

  /*********************UseEffect to set the page index and search field to empty while changing the tabs */
  useEffect(() => {
    setCoursePageIndex(0);
    setUnitPageIndex(0);
    setCourseDifficulty("");
    setCourseTags("");
    setLoader(true);
    if (value === 0) {
      getAllCourse(0);
    } else {
      getAllUnit(0);
    }
     //eslint-disable-next-line
  }, [value]);

  /************UseEffect to set the data from the parent page like the course and units */

  useEffect(() => {
    setSelectedCourse(programSelectedCourses);
    setselectedUnitData(new Set(programSelectedUnit));
    let programUnitId = new Set();
    programSelectedUnit?.forEach((programUnit) => {
      programUnitId.add(programUnit.id);
    });
    setCheckboxId(programUnitId);
     //eslint-disable-next-line
  }, [isOpen]);

  /****************Modify the course data as per the requirments */
  const courseDataHandler = useMemo(
    () => CourseHandler(allCourseData),
    [allCourseData]
  );

  /***************Check weather the selected course units is present in unit and vise versa and update based on the case */
  //eslint-disable-next-line
  const updatedUnit = useMemo(() => {
    let indexArray = [];
    let diselectedUnitArray = [];
    const updatedUnitIds = new Set();
    const updatedUnitsData = new Set();
    selectedCourse.forEach((courseData, index) => {
      let diselectedUnitCount = 0;
      courseData.units.forEach((unitsData) => {
        indexArray.push(unitsData.unitid);
        if (unitsData.isunitSelected === false)
          diselectedUnitCount = diselectedUnitCount + 1;
      });
      if (diselectedUnitCount === courseData.units.length) {
        diselectedUnitArray.push(courseData.courseId);
      }
    });
    checkboxId.forEach((checkboxId) => {
      if (!indexArray.includes(checkboxId)) {
        updatedUnitIds.add(checkboxId);
      }
    });

    selectedUnitData.forEach((unitData) => {
      if (!indexArray.includes(unitData.id)) {
        updatedUnitsData.add(unitData);
      }
    });

    setselectedUnitData(updatedUnitsData);
    setCheckboxId(updatedUnitIds);
    if (diselectedUnitArray.length > 0) {
      setSelectedCourse((prevData) =>
        prevData.filter(
          (filterCourseData) =>
            !diselectedUnitArray.includes(filterCourseData.courseId)
        )
      );
    }
    setSelctedUnitFromCourseIds(
      selectedCourse
        .flatMap((course) => course.units)
        .filter((unit) => unit.isunitSelected)
        .map((unit) => unit.unitid)
    );

    const selectedUnits = selectedCourse.flatMap((course) => {
      const selectedUnitIds = course.units
        .filter((unit) => unit.isunitSelected)
        .map((unit) => unit.unitid);

      return selectedUnitIds.length > 0
        ? [{ courseId: course.courseId, units: selectedUnitIds }]
        : [];
    });
    setSelectedCoursewithUnits(selectedUnits);
    //eslint-disable-next-line
  }, [selectedCourse]);

  /********************Handle tab value change ***********************************************/
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /*********************Get all the difficulty level *******************************************/

  const getAllDifficultyLevels = () => {
    API.getDifficultyLevel().then(({ response }) => {
      const DiffcultyData = response.data.data;
      const uniqueDifficulties = [
        ...new Map(
          DiffcultyData.map((difficulty) => [
            difficulty.difficulty_name,
            difficulty,
          ])
        ).values(),
      ];
      setOptions(DropDownHandler(uniqueDifficulties));
    });
  };

  /****** Handler for Unit Checkbox Click ********************************************************/
  const handleUnitCheckboxClick = (selectedId, UnitData, event) => {
    const { UpdatedUnits, isUnitexist } = unitCheckBoxHandler(
      selectedId,
      selectedCourse,
      event.target.checked,
      selctedUnitFromCourseIds
    );
    setSelectedCourse(UpdatedUnits);
    if (isUnitexist)
      AddCourseCheckBoxHandler(
        selectedId,
        checkboxId,
        setCheckboxId,
        selectedUnitData,
        setselectedUnitData,
        UnitData
      );
  };

  /*****************Handler for Course Checkbox Click ************************************************/
  const handleCourseCheckBoxSelect = (event, courseData) => {
    if (event.target.checked) {
      setSelectedCourse([
        ...selectedCourse,
        {
          courseId: courseData.courseId,
          courseName: courseData.courseTitle,
          duration: courseData.time,
          status: courseData.courseStatus === 1 ? "Active" : "In-Active",
          units: courseData.units.map((unitData) => {
            return {
              ...unitData,
              isunitSelected: !selctedUnitFromCourseIds.includes(
                unitData.unitid
              ),
            };
          }),
        },
      ]);
    } else {
      setSelectedCourse((prevData) =>
        prevData.filter((data) => data.courseId !== courseData.courseId)
      );
    }
  };

  /**************************Handler to check the checkBox selected from Course Units *************************/
  const handleCourseUnitSelcted = (Id) => {
    let isUnitSelctedInCourse = false;
    if (selectedCourse.length > 0) {
      selectedCourse.forEach((individualSelctedCourse) => {
        individualSelctedCourse.units.forEach((individualUnitData) => {
          if (
            individualUnitData.unitid === Id &&
            individualUnitData.isunitSelected === true
          ) {
            isUnitSelctedInCourse = true;
          }
        });
      });
    } else return isUnitSelctedInCourse;
    return isUnitSelctedInCourse;
  };

  /*********************Handler for Modal close set the index and other values to empty *********************/
  const handleModalClose = () => {
    setIsOpen(false);
    setValue(0);
    setCourseDifficulty("");
    setCourseTags("");
    setCoursePageIndex(0);
    setUnitPageIndex(0);
  };

  /****************Handler for Paginate page change for Course ****************************************/
  const handlePageClickCourse = (data) => {
    setCoursePageIndex(data.selected);
  };

  /****************Handler for Paginate page change for Unit ***************************************/

  const handlePageClickUnit = (data) => {
    setUnitPageIndex(data.selected);
  };

  /**************************Disable or Enable the Course Unit CheckBox if selected In some other course */
  const checkIfCourseUnitDisabled = (courseId, unitId) => {
    return CheckBoxDisableHandler(
      courseId,
      unitId,
      selectedCourse,
      selectedCoursewithUnits
    );
  };

  /**************************Check or UnCheck the Course Unit CheckBox if selected In some other course */

  const checkifCourseUnitSelected = (courseId, unitId, unitData) => {
    const course = selectedCourse.filter(
      (courseData) => courseData.courseId === courseId
    );
    if (!course) {
      let unitExistInSomeCourse = false;
      // eslint-disable-next-line
      selectedCourse.map((courseData) => {
        //eslint-disable-next-line
        courseData.units.map((unit) => {
          if (unit.unitid === unitId) {
            unitExistInSomeCourse = true;
          }
        });
      });
      if (unitExistInSomeCourse) {
        return false;
      }
    }

    const filtredCourse = selectedCourse.filter(
      (courseData) => courseData.courseId === courseId
    );

    if (filtredCourse.length > 0) {
      const ifUnitsExiste = filtredCourse[0].units.filter(
        (unit) => unit.unitid === unitId && unit.isunitSelected === true
      );
      if (ifUnitsExiste.length > 0) {
        return true;
      }
    }
    return false;
  };

  /**************************Check or UnCheck the  Unit CheckBox if selected In some other course */

  const courseUnitHandleCheckBoxSelect = (
    courseId,
    unitId,
    event,
    unitsData
  ) => {
    if (event.target.checked) {
      let UpdatedUnits = courseUnitCheckboxHandler(
        courseId,
        unitId,
        selectedCourse,
        event.target.checked,
        unitsData,
        selctedUnitFromCourseIds
      );

      setSelectedCourse(UpdatedUnits);
    } else {
      let UpdatedUnits = courseUnitCheckboxHandler(
        courseId,
        unitId,
        selectedCourse,
        event.target.checked
      );
      setSelectedCourse(UpdatedUnits);
    }
  };

  /***********************************Final Adding of Course and Units on click of add button **************************/
  const handleUnitCourseAdd = () => {
    const array = Array.from(selectedUnitData);
    

    if (array.length === 0 && selectedCourse.length === 0) {
      setNotify({
        isOpen: true,
        message: "Add any Unit or Course",
        type: "error",
      });
    } else {
      programSetSelectedUnit(Array.from(selectedUnitData));
      programSetSelectedCourses(selectedCourse);
      // editBootCamp();
      handleModalClose();
    }
  };

  /**************************************Updated Course Data for Displaying *****************/
  let UpdatedCourseData = courseDataHandler ? courseDataHandler : [];

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "end",
          },
          "& .MuiPaper-root": {
            height: "fit-content",
            margin: "0px 0px 0px 0px",
            width: "62%",
            background: "transparent",
          },
        }}
        open={isOpen}
        onClose={handleModalClose}
        style={{
          overflowY: "hidden !important",
          backgroundColor: "transprent",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              marginBottom: "6.2%",
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Add Course"
                {...a11yProps(0)}
                style={{
                  width: "30%",
                  backgroundColor: "white",
                  borderRadius: "10px 0px 0px 0px",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  background: "#F3F3F3",
                  color: "#000000",
                  lineHeight: "20px",
                }}
              />
              <Tab
                label="Add Unit"
                {...a11yProps(1)}
                style={{
                  width: "30%",
                  backgroundColor: "white",
                  borderRadius: "0px 10px 0px 0px",
                  fontSize: "16px",
                  fontFamily: "poppins",
                  background: "#F3F3F3",
                  color: "#000000",
                  lineHeight: "20px",
                }}
              />
            </Tabs>
          </Box>
          <Box
            style={{
              backgroundColor: "white",
              marginTop: "-1px",
              height: "100%",
            }}
          >
            {loader ? (
              <Box
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  height: "80vh",
                  marginTop: "-6.6%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <Box>
                {value === 0 ? (
                  <Box style={{ width: "95%", marginLeft: "2.5%" }}>
                    <Grid container spacing={5}>
                      <Grid item xs={6}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography style={InputCss}>Tags/Units</Typography>
                          <Custom.Input
                            name="Tags"
                            placeholder="Select Tags"
                            size="small"
                            s
                            value={courseTags}
                            onChange={(e) => setCourseTags(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography style={InputCss} variant="h6">
                            Difficulty Level
                          </Typography>
                          <Custom.ReusableDropdown
                            placeholder="Please Select Difficulty"
                            Allvalue={"All"}
                            displayEmpty
                            name="difficulty"
                            style={{
                              height: 37,
                              fontFamily: "Poppins",
                            }}
                            value={courseDifficulty}
                            onChange={(e) =>
                              setCourseDifficulty(e.target.value)
                            }
                            options={options}
                            size="small"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Box style={{ height: "44vh", overflowY: "scroll" }}>
                      <Typography
                        style={InputCss}
                        variant="h6"
                        className={"Units"}
                      >
                        Courses
                      </Typography>
                      {UpdatedCourseData.length === 0 ? (
                        <Box
                          className="NoUnitsDiv"
                          sx={{
                            width: "98%",
                            // marginLeft: "2.5%",
                            height: "42vh",
                          }}
                        >
                          <Typography
                            variant="h9"
                            style={{ fontSize: 20, fontWeight: 600 }}
                          >
                            No Course Found
                          </Typography>
                        </Box>
                      ) : null}

                      {UpdatedCourseData.map((allCourseData) => {
                        return (
                          // UpdatedCourseData.length !== 0 ? (
                          <Grid container className="InnerGrid">
                            <Grid style={{ width: "100%" }}>
                              <div className="UnitCard">
                                <Box
                                  style={{
                                    display: "flex",
                                    marginTop: "8px",
                                    marginLeft: "6px",
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      !!selectedCourse.find(
                                        (IndSelectedCourse) =>
                                          IndSelectedCourse?.courseId ===
                                          allCourseData.courseId
                                      )
                                    }
                                    onChange={(event) =>
                                      handleCourseCheckBoxSelect(
                                        event,
                                        allCourseData
                                      )
                                    }
                                  />
                                  <Typography
                                    style={UnitTopic}
                                    variant="h12"
                                    className="uniTopic"
                                  >
                                    {allCourseData.courseTitle}
                                  </Typography>
                                </Box>
                                <Typography className="UnitDuration">
                                  {allCourseData.time} min
                                </Typography>
                              </div>

                              <div className="UnitDescription">
                                <Typography
                                  noWrap={true}
                                  className="UnitDecpTypo"
                                >
                                  {allCourseData.courseDetails}
                                </Typography>
                              </div>
                              <Accordion
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  boxShadow: "none",
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  style={{
                                    marginLeft: "1%",
                                    justifyContent: "right",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "right",
                                      width: "100%",
                                    }}
                                  >
                                    <Typography
                                      style={CourseUnitHeader}
                                      variant="h6"
                                    >
                                      View all Units
                                    </Typography>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{
                                    paddingLeft: "20px",
                                    marginTop: "-8px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  {allCourseData.units.map((unitsData) => {
                                    return (
                                      <Box
                                        style={{
                                          paddingBottom: "10px",
                                          display: "flex",
                                        }}
                                      >
                                        <Checkbox
                                          checked={checkifCourseUnitSelected(
                                            allCourseData.courseId,
                                            unitsData.unitid,
                                            unitsData
                                          )}
                                          onChange={(event) =>
                                            courseUnitHandleCheckBoxSelect(
                                              allCourseData.courseId,
                                              unitsData.unitid,
                                              event,
                                              unitsData
                                            )
                                          }
                                          disabled={checkIfCourseUnitDisabled(
                                            allCourseData.courseId,
                                            unitsData.unitid
                                          )}
                                        />
                                        <Typography style={UnitTopic}>
                                          {unitsData.unitTitle}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        );
                        // ) : (
                        // <Box
                        //   className="NoUnitsDiv"
                        //   sx={{
                        //     width: "95%",
                        //     marginLeft: "2.5%",
                        //     height: "52vh",
                        //   }}
                        // >
                        //   <Typography
                        //     variant="h9"
                        //     style={{ fontSize: 20, fontWeight: 600 }}
                        //   >
                        //     No Course Found
                        //   </Typography>
                        // </Box>
                        // );
                      })}
                    </Box>
                    {courseCount > 4 ? (
                      <Box style={{ marginBottom: "20px" }}>
                        <ReactPaginate
                          previousLabel={<>&laquo;</>}
                          nextLabel={<>&raquo;</>}
                          pageCount={Math.ceil(courseCount / 4)}
                          onPageChange={handlePageClickCourse}
                          containerClassName={"pagination"}
                          previousLinkClassName={"pagination__link"}
                          nextLinkClassName={"pagination__link"}
                          disabledClassName={"pagination__link--disabled"}
                          activeClassName={"pagination__link--active"}
                          forcePage={coursePageIndex}
                        />
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                ) : (
                  <Box style={{ width: "95%", marginLeft: "2.5%" }}>
                    <Grid container spacing={5}>
                      <Grid item xs={6}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography style={InputCss}>Tags/Units</Typography>
                          <Custom.Input
                            name="Tags"
                            placeholder="Select Tags"
                            size="small"
                            s
                            value={courseTags}
                            onChange={(e) => setCourseTags(e.target.value)}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography style={InputCss} variant="h6">
                            Difficulty Level
                          </Typography>
                          <Custom.ReusableDropdown
                            placeholder="Please Select Difficulty"
                            Allvalue={"All"}
                            displayEmpty
                            name="difficulty"
                            style={{
                              height: 37,
                              fontFamily: "Poppins",
                            }}
                            value={courseDifficulty}
                            onChange={(e) =>
                              setCourseDifficulty(e.target.value)
                            }
                            options={options}
                            size="small"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Box>
                      <Box style={{ height: "44vh", overflowY: "scroll" }}>
                        <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                          Units
                        </Typography>
                        {allUnits?.length <= 0 ? (
                          <Box
                            className="NoUnitsDiv"
                            sx={{
                              width: "95%",
                              marginLeft: "2.5%",
                              height: "52vh",
                            }}
                          >
                            <Typography
                              variant="h9"
                              style={{ fontSize: 20, fontWeight: 600 }}
                            >
                              No Units Found
                            </Typography>
                          </Box>
                        ) : (
                          allUnits?.map((allUnitData) => {
                            return (
                              <Grid container className="InnerGrid">
                                <Grid style={{ width: "100%" }}>
                                  <div className="UnitCard">
                                    <Box style={{ display: "flex" }}>
                                      <Checkbox
                                        checked={
                                          checkboxId.has(allUnitData.id) ||
                                          handleCourseUnitSelcted(
                                            allUnitData.id
                                          )
                                        }
                                        onChange={(event) =>
                                          handleUnitCheckboxClick(
                                            allUnitData.id,
                                            allUnitData,
                                            event
                                          )
                                        }
                                      />
                                      <Typography
                                        style={UnitTopic}
                                        variant="h12"
                                        className="uniTopic"
                                      >
                                        {allUnitData.topic}
                                      </Typography>
                                    </Box>
                                    <Typography className="UnitDuration">
                                      {allUnitData.duration} min
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: "7.3%",
                                    }}
                                  >
                                    {allUnitData.tagDetails?.map((txt, key) => (
                                      <Typography
                                        key={key}
                                        numberOfLines={1}
                                        style={styles.UnitTagsStyle}
                                      >
                                        {txt.tag_name}
                                      </Typography>
                                    ))}
                                  </div>
                                  <div className="UnitDescription">
                                    <Typography
                                      noWrap={true}
                                      className="UnitDecpTypo"
                                    >
                                      {allUnitData.description}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            );
                          })
                        )}
                      </Box>

                      {unitCount > 4 ? (
                        <Box style={{ marginBottom: "20px" }}>
                          <ReactPaginate
                            previousLabel={<>&laquo;</>}
                            nextLabel={<>&raquo;</>}
                            pageCount={Math.ceil(unitCount / 4)}
                            onPageChange={handlePageClickUnit}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            forcePage={unitPageIndex}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            <Box
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "row",
                justifyContent: "flex-end",
                paddingBottom: "10px",
              }}
            >
              <ReusableButton
                variant="outlined"
                buttonName="Cancel"
                className={"modalCancelBtn"}
                size="medium"
                // style={styles.modalCancelBtn}
                onClick={() => {
                  handleModalClose();
                }}
              >
                Cancel
              </ReusableButton>

              <ReusableButton
                className={"addbutton"}
                type="submit"
                variant="contained"
                buttonName="Add"
                size="medium"
                style={styles.modalAddButton}
                onClick={() => handleUnitCourseAdd()}
              >
                Add
              </ReusableButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
export default React.memo(AddCourseUnitsModal);
