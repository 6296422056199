import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import ReusableButton from "../../../components/Custom/button/button";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { SxStyles } from "../../../styles/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Notification from "../../../components/Custom/Notification";
import { API } from "../../../Networking/API";
import { InputLabel } from "@material-ui/core";

const VideoWithQuizType = (props) => {
  const { data, handleClose, getTrainingMaterial} = props;
  const[videomaterial,setVideoMaterial]=useState(false);
  const[checked,setChecked]=useState(false);
  const[questionsMaterial,setQuestionsMaterial]=useState(false);
  const styles = SxStyles.styles;
  const [vqdata, setVqdata] = useState({
    filename: data.filename,
    questions: data.material,
    videolink: data.videoLink,
    duration: data.duration,
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  let mandateId="";
  if (window.localStorage.getItem("UnitId")) {
    mandateId = window.localStorage.getItem("UnitId");
  } else {
    mandateId = window.localStorage.getItem("mandateId");
  }
  const SheetJSFTVideo = ["mp4"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  const SheetJSFTCSV = ["csv"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    setVqdata({ ...vqdata, videolink: file });
  };
  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setVqdata({ ...vqdata, questions: file });
  };
  const handleChange = (event) => {
    setVqdata({ ...vqdata, [event.target.name]: event.target.value });
  };
  
  useEffect(()=>{
    if(data.material.length===0){
        setVideoMaterial(true);
    }
  },[videomaterial]);

  useEffect(()=>{
    if(data.videoLink.length===0){
        setQuestionsMaterial(true);
    }
  },[questionsMaterial]);

  const handleUpdate = () => {
    if(vqdata.filename===""  || vqdata.videolink==="" || vqdata.duration==="" || !vqdata.questions){
        return setNotify({
            isOpen: true,
            message: "Please Fill Mandatory Fields",
            type: "error",
          });
    }
    setChecked(true);
    API.updateVideoWithQuiz(
        data.id,
        vqdata.duration,
        vqdata.filename,
        "Video_With_Quiz",
        mandateId,
        vqdata.questions==="N/A" ? "" : vqdata.questions,
        vqdata.videolink,
).then(({ response }) => {
        if (response.api_status === 200) {
          setNotify({
            isOpen: true,
            message: response?.data[0]?.message,
            type: "success",
          });
          getTrainingMaterial(window.localStorage.getItem("mandateId"));
        setTimeout(()=>{
            handleClose();
          },1000);
        } else {
            setChecked(false);
          setNotify({
            isOpen: true,
            message: response?.error[0].error_msg,
            type: "error",
          });
        }
      });
  };
  return (
    <div>
        <h2 style={{ color: "black" }}>Edit Video With Quiz:</h2>
      <Grid container spacing={2} sx={{ padding: "10px" }}>
        <Grid
          item
          xs={12}
          lg={3.5}
          md={3}
          sm={5}
          // style={{ marginLeft: "3%" }}
        >
          <InputLabel className="InputLabel" style={styles.label}>
            Name
            <span style={styles.madatoryMark}>*</span>
          </InputLabel>
          <ReusableInputfield
            // name="duration"
            // id="duration"
            placeholder="Slide name"
            // disabled={mandateId === null ? false : isDisable}
            className="name-Training-material"
            name="filename"
            id="slideName"
            size="small"
            value={vqdata.filename}
            //type="number"
            onChange={handleChange}
            InputProps={{
              autoComplete: "off",
              inputProps: {
                style: { padding: "9px 12px", width: "90%" },
              },
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={3.5}
          md={3}
          sm={5}
          style={
            {
              display: "flex",
              flexDirection: "column",
              // marginLeft: "3%",
            }
            // errors.file
            //   ? styles.inputContainerValidator
            // styles.inputContainer
          }
          //   sx={{ marginLeft: "10%" }}
        >
          <div style={styles.inputContainer}>
            <InputLabel
              className="InputLabel"
              style={styles.label}
              variant="h6"
            >
              Upload Video
              <span style={styles.madatoryMark}>*</span>
            </InputLabel>
            {videomaterial ? 
            <>
            <input
              type="file"
              name="videolink"
              id="file"
              class="upload-box"
              //   ref={inputRef}
              accept={SheetJSFTVideo}
              style={{
                fontSize: "0.8em",
                fontFamily: "Poppins",
                height: "41px",
                position: "relative",
                // bottom: "3px",
                width: "100%",
                marginTop: "3px",
              }}
              onChange={(e) => handleVideoChange(e)}
            />
            </>
            :
            <div style={{ display: "flex" }}>
            <ReusableInputfield
              name="pdffile"
              id="pdffile"
              value={
                vqdata.videolink.length > 19
                  ? `${vqdata.videolink.slice(0, 17)}...`
                  : vqdata.videolink
              }
              readOnly={true}
              size="small"
              style={{ height: "41px !important" }}
            />
            <IconButton
              aria-label="delete"
              style={{
                position: "relative",
                right: "15%",
                paddingTop: "8px",
              }}
              onClick={() => {
                 setVqdata({...vqdata,videolink:""});
              //   setPptlink(false);
                setVideoMaterial(true);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </div>
}
            <Typography style={styles.note} variant="p">
              Only video file allowed
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} lg={3.5} md={3} sm={5}>
          <div style={styles.inputContainer}>
            <Typography style={styles.label} variant="h6">
              Upload Questions
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            {/* <span> */}
            {questionsMaterial ? 
            
           <>
            <input
              type="file"
              name="questions"
              id="file"
              class="upload-box"
              //   ref={inputRef}
              accept={SheetJSFTCSV}
              style={{
                fontSize: "0.8em",
                fontFamily: "Poppins",
                height: "41px",
                position: "relative",
                width: "100%",
              }}
              onChange={(e) => handleFileChange(e)}
            />
            </>
            :
            <div style={{ display: "flex" }}>
            <ReusableInputfield
              name="pdffile"
              id="pdffile"
              value={vqdata.questions==="N/A" ?  "https://absyz-aca.." : vqdata.questions }
              readOnly={true}
              size="small"
              style={{ height: "41px !important" }}
            />
            <IconButton
              aria-label="delete"
              style={{
                position: "relative",
                right: "15%",
                paddingTop: "8px",
              }}
              onClick={() => {
                 setVqdata({...vqdata,questions:""});
                setQuestionsMaterial(true);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </div>
}
            <Typography style={styles.note} variant="p">
              Only .csv format allowed
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          lg={3.5}
          md={3}
          sm={5}
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "0%",
          }}
        >
          <div style={styles.inputContainer} sx={{ marginLeft: "0px" }}>
            <Typography style={styles.label} variant="h6">
              Duration(in mins)
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            <span>
              <ReusableInputfield
                name="duration"
                id="duration"
                placeholder="Duration"
                className="name-Training-material"
                value={vqdata.duration}
                style={{
                  height: "41px",
                  width: "100%",
                }}
                onChange={(e) => handleChange(e)}
                // error={touched.duration && Boolean(errors.duration)}
                // helperText={touched.duration && errors.duration}
                size="small"
                InputProps={{
                  autoComplete: "off",
                  inputProps: {
                    style: { padding: "10px 14px" },
                  },
                }}
                type="text"
              />
            </span>
          </div>
        </Grid>
        <Box
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
                marginRight: "10px",
                marginTop:"10px"
              }}
            >
              <ReusableButton
                size="medium"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={styles.cancelButton}
                onClick={handleClose}
              />
              <ReusableButton
                size="medium"
                variant="contained"
                className="qstnUpldPageBttn"
                buttonName={checked ? "Updating..." : "Update"}
                style={{...styles.createBtnDialog,marginLeft:"10px"}}
                onClick={handleUpdate}
              />
            </Box>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default VideoWithQuizType;
