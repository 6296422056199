import React, { useState, useEffect } from "react";
import { Custom } from "../../../components/Custom/Custom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import ReactPaginate from "react-paginate";
import { API } from "../../../Networking/API";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { MyForm } from "../../../components/MyForm";
import "./mentor-session.css";
import ReactLoading from "react-loading";
import Button from "../../../components/Custom/button/button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

// const getDepartment = [
//   { id: "1", title: "All" },
//   { id: "2", title: "Upcoming" },
//   { id: "3", title: "Pending" },
//   { id: "4", title: "Assessed" },
//   { id: "5", title: "Completed" },
// ];
const getDepartment = [
  { id: "1", title: "All" },
  { id: "2", title: "upcoming" },
  { id: "3", title: "today" },
  { id: "4", title: "completed" },
];

const styles = makeStyles((theme) => ({
  inputStyle: {
    "& .MuiInputBase-fullWidth": {
      width: "100%",
      height: "44px",
    },
  },
}));

const initialValues = {
  sessionName: "",
};

const MentorSession = () => {
  const [done, setDone] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const classes = styles();
  const [CompletedData, setCompletedData] = useState();
  // const [userData, setUserData] = useState("");
  const [noSession, setNoSession] = useState(false);
  const jwtToken = Cookies.get("jwt_token");
  const batchid = Cookies.get("batch_id");
  // const [handleInputChange, setHandleInputChange] =useState();
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  let navigate = useNavigate();
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true);

  const handleInputChangeFilter = (e) => {
    setFilter(e.target.value);
  };

  // const handleSearch = (e) =>{
  //   setsearch(e.target.value)
  // }

  // useEffect(() => {
  //   getCompletedData();
  // }, [filter]);

  useEffect(() => {
    getCompletedData("", 1);
  }, []);

  const handleSearch = () => {
    setDone(false);
    var searchWord = values.sessionName.toLowerCase().trim();
    if (searchWord.length !== 0) {
      setPageNumber(1);
      getCompletedData(searchWord, 1);
    } else {
      setPageNumber(1);
      getCompletedData(searchWord, 1);
    }
  };

  useEffect(() => {
    setDone(false);
    var searchWord = values.sessionName.toLowerCase().trim();
    setPageNumber(1);
    getCompletedData(searchWord, 1);
  }, [filter]);

  const getCompletedData = async (search, page) => {
    let value;
    if (filter === "All") {
      value = "";
    } else {
      value = filter;
    }

    API.getSessionDetails(page, value, search, batchid).then(({ response }) => {
    
      if (response.api_status === 200) {
        setNoSession(false);
        setErrorMsg("");
        setErrorStatus(false);

        // const dataArray = response.data[0].schedules;
        const dataArray = response.data[0].completed;
        setPageCount(Math.ceil(response.data[0].total_count / 6));
        const newData = [];
        dataArray.map((data) => {
          const completedSessionDetails = {
            day: data.day,
            diff: data.diff,
            duration: data.duration,
            from_time: data.from_time,
            schedule_pk: data.schedule_pk,
            schedule_type: data.schedule_type,
            time: data.time,
            to_time: data.to_time,
            topic: data.topic,
            topic_id: data.topic_id,
            trainer_id: data.trainer_id,
            training_date: data.training_date,
            user_name: data.user_name,
            user_email: data.user_email,
          };
          newData.push(completedSessionDetails);
        });

        setCompletedData(newData);
        setFilteredData(newData);
      } else {
        setErrorMsg(response.error[0].error_msg);
        setErrorStatus(true);
        setFilteredData([]);
        setPageCount(0);
      }
      setDone(true);
    });
  };
  const handlePageClick = async (event) => {
    setDone(false);
    setPageNumber(event.selected + 1);
    getCompletedData(values.sessionName.trim(), event.selected + 1);
    // }
  };
  return (
    <>
      <h1 className="trainerHeading">Session Details</h1>
      <Button
        buttonName="Back"
        className="traineebutton"
        startIcon={<ArrowBackIosIcon />}
        style={{ textTransform: "none", color: "#02215b" }}
        // onClick={() => navigate("/trainee/home/calendardetails")}
        onClick={() => navigate("/user/bootcamp/mentor/home/")}
      />
      <Box className="box2">
        <Grid container>
          <Grid item xs={2.5}>
            <Custom.Dropdown
              name="sessions"
              label="All Sessions"
              value={filter}
              onChange={handleInputChangeFilter}
              options={getDepartment}
              // error={errors.department}
              size="small"
              className="mentorclassName"
              style={{
                height: 40,
                alignItems: "center",
                display: "flex",
                fontFamily: "Poppins",
                "& .MuiPopover-paper": {
                  height: "19.5vh",
                  minWidth: "180px",
                },
              }}
            ></Custom.Dropdown>
          </Grid>
          <Grid item xs={0.25}></Grid>
          <Grid item xs={7.45}>
            <Custom.Input
              name="sessionName"
              type="text"
              placeholder="Search by name"
              size="small"
              className={classes.inputStyle}
              onChange={handleInputChange}
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={1}>
            <ReusableButton
              // onClick={handleSubmit}
              type="search"
              size="large"
              variant="contained"
              buttonName="Search"
              onClick={handleSearch}
              style={{
                backgroundColor: "#02215b",
                borderRadius: 6,
                padding: "8px 36px",
                width: "117px",
                height: "38px",
                flexGrow: 0,
                margin: " 3px 0 3px 30px",
                padding: " 11px 27.5px 11px 27.5px",
                backgroundColor: " #02215b",
              }}
              className="buttonStyle"
            >
              Search
            </ReusableButton>
          </Grid>
        </Grid>
      </Box>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : filteredData.length !== 0 ? (
        <Grid container>
          {filteredData.map((item, i) => (
            <>
              <Grid item xs={6} md={4} key={item.schedule_pk}>
                <Link
                  to="/user/bootcamp/mentor/home/session-details"
                  style={{ textDecoration: "none", color: "none" }}
                  state={{ data: item.schedule_pk }}
                >
                  <Box className={"cardBox2"}>
                    <Grid
                      container
                      sx={{
                        verticalAlign: "center",
                        // backgroundImage: ,
                        padding: "0px 0px",
                        display: "flex",

                        justifyContent: "end",
                        alignContent: "center",
                        height: "96px",
                        width: "100%",
                        borderTopLeftRadius: "12px",
                      }}
                      className={
                        item.schedule_type === 0
                          ? "background-image1"
                          : "mentor-background-image2"
                      }
                    >
                      <Grid item xs={3} md={4}></Grid>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        // justifyContent="flex-start"
                        style={{
                          margin: " 15px 0 0 122.8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={
                          item.day === "Pending"
                            ? "trainertoday"
                            : item.day === "Assessed"
                            ? "trainercompleted"
                            : "trainerupcoming"
                        }
                      >
                        <div>
                          <p className="trainerspanText1">{item.day}</p>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{ paddingLeft: "24px", marginTop: "0px" }}
                    >
                      <Grid item xs={6} md={7}>
                        <h1 className="heading3">{item.topic}</h1>
                      </Grid>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <p className="timeText2">{item.training_date}</p>
                      </Box>
                    </Grid>
                  </Box>
                </Link>
              </Grid>
            </>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="sub">No Sessions Available</h2>
        </Box>
      )}
      <Box
        sx={{
          marginBottom: "100px",
          marginRight: "30px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {pageCount > 1 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >>"
            forcePage={pageNumber - 1}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="<<"
            renderOnZeroPageCount={null}
            pageClassName="pageClassNameTrainer"
            pageLinkClassName="pageLinkTrainer"
            previousClassName="previousClassTrainer"
            previousLinkClassName="previousLinkClassTrainer"
            nextClassName="nextClassTrainer"
            nextLinkClassName="nextLinkClassTrainer"
            breakClassName="breakClassTrainer"
            breakLinkClassName="breakLinkClassTrainer"
            containerClassName="paginationTrainer"
            activeClassName="activeClassTrainer"
            disabledClassName="disabledClassName"
          />
        ) : null}
      </Box>
    </>
  );
};

export default MentorSession;
