function useVideoLinkValidator(url){
  const regexList = [
    /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/i,
    /\.(mp4|avi|mkv|mov|wmv|flv|webm|mpeg|mpg|3gp|ogg|ogv|asf|ts|wma|mk3d|m4a|m4v|f4v|f4p|f4a|f4b)$/i,
    /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/(edit|view)\?usp=drivesdk/,
  ];
  function checkURLWithRegexList(url) {
    return regexList.map((regex) => regex.test(url));
  }
  const results = checkURLWithRegexList(url);
  return results.some((element) => element === true);
};

export default useVideoLinkValidator;
