import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { InputLabel, IconButton } from "@material-ui/core";
import { SxStyles } from "../../../styles/styles";
import "./mandatoryTraining-layout.css";
import ReusableButton from "../../../components/Custom/button/button";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import Notification from "../../../components/Custom/Notification";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { API } from "../../../Networking/API";
const VideoType = (props) => {
  const { data, handleClose, getTrainingMaterial } = props;
  const[checked,setChecked]=useState(false);
  const styles = SxStyles.styles;
  const [updateMaterial, setUpdateMaterial] = useState(false);
  // State variables for input values
  const [videoData, setVideoData] = useState({
    filename: data.filename,
    material: data.material,
    description: data.description,
    duration: data.duration,
  });
  let mandateId = "";
  if (window.localStorage.getItem("UnitId")) {
    mandateId = window.localStorage.getItem("UnitId");
  } else {
    mandateId = window.localStorage.getItem("mandateId");
  }
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (files) videoData.material = files[0];
    else videoData[name] = value;
    setVideoData({
      ...videoData,
      filename: videoData.filename,
      duration: videoData.duration,
      description: videoData.description,
    });
  };

  const handleAddButtonClick = () => {
    if (videoData.filename === "" || videoData.material === "" || videoData.description === "" || videoData.duration === "") {
        return setNotify({
          isOpen: true,
          message: "Please Enter Mandatory Fields",
          type: "error",
        });
      }
      setChecked(true);
    API.updateTrainingMaterial(
      data.id,
      videoData.filename,
      videoData.material,
      videoData.videoLink,
      mandateId,
      videoData.duration,
      videoData.description
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: response?.data[0]?.message,
          type: "success",
        });
        getTrainingMaterial(window.localStorage.getItem("mandateId"));
        setUpdateMaterial(false);
        setTimeout(()=>{
            handleClose();
          },600);
      } else {
        setChecked(false);
        setNotify({
          isOpen: true,
          message: response?.data[0]?.message,
          type: "error",
        });
      }
    });
  };
  useEffect(() => {
    if (data.material.length === 0) {
      setUpdateMaterial(true);
    }
  }, [updateMaterial]);
  return (
    <>
      <h2 style={{ color: "black" }}>Edit Video:</h2>
      <Grid
        container
        spacing={2}
        style={{
          padding: "10px",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Grid item xs={12} lg={4} md={3} sm={5}>
          <InputLabel className="InputLabel" style={styles.label}>
            Name
            <span style={styles.madatoryMark}>*</span>
          </InputLabel>
          <ReusableInputfield
            placeholder="Slide name"
            className="name-Training-material"
            name="filename"
            id="slideName"
            size="small"
            value={videoData.filename}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={4} md={3} sm={5} className="fileContainerFiles">
          <div>
            <InputLabel className="InputLabel" style={styles.label}>
              {updateMaterial ? "Upload Video File" : "Video File"}
              <span style={styles.madatoryMark}>*</span>
            </InputLabel>
          </div>
          {updateMaterial ? (
            <>
              <input
                type="file"
                name="file"
                className="upload-box-files"
                accept="video/mp4,video/x-m4v,video/*"
                style={{
                  marginTop: "1%",
                  fontSize: "0.8em",
                  fontFamily: "Poppins",
                  paddingBottom: "0%",
                  height: "41px",
                  width: "220px",
                }}
                onChange={handleChange}
              />
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <ReusableInputfield
                  name="pdffile"
                  id="pdffile"
                  value={
                    videoData?.material?.length > 19
                      ? `${videoData?.material.slice(0, 20)}...`
                      : videoData?.material
                  }
                  readOnly={true}
                  size="small"
                  style={{ height: "41px !important" }}
                />
                <IconButton
                  aria-label="delete"
                  style={{
                    position: "relative",
                    right: "15%",
                    paddingTop: "8px",
                  }}
                  onClick={() => {
                    videoData.material="";
                    // setLinkFilled(false);
                    setUpdateMaterial(true);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            </>
          )}
        </Grid>

        <Grid item xs={12} lg={4} md={3} sm={5}>
          <InputLabel className="InputLabel" style={styles.label}>
            Description
            <span style={styles.madatoryMark}>*</span>
          </InputLabel>
          <ReusableInputfield
            className="name-Training-material"
            name="description"
            id="description"
            placeholder="Description"
            size="small"
            value={videoData.description}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={5} md={3} lg={4}>
          <div style={styles.inputContainer} sx={{ marginLeft: "0px" }}>
            <Typography style={styles.label} variant="h6">
              Duration(in mins)
              <span style={styles.madatoryMark}>*</span>
            </Typography>
            <ReusableInputfield
              name="duration"
              id="duration"
              placeholder="Duration"
              style={{
                height: "41px",
                width: "100%",
              }}
              size="small"
              value={videoData.duration}
              onChange={handleChange}
              type="text"
            />
          </div>
        </Grid>
        <Box
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            marginRight: "10px",
          }}
        >
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            buttonName="Cancel"
            style={styles.cancelButton}
            onClick={handleClose}
          />
          <ReusableButton
            size="medium"
            variant="contained"
            className="qstnUpldPageBttn"
            buttonName={checked ? "Updating..." : "Update"}
            style={styles.createBtnDialog}
            onClick={handleAddButtonClick}
          />
        </Box>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
export default VideoType;
