import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { Custom } from "../../../components/Custom/Custom";
import { InputLabel } from "@material-ui/core";
import "./mandatoryTraining-layout.css";
import { IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReusableButton from "../../../components/Custom/button/button";
import { useFormik } from "formik";
import { CreateMandateIntroduction } from "../../../Validation/FieldValidatorSchema";
import { SxStyles } from "../../../styles/styles";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { API } from "../../../Networking/API";
import { DropDownHandler } from "../../../handler/dropDownHandler";
import Notification from "../../../components/Custom/Notification";
import ReactLoading from "react-loading";
import DeletePopUp from "../masterData-layout/masterDataModal/deletePopUp";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { CreateMandateTrainingHelper } from "../../../helper/mandatoryHelper";
import DataTableDragMandate from "./mandatoryDraggableSildeTable";
import SlideImageEditDialog from "./mandatoryImageSlideEdit";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { ReusableRememberMe } from "../../../components/Custom/remember-me/remember-me";
import Filetype from "./filetype";
import VideoType from "./videotype";
import PptType from "./ppttype";
import VideoWithQuizType from "./videowithquiztype";
const MandatoryIntroduction = (props) => {
  const styles = SxStyles.styles;
  let { mandateData, setDisabled, isUnit } = props;
  const [isDisable, setIsDisabled] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [files, setFiles] = useState([]);
  const [deleteModal, setDeletModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [openFileAddition, setOpenFileAddition] = useState(false);
  const [loaderForSaveLaterTM, setLoaderForSaveLaterTM] = useState(false);
  const [loaderForPublishTM, setLoaderForPublishTM] = useState(false);
  const [columns, setColumns] = useState(0);
  const [open, setOpen] = useState(false);
  const [Editopen, setEditOpen] = useState(false);
  const [mediaType, setMediaType] = useState("");
  const[updateMaterial,setUpdateMaterial]=useState(false);
  const [deleteTrainingMaterialId, setDeleteTrainingMaterialId] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const dataTableHeader = [
    'checkBox',
    "Name",
    "Video Link",
    "Material",
    "Duration",
    "Actions",
  ];
  const [draggableData, setDraggableData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [filetoUpload, setFile] = useState();
  const [trainingSlides, setTrainingSLides] = useState([]);
  const [SlideId, setSlideId] = useState();
  let mandateId = "";
  if (window.localStorage.getItem("UnitId")) {
    mandateId = window.localStorage.getItem("UnitId");
  } else {
    mandateId = window.localStorage.getItem("mandateId");
  }
  const [editName, setEditName] = useState("");
  const[materialType,setMaterialType]=useState("");
  const [editDesc, setEditDesc] = useState("");
  const [editCount, setEditCount] = useState("");
  const [editID, setEditID] = useState("");
  const [done, setDone] = useState(true);
  const [checkupload, setCheckupload] = useState(false);
  const [linkfilled, setLinkFilled] = useState(false);
  const [pptfilled, setPPTFilled] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const inputRef = React.useRef();

  const SheetJSFTCSV = ["csv"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");

  const SheetJSFTPPT = ["ppt, ppsx, pptx, pdf"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  const [loaderForSaveLaterUnit, setloaderForSaveLaterUnit] = useState(false);
  const [loaderForPublishUnit, setloaderForPublishUnit] = useState(false);
  const[fileData,setFileData]=useState({
    filename:"",
    duration:"",
    id:"",
    videoLink:"",
    material:"",
    description:"",
});
  const [openPopup, setopenPopup] = useState(false);
  const [number, setNumber] = useState(0);
  const [pptFile, setPPTFile] = useState("");

  const handleOpenDialog = () => {
    if (number > 0) {
      setOpenFileAddition(!openFileAddition);
    }
  };

  let navigate = useNavigate();
  let st = {
    "& .MuiOutlinedInput-input": {
      padding: "10px 10px",
    },
  };

  const mediaTypes = [
    // {
    //   id: 1,
    //   name: "Question",
    //   value: "QUESTION_TYPE",
    // },
    {
      key: 2,
      title: "Files",
      value: "FILES",
    },
    {
      key: 3,
      title: "Video",
      value: "VIDEO",
    },
    {
      key: 4,
      title: "Video with Quizz",
      value: "VIDEO_WITH_QUIZ",
    },
    {
      key: 5,
      title: "PPT",
      value: "PPT",
    },
  ];

  const SheetJSFTVideo = ["mp4"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");

  const SheetJSFT = ["png,jpeg, mp4"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
    const SheetCommon = ["png,jpeg, mp4"]
    .map(function (x) {
      return "." + x;
    })
    .join(",");
  const formik = useFormik({
    initialValues: {
      fileName: "",
      videoLink: "",
      file: "",
      trainingName: "",
      mediaType: "",
      columns: 0,
      slideName: "",
      duration: "",
      pptLink: "",
    },
    validationSchema: CreateMandateIntroduction,
    onSubmit: (values) => {
      // handleUnitCreation();
    },
  });

  const validateVideoLink = (url) => {
    const regexList = [
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/i,
      /\.(mp4|avi|mkv|mov|wmv|flv|webm|mpeg|mpg|3gp|ogg|ogv|asf|ts|wma|mk3d|m4a|m4v|f4v|f4p|f4a|f4b)$/i,
      /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/(edit|view)\?usp=drivesdk/,
    ];
    function checkURLWithRegexList(url) {
      return regexList.map((regex) => regex.test(url));
    }
    const results = checkURLWithRegexList(url);
    return results.some((element) => element === true);
  };

  const handleAddButtonClick = () => {
    if (!handleMaterialAdding()) {
      return setNotify({
        isOpen: true,
        message: "Fill all the mandatory fields",
        type: "error",
      });
    }
    if (formik.values.mediaType === "FILES") {
      if (!validateVideoLink(formik.values.videoLink) && !formik.values.file)
        return setNotify({
          isOpen: true,
          message: "Please Provide Valid Video Link",
          type: "error",
        });
    }
    setDone(true);
    const { fileName, videoLink, pptLink, file, duration } = formik.values;
    API.addAdminTrainingMaterial(
      mandateId,
      fileName,
      videoLink,
      pptLink,
      file,
      duration,
      formik.values.mediaType
    ).then(({ response }) => {
      //   inputRef.current.value = null;
      if (response.api_status === 200) {
        formik.resetForm();
        getTrainingMaterial();
        setFiles([]);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        setDone(false);
        setNotify({
          isOpen: true,
          message: response.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    getTrainingMaterial();
  }, []);

  const getTrainingMaterial = (message) => {

    if (message === "Training Material deleted successfully") {
      setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
    }
    API.getAdminTrainingMaterial(mandateId).then(({ response }) => {
      setDone(false);

      if (response.data.api_status === 200) {
        const data = response.data.data;
        setDeleteId(response.data.data[0].unit_id);
        const formattedData = data.map((each) => ({
          id: each.material_pk,
          trainingName: each.material_name,
          trainingVideo:
            each.video_link !== null && each.video_link !== ""
              ? each.video_link
              : "N/A",
          trainingMaterial:
            each.material_url !== null ? each.material_url : "N/A",
        }));
        const FinalFormData = data.map((each, index) => ({
          id: each.material_pk,
          sno: index + 1,
          first: each.material_name,
          second:
            each.video_link !== null && each.video_link !== ""
              ? each.video_link
              : "N/A",
          third: each.material_url !== null ? each.material_url : "N/A",
          fourth: each.duration,
          isChecked: false,
          description:each.description,
          type:each.media_type
          // five: "view",
        }));
        setTrainingSLides(FinalFormData);
        // setTableData(formattedData);
      } else {
        setTrainingSLides([]);
      }
    });
  };
  useEffect(() => {
    getDifficultyLevels();
    getTrainingMaterial(window.localStorage.getItem("mandateId"));
  }, []);

  useEffect(() => {
    if (!mandateData) {
      setLoader(false);
    } else {
      setIsDisabled(true);
      setLoader(true);
    }
  }, [mandateData]);

  const getDifficultyLevels = () => {
    API.getDifficultyLevel().then(({ response }) => {
      const DiffcultyData = response.data.data;
      setDifficultyLevel(DropDownHandler(DiffcultyData));
    });
  };

  const handleChangedValues = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    formik.handleChange({
      target: {
        name: `${e.target.name}`,
        value: numericValue,
      },
    });
  };

  const handleUnitCreation = () => {
    CreateMandateTrainingHelper(
      formik,
      setIsDisabled,
      setNotify,
      setIsCreate,
      isCreate,
      setDisabled,
      navigate,
      setloaderForSaveLaterUnit,
      setloaderForPublishUnit
    );
  };

  const deleteModalClose = () => {
    setDeletModal(false);
  };

  const HandleDeleteUnit = async () => {
    setDeletModal(false);
    navigate("/home/courses");
  };

  const cancelUnit = async () => {
    if (!isDisable) {
      setDeletModal(true);
    } else {
      navigate("/home/courses");
    }
  };

  let renderButton;

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
    setFiles((oldArray) => [...oldArray, file]);
    if( file === undefined ){
      setLinkFilled(false);
    } else setLinkFilled(true);
  };

  const handlePPTChange = (event) => {
    const file = event.target.files[0];

    setPPTFile(file);
  };

  // const handlePPTChange = (event, setFieldValue) => {
  //
  //   const file = event.target.files[0];
  //   setFieldValue("video", file);
  //   setFiles((oldArray) => [...oldArray, file]);
  // };

  const handleVideoChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("video", file);
    setFiles((oldArray) => [...oldArray, file]);
  };

  const groupAddImages = () => {
    setCheckupload(true);
    setLoaderForPublishTM(true);

    API.addGroupImages(
      filetoUpload ? filetoUpload : [],
      formik.values.mediaType,
      formik.values.slideName,
      formik.values.trainingName,
      formik.values.columns,
      mandateId
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setCheckupload(false);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
        getTrainingMaterial(window.localStorage.getItem("mandateId"));
        setFile();
        setLoaderForPublishTM(false);
        // formik.setFieldValue("mediaType", "");
        // formik.setFieldValue("trainingName", "");
        // formik.setFieldValue("columns", "");
        formik.resetForm();
      } else {
        setCheckupload(false);
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const addVideos = () => {
    if (
      !filetoUpload ||
      !formik.values.trainingName ||
      !formik.values.slideName ||
      !formik.values.duration
    ) {
      return setNotify({
        isOpen: true,
        message: "Fill All Mandatory fields",
        type: "error",
      });
    }
    setCheckupload(true);
    setLoaderForPublishTM(true);
    API.addVideos(
      filetoUpload,
      formik.values.trainingName,
      formik.values.slideName,
      mandateId,
      formik.values.mediaType,
      formik.values.duration
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setCheckupload(false);
        formik.resetForm();
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        setCheckupload(false);
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
      getTrainingMaterial(window.localStorage.getItem("mandateId"));
      setFile();
    });
  };

  const uploadVideoWithQuizz = () => {
    if (!formik.values.slideName || !files || !formik.values.duration)
      return setNotify({
        isOpen: true,
        message: "Please fill all the mandatory fields",
        type: "error",
      });
    setLoaderForPublishTM(true);

    API.postVideoWithQuizz(
      formik.values.slideName,
      formik.values.duration,
      files,
      mandateId
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setFiles([]);
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
        formik.resetForm();
        getTrainingMaterial(window.localStorage.getItem("mandateId"));
      } else {
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response?.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    if (formik.values.pptLink.length > 0) {
      setPPTFilled(true);
    } else if (pptFile) {
      setLinkFilled(true);
    } else {
      setPPTFilled(false);
      setLinkFilled(false);
    }
  }, [pptFile, formik.values.pptLink]);
  useEffect(() => {
     if (files) {
      // setLinkFilled(true);
    }
    else {
      setPPTFilled(false);
      setLinkFilled(false);
    }
  }, [files, formik.values.videoLink]);

  const uploadPPT = () => {
    if (
      !formik.values.slideName ||
      !formik.values.duration ||
      (!formik.values.pptLink && pptFile?.length === 0) ||
      !formik.values.mediaType ||
      files.length ===0
    )
      return setNotify({
        isOpen: true,
        message: "Please fill all the mandatory fields",
        type: "error",
      });
    setLoaderForPublishTM(true);

    API.postPPT(
      formik.values.slideName,
      formik.values.duration,
      formik.values.pptLink,
      formik.values.mediaType,
      mandateId,
      files[0],
      pptFile
    ).then(({ response }) => {
      if (response.api_status === 200) {
        setFiles([]);
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
        setPPTFilled(false);
        formik.resetForm();
        getTrainingMaterial(window.localStorage.getItem("mandateId"));
      } else {
        setLoaderForPublishTM(false);
        setNotify({
          isOpen: true,
          message: response?.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const handleMaterialAdding = () => {
    if (trainingSlides?.length > 0) {
      if (!formik.values.mediaType) return true;
      else if (!formik.values.mediaType) return false;
    } else {
      if (!formik.values.mediaType.length) return false;
    }
    if (formik.values.mediaType === "FILES") {
      if (formik.values.file || formik.values.videoLink) {
        if (!formik.values.fileName || !formik.values.duration) return false;
        return true;
      }
      return false;
    } else if (formik.values.mediaType === "VIDEO") {
      if (
        !formik.values.videoLink ||
        !formik.values.slideName ||
        !formik.values.duration
      )
        return false;
      return true;
    } else if (formik.values.mediaType === "VIDEO_WITH_QUIZ") {
      if (!formik.values.slideName || !files || !formik.values.duration) return false;
      return true;
    } else if (formik.values.mediaType === "PPT") {
      if (
        !formik.values.slideName ||
        !formik.values.duration ||
        (!formik.values.pptLink && pptFile.length === 0) ||
        !formik.values.mediaType ||
        files.length ===0
      ) {
        return false;
      }
      return true;
    }
    return true;
  };

  const handleSaveForLaterButton = () => {
    if (!handleMaterialAdding())
      return setNotify({
        isOpen: true,
        message:
          !formik.values.mediaType.length && trainingSlides?.length === 0
            ? "Please Select the media type"
            : "Please Add Training Material",
        type: "error",
      });
    setLoaderForSaveLaterTM(true);
    API.AdminSaveForLaterApi(mandateId).then(({ response }) => {
      if (response.data.api_status === 200) {
        setLoaderForSaveLaterTM(false);
        let responseMsg = response.data.data[0].message;
        setNotify({
          isOpen: true,
          message: isUnit
            ? responseMsg.replace("Mandatory Training", "Unit")
            : responseMsg,
          type: "success",
        });
      } else {
        setLoaderForSaveLaterTM(false);
        setNotify({
          isOpen: true,
          message: "Something went wrong, Please try again later",
          type: "error",
        });
      }
    });
  };

  if (mandateId === null || isDisable === false) {
    renderButton = (
      <ReusableButton
        className="addbutton"
        type="submit"
        size="medium"
        variant="contained"
        endIcon={
          !loaderForSaveLaterUnit ? (
            ""
          ) : (
            <CircularProgress size={20} style={{ color: "white" }} />
          )
        }
        buttonName={!loaderForSaveLaterUnit ? "Save for Later" : "Saving..."}
        style={
          !loaderForPublishUnit
            ? styles.saveForLaterBtn
            : styles.saveForLaterBtnDisabled
        }
        disabled={loaderForPublishUnit ? true : false}
      ></ReusableButton>
    );
  } else if (mandateId !== null || isDisable === true) {
    renderButton = (
      <ReusableButton
        className="addbutton"
        size="medium"
        variant="contained"
        style={styles.saveForLaterBtn}
        buttonName="Edit"
        onClick={() => {
          setTimeout(function () {
            setIsDisabled(false);
          }, 500);
        }}
      ></ReusableButton>
    );
  }

  useEffect(() => {
    if (columns > 0) {
      setOpenFileAddition(true);
      handleOpenDialog();
    }
  }, [columns]);
  const handleFileUpload = (event) => {
    setFile(event.target.files);
  };

  const uploadQuestionsFormandate = () => {
    setCheckupload(true);

    const id = localStorage.getItem("mandateId");
    API.uploadSlideQuestions(
      filetoUpload ? filetoUpload[0] : [],
      id,
      formik.values.mediaType
    ).then((response) => {
      if (response.response.api_status === 200) {
        setCheckupload(false);
        setNotify({
          isOpen: true,
          message: response.response.data[0].message,
          type: "Success",
        });
        setFile();
        formik.setFieldValue("mediaType", "");
        getTrainingMaterial(window.localStorage.getItem("mandateId"));
      } else {
        setCheckupload(false);
        setNotify({
          isOpen: true,
          message: response.response.error[0].error_msg,
          type: "error",
        });
      }
      formik.resetForm();
    });
  };

  const handleCloseInByCancel = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setUpdateMaterial(false);
    setEditOpen(false);
  };

  const handleDeleteInpre = (params) => {
    setDeleteTrainingMaterialId(params);
    setOpen(true);
  };

  const handleCloseDelete = (id) => {
    setOpen(false);
    setDone(true);

    API.deleteAdminMandatoryTrainingMaterial(id, mandateId).then((result) => {
      if (result.response.api_status === 200) {
        //
        //       setPageIndex(0);
        //       setPageCount(0);
        setNotify({
          isOpen: true,
          message: result.response.data[0].message,
          type: "success",
        });
        setDone(false);
        getTrainingMaterial(window.localStorage.getItem("mandateId"));
      } else {
        setNotify({
          isOpen: true,
          message: result?.error[0]?.error_msg,
          type: "error",
        });
      }
    });
  };

  const handleEditModal = (
    id,
    firstData,
    secondData,
    thirdData,
    fourthData,
    type,
    description
  ) => {
    setEditName(firstData);
    setEditDesc(secondData);
    setEditID(id);
    setMaterialType(type);
    setEditCount(fourthData);
    setMediaType(thirdData);
    setEditOpen(true);
    if(mediaType){
      setUpdateMaterial(false);
    }else{
      setUpdateMaterial(true);

    }
    setFileData({
      filename:firstData,
      duration:fourthData,
      id:id,
      videoLink:secondData,
      material:thirdData,
      description:description

    });
  };
  const handleEditUpdate = (slideID, slideName, Description, slideCount,mediaType) => {
    if(!slideID || !slideName || !Description || !mediaType || !slideCount){
     return setNotify({
        isOpen: true,
        message: "Please Fill Mandatory Fields",
        type: "error",
      });
    }
    if (slideName && slideCount) {
      setEditOpen(false);
      setDone(true);

      API.updateTrainingMaterial(
        slideID,
        slideName,
        mediaType === "N/A" ? "" : mediaType,
        Description,
        mandateId,
        slideCount
      ).then(({ response }) => {
        if (response.api_status === 200) {
          //       setPageIndex(0);
          //       setPageCount(0);
          setNotify({
            isOpen: true,
            message: response?.data[0]?.message,
            type: "success",
          });
          getTrainingMaterial(window.localStorage.getItem("mandateId"));
          setUpdateMaterial(false);
        } else {
          setNotify({
            isOpen: true,
            message: response?.data[0]?.message,
            type: "error",
          });
        }
      });
    } 
  };
  const handleViewClick = (params) => {
    const newdata = trainingSlides.filter((arr, index) => index + 1 === params);
    if (newdata[0].second === "N/A" || newdata[0].second === "undefined" ) {
      const material = newdata[0].third;
      window.open(material, "_blank");
    } else {
      const material = newdata[0].second;
      window.open(material, "_blank");
    }
  };


  const handleCreateButton = () => {
    setLoaderForPublishTM(true);
    if (!isUnit) {
      API.finalAdminCreationPublish(mandateId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setLoaderForPublishTM(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setLoaderForPublishTM(false);
          setNotify({
            isOpen: true,
            message: response?.data?.error[0].error_msg,
            type: "error",
          });
        }
      });
    } else {
      //for unit
      API.finalUnitCreation(mandateId).then(({ response }) => {
        if (response.data.api_status === 200) {
          setLoaderForPublishTM(false);
          setNotify({
            isOpen: true,
            message: response.data.data[0].message,
            type: "success",
          });
          navigate("/home/courses");
        } else {
          setLoaderForPublishTM(false);
          setNotify({
            isOpen: true,
            message: response?.error[0].error_msg,
            type: "error",
          });
        }
      });
    }
  };

  const OpenTheEditBox = (slideId) => {
    setSlideId(slideId);
    setopenPopup(true);
  };

  useEffect(() => {
    updateTrainingMaterial();
  }, [trainingSlides]);

  const updateTrainingMaterial = () => {
    const sortableIds = trainingSlides.map((x) => x.id);
    API.AdminTrainingMaterialReorder(mandateId, sortableIds).then(
      ({ response }) => {
        if (response.api_status === 200) {
          // setNotify({
          //   isOpen: true,
          //   message: response.data[0].message,
          //   type: "success",
          // });
        }
      }
    );
  };

  // useEffect(() => {
  //
  //   const SlideId = trainingSlides.map((data) => {
  //     return data.id;
  //   });
  //   API.ReorderSlide(
  //     SlideId,
  //     parseInt(window.localStorage.getItem("mandateId"))
  //   ).then(({ response }) => {});
  // }, [trainingSlides]);

  useEffect(() => {
    // formik.resetForm();
    // formik.setFieldValue("mediaType", "");
    formik.setFieldValue("trainingName", "");
    formik.setFieldValue("columns", "");
    formik.setFieldValue("slideName", "");
    // formik.setFieldValue("pptLink", "");
  }, [formik.values.mediaType]);
  return (
    <>
      <Box
        style={{
          backgroundColor: "white",
          padding: "40px 0px 40px 20px",
          marginBottom: "60px",
        }}
      >
        {/* {Object.keys(mandateData).length === 0 ? (
          <Box
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : ( */}
        <form onSubmit={formik.handleSubmit}>
          <Grid container style={{}} spacing={2}>
            <Grid
              item
              xs={10}
              lg={3}
              md={3}
              sm={5}
              //   style={{ marginRight: "34px" }}
            >
              <InputLabel className="InputLabel" style={styles.labelMedia}>
                Type of media
                <span style={styles.madatoryMark}>*</span>
              </InputLabel>

              <FormControl
                sx={{ width: 220, marginBottom: "1%" }}
                // error={Boolean(formiks.errors.domain)}
              >
                {/* <Select
                  displayEmpty
                  size="small"
                  value={formik.values.mediaType}
                  name="mediaType"
                  id="mediaType"
                  onChange={formik.handleChange}
                  // MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Select Collection </em>
                  </MenuItem>
                  {mediaTypes.map((each) => (
                    <MenuItem
                      key={each.id}
                      value={each.value}
                      // style={getStyles(theme)}
                    >
                      {each.name}
                    </MenuItem>
                  ))}
                </Select> */}
                <Custom.ReusableDropdown
                  // disabled={isDisable}
                  name="mediaType"
                  id="mediaType"
                  placeholder="Select Collection"
                  displayEmpty
                  options={mediaTypes}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setPPTFilled(false);
                    setLinkFilled(false);
                  }}
                  value={formik.values.mediaType}
                  className="batchmuipopober"
                  size="small"
                  style={{
                    height: 40,
                    fontFamily: "Poppins",
                  }}
                  error={
                    formik.touched.mediaType && Boolean(formik.errors.mediaType)
                  }
                  helperText={
                    formik.touched.mediaType && formik.errors.mediaType
                  }
                ></Custom.ReusableDropdown>
                {formik.touched.mediaType && (
                  <FormHelperText>{formik.errors.mediaType}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {formik.values.mediaType === "FILES" ? (
              <>
                <Grid container spacing={2} className="training-materail">
                  <Grid item xs={2.4} sm={3} md={3} lg={3}>
                    <div style={styles.inputContainer}>
                      <Typography style={styles.label} variant="h6">
                        Name
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <span>
                        <ReusableInputfield
                          className="name-Training-material"
                          name="fileName"
                          id="fileName"
                          placeholder="Name"
                          value={formik.values.fileName}
                          onChange={formik.handleChange}
                          size="small"
                          style={styles.inputFleid}
                          error={
                            formik.touched.fileName &&
                            Boolean(formik.errors.fileName)
                          }
                          helperText={
                            formik.touched.fileName && formik.errors.fileName
                          }
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={2.3}
                    sm={3}
                    md={3}
                    lg={3}
                    style={
                      formik.errors.file
                        ? styles.inputContainerValidator
                        : styles.inputContainer
                    }
                  >
                    <div style={styles.inputContainer}>
                      <Typography style={styles.label} variant="h6">
                        Video Link
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <span>
                        <ReusableInputfield
                          className="name-Training-material"
                          name="videoLink"
                          id="videoLink"
                          placeholder="Video Link"
                          value={formik.values.videoLink}
                          style={{
                            height: "41px",
                            width: "100%",
                          }}
                          onChange={formik.handleChange}
                          disabled={linkfilled ? true : false}
                          error={
                            formik.touched.file && Boolean(formik.errors.file)
                          }
                          helperText={formik.touched.file && formik.errors.file}
                          size="small"
                        />
                      </span>
                      <div></div>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={3.3}
                    sm={3}
                    md={3}
                    lg={3}
                    style={styles.fileContainerFiles}
                  >
                    <div style={styles.inputContainer}>
                      <Typography style={styles.label} variant="h6">
                        Upload Materials
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        class="upload-box-files"
                        ref={inputRef}
                        accept={SheetJSFTPPT}
                        style={{
                          pointerEvents: formik.values.videoLink
                            ? "none"
                            : "visible",
                          fontSize: "0.8em",
                          fontFamily: "Poppins",
                          height: "38px",
                          position: "relative",
                          opacity: formik.values.videoLink ? 0.5 : 1,
                          marginTop: "8px",
                        }}
                        onChange={(e) =>
                          handleFileChange(e, formik.setFieldValue)
                        }
                      />
                      <Typography style={styles.note1} variant="p">
                        Only .pdf .ppt, pptx, mp4 format allowed
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={2.3}
                    sm={3}
                    md={3}
                    lg={3}
                    style={
                      formik.errors.file
                        ? styles.inputContainerValidator
                        : styles.inputContainer
                    }
                  >
                    <div style={styles.inputContainer}>
                      <Typography style={styles.label} variant="h6">
                        Duration(in mins)
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <span>
                        <ReusableInputfield
                          name="duration"
                          id="duration"
                          placeholder="Duration"
                          value={formik.values.duration}
                          style={{
                            height: "41px",
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            autoComplete: "off",
                            inputProps: {
                              style: { padding: "8px 12px", width: "90%" },
                            },
                          }}
                          onChange={(e) => handleChangedValues(e)}
                          error={
                            formik.touched.duration &&
                            Boolean(formik.errors.duration)
                          }
                          helperText={
                            formik.touched.duration && formik.errors.duration
                          }
                          size="small"
                        />
                      </span>
                    </div>
                  </Grid>
                  {/* <Grid
                    item
                    // xs={0.2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "2%",
                      paddingTop: "1%",
                    }}
                  ></Grid> */}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "100%",
                      marginRight: "10px",
                    }}
                  >
                    <ReusableButton
                      type="button"
                      size="large"
                      variant="outlined"
                      buttonName="Add"
                      onClick={() => {
                        handleAddButtonClick();
                      }}
                      style={styles.addbuttonTrainingMaterialFiles}
                    ></ReusableButton>
                  </Box>
                </Grid>
              </>
            ) : null}
            {formik.values.mediaType === "QUESTION_TYPE" ? (
              <>
                <Grid
                  item
                  xs={10}
                  lg={2.2}
                  md={2}
                  sm={5}
                  //   style={{ marginRight: "34px" }}
                >
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                    class="upload-box"
                    accept={SheetJSFTCSV}
                    style={{
                      marginTop: "13.5%",
                      marginLeft: "15%",
                      fontSize: "0.8em",
                      fontFamily: "Poppins",
                      paddingBottom: "0%",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  lg={2.2}
                  md={2}
                  sm={5}
                  style={{ marginLeft: "9%" }}
                >
                  <ReusableButton
                    className="addbutton"
                    size="large"
                    variant="contained"
                    disabled={checkupload ? true : false}
                    onClick={uploadQuestionsFormandate}
                    buttonName={checkupload ? "uploading..." : "Upload CSV"}
                    style={{
                      // backgroundColor: localStorage.getItem("mandateId")
                      //   ? "#fb2485"
                      //   : "grey",
                      backgroundColor: checkupload ? "grey" : "#fb2485",

                      // backgroundColor: "#fb2485",
                      marginTop: "13%",
                      fontSize: "18px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAalign: "center",
                      color: "#fff",
                      //   padding: "0px 15px",
                      borderRadius: "4px",
                      textTransform: "none",
                      height: "40px",
                      width: "10vw",
                    }}
                  />
                </Grid>
              </>
            ) : null}
            {formik.values.mediaType === "GROUP_OF_IMAGES" ? (
              <>
                <Grid
                  item
                  xs={12}
                  lg={2.1}
                  md={2}
                  sm={5}
                  style={{ marginLeft: "2%" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Slide Name
                    <span style={styles.madatoryMark}>*</span>
                  </InputLabel>
                  <ReusableInputfield
                    // name="duration"
                    // id="duration"
                    // placeholder="Duration"
                    // disabled={mandateId === null ? false : isDisable}
                    name="slideName"
                    id="slideName"
                    size="small"
                    value={formik.values.slideName}
                    //type="number"
                    onChange={formik.handleChange}
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        style: { padding: "9px 12px", width: "90%" },
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={2.2}
                  md={2}
                  sm={5}
                  //   style={{ marginRight: "34px" }}
                >
                  <div style={{ marginLeft: "15%" }}>
                    <InputLabel className="InputLabel" style={styles.label}>
                      Upload File
                      <span style={styles.madatoryMark}>*</span>
                    </InputLabel>
                  </div>
                  <input
                    type="file"
                    class="upload-box"
                    accept={SheetJSFT}
                    style={{
                      marginTop: "0%",
                      marginLeft: "15%",
                      fontSize: "0.8em",
                      fontFamily: "Poppins",
                      paddingBottom: "0%",
                    }}
                    multiple
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={2.1}
                  md={2}
                  sm={5}
                  style={{ marginLeft: "10.5%" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Description{" "}
                  </InputLabel>
                  <ReusableInputfield
                    name="trainingName"
                    id="trainingName"
                    size="small"
                    value={formik.values.trainingName}
                    onChange={formik.handleChange}
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        style: { padding: "9px 12px", width: "90%" },
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  lg={2.1}
                  md={2}
                  sm={5}
                  style={{ marginTop: "1%" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Images Per Row
                    <span style={styles.madatoryMark}>*</span>
                  </InputLabel>
                  <ReusableInputfield
                    name="columns"
                    id="columns"
                    size="small"
                    value={formik.values.columns}
                    type="number"
                    onChange={formik.handleChange}
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        style: { padding: "10px 12px", width: "90%" },
                      },
                    }}
                  />
                </Grid>
                {/* <Grid
                  item
                  xs={10}
                  lg={2.2}
                  md={2}
                  sm={5}
                  style={{ marginLeft: "1%" }}
                > */}
                <ReusableButton
                  className="addbutton"
                  size="large"
                  variant="contained"
                  disabled={checkupload ? true : false}
                  onClick={() => groupAddImages()}
                  // buttonName="Upload"
                  buttonName={checkupload ? "uploading..." : "Upload"}
                  style={{
                    // backgroundColor: localStorage.getItem("mandateId")
                    //   ? "#fb2485"
                    //   : "grey",
                    backgroundColor: checkupload ? "grey" : "#fb2485",
                    marginTop: "3.5%",
                    fontSize: "18px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#fff",
                    //   padding: "0px 15px",
                    borderRadius: "4px",
                    textTransform: "none",
                    height: "40px",
                    width: "10vw",
                  }}
                />
                {/* </Grid> */}
              </>
            ) : null}
            {formik.values.mediaType === "VIDEO" ? (
              <>
                <Grid
                  container
                  spacing={2}
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    md={3}
                    sm={5}
                    // style={{ marginLeft: "2%" }}
                  >
                    <InputLabel className="InputLabel" style={styles.label}>
                      Slide Name
                      <span style={styles.madatoryMark}>*</span>
                    </InputLabel>
                    <ReusableInputfield
                      // name="duration"
                      // id="duration"
                      placeholder="Slide name"
                      // disabled={mandateId === null ? false : isDisable}
                      className="name-Training-material"
                      name="slideName"
                      id="slideName"
                      size="small"
                      value={formik.values.slideName}
                      //type="number"
                      onChange={formik.handleChange}
                      InputProps={{
                        autoComplete: "off",
                        inputProps: {
                          // style: { padding: "9px 12px", width: "90%" },
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={3}
                    md={3}
                    sm={5}
                    className="fileContainerFiles"
                      style={{ marginBottom: "-2%" }}
                  >
                    <div>
                      <InputLabel className="InputLabel" style={styles.label}>
                        Upload File
                        <span style={styles.madatoryMark}>*</span>
                      </InputLabel>
                    </div>
                    <input
                      type="file"
                      //   key={index}
                      class="upload-box-files"
                      // accept={SheetJSFT}
                      accept="video/mp4,video/x-m4v,video/*"
                      style={{
                        marginBottom: "-2%",
                        // marginLeft: "10%",
                        fontSize: "0.8em",
                        fontFamily: "Poppins",
                        paddingBottom: "0%",
                        height: "41px",
                        // marginRight: "2%",
                      }}
                      onChange={(e) => {
                        handleFileUpload(e);
                      }}
                    />
                     <Typography style={styles.note} variant="p">
                        Only video file allowed
                      </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={3}
                    md={3}
                    sm={5}
                    // style={{ marginLeft: "8%" }}
                  >
                    <InputLabel className="InputLabel" style={styles.label}>
                      Description
                      <span style={styles.madatoryMark}>*</span>
                    </InputLabel>
                    <ReusableInputfield
                      className="name-Training-material"
                      name="trainingName"
                      id="trainingName"
                      placeholder="Description"
                      // disabled={mandateId === null ? false : isDisable}
                      size="small"
                      value={formik.values.trainingName}
                      onChange={formik.handleChange}
                      InputProps={{
                        autoComplete: "off",
                        inputProps: {
                          style: { padding: "9px 12px", width: "90%" },
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={3}
                    lg={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "0%",
                    }}
                  >
                    <div
                      style={styles.inputContainer}
                      sx={{ marginLeft: "0px" }}
                    >
                      <Typography style={styles.label} variant="h6">
                        Duration(in mins)
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <span>
                        <ReusableInputfield
                          name="duration"
                          id="duration"
                          placeholder="Duration"
                          value={formik.values.duration}
                          style={{
                            height: "41px",
                            width: "100%",
                          }}
                          onChange={(e) => handleChangedValues(e)}
                          // error={touched.duration && Boolean(errors.duration)}
                          // helperText={touched.duration && errors.duration}
                          size="small"
                          InputProps={{
                            autoComplete: "off",
                            inputProps: {
                              style: { padding: "10px 14px" },
                            },
                          }}
                          type="text"
                        />
                      </span>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    // lg={2.2}
                    // md={2}
                    // sm={5}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <ReusableButton
                      // className="addbutton"
                      size="large"
                      variant="contained"
                      disabled={checkupload ? true : false}
                      onClick={() => addVideos()}
                      buttonName={checkupload ? "Uploading..." : "Upload Video"}
                      // buttonName="Upload Video"
                      style={{
                        // backgroundColor: localStorage.getItem("mandateId")
                        //   ? "#fb2485"
                        //   : "grey",
                        // backgroundColor: "#fb2485",
                        backgroundColor: checkupload ? "grey" : "#fb2485",
                        fontSize: "16px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAalign: "center",
                        color: "#fff",
                        padding: "12px 25px",
                        borderRadius: "4px",
                        textTransform: "none",
                        margin: "0px",
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}

            {formik.values.mediaType === "VIDEO_WITH_QUIZ" ? (
              <>
                <Grid container spacing={2} sx={{ padding: "10px" }}>
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    md={3}
                    sm={5}
                    // style={{ marginLeft: "3%" }}
                  >
                    <InputLabel className="InputLabel" style={styles.label}>
                      Slide Name
                      <span style={styles.madatoryMark}>*</span>
                    </InputLabel>
                    <ReusableInputfield
                      // name="duration"
                      // id="duration"
                      placeholder="Slide name"
                      // disabled={mandateId === null ? false : isDisable}
                      className="name-Training-material"
                      name="slideName"
                      id="slideName"
                      size="small"
                      value={formik.values.slideName}
                      //type="number"
                      onChange={formik.handleChange}
                      InputProps={{
                        autoComplete: "off",
                        inputProps: {
                          style: { padding: "9px 12px", width: "90%" },
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={3}
                    md={3}
                    sm={5}
                    style={
                      {
                        display: "flex",
                        flexDirection: "column",
                        // marginLeft: "3%",
                      }
                      // errors.file
                      //   ? styles.inputContainerValidator
                      // styles.inputContainer
                    }
                    //   sx={{ marginLeft: "10%" }}
                  >
                    <div style={styles.inputContainer}>
                      <InputLabel
                        className="InputLabel"
                        style={styles.label}
                        variant="h6"
                      >
                        Upload Video
                        <span style={styles.madatoryMark}>*</span>
                      </InputLabel>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        class="upload-box"
                        //   ref={inputRef}
                        accept={SheetJSFTVideo}
                        style={{
                          fontSize: "0.8em",
                          fontFamily: "Poppins",
                          height: "41px",
                          position: "relative",
                          // bottom: "3px",
                          width: "100%",
                          marginTop: "3px",
                        }}
                        onChange={(e) =>
                          handleVideoChange(e, formik.setFieldValue)
                        }
                      />
                      <Typography style={styles.note} variant="p">
                        Only video file allowed
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3} md={3} sm={5}>
                    <div style={styles.inputContainer}>
                      <Typography style={styles.label} variant="h6">
                        Upload Questions
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      {/* <span> */}
                      <input
                        type="file"
                        name="file"
                        id="file"
                        class="upload-box"
                        //   ref={inputRef}
                        accept={SheetJSFTCSV}
                        style={{
                          fontSize: "0.8em",
                          fontFamily: "Poppins",
                          height: "41px",
                          position: "relative",
                          width: "100%",
                        }}
                        onChange={(e) =>
                          handleFileChange(e, formik.setFieldValue)
                        }
                      />
                      <Typography style={styles.note} variant="p">
                        Only .csv format allowed
                      </Typography>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={3}
                    md={3}
                    sm={5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "0%",
                    }}
                  >
                    <div
                      style={styles.inputContainer}
                      sx={{ marginLeft: "0px" }}
                    >
                      <Typography style={styles.label} variant="h6">
                        Duration(in mins)
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <span>
                        <ReusableInputfield
                          name="duration"
                          id="duration"
                          placeholder="Duration"
                          className="name-Training-material"
                          value={formik.values.duration}
                          style={{
                            height: "41px",
                            width: "100%",
                          }}
                          onChange={(e) => handleChangedValues(e)}
                          // error={touched.duration && Boolean(errors.duration)}
                          // helperText={touched.duration && errors.duration}
                          size="small"
                          InputProps={{
                            autoComplete: "off",
                            inputProps: {
                              style: { padding: "10px 14px" },
                            },
                          }}
                          type="text"
                        />
                      </span>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <ReusableButton
                      size="large"
                      variant="contained"
                      onClick={() => uploadVideoWithQuizz()}
                      buttonName="Upload"
                      style={{
                        // backgroundColor: localStorage.getItem("mandateId")
                        //   ? "#fb2485"
                        //   : "grey",
                        backgroundColor: "#fb2485",
                        //   marginTop: "13%",
                        fontSize: "16px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAalign: "center",
                        color: "#fff",
                        borderRadius: "4px",
                        textTransform: "none",
                        padding: "12px 40px",
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}

            {formik.values.mediaType === "PPT" ? (
              <>
                <Grid
                  container
                  className="training-materail"
                  spacing={2}
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <Grid item xs={12} lg={3} md={3} sm={5}>
                    <div style={styles.inputContainer}>
                      <Typography style={styles.label} variant="h6">
                        Name
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <span>
                        <ReusableInputfield
                          name="slideName"
                          id="slideName"
                          placeholder="Name"
                          className="name-Training-material"
                          value={formik.values.slideName}
                          onChange={formik.handleChange}
                          size="small"
                          style={{
                            height: "41px",
                            width: "100%",
                            marginTop: "-3px",
                          }}
                          // error={touched.fileName && Boolean(errors.fileName)}
                          // helperText={touched.fileName && errors.fileName}
                        />
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3} md={3} sm={5}>
                    <div style={styles.inputContainer}>
                      <Typography style={styles.label} variant="h6">
                        PPT Link
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <span>
                        <ReusableInputfield
                          name="pptLink"
                          id="pptLink"
                          placeholder="PPT Link"
                          className="name-Training-material"
                          value={formik.values.pptLink}
                          style={{
                            height: "41px",
                            width: "100%",
                            marginTop: "-3px",
                          }}
                          onChange={formik.handleChange}
                          disabled={linkfilled ? true : false}
                          // error={touched.pptLink && Boolean(errors.pptLink)}
                          // helperText={touched.pptLink && errors.pptLink}
                          size="small"
                        />
                      </span>
                      <div></div>
                    </div>
                  </Grid>

                  {/* for upload ppt */}
                  <Grid item xs={12} lg={3} md={3} sm={5}>
                    <div style={styles.inputContainer}>
                      <Typography style={styles.label} variant="h6">
                        Upload PPT
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        class="upload-box"
                        accept={SheetJSFTPPT}
                        style={{
                          pointerEvents: pptfilled ? "none" : "visible",
                          fontSize: "0.8em",
                          fontFamily: "Poppins",
                          height: "40px",
                          position: "relative",
                          bottom: "3px",
                          opacity: pptfilled ? 0.5 : 1,
                          width: "250px",
                          marginTop: "-3px",
                        }}
                        onChange={(e) => handlePPTChange(e)}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3} md={3} sm={5}>
                    <div style={styles.inputContainer}>
                      <InputLabel
                        className="InputLabel"
                        style={styles.label}
                        variant="h6"
                      >
                        Thumbnail
                        <span style={styles.madatoryMark}>*</span>
                      </InputLabel>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        class="upload-box"
                        accept={SheetJSFT}
                        style={{
                          fontSize: "0.8em",
                          fontFamily: "Poppins",
                          height: "40px",
                          position: "relative",
                          bottom: "3px",
                          marginTop: "6px",
                          width: "220px",
                        }}
                        onChange={(e) =>
                          handleFileChange(e, formik.setFieldValue)
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3} md={3} sm={5}>
                    <div style={styles.inputContainer}>
                      <Typography style={styles.label} variant="h6">
                        Duration(in mins)
                        <span style={styles.madatoryMark}>*</span>
                      </Typography>
                      <span>
                        <ReusableInputfield
                          name="duration"
                          id="duration"
                          placeholder="Duration"
                          className="name-Training-material"
                          value={formik.values.duration}
                          style={{
                            height: "39px",
                            width: "100%",
                          }}
                          type="text"
                          InputProps={{
                            autoComplete: "off",
                            inputProps: {
                              style: { padding: "10px 12px", width: "90%" },
                            },
                          }}
                          onChange={(e) => handleChangedValues(e)}
                          // error={touched.duration && Boolean(errors.duration)}
                          // helperText={touched.duration && errors.duration}
                          size="small"
                        />
                      </span>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <ReusableButton
                      type="submit"
                      size="large"
                      variant="outlined"
                      buttonName="Add"
                      // style={styles.addbuttonTrainingMaterial1}
                      style={{
                        backgroundColor: "#fb2485",
                        fontSize: "16px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAalign: "center",
                        color: "#fff",
                        borderRadius: "4px",
                        textTransform: "none",
                        padding: "12px 40px",
                        border: "none",
                      }}
                      onClick={() => uploadPPT()}
                    ></ReusableButton>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
          {loaderForPublishTM ? (
            <Box
              sx={{
                flexGrow: 1,
              }}
              style={{
                height: "50vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            ""
          )}
          {/* <Box sx={{ width: "95%", marginTop: "5%" }}>
            <DataTableDragMandate
              draggableData={trainingSlides}
              setDraggableData={setDraggableData}
              handleDeleteInpre={handleDeleteInpre}
              OpenTheEditBox={OpenTheEditBox}
              //   handleDeleteInpre={handleDeleteInpre}
              pageIndex={pageIndex}
              pageLimit={trainingSlides.length}
              IsActivity={true}
              link={false}
              dataTableHeader={dataTableHeader}
            />
          </Box> */}

          {done ? (
            <Box sx={{ flexGrow: 1 }} style={styles.loadingContainer}>
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <>
              {trainingSlides.length > 0 ? (
                <>
                  <Box sx={{ marginTop: "5%" }} className="draggable-table">
                    <DataTableDragMandate
                      draggableData={trainingSlides}
                      setDraggableData={setTrainingSLides}
                      handleDeleteInpre={handleDeleteInpre}
                      handleViewClick={handleViewClick}
                      handleEditModal={handleEditModal}
                      OpenTheEditBox={OpenTheEditBox}
                      pageIndex={pageIndex}
                      pageLimit={trainingSlides.length}
                      IsActivity={true}
                      link={true}
                      dataTableHeader={dataTableHeader}
                      deleteId={deleteId}
                      getTrainingMaterial={getTrainingMaterial}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box style={styles.tableErrorMsg}>
                    No Training Material found. Please Add Training Material
                  </Box>
                </>
              )}
            </>
          )}

          <Box style={styles.buttonContainer}>
            <Box style={{ marginRight: "40px" }}>
              <ReusableButton
                onClick={cancelUnit}
                size="large"
                variant="outlined"
                buttonName="Cancel"
                style={styles.cancelButton}
                disabled={
                  loaderForSaveLaterUnit || loaderForPublishUnit ? true : false
                }
              ></ReusableButton>

              <ReusableButton
                onClick={handleSaveForLaterButton}
                className="addbutton"
                size="large"
                variant="contained"
                endIcon={
                  !loaderForSaveLaterTM ? (
                    ""
                  ) : (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  )
                }
                buttonName={!loaderForSaveLaterTM ? "Save for Later" : "Saving"}
                style={
                  !loaderForPublishTM
                    ? styles.saveForLaterBtn
                    : styles.saveForLaterBtnDisabled
                }
                disabled={loaderForPublishTM ? true : false}
              ></ReusableButton>

              <ReusableButton
                // onClick={() => {
                //   setIsCreate(true);
                // }}
                onClick={handleCreateButton}
                type="submit"
                size="large"
                variant="contained"
                endIcon={
                  !loaderForPublishUnit ? (
                    ""
                  ) : (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  )
                }
                buttonName={!loaderForPublishUnit ? "Publish" : "Publishing"}
                style={
                  !loaderForSaveLaterUnit && mandateId
                    ? styles.createBtn
                    : styles.createBtnDisabled
                }
                disabled={loaderForSaveLaterUnit || !mandateId ? true : false}
              ></ReusableButton>
            </Box>
          </Box>
          <DeletePopUp
            deleteModal={deleteModal}
            deleteModalClose={deleteModalClose}
            HandleDeleteUnit={HandleDeleteUnit}
          />
        </form>
        {/* )} */}
        <Notification notify={notify} setNotify={setNotify} />
        <SlideImageEditDialog
          openPopup={openPopup}
          setopenPopup={setopenPopup}
          SlideId={SlideId}
        />
      </Box>

      <Dialog
        divider
        open={open}
        maxWidth="sm"
        onClose={handleCloseInByCancel}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none" },
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
            <span style={{ fontWeight: "bold", marginLeft: "5px" }}></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            size="medium"
            variant="outlined"
            className="qstnUpldPageBttn"
            buttonName="Cancel"
            style={styles.cancelButton}
            onClick={handleCloseInByCancel}
          />
          <ReusableButton
            size="medium"
            variant="contained"
            className="qstnUpldPageBttn"
            buttonName="Delete"
            style={styles.createBtnDialog}
            onClick={() => handleCloseDelete(deleteTrainingMaterialId)}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        divider
        open={Editopen}
        fullWidth="true"
        maxWidth="md"
        onClose={handleEditClose}
        BackdropProps={{
          style: styles.dialogBoxContainer,
        }}
      >
        {/* <DialogTitle>Edit Slide</DialogTitle> */}
        <DialogContent
          style={{ boxShadow: "none" }}
          BackdropProps={{
            style: { boxShadow: "none", transition: "none", width: "100%" },
          }}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ width: "100%" }}
          >
            {materialType==="FILES" ? <Filetype data={fileData} handleClose={handleEditClose} getTrainingMaterial={getTrainingMaterial} type={materialType}/> : materialType==="VIDEO" ? <VideoType data={fileData} handleClose={handleEditClose} getTrainingMaterial={getTrainingMaterial} type={materialType} /> : materialType==="PPT" ? <PptType data={fileData} handleClose={handleEditClose} getTrainingMaterial={getTrainingMaterial} type={materialType} /> : <VideoWithQuizType data={fileData} handleClose={handleEditClose} getTrainingMaterial={getTrainingMaterial} type={materialType}/> }
            
          </DialogContentText>
        </DialogContent>
       
      </Dialog>
    </>
  );
};
export default MandatoryIntroduction;
