import ReusableButton from "../../../components/reusable-button/reusable-button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { DeleteCourseHelper } from "../../../helper/courseHelper";
import { DeleteUnitHelper } from "../../../helper/unitHelper";
import { DeleteProgramHelper } from "../../../helper/programHelper";
import { DeleteMandatoryTrainingHelper } from "../../../helper/mandatoryHelper";
import { useNavigate } from "react-router-dom";
import * as React from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2.5,
  boxShadow: 24,
  p: 4,
};

export const DeletionPop = ({
  id,
  open,
  setOpen,
  type,
  setNotify,
  getAllUnits,
  getAllCourses,
  closeModal,
}) => {
  let navigate = useNavigate();
  
  const deleteCourseUnit = async () => {
    if (type === "Course") {
      await DeleteCourseHelper(id, setOpen, setNotify, open);
    } else if (type === "Program") {
      await DeleteProgramHelper(id, setOpen, setNotify, open);
    } else if (type === "Mandatory Training") {
      await DeleteMandatoryTrainingHelper(setNotify, navigate, id);
    } else {
      await DeleteUnitHelper(setNotify, navigate, id);
    }
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          sx={{ fontWeight: "600" }}
          variant="h6"
          component="h2"
        >
          Delete {`${type}`}
        </Typography>
        <Typography sx={{ marginTop: "3%" }} id="modal-modal-description">
          Do you want to proceed?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5%",
          }}
        >
          <ReusableButton
            size="large"
            variant="outlined"
            buttonName="No"
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#000936",
              border: "1px solid #000936",
              padding: "7px 20px",
              borderRadius: "4px",
              marginRight: "8px",
              textTransform: "none",
            }}
            onClick={closeModal}
          />
          <ReusableButton
            size="large"
            variant="contained"
            buttonName="Yes"
            style={{
              backgroundColor: "#fb2485",
              marginRight: "10px",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAalign: "center",
              color: "#fff",
              padding: "8px 28px",
              borderRadius: "4px",
              textTransform: "none",
            }}
            onClick={() => deleteCourseUnit()}
          ></ReusableButton>
        </div>
      </Box>
    </Modal>
  );
};

export const DeletionPopUp = React.memo(DeletionPop);
