/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import RButton from "../../../../../../components/Custom/button/button";
import CustomDataGrid from "../../../../../../components/reusable-datagrid/CustomDataGrid";
import ReactLoading from "react-loading";
import { API } from "../../../../../../Networking/API";
import { useParams } from "react-router-dom";
import MandateUserInitialQuiz from "./mandate-user-initial-quiz";
import MandateUserQuizQuestions from "./mandate-user-quiz-questions";

const quizValuesafterresults = {
  totalquest: "",
  correctquest: "",
  percentagescored: "",
};

const quizValues = {
  count: "",
  noOFques: "",
};

const MandateUserQuiz = (props) => {
  const { userUnitTrackId, trainingId, ClearLocalStorage, handleSetFinalQuiz } =
    props;
  const { traningId } = useParams();

  const [done, setDone] = useState(false);
  const [quizArray, setQuizArray] = useState([]);
  const [quizdata, setQuizdata] = useState("");
  const [quizResultStatus, setQuizResultStatus] = useState(null);
  const [startQuizButton, setStartQuizButton] = useState("");
  const [topicName, setTopicName] = useState("");
  const [quizDetails, setQuizDetails] = useState(quizValues);
  const [quizDetailsAfterResults, setQuizDetailsAfterResults] = useState(
    quizValuesafterresults
  );
  const [attempts, setAttempts] = useState(null);
  const [resultLoaded, setResultLoaded] = useState(true);
  const [viewQuizStatusButton, setViewQuizStatusButton] = useState(true);
  const [quizStarted, setQuizStarted] = useState(false);

  const datas = [
    {
      id: 1,
      Information: "Number Questions",
      Status: `${quizDetails.noOFques}`,
    },
    {
      id: 2,
      Information: "Time Duration",
      Status: `${quizDetails.count} mins `,
    },
    { id: 3, Information: "Attempts", Status: attempts },
  ];

  const column = [
    {
      field: "Information",
      headerName: "Information",
      headerClassName: "super-app-theme--header",
      className: "quiz-class-column",
      flex: 1,
    },
    {
      field: "Status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
  ];
  let FinalQuizStarted = localStorage.getItem(
    `IsFinalQuizStarted_${userUnitTrackId}`
  );
  useEffect(() => {
    // if (FinalQuizStarted) {
    getQuizDetails();
    setDone(false);
    // }
  }, []);


  const getQuizDetails = () => {
    API.getMandatoryTraninigById(traningId, userUnitTrackId).then(
      ({ response }) => {
        setDone(true);
        if (response.api_status === 200) {
          setDone(true);
          const quizToWrite = response.data?.finalQuiz;
          setAttempts(response.data?.attempts);
          setQuizArray(quizToWrite);

          const quizResult = quizToWrite ? quizToWrite[0].userQuizz : null;

          setQuizResultStatus(quizResult); // will be null before user taking attempt and will have object after attempt with result

          // if userQuizz value present 1 else 0

          const quizResultValues = quizToWrite ? quizToWrite[0].userQuizz : null;
          if ((quizToWrite&&quizToWrite?.length > 0) && quizToWrite !==null) {
            const formattedQuizToWrite = quizToWrite.map((eachdata) => ({
              id: eachdata.quizz_pk,
              noOfQuest: eachdata.no_of_questions,
              duration: eachdata.countdown,
              quizNumber: eachdata.quizz_pk,
              questcount: eachdata.no_of_questions,
              // topic: eachdata.topic,
              // buttonstart: eachdata.button,
            }));
            setQuizdata(formattedQuizToWrite);
            // setTopicName(formattedQuizToWrite.topic);
            setQuizDetails({
              ...quizDetails,
              count: quizToWrite[0].countdown,
              noOFques: quizToWrite[0].no_of_questions,
            });
          }
          setQuizDetailsAfterResults({
            ...quizDetailsAfterResults,
            totalquest: quizResultValues ? quizResultValues.total_questions : 0,
            correctquest: quizResultValues
              ? quizResultValues.correct_answers
              : "",
            percentagescored: quizResultValues
              ? Math.ceil(quizResultValues.percentage)
              : "",
            quizQuestions: quizResultValues ? quizResultValues.questions : [],
          });
        } else {
          setDone(true);

          setQuizdata(response.error[0].error_msg);
        }
      }
    );
  };

  const handleStartQuiz = () => {
    setQuizStarted(true);
    handleSetFinalQuiz("final");
  };

  const QuizStatusFunction = () => {
    setResultLoaded(false);
  };

  return (
    <div className="quiz">
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          {quizArray !== null && quizArray?.length > 0 ? (
            <>
              <div className="section1-quiz">
                {!quizStarted ? (
                  <MandateUserInitialQuiz datas={datas} column={column} />
                ) : (
                  // quiz questions result after attempt
                  // <> No quiz foundio</>
                  <>
                    <MandateUserQuizQuestions
                      quizdata={quizdata}
                      trainingId={traningId}
                      userUnitTrackId={userUnitTrackId}
                      setQuizStarted={setQuizStarted}
                      ClearLocalStorage={ClearLocalStorage}
                      // errorMsg={errorMsg}
                    />
                  </>
                )}
              </div>
              {quizResultStatus !== null ? ( //change the === to !== for it work as per requirement
                <></>
              ) : (
                <>
                  {!quizStarted ? (
                    <>
                      <div className="button-start-quiz">
                        <RButton
                          buttonName="Start Quiz"
                          className={"button-start-quiz-sub"}
                          style={{
                            textTransform: "none",
                            backgroundColor: "rgb(251, 36, 133)",
                          }}
                          onClick={() => handleStartQuiz()}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <>
              <p style={{ backgroundColor: "white" }}>No Quiz Founddd.</p>
            </>
          )}

          {/* <h1>This is Quiz</h1> */}
        </>
      )}
    </div>
  );
};

export default MandateUserQuiz;
