import { Box, Typography, IconButton } from "@material-ui/core";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./mandatoryTraining-layout.css";
import { Custom } from "../../../components/Custom/Custom";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import Notification from "../../../components/Custom/Notification";
import { SxStyles } from "../../../styles/styles";
import { useFormik } from "formik";
import { QuizValidations } from "../../../Validation/FieldValidatorSchema";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import { API } from "../../../Networking/API";
import { useNavigate } from "react-router";
import ReactLoading from "react-loading";
import { InputLabel } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";

const MandateQuiz = (props) => {
  const styles = SxStyles.styles;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [departmentApiDD, setDepartmentApiDD] = useState([]);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  const mandateId = localStorage.getItem("mandateId");
  const [done, setDone] = useState(true);
  const [loaderForSaveLaterQZ, setloaderForSaveLaterQZ] = useState(false);
  const [loaderForPublishQZ, setloaderForPublishQZ] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    getQuizType();
    getQuizData();
  }, []);

  const getQuizType = () => {
    API.getAdminQuizType(mandateId).then(({ response }) => {
      if (response.data.api_status === 200) {
        const data = response.data.data;
        const formattedData = data.map((each) => ({
          title: each.quiz_number,
          count: each.questions_count,
          value: each.quiz_number,
        }));
        setDepartmentApiDD(formattedData);
      } else {
        setDepartmentApiDD([]);
      }
    });
  };

  const getQuizData = () => {
    API.getAdminQuizzes(mandateId).then(({ response }) => {
      setDone(false);
      if (response.data.api_status === 200) {
        
        const data = response.data.data;
        const formatteddata = data.map((each, index) => ({
          id: each.quizz_pk,
          sno: index + 1,
          quizNumber: each.quiz_number,
          //passscore: `${each.pass_score}%`,
          noofquestions: each.no_of_questions,
        }));
        setTableData(formatteddata);
        setDisableFields(true);
        setQuizMandate(true);
        props.setQuizDisabled(true);
      } else {
        setTableData([]);
        setDisableFields(false);
        // setQuizMandate(false);
        // props.setQuizDisabled(false);
      }
    });
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    handleChange,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      quiz: "",
      numberOfQuestions: "",
      duration: "",
      //passPercentage: "",
      description: "",
      TotalQuestions: "",
      isCheckAssignment: "",
    },
    validationSchema: QuizValidations,
    onSubmit: (values) => {
      handleAddQuiz();
    },
  });
  const [quizMandate, setQuizMandate] = useState(
    props.mandateQuiz ? props.mandateQuiz : false
  );
  
  const [disableFields, setDisableFields] = useState(false);

  useEffect(() => {
    const optionKey = departmentApiDD.filter(
      (data) => data.title === values.quiz
    );
    if (optionKey.length > 0) {
      setFieldValue("quiz", values.quiz);
      setFieldValue("TotalQuestions", optionKey[0].count);
    }
  }, [values.quiz]);

  const handleCloseDelete = (id) => {
    setDone(true);
    API.deleteAdminQuizzes(mandateId, id).then(({ result }) => {
      const response = result;
      getQuizData();
      setOpen(false);
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: "Quiz Deleted Successfully",
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const handleCloseInByCancel = () => {
    setOpen(false);
  };

  

  const handleDeleteInpre = (params) => {
    setId(params.row.id);
    setOpen(true);
  };

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "sno",
      headerName: "S.No",
      width: 50,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "quizNumber",
      headerName: "Quiz",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    // {
    //   field: "passscore",
    //   headerName: "Pass Percentage",
    //   width: 300,
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "left",
    //   align: "left",
    // },
    {
      field: "noofquestions",
      headerName: "No of Questions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "cellsDataAction",
      flex: 1,
      width: 180,
      headerClassName: "training-mat-header",
      headerAlign: "start",
      align: "center",
      renderCell: (params) => (
        <>
          <span className="editbutton">
            <Tooltip title="Delete">
              <IconButton aria-label="edit" size="small">
                <DeleteOutlineIcon
                  color="primary"
                  fontSize="inherit"
                  style={{
                    minWidth: "14px !important",
                    color: "blue !important",
                  }}
                  onClick={() => handleDeleteInpre(params)}
                />
              </IconButton>
            </Tooltip>
            <Dialog
              divider
              open={open}
              maxWidth="sm"
              onClose={handleCloseInByCancel}
              BackdropProps={{
                style: styles.dialogBoxContainer,
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                style={{ boxShadow: "none" }}
                BackdropProps={{
                  style: { boxShadow: "none", transition: "none" },
                }}
              >
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete?
                  <span
                    style={{ fontWeight: "bold", marginLeft: "5px" }}
                  ></span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="medium"
                  variant="outlined"
                  className="qstnUpldPageBttn"
                  buttonName="Cancel"
                  style={styles.cancelButton}
                  onClick={handleCloseInByCancel}
                />
                <Button
                  size="medium"
                  variant="contained"
                  className="qstnUpldPageBttn"
                  buttonName="Delete"
                  style={styles.createBtnDialog}
                  onClick={() => handleCloseDelete(id)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];

  const handleAddQuiz = () => {
    setDone(true);
    const { quiz, numberOfQuestions, duration, description } = values;
    API.addAdminQuizz(
      mandateId,
      quiz,
      numberOfQuestions,
      duration,
      "",
      description
    ).then(({ response }) => {
      getQuizData();
      resetForm();
      if (response.api_status === 200) {
        setNotify({
          isOpen: true,
          message: response.data[0].message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  

  const handleSaveForLaterButton = () => {
    setloaderForSaveLaterQZ(true);
    API.AdminSaveForLaterApi(mandateId).then(({ response }) => {
      if (response.data.api_status === 200) {
        setloaderForSaveLaterQZ(false);
        setNotify({
          isOpen: true,
          message: response.data.data[0].message,
          type: "success",
        });
      } else {
        setloaderForSaveLaterQZ(false);
        setNotify({
          isOpen: true,
          message: response.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  const handleCreateButton = () => {
    setloaderForPublishQZ(true);
    API.finalAdminCreationPublish(mandateId).then(({ response }) => {
      
      if (response.data.api_status === 200) {
        setloaderForPublishQZ(false);
        setNotify({
          isOpen: true,
          message: response.data.data[0].message,
          type: "success",
        });
        navigate("/home/courses");
      } else {
        setloaderForPublishQZ(false);
        setNotify({
          isOpen: true,
          message: response.data.error[0].error_msg,
          type: "error",
        });
      }
    });
  };

  // const handleCreateButton = () => {
  //   setloaderForPublishQZ(true);
  //   API.finalUnitCreation(mandateId).then(({ response }) => {
  //     if (response.data.api_status === 200) {
  //       setloaderForPublishQZ(false);
  //       setNotify({
  //         isOpen: true,
  //         message: response.data.data[0].message,
  //         type: "success",
  //       });
  //       navigate("/home/courses");
  //     } else {
  //       setloaderForPublishQZ(false);
  //       setNotify({
  //         isOpen: true,
  //         message: response.data.error[0].error_msg,
  //         type: "error",
  //       });
  //     }
  //   });
  // };

  const handleChangeQuiz = async (e) => {
    let _status;
    
    if (e.target.value === "true") {
      _status = true;
      props.setQuizDisabled(_status);
    } else {
      _status = false;
      props.setQuizDisabled(_status);
    }
    
    setQuizMandate(_status);
    API.AdminQuizTrainingStatusChange(_status, mandateId).then(
      ({ response }) => {
        
      }
    );
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "white",
          padding: "40px 0px 40px 20px",
          marginBottom: "60px",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container style={{ marginLeft: "14px" }}>
            <Grid item xs={12} style={{ marginBottom: "22px" }}>
              <FormControl>
                <Typography style={styles.label1}>
                  Is Quiz Required
                  <span style={styles.madatoryMark}>*</span>
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="isCheckAssignment"
                  id="isCheckAssignment"
                  value={quizMandate}
                  style={{ marginLeft: "0px", marginTop: "5px" }}
                  onChange={handleChangeQuiz}
                  // disabled={disableFields}
                  // defaultValue={}
                >
                  <FormControlLabel
                    value={true}
                    className="radiobtn"
                    control={<Radio style={{ color: "rgb(155 158 206)" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    className="radiobtn"
                    control={<Radio style={{ color: "rgb(155 158 206)" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {quizMandate ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  lg={2.1}
                  md={2}
                  style={{ marginRight: "22px" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Select Quiz
                  </InputLabel>

                  <Custom.ReusableDropdown
                    name="quiz"
                    id="quiz"
                    displayEmpty
                    value={values.quiz}
                    options={departmentApiDD}
                    onChange={handleChange}
                    placeholder="Select Quiz"
                    error={touched.quiz && Boolean(errors.quiz)}
                    helperText={touched.quiz && errors.quiz}
                    size="medium"
                    style={styles.dropdownWidth}
                    disabled={disableFields}
                  ></Custom.ReusableDropdown>
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={2.2}
                  md={2}
                  sm={5}
                  style={{ marginRight: "22px" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Total No. of Questions
                  </InputLabel>

                  <ReusableInputfield
                    name="TotalQuestions"
                    id="TotalQuestions"
                    value={values.TotalQuestions}
                    size="medium"
                    placeholder={"No of questions"}
                    disabled={true}
                    style={styles.disableInputField}
                    className={"inputColor"}
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        style: { padding: "8.6px 14px" },
                      },
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={2.1}
                  md={2}
                  sm={5}
                  style={{ marginRight: "22px" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Number of Questions
                  </InputLabel>

                  <ReusableInputfield
                    name="numberOfQuestions"
                    id="numberOfQuestions"
                    placeholder="Question count"
                    size="small"
                    type={"number"}
                    onChange={handleChange}
                    value={values.numberOfQuestions}
                    style={{ height: "5px" }}
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        style: { padding: "8px 14px" },
                      },
                    }}
                    error={
                      touched.numberOfQuestions &&
                      Boolean(errors.numberOfQuestions)
                    }
                    helperText={
                      touched.numberOfQuestions && errors.numberOfQuestions
                    }
                    disabled={disableFields}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={2.1}
                  md={2}
                  sm={5}
                  style={{ marginRight: "22px" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Quiz Duration
                  </InputLabel>

                  <ReusableInputfield
                    type={"number"}
                    size="small"
                    className="questanddurationclass"
                    minWidth="300px"
                    name="duration"
                    id="duration"
                    placeholder="In Minutes"
                    onChange={handleChange}
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        style: { padding: "8px 14px" },
                      },
                    }}
                    style={styles.inputFleid}
                    value={values.duration}
                    error={touched.duration && Boolean(errors.duration)}
                    helperText={touched.duration && errors.duration}
                    disabled={disableFields}
                  />
                </Grid>
                {/* <Grid item xs={12} lg={2} md={1.5} sm={5}>
                  <InputLabel className="InputLabel" style={styles.label}>
                    Pass Percentage
                  </InputLabel>
                  <ReusableInputfield
                    type={"number"}
                    size="medium"
                    className="questanddurationclass"
                    name="passPercentage"
                    id="passPercentage"
                    placeholder="In %"
                    style={{ width: "100%" }}
                    value={values.passPercentage}
                    onChange={handleChange}
                    InputProps={{
                      autoComplete: "off",
                      inputProps: {
                        style: { padding: "8px 14px" },
                      },
                    }}
                    error={
                      touched.passPercentage && Boolean(errors.passPercentage)
                    }
                    helperText={touched.passPercentage && errors.passPercentage}
                    disabled={disableFields}
                  />
                </Grid> */}
                <Grid
                  item
                  sm={11.5}
                  md={11.6}
                  xs={11.5}
                  style={{ marginTop: "30px" }}
                >
                  <InputLabel className="InputLabel" style={styles.label}>
                    Quiz Instruction
                  </InputLabel>
                  <ReusableInputfield
                    rows={4}
                    displayEmpty
                    placeholder="Description"
                    style={{ width: "98%" }}
                    className="description_in_Pre-requisite"
                    name="description"
                    id="description"
                    onChange={handleChange}
                    value={values.description}
                    multiline
                    size="medium"
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      inputProps: {
                        style: {
                          resize: "auto",
                          height: "100%",
                        },
                      },
                    }}
                    disabled={disableFields}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
          {quizMandate ? (
            <Grid
              container
              spacing={5}
              style={{ marginBottom: "5px", paddingRight: "20px" }}
            >
              <Grid
                item
                xs={12}
                style={styles.addBtn}
                className="add-button-grid"
              >
                <ReusableButton
                  size="large"
                  variant="outlined"
                  buttonName="Add"
                  type="submit"
                  className="add-button-styling"
                  style={styles.button}
                  disabled={disableFields}
                ></ReusableButton>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </form>
        {quizMandate ? (
          <>
            {done ? (
              <Box sx={{ flexGrow: 1 }} style={styles.loadingContainer}>
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"#fb2485"}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <>
                {tableData.length > 0 ? (
                  <>
                    <Box style={{ marginRight: "28px" }}>
                      <DataTable
                        rows={tableData}
                        columns={columns}
                        rowHeight={40}
                        autoHeight
                        style={{
                          backgroundColor: "white",
                        }}
                        hideFooter={true}
                        hideFooterPagination={true}
                        hideFooterSelectedRowCount={true}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box style={styles.tableErrorMsg}>
                      No Quiz Found. Please Add A Quiz
                    </Box>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
        <Box style={styles.btnContainer}>
          <Box style={styles.buttonContainer}>
            <Box style={{ marginRight: "40px" }}>
              <ReusableButton
                onClick={() => navigate("/home/courses")}
                size="large"
                variant="outlined"
                buttonName="Cancel"
                style={styles.cancelButton}
                disabled={
                  loaderForSaveLaterQZ || loaderForPublishQZ ? true : false
                }
              ></ReusableButton>
              <ReusableButton
                onClick={handleSaveForLaterButton}
                className="addbutton"
                size="large"
                variant="contained"
                endIcon={
                  !loaderForSaveLaterQZ ? (
                    ""
                  ) : (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  )
                }
                buttonName={!loaderForSaveLaterQZ ? "Save for Later" : "Saving"}
                style={
                  !loaderForPublishQZ
                    ? styles.saveForLaterBtn
                    : styles.saveForLaterBtnDisabled
                }
                disabled={loaderForPublishQZ ? true : false}
              ></ReusableButton>
              <ReusableButton
                onClick={handleCreateButton}
                size="large"
                variant="contained"
                endIcon={
                  !loaderForPublishQZ ? (
                    ""
                  ) : (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  )
                }
                buttonName={!loaderForPublishQZ ? "Publish" : "Publishing"}
                style={
                  !loaderForSaveLaterQZ
                    ? styles.createBtn
                    : styles.createBtnDisabled
                }
                disabled={loaderForSaveLaterQZ ? true : false}
              ></ReusableButton>
            </Box>
          </Box>
        </Box>
        <Notification notify={notify} setNotify={setNotify} />
      </Box>
    </>
  );
};

export default MandateQuiz;
