import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Grid, Box, Button, DialogContentText, TextareaAutosize } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import QuizLayout from "./quizLayout";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "./style.css";
import { API } from "../../../Networking/API";
import { CodeFormatter } from "../../../helper/codeFormatter";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
function Questions(props) {
  const { callback, showRes, fin, loaded } = props;
  const location = useLocation();
  const [subcatnamedisplay, setSubCatNameDisplay] = useState();
  const [question, setQuestion] = useState([]);
  // const [countDown, setCountDown] = useState();
  const [optionsOpted, setOptionsOpted] = useState("");
  const [value, setValue] = useState();
  const [questIndex, setQuestIndex] = useState(0);
  const [questId, setQuestId] = useState();
  const [quizId, setQuizId] = useState();
  const [userQuizId, setUserQuizId] = useState();
  const [results, setResults] = useState();
  const [done, setDone] = useState(undefined);
  const [res, setRes] = useState(false);
  const [start, setStart] = useState(false);
  const [visited, setVisited] = useState(0);
  const [answered, setAnswered] = useState([]);
  const [quizNumber01, setQuizNumber01] = useState();
  const [errorStatus, setErrorStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [Timer, SetTimer] = useState();

  useEffect(() => {
    setSubCatNameDisplay(location.state.topic);
    setQuizNumber01(location.state.quizNumber);
    timerrr();
  }, []);

  useEffect(() => {
    if (quizNumber01 !== undefined) {
      getAllQuestions(quizNumber01);
    }
  }, [quizNumber01]);

  const timerrr = () => {
    var jsonStr = localStorage.getItem("minsecs");
    if (jsonStr !== undefined && jsonStr !== null) {
      var data = JSON.parse(jsonStr); // Parse the JSON string into a JavaScript object
      const combinedValue = parseFloat(`${data.Minutes}.${data.Seconds}`);
      SetTimer(combinedValue);
      return combinedValue;
    }
  };
  const handleChange = (event, index) => {
    setValue(event.target.value);
    setOptionsOpted(event.target.value);
    setAnswered([...answered, index]);
  };
  useEffect(() => {
    if (fin) {
      setShowModal(true);
      onClickSubmit(userQuizId, question[visited].question_pk);
    }
  }, [fin]);

  const removeQuizDataCookie = () => {
    localStorage.removeItem("minsecs");
    Cookies.remove("quizdata");
    window.opener = null;
    window.open("", "_self", ""); //bug fix
    window.close();
  };

  const getAllQuestions = async (quizNumber01) => {
    try {
      API.getTraineeallquestions(quizNumber01).then(({ response }) => {
        if (response.data.api_status === 200) {
          setRes(false);
          setStart(true);
          setDone(true);
          loaded(true);
          setQuestion(response.data.data[0].questions);
          if (
            response.data.data[0].remianing_duration !== null &&
            response.data.data[0].remianing_duration !== undefined
          ) {
            var remianing_duration1 = response.data.data[0].remianing_duration;

            const remainingTime =remianing_duration1.toString().split(".");
            var parts =JSON.stringify({ Minutes: remainingTime[0], Seconds: remainingTime[1]?  remainingTime[1] :0});
            localStorage.setItem("countdownfromapi", parts);
          } else {
            localStorage.setItem(
              "countdownfromapi",
              response.data.data[0].countdown
            );
          }
          setQuizId(response.data.data[0].schedule_details.user_qz_id);
          setQuestId(response.data.data[0].questions[0].question_pk);
          setUserQuizId(response.data.data[0].schedule_details.user_qz_id);
          setOptionsOpted(response.data.data[0].questions[0].userOption);
          setAnswered(response.data.data[0].userOptionIdx);
        } else if (response.data.api_status === 404) {
          setDone(true);
          setRes(true);
          loaded(true);
        } else if (response.data.api_status === 400) {
          setDone(true);
          setRes(true);
          loaded(true);
          setErrorStatus(true);
        } else if (response.data.api_status === 502) {
          setDone(true);
          setRes(true);
          loaded(true);
        }
      });
    } catch (e) {
      setRes(true);
      loaded(true);
    }
  };

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const arr1 = getUniqueListBy(question, "question");
  const navigate = useNavigate();

  const onClickPrevious = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");
    setVisited(param1 - 1);
    const remainingTime = timerrr();

    await API.postTraineeOnclick(optionsOpted, param, id, remainingTime)
      .then(({ response }) => {
        // "here, if you click previous you will get previous qstn option along with selected option"

        // setOptionsOpted('');
        setQuestIndex(questIndex - 1);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log("error", error));
    await API.putTraineeOnclick(question[param1 - 1].question_pk, id)
      .then(({ response }) => {
        setOptionsOpted(response.data[0].user_option);
        setQuestIndex(questIndex - 1);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log("error", error));
    setShow(false);
  };

  const onClickNext = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");
    setVisited(param1 + 1);
    const remainingTime = timerrr();
    await API.postTraineeOnClickNext(optionsOpted, param, id, remainingTime)
      .then(({ response }) => {
        // setOptionsOpted('');
        setQuestIndex(questIndex + 1);
        if (response.api_status === 200) {
          nextQuestion(question[param1 + 1].question_pk, id);
          setShow(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const nextQuestion = async (question_pk, id) => {
    await API.putTraineeOnClickNext(question_pk, id)
      .then(({ response }) => {
        if (response.api_status === 200) {
          setOptionsOpted(response.data[0].user_option);
          setQuestIndex(questIndex + 1);
        } else if (response.api_status === 404) {
          setShow(false);
        }
        setShow(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handleClickOpenDelModal = (e, id, param) => {
    setShow(false);
    setOpenModal(true);
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const onClickSubmit = async (id, param) => {
    Cookies.remove("quizdata");
    apiCall(id, param);
  };

  const apiCall = async (id, param) => {
    const remainingTime = timerrr();
    await API.postTraineeAPICall(optionsOpted, param, id, remainingTime)
      .then(({ response }) => {
        const ResultsOfQuiz = async (id) => {
          const response = await API.getTraineeQuizResults(id);
          setOpenModal(false);
          let result = response.response.data.data[0];
          setResults(result);
          setDone(true);
          result.topic = location.state.topic;
          result.id = location.state.schid;
          setShow(false);
          // navigate("/trainee/home/quizResult", { state: { result } });
          navigate("/user/bootcamp/home/quizResult", { state: { result } });
        };
        ResultsOfQuiz(id);
      })
      .catch((error) => console.log("error", error));
  };
  const handleQClick = async (index, e, id, param1, param) => {
    setOptionsOpted("");
    setVisited(index);
    e.preventDefault();
    const remainingTime= timerrr();
    await API.postTraineehandleClik(
      optionsOpted,
      question[visited].question_pk,
      id,
      remainingTime
    )
      .then(({ response }) => {
        setOptionsOpted("");
        setQuestIndex(index);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log("error", error));
    await API.putTraineehandleClik(question[index].question_pk, id)
      .then(({ response }) => {
        setOptionsOpted(response.data[0].user_option);
        setQuestIndex(index);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log("error", error));
    setShow(false);
  };
  return (
    <>
      {/* <script type = "text/javascript" >  
    {
    `function preventBack() { window.history.forward(); }  
    
    setTimeout("preventBack()", 0)  
    window.onunload =  () => { null }`
    }

</script>  */}
      {/* <script type="text/javascript">
          {`window.onbeforeunload = function() {
    return "Dude, are you sure you want to leave? Think of the kittens!";
}`}
        </script> */}
      {/* <Helmet>     
      <script>
{`window.onbeforeunload = function (e) {
    e = e || window.event;

    // For IE and Firefox prior to version 4
    if (e) {
        e.returnValue = 'Sure?';
    }

    // For Safari
    return 'Sure?';
};`}
</script>
</Helmet> */}
      {showRes({ res })}
      {res ? (
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "white",
            padding: "0px !important",
            height: "30vh",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                {errorStatus ? (
                  <b>Quiz has been submitted!! </b>
                ) : (
                  <b>Questions Not Available At The Moment. </b>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                {errorStatus ? (
                  <b>Please check back for results!!</b>
                ) : (
                  <b>Please Try Again After Sometime. </b>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <span onClick={removeQuizDataCookie}>
                  <ReusableButton
                    size="large"
                    variant="outlined"
                    className="candidateDltepageCancelBttn"
                    buttonName="Close"
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAalign: "center",
                      color: "#000936",
                      border: "1px solid #000936",
                      padding: "7px 20px",
                      borderRadius: "4px",
                      marginRight: "8px",
                      // marginBottom:"25px",
                      textTransform: "none",
                    }}
                    //  onClick={(e) =>
                    //  handleHome(e, location.state.data.dp_sub_caty_pk)
                    //  }
                  />
                </span>
              </Grid>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          {show ? (
            <>
              <Grid
                item
                xs={9.5}
                style={{
                  backgroundColor: "white",
                  padding: "0px !important",
                  minHeight: "65vh",

                  borderBottomLeftRadius: "10px",
                }}
              >
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50vh",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#fb2485"}
                    height={100}
                    width={100}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={2.5}
                style={{
                  backgroundColor: "#02215d",
                  padding: "0px !important",
                  minHeight: "65vh",
                  borderBottomRightRadius: "10px",
                  overflow: "auto",
                }}
              >
                <div className="containerOfOptions2">
                  {arr1.map((data, index) => {
                    return (
                      <>
                        <Button
                          className="rect1"
                          size="small"
                          onClick={(e) => {
                            handleQClick(
                              index,
                              e,
                              quizId,
                              questIndex,
                              data.question_pk
                            );
                            setShow(true);
                          }}
                        >
                          <QuizLayout
                            qnum={index + 1}
                            color={visited === index ? "#fb2485" : null}
                            ans={answered.includes(index) ? true : false}
                            visit={index == visited ? true : false}
                          />
                        </Button>
                      </>
                    );
                  })}
                </div>
              </Grid>
            </>
          ) : (
            <>
              {callback({ start })}
              {arr1.map((data, index) => {
                const questionname = data.question;
                const questionType = data?.language_type;
                data.options.selectedOption = data.userOption;
                data.options.questPk = data.question_pk;
                // eslint-disable-next-line no-lone-blocks
                {
                  if (index === questIndex) {
                    return (
                      <>
                        <Grid
                          item
                          xs={9.5}
                          style={{
                            backgroundColor: "white",
                            padding: "0px !important",
                            // minHeight: "65vh",
                            marginBottom: "5%",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                            }}
                          >
                            <Grid item xs={11}>
                              {questionType === "Others" ||
                              questionType === null ||
                              questionType === "" ? (
                                <b> {questionname.replace(/�/g, " ")} </b>
                              ) : (
                                <ReusableInputfield
                                  rows={6}
                                  disabled
                                  value={CodeFormatter(
                                    questionname,
                                    questionType
                                  )}
                                  multiline
                                  InputProps={{
                                    inputComponent: TextareaAutosize,
                                    inputProps: {
                                      style: {
                                        resize: "auto",
                                        height: "100%",
                                        width: "63vw",
                                        overflow:"visible",
                                        "-webkit-text-fill-color": "black",
                                      },
                                    },
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>

                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={optionsOpted}
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                          >
                            {[data.options].map((opt) => {
                              return (
                                <>
                                  {opt.a.a && opt.a.a !== "null" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Grid item xs={0.5}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.a.id}
                                            control={<Radio />}
                                            label={opt.a.a.replace(/�/g, " ")}
                                          />
                                        </Grid>
                                        <Grid item xs={0.5}></Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                  )}
                                  {opt.b.b && opt.b.b !== "null" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Grid item xs={0.5}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.b.id}
                                            control={<Radio />}
                                            label={opt.b.b.replace(/�/g, " ")}
                                          />
                                        </Grid>
                                        <Grid item xs={0.5}></Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                  )}
                                  {opt.c && opt.c.c && opt.c.c !== "null" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Grid item xs={0.5}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.c.id}
                                            control={<Radio />}
                                            label={opt.c.c.replace(/�/g, " ")}
                                          />
                                        </Grid>
                                        <Grid item xs={0.5}></Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                  )}
                                  {opt.d && opt.d.d && opt.d.d !== "null" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Grid item xs={0.5}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.d.id}
                                            control={<Radio />}
                                            label={opt.d.d.replace(/�/g, " ")}
                                          />
                                        </Grid>
                                        <Grid item xs={0.5}></Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                  )}
                                  {/* <Grid item xs={12} style={{display:'flex',justifyContent:'start'}}><FormControlLabel value="fe\male" control={<Radio />} label={opt.b} /></Grid>
<Grid item xs={12} style={{display:'flex',justifyContent:'start'}}><FormControlLabel value="fe'male" control={<Radio />} label={opt.c} /></Grid>
<Grid item xs={12} style={{display:'flex',justifyContent:'start'}}><FormControlLabel value="felmale" control={<Radio />} label={opt.d} /></Grid> */}
                                </>
                              );
                            })}
                          </RadioGroup>

                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <Grid item xs={1}></Grid>
                            {/* <Link
                              to="/user/home/quizSelection"
                              state={{ data: location.state.data }}
                              style={{ textDecoration: 'none' }}
                            >
                              <ReusableButton
                                size="large"
                                variant="outlined"
                                className="candidateDltepageCancelBttn"
                                buttonName="Cancel"
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                  fontStretch: 'normal',
                                  fontStyle: 'normal',
                                  lineHeight: 'normal',
                                  letterSpacing: 'normal',
                                  textAalign: 'center',
                                  color: '#000936',
                                  border: '1px solid #000936',
                                  padding: '7px 20px',
                                  borderRadius: '4px',
                                  marginRight: '8px',
                                  width: '98px',
                                  height: '44px',
                                  marginTop:"24px",
                                  // marginBottom:"25px",
                                  textTransform: 'none',
                                }}
                                //   onClick={handleCloseByCancelModal}
                              />
                            </Link> */}
                            <Grid item xs={6}></Grid>

                            <Grid item xs={1}>
                              {index === 0 ? null : (
                                <ReusableButton
                                  size="large"
                                  variant="outlined"
                                  className="candidateDltepageCancelBttn"
                                  buttonName="Previous"
                                  style={{
                                    width: "98px",
                                    height: "44px",
                                    flexGrow: 0,
                                    margin: "24px 50px 0 20px",
                                    padding: "13px 32px 13px 33px",
                                    // marginBottom:"25px",
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAalign: "center",
                                    color: "#000936",
                                    padding: "8px 12px",
                                    borderRadius: "4px",
                                    textTransform: "none",
                                    border: "1px solid #000936",
                                  }}
                                  // onClick={()=>setQuestIndex(questIndex-1)}
                                  onClick={(e) => {
                                    onClickPrevious(
                                      e,
                                      quizId,
                                      questIndex,
                                      data.question_pk
                                    );
                                    setShow(true);
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={0.8}></Grid>
                            <Grid item xs={1}>
                              {index + 1 < arr1.length ? (
                                <ReusableButton
                                  size="large"
                                  variant="contained"
                                  className="candidateDltepageDeleteBttn"
                                  buttonName="Next"
                                  style={{
                                    backgroundColor: "#fb2485",
                                    width: "98px",
                                    height: "44px",
                                    flexGrow: 0,
                                    margin: "24px 50px 0 20px",
                                    padding: "13px 32px 13px 33px",
                                    // marginBottom:"25px",
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAalign: "center",
                                    color: "#fff",
                                    padding: "8px 28px",
                                    borderRadius: "4px",
                                    textTransform: "none",
                                  }}
                                  onClick={(e) => {
                                    onClickNext(
                                      e,
                                      quizId,
                                      questIndex,
                                      data.question_pk
                                    );
                                    setShow(true);
                                  }}
                                ></ReusableButton>
                              ) : (
                                <>
                                  {/* // <Link
                                //   to="/user/home/quizResult"
                                //   state={{ data: location.state.data }}
                                //   style={{ textDecoration: 'none' }}
                                // > */}
                                  <ReusableButton
                                    size="large"
                                    variant="contained"
                                    className="candidateDltepageDeleteBttn"
                                    buttonName="Submit"
                                    style={{
                                      backgroundColor: "#fb2485",
                                      width: "98px",
                                      height: "44px",
                                      flexGrow: 0,
                                      margin: "27px 50px 0 20px",
                                      padding: "13px 32px 13px 33px",
                                      // marginBottom:"25px",
                                      fontSize: "16px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      lineHeight: "normal",
                                      letterSpacing: "normal",
                                      textAalign: "center",
                                      color: "#fff",
                                      padding: "8px 28px",
                                      borderRadius: "4px",
                                      textTransform: "none",
                                    }}
                                    //    onClick={()=>setQuestIndex(questIndex+1)}
                                    // onClick={(e) => {
                                    //   onClickSubmit(
                                    //     e,
                                    //     userQuizId,
                                    //     data.question_pk
                                    //   );
                                    //   setShow(true);
                                    // }}
                                    onClick={(e) => {
                                      handleClickOpenDelModal(
                                        e,
                                        userQuizId,
                                        data.question_pk
                                      );
                                      // setShow(true);
                                    }}
                                  ></ReusableButton>

                                  {/* </Link> */}
                                  <Dialog
                                    open={openModal}
                                    className="dialogBox"
                                    // TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleCloseByCancelModal}
                                    aria-describedby="alert-dialog-slide-description"
                                    BackdropProps={{
                                      style: {
                                        backgroundColor: "black",
                                        opacity: "0.5",
                                        boxShadow: "0px 0px 0px 0px !important",
                                      },
                                    }}
                                    //BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                  >
                                    <DialogContent
                                    //  BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                    >
                                      <DialogContentText
                                        id="alert-dialog-slide-description"
                                        style={{ color: "#000" }}
                                      >
                                        Are you sure you want to submit the
                                        quiz?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingBottom: "30px",
                                      }}
                                    >
                                      <ReusableButton
                                        size="large"
                                        variant="outlined"
                                        className="candidateDltepageCancelBttn"
                                        buttonName="CANCEL"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "normal",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          textAalign: "center",
                                          color: "#000936",
                                          border: "1px solid #000936",
                                          padding: "7px 20px",
                                          borderRadius: "4px",
                                          marginRight: "8px",
                                          // marginBottom:"25px",
                                          // textTransform: "none",
                                        }}
                                        onClick={handleCloseByCancelModal}
                                      />
                                      <ReusableButton
                                        size="large"
                                        variant="contained"
                                        className="candidateDltepageDeleteBttn"
                                        buttonName="OK"
                                        style={{
                                          backgroundColor: "#fb2485",
                                          marginRight: "10px",
                                          // marginBottom:"25px",
                                          fontSize: "16px",
                                          fontWeight: "normal",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          textAalign: "center",
                                          color: "#fff",
                                          padding: "8px 28px",
                                          borderRadius: "4px",
                                          // textTransform: "none",
                                        }}
                                        // onClick={() => handleDelete(id)}
                                        onClick={(e) => {
                                          onClickSubmit(
                                            userQuizId,
                                            data.question_pk
                                          );
                                        }}
                                      >
                                        Submit
                                      </ReusableButton>
                                    </DialogActions>
                                  </Dialog>
                                </>
                              )}
                            </Grid>
                            {/* <Grid item xs={1}></Grid> */}
                          </Grid>
                        </Grid>
                        {show ? (
                          <Grid
                            item
                            xs={9.5}
                            style={{
                              backgroundColor: "white",
                              padding: "0px !important",
                              minHeight: "65vh",

                              borderBottomLeftRadius: "10px",
                            }}
                          >
                            <Box
                              sx={{ flexGrow: 1 }}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "50vh",
                              }}
                            >
                              <ReactLoading
                                type={"spinningBubbles"}
                                color={"#fb2485"}
                                height={100}
                                width={100}
                              />
                            </Box>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs={2.5}
                          style={{
                            backgroundColor: "#02215d",
                            padding: "0px !important",
                            // minHeight: "65vh",
                            // overflow: "auto",
                            marginBottom: "5%",
                          }}
                        >
                          <div className="containerOfOptions2">
                            {arr1.map((data, index) => {
                              return (
                                <>
                                  <Button
                                    className="rect1"
                                    size="small"
                                    onClick={(e) => {
                                      handleQClick(
                                        index,
                                        e,
                                        quizId,
                                        questIndex,
                                        data.question_pk
                                      );
                                      setShow(true);
                                    }}
                                  >
                                    <QuizLayout
                                      qnum={index + 1}
                                      color={
                                        visited === index ? "#fb2485" : null
                                      }
                                      ans={
                                        answered.includes(index) ? true : false
                                      }
                                      visit={index == visited ? true : false}
                                    />
                                  </Button>
                                </>
                              );
                            })}
                          </div>
                        </Grid>
                      </>
                    );
                  }
                }
              })}
            </>
          )}
        </>
      )}
      <Dialog
        open={showModal}
        BackdropProps={{
          style: {
            backgroundColor: "black",
            opacity: "0.5",
            boxShadow: "0px 0px 0px 0px !important",
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            Quiz Timed Out!! Please Click ok to Submit
          </DialogContentText>
          <DialogActions>
            <Link
              to="/user/bootcamp/home/quizResult"
              // state={{ data: location.state.data }}
              style={{ textDecoration: "none" }}
            >
              <ReusableButton
                size="large"
                variant="contained"
                className="candidateDltepageDeleteBttn"
                buttonName="OK"
                style={{
                  backgroundColor: "#fb2485",
                  marginRight: "10px",
                  // marginBottom:"25px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#fff",
                  padding: "8px 28px",
                  borderRadius: "4px",
                  textTransform: "none",
                }}
                //    onClick={()=>setQuestIndex(questIndex+1)}
                onClick={(e) => {
                  setShowModal(false);

                  onClickSubmit(userQuizId, question[visited].question_pk);
                  setShow(true);
                }}
              ></ReusableButton>
            </Link>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
      // open={showModal}
      >
        <DialogContent>
          <DialogContentText>Test is Submitted due to reload</DialogContentText>
          <DialogActions>
            <Link
              to="/user/bootcamp/home/quizResult"
              state={{ data: location.state.data }}
              style={{ textDecoration: "none" }}
            >
              <ReusableButton
                size="large"
                variant="contained"
                className="candidateDltepageDeleteBttn"
                buttonName="OK"
                style={{
                  backgroundColor: "#fb2485",
                  marginRight: "10px",
                  // marginBottom:"25px",
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAalign: "center",
                  color: "#fff",
                  padding: "8px 28px",
                  borderRadius: "4px",
                  textTransform: "none",
                }}
                //    onClick={()=>setQuestIndex(questIndex+1)}
                onClick={(e) => {
                  setShowModal(false);

                  onClickSubmit(userQuizId, question[visited].question_pk);
                  setShow(true);
                }}
              ></ReusableButton>
            </Link>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* <Prompt
          when={isPrompt()}
          message={() => 'Are you sure you want to leave this page?'}
        /> */}
    </>
  );
}

export default Questions;
